<div class="treeDamagesEditHeader">Kontrollmeldungen Anlegen/Bearbeiten</div>
<div class="formWrapper" [formGroup]="treeDamagesForm">
  <mat-form-field floatLabel="always" appearance="fill">
    <mat-label>Meldungsart</mat-label>
    <mat-select
      formControlName="okay"
      [value]="0"
      disableOptionCentering
      panelClass="disableOptionCentering"
    >
      <mat-option [value]="2">Schadensmeldung</mat-option>
      <mat-option [value]="1">i.O.-Meldung</mat-option>
    </mat-select>
  </mat-form-field>

  <mat-form-field floatLabel="always" appearance="fill">
    <mat-label>Erfasser</mat-label>
    <input matInput formControlName="recorder" />
    <button
      *ngIf="treeDamagesForm.get('recorder').value"
      matSuffix
      mat-icon-button
      aria-label="Clear"
      (click)="this.treeDamagesForm.patchValue({ recorder: '' })"
    >
      <mat-icon>close</mat-icon>
    </button>
  </mat-form-field>

  <mat-form-field floatLabel="always" appearance="fill">
    <mat-label>{{ SchadensdatumLabel }}</mat-label>
    <input
      #damageDateInput
      matInput
      [matDatepicker]="damage_date"
      (dateChange)="setDate('damageDate', $event.value)"
      readonly
    />
    <mat-datepicker-toggle
      matSuffix
      [for]="damage_date"
    ></mat-datepicker-toggle>
    <mat-datepicker
      type="datetime"
      [twelveHour]="false"
      #damage_date
    ></mat-datepicker>
  </mat-form-field>

  <mat-form-field floatLabel="always" appearance="fill">
    <mat-label>Schadensende</mat-label>
    <input
      #damageEndInput
      matInput
      [matDatepicker]="damage_end"
      (dateChange)="setDate('damageEnd', $event.value)"
      [disabled]="disableDate"
      readonly
    />
    <mat-datepicker-toggle matSuffix [for]="damage_end"></mat-datepicker-toggle>
    <mat-datepicker
      type="datetime"
      [twelveHour]="false"
      #damage_end
    ></mat-datepicker>
  </mat-form-field>

  <mat-form-field floatLabel="always" appearance="fill">
    <mat-label>Baumorgan</mat-label>
    <mat-select
      formControlName="tree_part"
      [value]="0"
      disableOptionCentering
      panelClass="disableOptionCentering"
    >
      <mat-option
        *ngFor="let treePart of defaultValues['BSC_Baumteil']"
        [value]="treePart['value']"
        >{{ treePart["value"] }}</mat-option
      >
    </mat-select>
  </mat-form-field>

  <mat-form-field floatLabel="always" appearance="fill">
    <mat-label>Position</mat-label>
    <mat-select
      formControlName="position"
      [value]="0"
      disableOptionCentering
      panelClass="disableOptionCentering"
    >
      <mat-option
        *ngFor="let position of defaultValues['BSC_Position']"
        [value]="position['value']"
        >{{ position["value"] }}</mat-option
      >
    </mat-select>
  </mat-form-field>

  <mat-form-field floatLabel="always" appearance="fill">
    <mat-label>Schaden</mat-label>
    <input
      #treeDamageListingInput
      type="text"
      matInput
      formControlName="tree_damage_listing"
      [matAutocomplete]="auto"
    />
    <mat-error>Kein gültiger Schaden!</mat-error>
    <button
      *ngIf="treeDamagesForm.get('tree_damage_listing').value"
      matSuffix
      mat-icon-button
      aria-label="Clear"
      (click)="this.treeDamagesForm.patchValue({ tree_damage_listing: '' }); $event.stopPropagation(); treeDamageListingInput.click(); "
    >
      <mat-icon>close</mat-icon>
    </button>
    <mat-autocomplete
      autoActiveFirstOption
      #auto="matAutocomplete"
      [displayWith]="setAutocompleteDisplay"
    >
      <mat-option
        *ngFor="
          let treeDamageListing of filteredTreeDamageListingsOptions | async
        "
        [value]="treeDamageListing"
      >
        <span>
          <span>[{{ treeDamageListing.number }}] </span
          >{{ treeDamageListing.damage_type }}
        </span>
      </mat-option>
    </mat-autocomplete>
  </mat-form-field>

  <mat-form-field floatLabel="always" appearance="fill">
    <mat-label>Bemerkung</mat-label>
    <textarea matInput formControlName="note"></textarea>
    <button
      *ngIf="treeDamagesForm.get('note').value"
      matSuffix
      mat-icon-button
      aria-label="Clear"
      (click)="this.treeDamagesForm.patchValue({ note: '' });"
    >
      <mat-icon>close</mat-icon>
    </button>
  </mat-form-field>
</div>
