// this is the new graphQl service
// this service can be renamed to "GraphQLService" when the old service ("graphql.service.ts") is no longer needed and has been removed

import { Injectable } from "@angular/core";
import { Apollo, Query, QueryRef } from "apollo-angular";
import { DocumentNode } from "graphql";
import { dataQueries } from "../gql-queries/query-register.module";

@Injectable({
  providedIn: "root",
})
export class NewGraphQLService {
  public graphqlObject: string;

  constructor(private apollo: Apollo) {}

  /**
   *
   * @param graphqlObject string to identify querys (see query-register.module.ts)
   * @param variables Query variables ({offset, limit, args{sort, filter}})
   * @returns returns a query reference (can be used for loadmoreData methode)
   */
  requestAllData(
    graphqlObject: string,
    variables?: {
      offset?: number;
      limit?: number;
      args?: {
        sort?: {
          column: string[];
          direction: string;
        };
        filter: {
          address?: {
            location: string;
            district: string;
            street: string;
          }[];
          id?: {column: string, value: number}
        };
      };
    }
  ) {
    console.log(variables ? "requestFilteredData (graphQL)" : "requestAllData (graphQL)", graphqlObject, variables);
    let queryRef: QueryRef<Query> = this.apollo.watchQuery({
      query: dataQueries[graphqlObject].getAll,
      variables: variables,
    });

    return queryRef;
  }

  /**
   *
   * @param queryRef reference of the query that should load more Data
   * @param offset current length of the query result
   */
  loadmoreData(queryRef: QueryRef<Query>, offset: number) {
    queryRef.fetchMore({
      variables: {
        offset: offset,
      },
    });
  }

  getSingleData(graphqlObject: string, variables: {id: number}) {
    console.log("requestSingleData (graphQL)", graphqlObject);
    let queryRef: QueryRef<Query> = this.apollo.watchQuery({
      query: dataQueries[graphqlObject].getSingle,
      variables: variables,
    });

    return queryRef;
  }

  /**
   *
   * @param graphqlObject string to identify querys (see query-register.module.ts)
   * @param data object with data (object similar to graphql-schema object)
   * @param refreshQuerieVariables variables of the refresh query
   * @param refetch whether query should be updated (default true)
   * @returns returns a query reference
   */
  addData(
    graphqlObject: string,
    data: object,
    refreshQuerieVariables?: object,
    refetch: boolean = true
  ) {
    let queryRef = this.apollo.mutate({
      mutation: dataQueries[graphqlObject].add,
      variables: data,
      refetchQueries: refetch
        ? [
            {
              query: dataQueries[graphqlObject].getAll,
              variables: refreshQuerieVariables,
            },
          ]
        : null,
    });
    return queryRef;
  }

  /**
   *
   * @param graphqlObject string to identify querys (see query-register.module.ts)
   * @param data object with data (object similar to graphql-schema object)
   * @param refreshQuerieVariables variables of the refresh query
   * @param refetch whether query should be updated (default true)
   * @returns returns a query reference
   */
  updateData(
    graphqlObject: string,
    data: object,
    refreshQuerieVariables?: object,
    refetch: boolean = true
  ) {
    let queryRef = this.apollo.mutate({
      mutation: dataQueries[graphqlObject].update,
      variables: data,
      refetchQueries: refetch
        ? [
            {
              query: dataQueries[graphqlObject].getAll,
              variables: refreshQuerieVariables,
            },
          ]
        : null,
    });
    return queryRef;
  }

  /**
   *
   * @param graphqlObject string to identify querys (see query-register.module.ts)
   * @param id id of the record to be deleted
   * @param refreshQuerieVariables variables of the refresh query
   * @param refetch whether query should be updated (default true)
   * @returns returns a query reference
   */
  deleteData(
    graphqlObject: string,
    id: number,
    refreshQuerieVariables?: object,
    refetch: boolean = true
  ) {
    let queryRef = this.apollo.mutate({
      mutation: dataQueries[graphqlObject].delete,
      variables: { id: id },
      refetchQueries: refetch
        ? [
            {
              query: dataQueries[graphqlObject].getAll,
              variables: refreshQuerieVariables,
            },
          ]
        : null,
    });
    return queryRef;
  }

  getSpecialData(dataQuery: DocumentNode, queryVariables: object){
    console.log("requestSpecialData (graphQL)")
    return this.apollo.watchQuery({
      query: dataQuery,
      variables: queryVariables,
    });
  }
}
