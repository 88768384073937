import { NgModule } from "@angular/core";
import { AlphabeticalScrollbarComponent } from "./alphabetical-scrollbar.component";

@NgModule({
    declarations: [
        AlphabeticalScrollbarComponent
    ],
    imports: [],
    exports: [
        AlphabeticalScrollbarComponent
    ],
  })
  export class AlphabeticalScrollbarModule {}