import { Component, OnInit, AfterContentInit } from "@angular/core";

import { GraphqlService } from "../../services/graphql.service";
import { Apollo } from "apollo-angular";
import gql from "graphql-tag";

import { Map } from "ol";
import XYZ from "ol/source/XYZ";
import TileLayer from "ol/layer/Tile";
import View from "ol/View";
import { Heatmap } from "ol/layer";
import KML from "ol/format/KML";
import VectorSource from "ol/source/Vector";
import {
  ZoomSlider,
  ScaleLine,
  ZoomToExtent,
  Rotate,
  OverviewMap,
  MousePosition,
  FullScreen
} from "ol/control";
import Point from "ol/geom/Point";
import Feature from "ol/Feature";
import { fromLonLat } from "ol/proj";
import { Vector } from "ol/source";
import VectorLayer from "ol/layer/Vector";
import LineString from "ol/geom/LineString";
import { Style, Fill, Stroke, Text, Circle } from "ol/style";
import CircleStyle from "ol/style/Circle";
import Overlay from "ol/Overlay";
import { createStringXY, format } from "ol/coordinate";

import { DisplayGPSonMapObject } from "./displayGPSonMapObject";
import { HttpParams, HttpClient, HttpHeaders } from '@angular/common/http';


const getAllData = gql`
  query GPS_Data_Query($device: Int!) {
    GPS_Data_Query(device: $device) {
      gps_device
      longitude
      latitude
      timestamp
    }
  }
`;

const getAllKFZData = gql`
  query GPS_Device_Vehicle {
    GPS_Device_Vehicle {
      vehicle
      vehicleType
      vehicleLabel
      device
    }
  }
`;

@Component({
  selector: "app-fahrzeugortung",
  templateUrl: "./fahrzeugortung.component.html",
  styleUrls: ["./fahrzeugortung.component.sass"]
})
export class FahrzeugortungComponent implements OnInit, AfterContentInit {
  public map: Map;
  public source: XYZ;
  public layer: any; //TileLayer;
  public view: View;

  allVehicles = {};
  mapObjects = [];

  xCoord = 11.418946666667;
  yCoord = 53.601618333333;
  zoomFactor = 15;

  textTemplate: "Coords: ({x}:{y})";

  constructor(private apollo: Apollo, private http: HttpClient) {}

  ngOnInit() {
    this.source = new XYZ({
      url: "https://www.aidageo.de/hot/{z}/{x}/{y}.png"
    });
    //url: 'http://www.aidageo.de/hot/{z}/{x}/{y}.png'
    //url: 'http://tile.osm.org/{z}/{x}/{y}.png'

    this.layer = new TileLayer({
      source: this.source
    });

    this.view = new View({
      center: fromLonLat([this.xCoord, this.yCoord]),
      zoom: this.zoomFactor
    });

    this.map = new Map({
      target: "map",
      layers: [this.layer],
      view: this.view
    });

    var zoomslider = new ZoomSlider();
    this.map.addControl(zoomslider);

    var scaleline = new ScaleLine();
    this.map.addControl(scaleline);

    var zoomToExtent = new ZoomToExtent();
    this.map.addControl(zoomToExtent);

    var rotate = new Rotate();
    this.map.addControl(rotate);
    /*
    var overviewMap = new OverviewMap();
    this.map.addControl(overviewMap);
*/
    /*
    var mousePosition = new MousePosition();
    this.map.addControl(mousePosition);
*/

    // var fullScreen = new FullScreen();
    // this.map.addControl(fullScreen);

    console.log("läö");
    this.apollo
      .watchQuery({
        query: getAllKFZData
      })
      .valueChanges.subscribe(result => {
        console.log(result.data);

        this.allVehicles = result.data["GPS_Device_Vehicle"];

        for (let i = 0; i < result.data["GPS_Device_Vehicle"].length; i++) {
          if (result.data["GPS_Device_Vehicle"][i].device !== null) {
            console.log(
              "Erstelle device: " + result.data["GPS_Device_Vehicle"][i].device
            );
            this.mapObjects[i] = new DisplayGPSonMapObject(
              this.map,
              this.apollo,
              getAllData,
              result.data["GPS_Device_Vehicle"][i].device,
              result.data["GPS_Device_Vehicle"][i].vehicle,
              this.http
            );
          }
        }
      });
  }

  ngAfterContentInit() {
    console.log(this.mapObjects);
    console.log(this.mapObjects[1]);
    console.log(this.mapObjects.length);

    //this.mapObjects[1].getTimeStatus(); TODO: Fix die Scheiße hier. Das mapObjects Object gibt es zur Laufzeit hier nicht?
  }

  findMapObject(device) {
    for (let i = 0; i < this.mapObjects.length; i++) {
      if (this.mapObjects[i].device === device) {
        return i;
      }
    }
    return null;
  }

  // TODO: Das Panning muss auch wieder entfernt werden,
  // sollte ein zweites Mal geklickt werden.
  panView(device) {
    for (let i = 0; i < this.mapObjects.length; i++) {
      if (this.mapObjects[i].device === device) {
        this.mapObjects[i].panViewTo(true);
      } else {
        this.mapObjects[i].panViewTo(false);
      }
    }
  }

  toggleShowVehicle(device) {
    let toggleDevice = this.findMapObject(device);
    this.mapObjects[toggleDevice].toggleMarker();
  }
}
