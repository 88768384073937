import { NgModule } from "@angular/core";
import { GlobalModulesModule } from "src/app/global-modules.module";
import { PdfComponent } from "./pdf.component";

@NgModule({
    declarations: [
        PdfComponent
    ],
    imports: [GlobalModulesModule],
    exports: [
        PdfComponent
    ],
  })
  export class pdfModule {}