<div class="content-rows">
    <div class="content-frame">
        <div class="content-container has-main-content-padding">

<!-- <div id="top-bar">
    <img src="assets/img/geoDummy-logo.png">
</div> -->

<div id="map-background"></div>
<div id="map" class="map">

</div>
<table class="table is-hoverable is-striped is-narrow info-table">
    <tr>
        <th>Anzeigen</th>
        <th>Status</th>
        <th>KFZ</th>
        <th>Bez./Fahrer</th>
        <th>Folgen</th>
    </tr>
    
    <ng-container *ngFor="let entry of allVehicles | keyvalue">
        <ng-container *ngIf="entry.value.vehicle || entry.value.vehicleLabel">
            <tr>
                <td class="show-vehicle">
                    <label></label>
                    <input type="checkbox" checked (change)="toggleShowVehicle(entry.value.device)">
                    <span class="vehicle-checkbox"></span>
                </td>
                <td class="vehicle-status">
                    <!--
                    <ng-container [ngSwitch]="markerStatus">
                        <div *ngSwitchCase="1"><span class="status-marker marker-status-online"></span>S</div>
                        <div *ngSwitchDefault><span class="status-marker marker-status-offline"></span>T</div>
                    </ng-container>
                    -->
                    <div class="status-marker marker-status-online"></div>
                </td>
                <td>{{entry.value.vehicle}}</td>
                <td>{{entry.value.vehicleLabel}}</td>
                <td class="follow-vehicle"><div class="follow-vehicle-button" (click)="panView(entry.value.device)">
                    <svg style="width:24px;height:24px" viewBox="0 0 24 24">
                        <path fill="currentColor" d="M12,9A3,3 0 0,1 15,12A3,3 0 0,1 12,15A3,3 0 0,1 9,12A3,3 0 0,1 12,9M12,4.5C17,4.5 21.27,7.61 23,12C21.27,16.39 17,19.5 12,19.5C7,19.5 2.73,16.39 1,12C2.73,7.61 7,4.5 12,4.5M3.18,12C4.83,15.36 8.24,17.5 12,17.5C15.76,17.5 19.17,15.36 20.82,12C19.17,8.64 15.76,6.5 12,6.5C8.24,6.5 4.83,8.64 3.18,12Z" />
                    </svg>
                </div></td>
            </tr>
        </ng-container>
    </ng-container>
</table>
</div>
</div>
</div>
