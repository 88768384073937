// import { Injectable, OnDestroy, Optional } from "@angular/core";
// import { BehaviorSubject, combineLatest } from "rxjs";
// import { GraphqlService } from "../graphql.service";
// import { DataobjectCoordinate } from "../../baseclasses/dataobject-generic/dataobject-coordinate";

// @Injectable({
//   providedIn: 'root'
// })
// export class CoordinateService {

//   private _coordinateObject;

//   private _coordinateSubscription;
//   private _coordinateSubject: BehaviorSubject<object>;

//   constructor(private graphQl: GraphqlService,@Optional() private singleItem?: Boolean, @Optional() private itemID?: Number, @Optional() private onlyAddEdit?: Boolean) {

//     this._coordinateSubject = new BehaviorSubject<object>([]);
//     this._coordinateObject = new DataobjectCoordinate(this.graphQl, this.singleItem, this.itemID, null, null, this.onlyAddEdit);

//     this._coordinateSubscription = this._coordinateObject.dataObserver.subscribe( result => {
//       this._coordinateSubject.next(result);
//     });
//    }

//    ngOnDestroy() {
//     console.log("destroying");
//     this._coordinateSubscription.unsubscribe();
//   }

//   get coordinate() {
//     return this._coordinateSubject;
//   }

//   addCoordinate(values) {
//     let state = this._coordinateObject.baseObject.addData(values);
//     return state;
//   }

//   updateCoordinate(values) {
//     this._coordinateObject.baseObject.updateData(values);
//   }

//   deleteCoordinate(value) {
//     this._coordinateObject.baseObject.deleteData(value);
//   }
// }

import { Injectable } from "@angular/core";
import { Query, QueryRef } from "apollo-angular";
import { Observable } from "rxjs";
import { first, map } from "rxjs/operators";
import { deleteCoordinate } from "src/app/gql-queries/generic-queries/coordinate-queries.module";
import { NewGraphQLService } from "../new-graphql.service";

@Injectable({
  providedIn: "root",
})
export class CoordinateService {
  private graphqlObject: string = "coordinate";
  private queryRef: QueryRef<Query>;
  private coordinateDataObservable: Observable<any>;

  private queryVariables = {
    limit: 10000,
    offset: 27700,
    args: {
      sort: {
        column: [
          "address.country",
          "address.location",
          "address.district",
          "address.street",
          "address.house_number",
        ],
        direction: "ASC",
      },
      filter: null,
    },
  };

  constructor(private newGraphQlService: NewGraphQLService) {}

  getAllCoordinateData() {
    // create only new stream if not already exist
    if (!this.coordinateDataObservable) {
      this.queryRef = this.newGraphQlService.requestAllData(
        this.graphqlObject,
        this.queryVariables,
      );

      this.coordinateDataObservable = this.queryRef.valueChanges.pipe(
        map((result: any) => {
          return result.data.Coordinate;
        })
      );
    }

    return this.coordinateDataObservable;
  }

  getSingleCordinateData(coordinateId) {
    let queryRef = this.newGraphQlService.getSingleData(this.graphqlObject, {
      id: coordinateId,
    });
    return queryRef.valueChanges.pipe(
      first(),
      map((result: any) => {
        return result.data.Coordinate.length == 1
          ? result.data.Coordinate[0]
          : result.data.Coordinate;
      })
    );
  }

  addCoordinateData(coordinateData: object) {
    let queryRef = this.newGraphQlService.addData(
      this.graphqlObject,
      coordinateData
    );

    return queryRef.pipe(
      first(),
      map((result: any) => {
        return result.data.add_Coordinate;
      })
    );
  }

  // updateCoordianteData(coordinateData: object) {}

  deleteCoordinateData(coordinateId: number) {
    let queryRef = this.newGraphQlService.deleteData(
      this.graphqlObject,
      coordinateId,
    );

    return queryRef.pipe(
      first(),
      map((result: any) => {
        return result.data.delete_Coordinate;
      })
    );
  }
}
