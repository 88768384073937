<mat-form-field appearance="fill">
  <mat-label>Choose a year</mat-label>
  <input matInput [matDatepicker]="pyy">
  <mat-datepicker-toggle matSuffix [for]="pyy"></mat-datepicker-toggle>
  <mat-datepicker #pyy type="year" [yearsPerPage]="12" [yearsPerRow]="2">
    <mat-datepicker-actions>
      <button mat-button matDatepickerCancel>Cancel</button>
      <button mat-raised-button color="primary" matDatepickerApply>Apply</button>
    </mat-datepicker-actions>
  </mat-datepicker>
</mat-form-field>

