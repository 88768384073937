import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-pick-inventory-type-dialog',
  templateUrl: './pick-inventory-type-dialog.component.html',
  styleUrls: ['./pick-inventory-type-dialog.component.sass']
})
export class PickInventoryTypeDialogComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
