<button mat-icon-button title="Auf Karte wählen" (click)="editAddress()">
    <mat-icon>place</mat-icon>
</button>

<!-- <div *ngIf="addressData; else addAddress">
    <span (click)="editAddress()">
        <svg class="dialog-symbol-normal is-available-symbol" viewBox="0 0 24 24">
            <path
                d="M6 2C4.9 2 4 2.9 4 4V20C4 21.1 4.9 22 6 22H10V20.1L20 10.1V8L14 2H6M13 3.5L18.5 9H13V3.5M20.1 13C20 13 19.8 13.1 19.7 13.2L18.7 14.2L20.8 16.3L21.8 15.3C22 15.1 22 14.7 21.8 14.5L20.5 13.2C20.4 13.1 20.3 13 20.1 13M18.1 14.8L12 20.9V23H14.1L20.2 16.9L18.1 14.8Z" />
        </svg>
    </span>
</div>
<ng-template #addAddress>
    <span (click)="editAddress()">
        <svg class="dialog-symbol-big is-available-symbol" viewBox="0 0 24 24">
            <path
                d="M13,9H18.5L13,3.5V9M6,2H14L20,8V20A2,2 0 0,1 18,22H6C4.89,22 4,21.1 4,20V4C4,2.89 4.89,2 6,2M11,15V12H9V15H6V17H9V20H11V17H14V15H11Z" />
        </svg>
    </span>
</ng-template> -->