<div class="toolbar">
  <span class="toolbarHeader"
    >Spielplatzübersicht <span class="count">123</span></span
  >
  <div class="toolbarActions">
    <app-dialog-box-trigger
      [dimensions]="{
        width: '60vw',
        height: 'calc(100% - 20vh)'
      }"
      [formComponent]="addEditForm"
      [record]=""
      (submitValue)="addPlayground($event)"
      ><span class="material-symbols-outlined"
        >note_add</span
      ></app-dialog-box-trigger
    >
    <app-dialog-box-trigger
      [dimensions]="{
        width: '60vw',
        height: 'calc(100% - 20vh)'
      }"
      [formComponent]="showOnMap"
      [record]="mapData"
      [noFooter]="true"
      [noPadding]="true"
      (submitValue)="(null)"
      ><span class="material-symbols-outlined"
        >public</span
      ></app-dialog-box-trigger
    >
    <button mat-icon-button>
      <span class="material-symbols-outlined"> bug_report </span>
    </button>
    <button mat-icon-button>
      <span class="material-symbols-outlined"> bug_report </span>
    </button>
    <button mat-icon-button>
      <span class="material-symbols-outlined"> bug_report </span>
    </button>
  </div>
</div>
<div class="mainContent">
  <div #mainView class="mainView">
    <app-column web="12" tablet="12" class="contentContainer">
      <app-row web *ngFor="let entry of playgroundData" class="itemContainer">
        <app-column web="2" tablet="12" class="itemPhoto">
          <app-image-view
            [playgroundID]="entry.id"
            [playgroundPhoto]="entry.photo"
            [photoCount]="entry.photo_count"
          >
          </app-image-view>
        </app-column>
        <app-column web="10" tablet="12">
          <app-row web>
            <app-column web="12" tablet="12" class="itemHeader">
              <div class="itemTitle">
                <span>{{ entry.number }}</span
                >{{ entry.description }}
              </div>
              <div class="itemActions">
                <!-- <app-show-calendar></app-show-calendar> -->
                <!-- <app-pdf></app-pdf> -->
                <!-- <app-show-on-map [mapData]="[entry]"></app-show-on-map> -->
                <!-- <app-edit-spielplaetze-data
                  [playgroundID]="entry.id"
                ></app-edit-spielplaetze-data> -->
                <!-- <app-delete-data></app-delete-data> -->

                <app-dialog-box-trigger
                  [dimensions]="{
                    width: '60vw',
                    height: 'calc(100% - 20vh)'
                  }"
                  [formComponent]="addEditForm"
                  [record]=""
                  (submitValue)="editPlayground($event)"
                  ><span class="material-symbols-outlined"
                    >edit_document</span
                  ></app-dialog-box-trigger
                >

                <button mat-icon-button>
                  <span class="material-symbols-outlined"> bug_report </span>
                </button>
                <button mat-icon-button>
                  <span class="material-symbols-outlined"> bug_report </span>
                </button>
                <button mat-icon-button>
                  <span class="material-symbols-outlined"> bug_report </span>
                </button>
              </div>
            </app-column>
            <app-column web="4" tablet="12" class="itemValues">
              <div class="itemAddress">
                <a class="itemLink"
                  >{{ entry.address.street }} {{ entry.address.house_number }},
                  {{ entry.address.postcode }} {{ entry.address.location }}</a
                >
              </div>
              <div class="itemInfos">
                <ng-container *ngIf="entry.category !== ''">
                  <span>Kategorie</span>{{ entry.category }}<br />
                </ng-container>
                <ng-container *ngIf="entry.inventory_items && entry.inventory_items.length > 0; else noInvItems">
                  <span>Inventar-Anzahl</span>{{ entry.inventory_items.length }}
                </ng-container>
                <ng-template #noInvItems>
                  <span>Inventar-Anzahl</span>0
                </ng-template>
              </div>
              <div class="itemOperation">
                <ng-container
                  *ngIf="entry.commissioning_date !== '0000-00-00 00:00:00'"
                >
                  <div class="">
                    <span>Inbetriebnahme</span>
                    {{ entry.commissioning_date | date : "EE, dd.MM.yyyy" }}
                  </div>
                </ng-container>
                <ng-container
                  *ngIf="entry.decommissioning_date !== '0000-00-00 00:00:00'"
                >
                  <div class="n">
                    <span>Außerbetriebnahme</span>
                    {{ entry.decommissioning_date | date : "EE, dd.MM.yyyy" }}
                  </div>
                </ng-container>
              </div>
            </app-column>
            <app-column web="4" tablet="12" class="itemInspection">
              <app-last-inspection-display
                class="table-flex-container-inspection"
                [playgroundID]="entry.id"
              >
              </app-last-inspection-display>
            </app-column>
            <app-column web="4" tablet="12" class="itemDefects">
              <app-open-defects-display [playgroundID]="entry.id">
              </app-open-defects-display>
            </app-column>
          </app-row>
        </app-column>
      </app-row>
    </app-column>
  </div>
  <div class="locationTree">
    <app-location-tree
      [addressServiceType]="PlaygroundAddressesServiceType"
      (selectedAddresses)="addressFilterSubject.next($event)"
    ></app-location-tree>
  </div>
</div>
