import { Component, OnInit, Input, AfterViewInit } from "@angular/core";
import { MatDialog, MatDialogConfig } from "@angular/material/dialog";
import { PictureDialogComponent } from "src/app/modules/functions/show-pictures/picture-dialog/picture-dialog.component";
import { PlaygroundInspectionPhotosService } from "src/app/services/playground/playground-inspection-photos.service";

@Component({
  selector: "app-inspection-photos",
  templateUrl: "./inspection-photos.component.html",
  styleUrls: ["./inspection-photos.component.sass"]
})
export class InspectionPhotosComponent implements OnInit, AfterViewInit {
  thumbnailID = undefined;
  lastPhotoDate = "-";
  photoDialogIsClickable = false;
  playgroundPhotoArray = [];

  _photoSubscription: any;
  photoIDs: any;
  actualCount = 0;

  takelastTest: any;

  @Input() inspectionID: number;
  @Input() inspectionPhoto: any;
  @Input() photoCount?: number;

  constructor(
    public dialog: MatDialog,
    private photoService: PlaygroundInspectionPhotosService
  ) {}

  ngOnInit(): void {
    if (this.inspectionPhoto) {
      //console.log(this.inspectionPhoto);
      this.thumbnailID = this.inspectionPhoto[0].id;
      this.photoDialogIsClickable = true;

      //console.log(this.inspectionPhoto.length);

      this.actualCount = this.inspectionPhoto.length;
    }
  }

  showPhotoDialog() {
    // console.log("showing inspection photos");

    this.photoService.currentInspection = this.inspectionID;
    let serviceSubscription = this.photoService.currentInspectionPhotos.subscribe(
      result => {
        //console.log(result);
        const dialogConfig = new MatDialogConfig();

        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;
        dialogConfig.minWidth = "calc(100vw - 90px)";
        dialogConfig.minHeight = "calc(100vh - 90px)";

        dialogConfig.data = {
          pictures: result
        };

        const dialogRef = this.dialog.open(
          PictureDialogComponent,
          dialogConfig
        );

        dialogRef.afterClosed().subscribe(() => {
          serviceSubscription.unsubscribe();
        });
      }
    );
  }

  ngAfterViewInit() {}

  ngOnDestroy() {
    // this._photoSubscription.unsubscribe();
  }
}
