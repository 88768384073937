import { Component, forwardRef, Input, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import {
  ControlValueAccessor,
  NG_VALUE_ACCESSOR,
  FormGroup,
  FormControl,
  FormBuilder
} from "@angular/forms";
import { PickAcquisitionDialogComponent } from './pick-acquisition-dialog/pick-acquisition-dialog.component';
import { DefaultValuesService } from '../../../services/playground/administration/default-values.service';

@Component({
  selector: 'app-pick-acquisition',
  templateUrl: './pick-acquisition.component.html',
  styleUrls: ['./pick-acquisition.component.sass'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => PickAcquisitionComponent),
    multi: true
  }]
})
export class PickAcquisitionComponent implements ControlValueAccessor, OnInit {

  @Input('value') val: string;
  form: FormGroup;
  defaultValueCategory = 'INV_Erwerbsart'; // Eigentlich "unnötig", hilft aber beim kopieren und später verbessern
  valueArray = [];

  constructor(private dataService: DefaultValuesService, public dialog: MatDialog) { }

  ngOnInit(): void {
    this.form = new FormGroup({
      currentValue: new FormControl()
    });

    // this.dataService.defaultValues.subscribe(result => {
    //   this.valueArray = [...result[this.defaultValueCategory]];
    // });

  }

  openInventoryTypeDialog() {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = false;
    dialogConfig.autoFocus = true;
    // dialogConfig.minWidth = "calc(100vw - 190px)";
    // dialogConfig.minHeight = "calc(100vh - 190px)";
    dialogConfig.data = {
      currentValue: this.value,
      valueArray: this.valueArray
    };

    const dialogRef = this.dialog.open(
      PickAcquisitionDialogComponent,
      dialogConfig
    );

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        console.log(result);
        this.writeValue(result);
      }
    });
  }

  onChange: any = () => {};
  onTouched: any = () => {};

  get value() {
    return this.val;
  }

  set value(val) {
    this.val = val;
    this.onChange(val);
    this.onTouched();
  }

  writeValue(value) {
    this.value = value;    
    this.form.controls["currentValue"].setValue(value);
  }

  registerOnChange(fn) {
    this.onChange = fn;
  }

  registerOnTouched(fn) {
    this.onTouched = fn;
  }
}