import { Component, OnInit, ViewChild } from "@angular/core";
import { DefaultValuesService } from "src/app/services/playground/administration/default-values.service";
import { MatAccordion } from "@angular/material/expansion";
import { TableInteraction } from "src/app/baseclasses/table-interaction/table-interaction";
import { DialogHandler } from "src/app/baseclasses/dialog-handler/dialog-handler";
import { ErwerbsartDialogComponent } from "./erwerbsart-dialog/erwerbsart-dialog.component";
import { IdenttraegerartDialogComponent } from "./identtraegerart-dialog/identtraegerart-dialog.component";
import { KontrollartDialogComponent } from "./kontrollart-dialog/kontrollart-dialog.component";
import { KontrollergebnisDialogComponent } from "./kontrollergebnis-dialog/kontrollergebnis-dialog.component";
import { StatusDialogComponent } from "./status-dialog/status-dialog.component";
import { AllgemeinzustandDialogComponent } from "./allgemeinzustand-dialog/allgemeinzustand-dialog.component";
import { ErledigungZeiteinheitenDialogComponent } from "./erledigung-zeiteinheiten-dialog/erledigung-zeiteinheiten-dialog.component";
import { MatDialog, MatDialogConfig } from "@angular/material/dialog";
import { BehaviorSubject, Subscription } from "rxjs";

@Component({
  selector: "app-vorgabewerte",
  templateUrl: "./vorgabewerte.component.html",
  styleUrls: ["./vorgabewerte.component.sass"],
})
export class VorgabewerteComponent implements OnInit {
  private defaultValuesSubscription: Subscription;

  public defaultValuesPlayground: object;

  public cardContentErwerbsart = new BehaviorSubject(["", []]);
  public cardContentIdenttraegerart = new BehaviorSubject(["", []]);
  public cardContentKontrollart = new BehaviorSubject(["", []]);
  public cardContentKontrollergebnis = new BehaviorSubject(["", []]);
  public cardContentStatus = new BehaviorSubject(["", []]);
  public cardContentAllgemeinzustand = new BehaviorSubject(["", []]);
  public cardContentErledigungZeiteinheiten = new BehaviorSubject(["", []]);

  public opened = {
    Erwerbsart: true,
    Identtraegerart: true,
    Kontrollart: true,
    Kontrollergebnis: true,
    Status: true,
    Allgemeinzustand: true,
    ErledigungZeiteinheiten: true,
  };

  constructor(private defaultDataService: DefaultValuesService) {
    this.defaultValuesSubscription = this.defaultDataService
      .getAllDefaultValueData()
      .subscribe((result) => {
        this.defaultValuesPlayground = {
          Erwerbsart: result["INV_Erwerbsart"],
          Identtraegerart: result["INV_Identtraegerart"],
          Kontrollart: result["SPK_Kontrollart"],
          Kontrollergebnis: result["SPK_Ergebnis"],
          Status: result["IVK_Status"],
          Allgemeinzustand: result["IVK_Allgemeinzustand"],
          ErledigungZeiteinheiten: result["IVK_Erledigung_Zeiteinheiten"],
        };

        this.cardContentErwerbsart.next([
          "Erwerbsart",
          this.defaultValuesPlayground["Erwerbsart"]
            ? this.defaultValuesPlayground["Erwerbsart"]
            : [],
        ]);
        this.cardContentIdenttraegerart.next([
          "Identträgerart",
          this.defaultValuesPlayground["Identtraegerart"]
            ? this.defaultValuesPlayground["Identtraegerart"]
            : [],
        ]);
        this.cardContentKontrollart.next([
          "Kontrollart",
          this.defaultValuesPlayground["Kontrollart"]
            ? this.defaultValuesPlayground["Kontrollart"]
            : [],
        ]);
        this.cardContentKontrollergebnis.next([
          "Kontrollergebnis",
          this.defaultValuesPlayground["Kontrollergebnis"]
            ? this.defaultValuesPlayground["Kontrollergebnis"]
            : [],
        ]);
        this.cardContentStatus.next([
          "Status",
          this.defaultValuesPlayground["Status"]
            ? this.defaultValuesPlayground["Status"]
            : [],
        ]);
        this.cardContentAllgemeinzustand.next([
          "Allgemeinzustand",
          this.defaultValuesPlayground["Allgemeinzustand"]
            ? this.defaultValuesPlayground["Allgemeinzustand"]
            : [],
        ]);
        this.cardContentErledigungZeiteinheiten.next([
          "Erledigung Zeiteinheiten",
          this.defaultValuesPlayground["ErledigungZeiteinheiten"]
            ? this.defaultValuesPlayground["ErledigungZeiteinheiten"]
            : [],
        ]);
      });
  }

  ngOnInit(): void {}
}
