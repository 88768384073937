import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
// import { DataDialogComponent } from 'src/app/modules/data-dialog/data-dialog/data-dialog.component';




@Component({
  selector: 'app-delete-data',
  templateUrl: './delete-data.component.html',
  styleUrls: ['./delete-data.component.sass']
})
export class DeleteDataComponent implements OnInit {

  // @Input() deleteData: any;
  // @Input() deleteFlag: any;
  // @Output() returnInput: EventEmitter<any> = new EventEmitter<any>();

  // canBeDeleted = true;

  // constructor(public dialog: MatDialog) { }

  // ngOnInit() {
  //   /*
  //   if (!this.deleteFlag) {
  //     this.canBeDeleted = false;
  //   }
  //   */
  // }

  // deleteItem() {
  //   event.stopPropagation();
  //   const dialogConfig = new MatDialogConfig();

  //   dialogConfig.disableClose = false;
  //   dialogConfig.autoFocus = true;

  //   dialogConfig.data = {
  //     id: 1,
  //     title: 'Eintrag löschen?',
  //     result: null,
  //     addingDialog: false
  //   }

  //   // const dialogRef = this.dialog.open(DataDialogComponent, dialogConfig);

  //   // dialogRef.afterClosed().subscribe(result => {
  //   //   if (result) {
  //   //     this.returnInput.emit(this.deleteData);
  //   //   }
  //   // })    
  // }

  @Output() private values = new EventEmitter();
  @Input() public record: object;

  public canNotDeleted = false;

  ngOnInit(): void {
    if(this.record[1]["canDelete"] !== false){
      this.canNotDeleted = false;

      this.values.emit(this.record[1]);
    }else{
      this.canNotDeleted = true
    }
  }
}
