import { AfterContentInit, Directive, ElementRef, Input } from "@angular/core";

@Directive({
  selector: "span[matBadge]",
})
export class AmBadgeDirective implements AfterContentInit{
  @Input() customBadgeColor = "";

  constructor(private el: ElementRef) {
  }

  ngAfterContentInit(): void {
    if(/^#([a-fA-F0-9]{6}|[a-fA-F0-9]{3})$/.test(this.customBadgeColor)){
      this.el.nativeElement.children[0].style.backgroundColor = this.customBadgeColor;
    }else{
      console.warn("invalid hex color", this.customBadgeColor);
    };
  }

}
