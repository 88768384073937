<div class="toolbar">
  <div
    class="headerFilter"
    style="display: flex; flex-direction: row; align-items: center"
  >
    <span class="toolbarHeader">Kontrollen <span class="count">123</span></span>
    <div class="controlFilter">
      <label>Filter</label>
      <app-filter-choice></app-filter-choice>
    </div>
  </div>

  <div class="toolbarActions">
    <app-pdf></app-pdf>
  </div>
</div>
<div class="mainContent">
  <div #mainView class="mainView">
    <app-column web="12" tablet="12" class="contentContainer">
      <app-row web *ngFor="let entry of inspectionData" class="itemContainer">
        <app-column web="12" tablet="12">
          <app-row web>
            <app-column web="12" tablet="12" class="itemHeader">
              <div class="itemTitle">
                <span>{{ entry.playground.number }}</span
                >{{ entry.playground.description }}
              </div>
              <div class="itemActions">
                <button mat-icon-button>
                  <span class="material-symbols-outlined"> bug_report </span>
                </button>
                <button mat-icon-button>
                  <span class="material-symbols-outlined"> bug_report </span>
                </button>
                <button mat-icon-button>
                  <span class="material-symbols-outlined"> bug_report </span>
                </button>
              </div>
            </app-column>
            <app-column web="4" tablet="12" class="itemValues">
              <div class="stateWrapper">
                <div class="state-container stateHeader">
                  <div class="itemAddress">
                    <a class="itemLink"
                      >{{ entry.playground.address.street }}
                      {{ entry.playground.address.house_number }},
                      {{ entry.playground.address.postcode }}
                      {{ entry.playground.address.location }}</a
                    >
                  </div>
                </div>
                <div class="state-container text">
                  <span class="state-value">Kontrolle am</span
                  ><span class="state-text" style="display: initial;">
                    {{ entry.date | date : "EE, dd.MM.yyyy" }} <br> <span style="top: -10px; position: relative"><span class="colorLightGrey">um</span> {{ entry.time }}</span>
                  </span>
                </div>
                <div class="state-container">
                  <span class="state-value">Kontrollart</span
                  ><span class="state-text">{{ entry.type }}</span>
                </div>
              </div>
            </app-column>
            <app-column web="4" tablet="12" class="itemValues">
              <div class="stateWrapper">
                <ng-container *ngIf="entry.person1 || entry.person2">
                  <div class="state-container">
                    <span class="state-value">Kontrollperson</span
                    ><span class="state-text">
                      <ng-container *ngIf="entry.person1">{{
                        entry.person1
                      }}</ng-container
                      ><ng-container *ngIf="entry.person2"
                        >; {{ entry.person2 }}</ng-container
                      >
                    </span>
                  </div>
                </ng-container>
                <ng-container *ngIf="entry.result">
                  <div class="state-container">
                    <span class="state-value">Ergebnis</span
                    ><span class="state-text">
                      {{ entry.result }}
                    </span>
                  </div>
                </ng-container>
                <ng-container
                  *ngIf="
                    entry.inventory_inspection.length > 0 ||
                    entry.playground.inventory_items.length > 0
                  "
                >
                  <div class="state-container" style="align-items: unset">
                    <span class="state-value">Inventarkontrolle</span
                    ><span class="state-text" style="display: initial">
                      <ng-container
                        *ngIf="
                          entry.playground.inventory_items &&
                            entry.playground.inventory_items.length > 0;
                          else noItems
                        "
                      >
                        <span style="margin: 0" class="colorLightGrey">zu</span>
                        {{ entry.playground.inventory_items.length }}
                        <span class="colorLightGrey">Gegenständen</span>
                      </ng-container>
                      <ng-template #noItems>
                        <span style="margin: 0" class="colorLightGrey">zu</span>
                        0
                        <span class="colorLightGrey">Gegenständen</span>
                      </ng-template>
                      <br />
                      <ng-container
                        *ngIf="
                          entry.inventory_inspection &&
                            entry.inventory_inspection.length > 0;
                          else noInvInsp
                        "
                      >
                        <span style="top: -10px; position: relative">
                          {{ entry.inventory_inspection.length }}
                          <span class="colorLightGrey">Rückmeldungen</span>
                        </span>
                      </ng-container>
                      <ng-template #noInvInsp>
                        0 <span class="colorLightGrey">Rückmeldungen</span>
                      </ng-template>
                    </span>
                  </div>
                </ng-container>
              </div>
            </app-column>
            <app-column web="4" tablet="12" class="itemValues">
              <div class="stateWrapper">
                <div class="state-container stateHeader">
                  <span class="state-value">Rückmeldungen Gesamt</span
                  ><span class="state-counter"
                    ><span>{{ entry.inventory_inspection.length }}</span></span
                  >
                </div>
                <div class="state-container">
                  <span class="state-value">Ohne neue Beanstandungen</span
                  ><span class="state-counter">
                    <span class="state-counter"
                      ><span
                        customBadgeColor="#4caf50"
                        [ngClass]="{
                          'hide-text':
                            countInventoryInspectionsByType(
                              entry,
                              'ohne neue Beanstandung'
                            ) < 1
                        }"
                        matBadge="{{
                          countInventoryInspectionsByType(
                            entry,
                            'ohne neue Beanstandung'
                          )
                        }}"
                        matBadgeOverlap="true"
                      ></span
                    ></span>
                  </span>
                </div>
                <div class="state-container">
                  <span class="state-value">Handlungsbedarf</span
                  ><span class="state-counter">
                    <span class="state-counter"
                      ><span
                        customBadgeColor="#f6af00"
                        [ngClass]="{
                          'hide-text':
                            countInventoryInspectionsByType(
                              entry,
                              'Handlungsbedarf'
                            ) < 1
                        }"
                        matBadge="{{
                          countInventoryInspectionsByType(
                            entry,
                            'Handlungsbedarf'
                          )
                        }}"
                        matBadgeOverlap="true"
                      ></span
                    ></span>
                  </span>
                </div>
                <div class="state-container">
                  <span class="state-value">Gefahrbeseitigung</span
                  ><span class="state-counter">
                    <span class="state-counter"
                      ><span
                        customBadgeColor="#ff6d00"
                        [ngClass]="{
                          'hide-text':
                            countInventoryInspectionsByType(
                              entry,
                              'Gefahrbeseitigung'
                            ) < 1
                        }"
                        matBadge="{{
                          countInventoryInspectionsByType(
                            entry,
                            'Gefahrbeseitigung'
                          )
                        }}"
                        matBadgeOverlap="true"
                      ></span
                    ></span>
                  </span>
                </div>
                <div class="state-container">
                  <span class="state-value">Außerbetriebnahme</span
                  ><span class="state-counter">
                    <span class="state-counter"
                      ><span
                        customBadgeColor="#d50000"
                        [ngClass]="{
                          'hide-text':
                            countInventoryInspectionsByType(
                              entry,
                              'Außerbetriebnahme'
                            ) < 1
                        }"
                        matBadge="{{
                          countInventoryInspectionsByType(
                            entry,
                            'Außerbetriebnahme'
                          )
                        }}"
                        matBadgeOverlap="true"
                      ></span
                    ></span>
                  </span>
                </div>
              </div>
            </app-column>
          </app-row>
        </app-column>
      </app-row>
    </app-column>
  </div>
  <div class="locationTree">TODO Location Tree</div>
</div>
