import { Injectable } from "@angular/core";
import { Query, QueryRef } from "apollo-angular";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { NewGraphQLService } from "../new-graphql.service";

@Injectable({
  providedIn: 'root'
})
export class SuppliersService {
  private graphqlObject: string = "supplier";
  private queryRef: QueryRef<Query>;
  private treeDataObservable: Observable<any>;

  constructor(private newGraphQLService: NewGraphQLService) {}

  getAllSupplierData() {
    // create only new stream if not already exist
    if(!this.treeDataObservable){
      this.queryRef = this.newGraphQLService.requestAllData(
        this.graphqlObject,
      );
  
      this.treeDataObservable = this.queryRef.valueChanges.pipe(
        map((result: any) => {
          return result.data.Supplier;
        })
      );
    }

    return this.treeDataObservable;
  }
}
