import { Injectable } from "@angular/core";
import { Query, QueryRef } from "apollo-angular";
import { Observable } from "rxjs";
import { first, map } from "rxjs/operators";
import { NewGraphQLService } from "../../new-graphql.service";

@Injectable({
  providedIn: "root",
})
export class TreeDamagesService {
  private graphqlObject: string = "tree_damages";
  private queryRef: QueryRef<Query>;
  private treeDamageDataObservable: Observable<any>;

  private damageByTreeObservables: object = {};
  private damageByTreeQueryRefs: object = {};

  constructor(private newGraphQLService: NewGraphQLService) {}

  getSingleTreeDamage(damageId: number) {
    let queryRef = this.newGraphQLService.getSingleData(this.graphqlObject, {
      id: damageId,
    });

    let subscription = queryRef.valueChanges.pipe(
      map((result: any) => {
        return result.data.Tree_Damage;
      })
    );

    return subscription;
  }

  getTreeDamagesDataByTree(treeId: number) {
    // create only new stream if not already exist
    if (!this.damageByTreeObservables[treeId]) {
      this.damageByTreeQueryRefs[treeId] = this.newGraphQLService.requestAllData(
        this.graphqlObject,
        {
          args: { filter: { id: { column: "tree", value: treeId } } },
        }
      );

      this.damageByTreeObservables[treeId] = this.damageByTreeQueryRefs[treeId].valueChanges.pipe(
        map((result: any) => {
          return result.data.Tree_Damage;
        })
      );
    }

    return this.damageByTreeObservables[treeId];
  }

  addTreeDamageData(treeDamageData: object) {
    let queryRef = this.newGraphQLService.addData(
      this.graphqlObject,
      treeDamageData,
      null,
      false
    );

    return queryRef.pipe(
      first(),
      map((result: any) => {
        if (this.damageByTreeQueryRefs[treeDamageData["tree"]]) {
          this.damageByTreeQueryRefs[treeDamageData["tree"]].refetch();
        }
        return result.data.add_Tree_Damage;
      })
    );
  }

  updateTreeDamageData(treeDamageData: object) {
    let queryRef = this.newGraphQLService.updateData(
      this.graphqlObject,
      treeDamageData,
      null,
      false
    );

    return queryRef.pipe(
      first(),
      map((result: any) => {
        if (this.damageByTreeQueryRefs[treeDamageData["tree"]]) {
          this.damageByTreeQueryRefs[treeDamageData["tree"]].refetch();
        }
        return result.data.update_Tree_Damage;
      })
    );
  }

  deleteTreeDamageData(treeDamage: object) {
    let queryRef = this.newGraphQLService.deleteData(
      this.graphqlObject,
      treeDamage["id"],
      null,
      false
    );

    return queryRef.pipe(
      first(),
      map((result: any) => {
        if (this.damageByTreeQueryRefs[treeDamage["tree"]]) {
          this.damageByTreeQueryRefs[treeDamage["tree"]].refetch();
        }
        return result.data.delete_Tree_Damage;
      })
    );
  }
}
