import { Component, forwardRef, OnInit, Input } from "@angular/core";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";
import { SuppliersService } from "src/app/services/general/suppliers.service";
import {
  FormGroup,
  FormControl,
  Validators,
  FormBuilder,
} from "@angular/forms";

@Component({
  selector: "app-select-supplier",
  templateUrl: "./select-supplier.component.html",
  styleUrls: ["./select-supplier.component.sass"],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SelectSupplierComponent),
      multi: true,
    },
  ],
})
export class SelectSupplierComponent implements ControlValueAccessor {
  form: FormGroup;

  @Input("value") val: any;
  supplierList: any;

  constructor(
    private formBuilder: FormBuilder,
    private suppliersDataService: SuppliersService
  ) {}

  ngOnInit(): void {
    this.form = new FormGroup({
      supplierSelect: new FormControl(),
    });

    this.suppliersDataService.getAllSupplierData().subscribe((result) => {
      console.log(result);
      this.supplierList = result;
    });
  }

  onChange: any = () => {};
  onTouched: any = () => {};

  get value() {
    return this.val;
  }

  set value(val) {
    this.val = val;
    this.onChange(val);
    this.onTouched();
  }

  writeValue(supplier) {
    this.value = parseInt(supplier);
  }

  registerOnChange(fn) {
    this.onChange = fn;
  }

  registerOnTouched(fn) {
    this.onTouched = fn;
  }

  onSupplierSelected(event) {
    this.writeValue(event);
  }
}
