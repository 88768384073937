<h2 mat-dialog-title>{{head}}</h2>
<mat-dialog-content [formGroup]="form">
    <mat-form-field floatLabel="always" class="fullWidth" appearance="outline">
        <mat-label>Kategorie</mat-label>
        <input matInput formControlName="newName" />
        <mat-error *ngIf="form.get('newName').hasError('required')">
          Erforderlich
        </mat-error>
    </mat-form-field>
</mat-dialog-content>

<div class="row">
    <button class="submit" mat-flat-button (click)="save()" [disabled]="!form.valid">{{okButtonLabel}}</button>
    <button class="cancel" mat-flat-button mat-dialog-close>Abrechen</button>
</div>

<!-- <h2 mat-dialog-title>{{head}}</h2>
<div class="dialog-content" [formGroup]="form">
    <div class="dialog-content-container">
        <div class="field dialog-field">
            <label class="" for="Sortierung">Inventar-Typ Kategorie</label>
            <input type="text" class="input number-counter" formControlName="newName">
        </div>
    </div>
</div>
<div class="dialog-actions">
    <button class="button" (click)="close()">Abbrechen</button>
    <button class="button is-primary" (click)="save()" [disabled]="!form.valid">{{okButtonLabel}}</button>
</div> -->