import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Output,
  EventEmitter,
  ViewChild,
  ElementRef,
  AfterViewInit,
  Input,
  ChangeDetectorRef,
} from "@angular/core";
import { AbstractControl, FormControl, FormGroup, ValidationErrors, ValidatorFn } from "@angular/forms";
import * as moment from "moment";
import { Observable } from "rxjs";
import { map, startWith } from "rxjs/operators";
import { DefaultValuesService } from "src/app/services/tree/administration/default-values.service";
import { TreeDamageListingService } from "src/app/services/tree/tree-damages/tree-damage-listing.service";
import { SelectDamageListingComponent } from "./select-damage-listing/select-damage-listing.component";

@Component({
  selector: "app-add-edit-control-message",
  templateUrl: "./add-edit-control-message.component.html",
  styleUrls: ["./add-edit-control-message.component.sass"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddEditControlMessageComponent implements OnInit, AfterViewInit {
  @Input() record: object;

  @Output() values = new EventEmitter();

  @ViewChild("damageDateInput") damageDateInput: ElementRef;
  @ViewChild("damageEndInput") damageEndInput: ElementRef;

  public selectDamageListingComponent = SelectDamageListingComponent;

  public treeDamagesForm = new FormGroup({
    okay: new FormControl(2),
    recorder: new FormControl(""),
    damage_date: new FormControl(moment().format("YYYY-MM-DD HH:mm:ss")),
    damage_end: new FormControl(null),
    tree_part: new FormControl(""),
    position: new FormControl(""),
    tree_damage_listing: new FormControl(null, [this.typeObject()]),
    note: new FormControl(""),
  });

  public defaultValues: Array<object> = [];

  public disableDate = false;
  public SchadensdatumLabel = "Erfassungsdatum";

  public treeDamageListings: Array<object>;
  public filteredTreeDamageListingsOptions: Observable<Array<object>>;

  constructor(
    private defaultValuesService: DefaultValuesService,
    private treeDamageListingService: TreeDamageListingService,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    // console.log(this.treeDamagesForm.value);
    this.values.emit(this.treeDamagesForm.value);

    this.treeDamagesForm.valueChanges.subscribe((result) => {
      if (this.treeDamagesForm.valid) {
        if(result.tree_damage_listing && typeof result.tree_damage_listing === "object"){
          result.tree_damage_listing = result.tree_damage_listing.id;
        }else{
          result.tree_damage_listing = null;
        }
        if(result.okay === 1){
          // result.damage_date = null;
          result.damage_end = null;
          result.position = null;
        }
        this.values.emit(result);
      }else{
        this.values.emit(false);
      }
    });

    this.defaultValuesService
      .getAllDefaultValueData()
      .subscribe((defaultValues) => {
        this.defaultValues = defaultValues;
        this.cd.detectChanges();
      });

    this.treeDamagesForm.get("okay").valueChanges.subscribe((result) => {
      if (result === 1) {
        // this.treeDamagesForm.get("damage_date").disable();
        this.treeDamagesForm.get("damage_end").disable();
        this.treeDamagesForm.get("position").disable();
        this.treeDamagesForm.get("tree_damage_listing").disable();

        this.disableDate = true;
        this.SchadensdatumLabel = "Meldungsdatum";
      } else {
        // this.treeDamagesForm.get("damage_date").enable();
        this.treeDamagesForm.get("damage_end").enable();
        this.treeDamagesForm.get("position").enable();
        this.treeDamagesForm.get("tree_damage_listing").enable();

        this.disableDate = false;
        this.SchadensdatumLabel = "Erfassungsdatum";
      }
    });

    // =====EDIT======
    if (this.record) {
      console.log(this.record);
      this.treeDamagesForm.patchValue({
        okay: this.record["okay"],
        recorder: this.record["recorder"],
        damage_date: this.record["damage_date"],
        damage_end: this.record["damage_end"],
        tree_part: this.record["tree_part"],
        position: this.record["position"],
        tree_damage_listing: this.record["tree_damage_listing"],
        note: this.record["note"],
      });
    }

    this.treeDamageListingService.getAllTreeDamageListingsData().subscribe(result=>{
      console.log(result)
      this.treeDamageListings = result;
      this.cd.detectChanges();

      this.filteredTreeDamageListingsOptions = this.treeDamagesForm.get("tree_damage_listing").valueChanges.pipe(
        startWith(''),
        map(value => this._filter(value))
      );
    })
  }

  ngAfterViewInit(): void {
    this.damageDateInput.nativeElement.value =
      moment().format("D.M.YYYY HH:mm");

    // =====EDIT======
    if (this.record) {
      if (typeof this.record["damage_date"] === "string") {
        this.damageDateInput.nativeElement.value = moment(
          this.record["damage_date"]
        ).format("D.M.YYYY HH:mm");
      }
      if (typeof this.record["damage_end"] === "string") {
        this.damageEndInput.nativeElement.value = moment(
          this.record["damage_end"]
        ).format("D.M.YYYY HH:mm");
      }
    }
  }

  setDate(value, inputDate) {
    const date = moment(inputDate).format("YYYY-MM-DD HH:mm:00");
    switch (value) {
      case "damageDate":
        if (date !== "Invalid date") {
          this.treeDamagesForm.patchValue({ damage_date: date });
        } else {
          this.treeDamagesForm.patchValue({ damage_date: "" });
        }
        break;
      case "damageEnd":
        if (date !== "Invalid date") {
          this.treeDamagesForm.patchValue({ damage_end: date });
        } else {
          this.treeDamagesForm.patchValue({ damage_end: "" });
        }
        break;
      default:
        break;
    }
  }

  setAutocompleteDisplay(value){
    return value ? `[${value.number}] ${value.damage_type}` : "";
  }

  typeObject(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      return typeof control.value === "object" || control.value === "" ? null : { typeObject: control.value };
    };
  }

  private _filter(value: string): Array<object> {
    if(typeof value == "object"){ return; };
    const filterValue = value.toLowerCase();

    const test = this.treeDamageListings.filter((treeDamageListing: any) => {
      const treeDamageListingString = `[${treeDamageListing.number}] ${treeDamageListing.damage_type}`.toLowerCase();

      return filterValue === treeDamageListingString;
    });

    if(test.length === 1){
      this.treeDamagesForm.get("tree_damage_listing").patchValue(test[0], {emitEvent: false});
    }

    return this.treeDamageListings.filter((treeDamageListing: any) => {
      const treeDamageListingString = `[${treeDamageListing.number}] ${treeDamageListing.damage_type}`.toLowerCase();

      return treeDamageListingString.includes(filterValue);
    });
  }
}
