import { Component, OnInit, Input } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { GraphqlService } from 'src/app/services/graphql.service';
import { ManageInspectionDialogComponent} from "./manage-inspection-dialog/manage-inspection-dialog.component";

@Component({
  selector: 'app-manage-inspection',
  templateUrl: './manage-inspection.component.html',
  styleUrls: ['./manage-inspection.component.sass']
})
export class ManageInspectionComponent implements OnInit {

  @Input() itemID: any;

  constructor(public dialog: MatDialog, private graphQLService: GraphqlService) { }

  ngOnInit(): void {
  }

  manageInspection() {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = false;
    dialogConfig.autoFocus = false;
    dialogConfig.height = "600px";
    dialogConfig.width = "800px";


    dialogConfig.data = {
      itemID: this.itemID
    }

    dialogConfig.panelClass = 'custom-dialog';

    const dialogRef = this.dialog.open(ManageInspectionDialogComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result => {

      console.log(result);
      /*
      if (result) {
        this.httpService.uploadPicture(result).subscribe(
          data => {
            let variableObject = {
              playground: this.playGroundID,
              photo: Number(data["file"])
            }
            this.graphQLService.sendMutation(this.addQuery, variableObject, this.baseQuery);
          },
          error => {
            console.error(error);
          }
        );
      }
      */
    })
  }

}
