import { NgModule } from "@angular/core";
import { GlobalModulesModule } from "src/app/global-modules.module";
import { ShowOnMapComponent } from "./show-on-map.component";


@NgModule({
    declarations: [
        ShowOnMapComponent,
    ],
    imports: [GlobalModulesModule],
    exports: [
        ShowOnMapComponent,
    ],
  })
  export class ShowOnMapModule {}