import { Component, forwardRef, Input, OnInit, Output, EventEmitter } from "@angular/core";
import { MatDialog, MatDialogConfig } from "@angular/material/dialog";
import {
  ControlValueAccessor,
  NG_VALUE_ACCESSOR,
  FormGroup,
  FormControl,
  FormBuilder
} from "@angular/forms";
import { InventoryService } from "src/app/services/playground/administration/inventory.service";
import { PickInventoryDialogComponent } from "./pick-inventory-dialog/pick-inventory-dialog.component";

@Component({
  selector: 'app-pick-inventory-display',
  templateUrl: './pick-inventory-display.component.html',
  styleUrls: ['./pick-inventory-display.component.sass'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => PickInventoryDisplayComponent),
      multi: true
    }
  ]
})
export class PickInventoryDisplayComponent implements ControlValueAccessor, OnInit {

  @Input("value") val: any;
  @Output() markAsDirty = new EventEmitter<any>();
  form: FormGroup;

  inventoryID: any;
  inventoryArray: any;

  constructor(private dataService: InventoryService, public dialog: MatDialog) { }

  ngOnInit(): void {
    this.form = new FormGroup({
      inventory_type: new FormControl()
    });

    this.dataService.inventoryType.subscribe(result => {
      this.inventoryArray = result;
      this.setDescriptionFromID();
    });

  }

  setDescriptionFromID() {
    for (let i = 0; i < this.inventoryArray.length; i++) {
      if (this.inventoryArray[i].id === this.inventoryID) {
        this.form.controls["inventory_type"].setValue(this.inventoryArray[i].description);
        break;
      }
    }
  }

  openInventoryTypeDialog() {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = false;
    dialogConfig.autoFocus = true;
    dialogConfig.data = {
      currentInventory: this.inventoryID
    };

    const dialogRef = this.dialog.open(
      PickInventoryDialogComponent,
      dialogConfig
    );

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.writeValue(result);
        this.markAsDirty.emit({control: "inventory_type", value: result}); // Das hier ist ein wahnsinniger Hack, weil das Element am Ende eben _nicht_ den Wert annimmt.
      }                                                                   // Bei anderen Elementen funktioniert aber das hier (copy&paste)
    });
  }


  onChange: any = () => {};
  onTouched: any = () => {};

  get value() {
    return this.val;
  }

  set value(val) {
    this.val = val;
    this.onChange(val);
    this.onTouched();
  }

  writeValue(data) {
    this.inventoryID = data;
    this.setDescriptionFromID();
  }

  registerOnChange(fn) {
    this.onChange = fn;
  }

  registerOnTouched(fn) {
    this.onTouched = fn;
  }
}
