<h1 mat-dialog-title>
    Bild hochladen
</h1>
<div mat-dialog-content>
    <img [src]="picturePreview" *ngIf="picturePreview" class="picture-preview">
    <p *ngIf="errorMessage" class="has-text-danger">{{errorMessage}}</p>
    <form [formGroup]="pictureForm">
        <div class="file">
            <label class="file-label">
                <input formControlName="pictureFile" class="file-input" type="file" name="resume" (change)="onImageSelected($event)">
                <span class="file-cta">
                    <!--
        <span class="file-icon">
          <i class="fas fa-upload"></i>
        </span>
        -->
                    <span class="file-label">
                        Bitte Bild auswählen…
                    </span>
                </span>
                <span class="file-name">
                    {{pictureName}}
                </span>
            </label>
        </div>
        <textarea [disabled]="!allowNote" name="pictureText" class="textarea" placeholder="Beschreibung oder Notizen" (change)="onPictureTextChanged($event)"></textarea>
    </form>
</div>
<div mat-dialog-actions>
    <button class="button" (click)="close()">Abbrechen</button>
    <button class="button" (click)="save()" [disabled]="!pictureForm.valid">Speichern</button>
</div>