<div class="body">
  <!-- eventual implement grid system  -->
  <div class="contentGroup" style="width: 100%">
    <div class="contentLabel">Baum</div>
    <div class="content" style="width: 100%">
      <div class="row">
        <div class="column">
          <app-default-value-card
            [cardContentSubject]="cardContentErziehungsform"
            [opened]="opened.Erziehungsform"
            (addData)="addData($event)"
            (updateData)="updateData($event)"
            (deleteData)="deleteData($event)"
          ></app-default-value-card>
          <app-default-value-card
            [cardContentSubject]="cardContentDamagedegree"
            [opened]="opened.Damagedegree"
            [color]="true"
            (addData)="addData($event)"
            (updateData)="updateData($event)"
            (deleteData)="deleteData($event)"
          ></app-default-value-card>
        </div>
        <div class="column">
          <app-default-value-card
            [cardContentSubject]="cardContentPflanzanlage"
            [opened]="opened.Pflanzanlage"
            (addData)="addData($event)"
            (updateData)="updateData($event)"
            (deleteData)="deleteData($event)"
          ></app-default-value-card>
        </div>
        <div class="column">
          <app-default-value-card
            [cardContentSubject]="cardContentplantingType"
            [opened]="opened.plantingType"
            (addData)="addData($event)"
            (updateData)="updateData($event)"
            (deleteData)="deleteData($event)"
          ></app-default-value-card>
        </div>
        <div class="column">
          <app-default-value-card
            [cardContentSubject]="cardContentlocationCondition"
            [opened]="opened.locationCondition"
            (addData)="addData($event)"
            (updateData)="updateData($event)"
            (deleteData)="deleteData($event)"
          ></app-default-value-card>
        </div>
      </div>
    </div>
  </div>

  <div class="contentGroup" style="width: 100%">
    <div class="contentLabel">Baum-Schaden</div>
    <div class="content" style="width: 100%"></div>
  </div>

  <div class="contentGroup" style="width: 100%">
    <div class="contentLabel">Schadenskatalog</div>
    <div class="content" style="width: 100%"></div>
  </div>

  <div class="contentGroup" style="width: 100%">
    <div class="contentLabel">Baum-Maßnahmen</div>
    <div class="content" style="width: 100%"></div>
  </div>
</div>
