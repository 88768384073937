<span class="note-wrapper">
    <form [formGroup]="textAreaForm">
        <textarea formControlName="pictureNote" (change)="onPictureTextChanged($event)"></textarea>
    </form>
    <span class="control-panel">
        <span *ngIf="editMode; else editView">
            <span (click)="dropChange()">
                <svg class="drop-change" style="width:24px;height:24px" viewBox="0 0 24 24">
                    <path 
                        d="M20 6.91L17.09 4L12 9.09L6.91 4L4 6.91L9.09 12L4 17.09L6.91 20L12 14.91L17.09 20L20 17.09L14.91 12L20 6.91Z" />
                </svg>
            </span>
            <span (click)="acceptChange()">
                <svg class="accept-change" style="width:24px;height:24px" viewBox="0 0 24 24">
                    <path
                        d="M9,20.42L2.79,14.21L5.62,11.38L9,14.77L18.88,4.88L21.71,7.71L9,20.42Z" />
                </svg>
            </span>
        </span>
        <ng-template #editView>
            <span (click)="editPhotoNote()">
                <svg style="width:24px;height:24px" viewBox="0 0 24 24">
                    <path fill="currentColor"
                        d="M6,2C4.89,2 4,2.89 4,4V20A2,2 0 0,0 6,22H10V20.09L12.09,18H6V16H14.09L16.09,14H6V12H18.09L20,10.09V8L14,2H6M13,3.5L18.5,9H13V3.5M20.15,13C20,13 19.86,13.05 19.75,13.16L18.73,14.18L20.82,16.26L21.84,15.25C22.05,15.03 22.05,14.67 21.84,14.46L20.54,13.16C20.43,13.05 20.29,13 20.15,13M18.14,14.77L12,20.92V23H14.08L20.23,16.85L18.14,14.77Z" />
                </svg>
            </span>
        </ng-template>
    </span>
</span>