import {
  animation,
  trigger,
  animateChild,
  group,
  transition,
  animate,
  style,
  query,
  state
} from "@angular/animations";

export const scrollResizeAnimation = trigger("resizeElement", [
  state(
    "full",
    style({
      width: "100%"
    })
  ),
  state(
    "side",
    style({
      width: "20vw"
    })
  ),
  transition(":enter", [style({ width: "100%" })]),
  transition("* => *", [animate("0.5s ease-in-out")])
]);

export const slideSubmenu = trigger("submenuOpenClose", [
  state(
    "open",
    style({
      opacity: 1,
      height: "*",
      overflow: "hidden"
    })
  ),
  state(
    "close",
    style({
      opacity: 0,
      overflow: "hidden",
      height: "0px"
    })
  ),
  transition(":enter", [style({ height: "0px", overflow: "hidden" })]),
  transition("* => *", [animate("0.5s ease-in-out")])
]);
