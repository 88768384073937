/**
 * Base Object to store the data of the application part in.
 */
class DataLink {
  private _dataStore: Array<any> | object | null | undefined;

  /**
   *
   * @param dataSource
   */
  constructor(private dataSource: Array<any> | object | null | undefined) {
    this._dataStore = dataSource;
  }

  /**
   * @returns
   */
  get dataStore(): Array<any> | object | null {
    return this._dataStore;
  }

  /**
   * @param data
   */
  set setDataStore(data : Array<any> | object | null) {
    this._dataStore = data;
  }

  /**
   * @param dataSource
   */
  set dataStore(dataSource: Array<any> | object | null) {
    this._dataStore = dataSource;
  }
}

/**
 * Extension of the DataObject to manipulate the data within.
 */
export class DataIndex extends DataLink {
  protected initialData: Array<any> | object | null = this.dataStore;
  protected isFiltered: Boolean = false; // evtl. obsolet

  //------------------------------------------------------------
  //                      Filter-Method/s
  //------------------------------------------------------------
  /**
   *
   * @param item
   * @param filter
   */
  private _filterArray(item: String | number, filter: any): Boolean {
    if (typeof item === "number") {
      return item.toString().includes(filter.toString());
    } else {
      return item.includes(filter);
    }
  }

  /**
   *
   * @param object
   * @param filter
   * @param filterOnly
   */
  private _filterObject(
    object: object,
    filter: any,
    filterOnly?: string | number
  ): object {
    let returnObject = Object.keys(object).reduce((returnObject, key) => {
      if (
        key === filterOnly ||
        filterOnly === undefined ||
        filterOnly === null
      ) {
        // TODO: Auf filter === Array checken und wenn nicht, anders filtern
        let result = filter.some(value => {
          if (object[key] && value !== null) {
            return object[key].toString() === value.toString();
          } else {
            return object[key] === value;
          }
        });
        if (result) {
          returnObject[key] = object[key];
        }
      }
      return returnObject;
    }, {});

    return returnObject;
  }

  /**
   *
   * @param array
   * @param filter
   * @param filterOnly
   */

  private _filterObjectArray(
    array: Array<object>,
    filter: String | number,
    filterOnly?: string
  ): Array<object> {
    let returnArray = array.filter(object => {
      let returnObject = this._filterObject(object, filter, filterOnly);
      if (Object.keys(returnObject).length !== 0) {
        return returnObject;
      }
    });

    return returnArray;
  }

  /**
   *
   * @param filter
   * @param filterOnly
   */
  /*
  filter(filter?: String | number, filterOnly?: string): void {
    if (this.dataStore === (null || undefined)) {
      return;
    }

    if (this.initialData === (null || undefined)) {
      this.initialData = this.dataStore;
    } else {
      this.dataStore = this.initialData;
    }

    if (!filter) {
      this.isFiltered = false;
      // TODO: Stelle noch einmal betrachten, ob hier die Daten neu geladen werden müssen
      return;
    }

    this.isFiltered = true;

    let filterResult;
    if (Array.isArray(this.dataStore)) {
      if (typeof this.dataStore[0] === "object") {
        filterResult = this._filterObjectArray(
          this.dataStore,
          filter,
          filterOnly
        );
      } else {
        filterResult = this.dataStore.filter(item =>
          this._filterArray(item, filter)
        );
      }
    } else {
      filterResult = this._filterObject(this.dataStore, filter);
    }

    this.dataStore = filterResult;
  }
  */

  /**
   *
   * @param filterData
   * @param filter Filter der auf das Array angewandt wird (optional; wenn null, wird das Array wieder zurück gesetzt)
   * @param filterOnly Filtert nur nach bestimmten Keys im Objekt (optional)
   */
  filterData(
    filterData: Array<object> | any,
    filter?: any,
    filterOnly?: string
  ) {
    if (filterData === (null || undefined)) {
      return [];
    }

    let filterResult;
    if (Array.isArray(filterData)) {
      if (typeof filterData[0] === "object") {
        filterResult = this._filterObjectArray(filterData, filter, filterOnly);
      } else {
        filterResult = filterData.filter(item =>
          this._filterArray(item, filter)
        );
      }
    } else {
      filterResult = this._filterObject(filterData, filter);
    }

    return filterResult;
  }

  /*******************************************************************************/

  /**
   *
   * @param object
   * @param filter
   * @param filterOnly
   */
  private _filterObjectByLength(
    object: object,
    filter: any,
    filterOnly?: string | number
  ): object {
    let returnObject = Object.keys(object).reduce((returnObject, key) => {
      if (
        key === filterOnly ||
        filterOnly === undefined ||
        filterOnly === null
      ) {
        let result = filter.some(value => {
          return object[key].length.toString() === value.toString();
        });
        if (result) {
          returnObject[key] = object[key];
        }
      }
      return returnObject;
    }, {});

    return returnObject;
  }

  /**
   *
   * @param array
   * @param filter
   * @param filterOnly
   */

  private _filterObjectArrayByLength(
    array: Array<object>,
    filter: String | number,
    filterOnly?: string
  ): Array<object> {
    let returnArray = array.filter(object => {
      let returnObject = this._filterObjectByLength(object, filter, filterOnly);
      if (Object.keys(returnObject).length !== 0) {
        return returnObject;
      }
    });

    return returnArray;
  }

  /**
   *
   * @param filterData
   * @param filter Filter der auf das Array angewandt wird (optional; wenn null, wird das Array wieder zurück gesetzt)
   * @param filterOnly Filtert nur nach bestimmten Keys im Objekt (optional)
   */
  filterDataByLength(
    filterData: Array<object> | any,
    filter?: any,
    filterOnly?: string
  ) {
    if (filterData === (null || undefined)) {
      return [];
    }

    let filterResult;
    if (Array.isArray(filterData)) {
      if (typeof filterData[0] === "object") {
        filterResult = this._filterObjectArrayByLength(
          filterData,
          filter,
          filterOnly
        );
      } else {
        console.error(
          "Fall nicht vorgesehen, bitte dataobject.ts, filterDataByLength() prüfen"
        );
        /*
        filterResult = filterData.filter(item =>
          this._filterArray(item, filter)
        );
        */
      }
    } else {
      console.error(
        "Fall nicht vorgesehen, bitte dataobject.ts, filterDataByLength() prüfen"
      );
      /* filterResult = this._filterObject(filterData, filter); */
    }

    return filterResult;
  }


  /**
   * NOTE: Sollte noch einmal überarbeitet werden, ist zu sehr auf playground-inspections zugeschnitten
   * @param filterData 
   * @param filter 
   */
  filterDataByTimeframe(filterData: Array<object> | any,
    filter?: any) {
    
    if (filterData === (null || undefined)) {
      return [];
    }

    if (Array.isArray(filterData)) {

      let beginDate = new Date(filter.filterArray[0].begin);
      let endDate = new Date(filter.filterArray[0].end);

      let filteredData = filterData.filter( entry => {
        let testingDate = new Date(entry.date);
        return testingDate >= beginDate && testingDate <= endDate;
      });
      return filteredData;
    }
  }

  //------------------------------------------------------------
  //                     Sort-Method/s
  //------------------------------------------------------------

  /**
   *
   * @param value1
   * @param value2
   */
  private _compareValue(value1, value2) {
    return value1.toString().localeCompare(value2.toString());
  }

  /**
   *
   * @param objectA
   * @param objectB
   * @param sortBy
   */
  private _compareObject(objectA, objectB, sortBy) {
    return objectA[sortBy.toString()]
      .toString()
      .localeCompare(objectB[sortBy.toString()].toString());
  }

  /**
   *
   * @param sortDirection
   */
  private _sortArrayData(
    sortData,
    sortDirection: String
  ): Array<String | number> {
    if (Array.isArray(sortData)) {
      return sortData.sort(
        sortDirection === "asc"
          ? (a, b) => {
              if (typeof a === "number" && typeof b === "number") {
                return a - b;
              } else {
                return this._compareValue(a, b);
              }
            }
          : (a, b) => {
              if (typeof a === "number" && typeof b === "number") {
                return b - a;
              } else {
                return this._compareValue(b, a);
              }
            }
      );
    }
  }

  /**
   *
   * @param sortData
   * @param sortDirection
   * @param sortBy
   */
  private _sortObjectArray(
    sortData: Array<object>,
    sortDirection: String,
    sortBy: String | number
  ): Array<object> {
    if (Array.isArray(sortData)) {
      return sortData.sort(
        sortDirection === "asc"
          ? (a, b) => {
                return this._compareObject(a, b, sortBy); 
            }
          : (a, b) => {
                return this._compareObject(b, a, sortBy);
            }
      );
    }
  }

  /**
   * TODO: sortData: any noch richtigstellen
   * Achtung: Die Daten, die sortiert werden, dürfen kein null enthalten,
   * da es sonst zu einem Fehler kommt. 
   *
   * @param sortData
   * @param sortDirection "asc" || "desc"
   * @param sortBy
   */
  sortData(
    sortData: Array<object> | any,
    sortDirection?: String,
    sortBy?: String | number
  ): Array<object> {
    if (sortData === null || sortData === undefined) {
      return;
    }

    if (sortDirection !== "asc" && sortDirection !== "desc") {
      console.error(
        "Sorting Direction not asc/desc, instead: " +
          sortDirection +
          " | Defaulting to 'asc'"
      );
      sortDirection = "asc";
    }

    if (Array.isArray(sortData)) {
      if (typeof sortData[0] === "object") {
        sortData = this._sortObjectArray(sortData, sortDirection, sortBy);
      } else {
        sortData = this._sortArrayData(sortData, sortDirection);
      }
    }
    return sortData;
  }

  /****************************************************************************/

  /**
   *
   * @param objectA
   * @param objectB
   * @param sortBy
   */
  private _compareObjectLength(objectA, objectB, sortBy) {
    return (
      objectA[sortBy.toString()].length - objectB[sortBy.toString()].length
    );
  }

  /**
   *
   * @param sortData
   * @param sortDirection
   * @param sortBy
   */
  private _lengthSortObjectArray(
    sortData: Array<object>,
    sortDirection: String,
    sortBy: any
  ): Array<object> {
    if (Array.isArray(sortData)) {
      return sortData.sort(
        sortDirection === "asc"
          ? (a, b) => {
              if (Array.isArray(a[sortBy]) && Array.isArray(b[sortBy])) {
                return this._compareObjectLength(a, b, sortBy);
              }
            }
          : (a, b) => {
              if (Array.isArray(a[sortBy]) && Array.isArray(b[sortBy])) {
                return this._compareObjectLength(b, a, sortBy);
              }
            }
      );
    }
  }

  /**
   *
   * @param sortData
   * @param sortDirection
   * @param sortBy
   */
  sortByLength(
    sortData: Array<object> | any,
    sortDirection?: String,
    sortBy?: String
  ): Array<object> {
    if (sortData === null || undefined) {
      return;
    }

    if (sortDirection !== "asc" && sortDirection !== "desc") {
      console.error(
        "Sorting Direction not asc/desc, instead: " +
          sortDirection +
          " | Defaulting to 'asc'"
      );
      sortDirection = "asc";
    }

    if (Array.isArray(sortData)) {
      if (typeof sortData[0] === "object") {
        sortData = this._lengthSortObjectArray(sortData, sortDirection, sortBy);
      }
    }
    return sortData;
  }

  /*******************************************************************************/

  /**
   *
   * @param objectA
   * @param objectB
   */
  private _compareDateObject(objectA, objectB) {
    return (
      (objectA
        ? new Date(objectA.date + " " + objectA.time).getTime()
        : new Date(0).getTime()) -
      (objectB
        ? new Date(objectB.date + " " + objectB.time).getTime()
        : new Date(0).getTime())
    );
  }

  /**
   *
   * @param object
   */
  public getLastDate(object: any) {
    let lastDate = object.reduce((previous, current) => {
      return (previous
        ? new Date(previous.date + " " + previous.time)
        : new Date(0)) > new Date(current.date + " " + current.time)
        ? previous
        : current;
    }, null);
    return lastDate;
  }

  /**
   *
   * @param sortData
   * @param sortDirection
   * @param sortBy
   */
  private _lastDateSortObjectArray(
    sortData: Array<object>,
    sortDirection: String,
    sortBy: any
  ): Array<object> {
    if (Array.isArray(sortData)) {
      return sortData.sort(
        sortDirection === "asc"
          ? (a, b) => {
              if (Array.isArray(a[sortBy]) && Array.isArray(b[sortBy])) {
                let lastDateA = this.getLastDate(a[sortBy]);
                let lastDateB = this.getLastDate(b[sortBy]);
                return this._compareDateObject(lastDateA, lastDateB);
              }
            }
          : (a, b) => {
              if (Array.isArray(a[sortBy]) && Array.isArray(b[sortBy])) {
                let lastDateA = this.getLastDate(a[sortBy]);
                let lastDateB = this.getLastDate(b[sortBy]);
                return this._compareDateObject(lastDateB, lastDateA);
              }
            }
      );
    }
  }

  /**
   *
   * @param sortData
   * @param sortDirection
   * @param sortBy
   */
  sortByLastDate(
    sortData: Array<object> | any,
    sortDirection?: String,
    sortBy?: String
  ): Array<object> {
    if (sortData === null || undefined) {
      return;
    }

    if (sortDirection !== "asc" && sortDirection !== "desc") {
      console.error(
        "Sorting Direction not asc/desc, instead: " +
          sortDirection +
          " | Defaulting to 'asc'"
      );
      sortDirection = "asc";
    }

    if (Array.isArray(sortData)) {
      if (typeof sortData[0] === "object") {
        sortData = this._lastDateSortObjectArray(
          sortData,
          sortDirection,
          sortBy
        );
      }
    }
    return sortData;
  }

  //------------------------------------------------------------
  //                    Helper-Method/s
  //------------------------------------------------------------

  /**
   *
   * @param firstArray
   * @param secondArray
   */
  private compareArrays(firstArray: Array<any>, secondArray: Array<any>) {
    let compareResult = false;
    if (Array.isArray(firstArray) && Array.isArray(secondArray)) {
      if (firstArray.length === secondArray.length) {
        for (let i = 0; i < firstArray.length; i++) {
          if (Array.isArray(firstArray[i])) {
            let checkResult = this.compareArrays(firstArray[i], secondArray[i]);
            if (checkResult === false) {
              return false;
            } else {
              compareResult = true;
            }
          } else {
            if (firstArray[i] === secondArray[i]) {
              compareResult = true;
            } else {
              return false;
            }
          }
        }
      } else {
        return false;
      }
    } else {
      return false;
    }
    return compareResult;
  }
}
