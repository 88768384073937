import { NgModule } from '@angular/core';

import { FahrzeugortungComponent } from './fahrzeugortung/fahrzeugortung.component';
import { FahrtenbuchComponent } from './fahrtenbuch/fahrtenbuch.component';
import { PoiAuswertungComponent } from './poi-auswertung/poi-auswertung.component';
import { FahrzeugverfolgungComponent } from './fahrzeugverfolgung.component';
import { GlobalModulesModule } from '../global-modules.module';

@NgModule({
  declarations: [
    FahrzeugverfolgungComponent,
    FahrzeugortungComponent, 
    FahrtenbuchComponent, 
    PoiAuswertungComponent
  ],
  imports: [GlobalModulesModule],
  exports: []
})
export class FahrzeugverfolgungModule { }
