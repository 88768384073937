import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { GraphqlService } from "../../graphql.service";
import { DataobjectInventoryType } from "../../../baseclasses/dataobject-playground/administration/dataobject-inventory-type";
import { dataQueries } from "src/app/gql-queries/query-register.module";
import { DataobjectGraphQL } from "src/app/baseclasses/dataobject-graphql/dataobject-graphql";

@Injectable({
  providedIn: "root"
})
export class InventoryService {

  private _inventoryTypeObject;
  private _inventoryObjectSubscription;
  private _inventoryTypeSubject: BehaviorSubject<object>;


  constructor(private graphQl: GraphqlService) {
    this._inventoryTypeSubject = new BehaviorSubject<object>([]);
    this._inventoryTypeObject = new DataobjectInventoryType(this.graphQl);

    this._inventoryObjectSubscription = this._inventoryTypeObject.dataObserver.subscribe( result => {
      this._inventoryTypeSubject.next(result);     
    });
  }

  ngOnDestroy() {
    this._inventoryObjectSubscription.unsubscribe();
  }

  get inventoryType() {
    return this._inventoryTypeSubject;
  }

  addInventoryType(values) {
    let state = this._inventoryTypeObject.baseObject.addData(values);
    return state;
  }

  updateInventoryType(values) {
    this._inventoryTypeObject.baseObject.updateData(values);
  }

  deleteInventoryType(value) {
    this._inventoryTypeObject.baseObject.deleteData(value);
  }

  updateInventoryTypeCategory(values) {
    this.graphQl.sendMutation(dataQueries["special_cases"].updateInventoryTypeCategory, values, ...DataobjectGraphQL._queryList);
  }
}
