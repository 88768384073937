// import { Injectable } from '@angular/core';
// import { BehaviorSubject, combineLatest } from "rxjs";
// import { GraphqlService } from "../graphql.service";

// import { DataobjectPlaygroundPhotos } from "../../baseclasses/dataobject-playground/dataobject-playground-photos";


// @Injectable({
//   providedIn: 'root'
// })
// export class PlaygroundPhotosService {

//   private _initialPhotoFilter = 0;
//   private _photoFilterSubject: BehaviorSubject<number>;
//   private _playgroundPhotoObject: any;
//   private _playgroundPhotoSubject: BehaviorSubject<object>;

//   constructor(private graphQl: GraphqlService) {
//     this._playgroundPhotoSubject = new BehaviorSubject<object>([]);
//     this._photoFilterSubject = new BehaviorSubject<number>(this._initialPhotoFilter);
//     this._playgroundPhotoObject = new DataobjectPlaygroundPhotos(this.graphQl);

//     combineLatest(this._playgroundPhotoObject.dataObserver, this._photoFilterSubject).subscribe( result => {

//       console.log(result);

//       let photoData = result[0];
//       let filterData = result[1];
//       let filteredPhotos = this._playgroundPhotoObject.filterData(photoData, [filterData], "playground");
//       console.log(filteredPhotos)
//       this._playgroundPhotoSubject.next(filteredPhotos);
//     });
//   }

//   set currentPlayground(playground) {
//     this._photoFilterSubject.next(playground);
//   }

//   get currentPlaygroundPhotos() {
//     return this._playgroundPhotoSubject;
//   }

// }


import { Injectable } from "@angular/core";

@Injectable({
  providedIn: 'root'
})
export class PlaygroundPhotosService {


  getThumbnail(){
    
  }
}
