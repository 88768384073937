import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import gql from "graphql-tag";

export const getAllTreePhotos = gql `
    query getAll{
        Tree_Photos {
            id
            tree {
                id,
                id_number,
                number,
                coordinate{
                    id,
                    longitude,
                    latitude,
                    altitude,
                    timestamp,
                    creation_date,
                    creation_user,
                    modify_date,
                    modify_user,
                    canDelete
                },
                address{
                    id,
                    country,
                    postcode,
                    location,
                    district,
                    street,
                    house_number,
                    latitude,
                    longitude,
                },
                expiration,
                parent_record,
                plat_number,
                plant_date,
                age_expectation,
                log_date,
                supplier{
                    id,
                    number,
                    name1,
                    name2,
                    address{
                        id,
                        country,
                        postcode,
                        location,
                        district,
                        street,
                        house_number,
                        latitude,
                        longitude,    
                    },
                    note,
                    creation_date,
                    creation_user,
                    modify_date,
                    modify_user,
                    canDelete,
                },
                inspection_interval,
                next_inspection,
                type{
                    id,
                    name_german,
                    name_botanic,
                },
                plantation,
                planting_Type,
                training_method,
                location_condition,
                height,
                trunk_height,
                trunk_diameter,
                trunk_amount,
                crown_diameter,
                crown_roots,
                safety_precautions,
                danger_potential,
                vincinity_danger,
                root_condition,
                root_collar_condition,
                trunk_condition,
                crown_root_condition,
                crown_condition,
                damage_degree,
                disc_width,
                disc_length,
                underplanting,
                surface_stabilisation,
                distance_buildings,
                distance_curb,
                distance_street,
                distance_path,
                distance_available_surface,
                distance_border,
                distance_grove,
                distance_waters,
                distance_landline_power,
                distance_landline_phone,
                distance_landline_lighting,
                distance_earth_wire_power,
                distance_earth_wire_phone,
                distance_earth_wire_lighting,
                distance_earth_wire_tv,
                distance_earth_wire_water,
                distance_earth_wire_sewage,
                distance_earth_wire_gas,
                distance_earth_wire_teleheating,
                control_group{
                    id,
                    subject {
                        id,
                        shortcut,
                        description,
                        note,
                        creation_date,
                        creation_user,
                        modify_date,
                        modify_user,
                        canDelete,
                    },
                    number,
                    name,
                    creation_date,
                    creation_user,
                    modify_date,
                    modify_user,
                    canDelete
                }
                notice,
                creation_date,
                creation_user,
                modify_date,
                modify_user,
                canDelete    
            }
            photo {
                id,
                thumbnail,
                path,
                file,
                timestamp,
                note,
                creation_date,
                creation_user,
                modify_date,
                modify_user,
                canDelete,
            }
            creation_date
            creation_user
            modify_date
            modify_user
            canDelete
        }
    }
`

@NgModule({
    declarations: [],
    imports: [CommonModule]
  })
export class TreePhotosQueriesModule {}