import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Output,
  Input,
  EventEmitter,
  ViewChild,
  ChangeDetectorRef,
} from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { Subject } from "rxjs";

@Component({
  selector: "app-image-picker",
  templateUrl: "./image-picker.component.html",
  styleUrls: ["./image-picker.component.sass"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ImagePickerComponent implements OnInit {
  @Input() private submitForm: Subject<object> = new Subject();
  @Input() private record: object;
  @Output() values = new EventEmitter();

  public imagePickerForm: FormGroup;

  public picturePreview: any;
  public pictureData: any;

  constructor() {}

  ngOnInit(): void {
    // this.imagePickerForm = new FormGroup({
    //   fileName: new FormControl("", [Validators.required]),
    //   fileInput: new FormControl("", [Validators.required]),
    //   notice: new FormControl(""),
    // });
    // this.imagePickerForm.valueChanges.subscribe((result) => {
    //   if (this.imagePickerForm.valid) {
    //     this.values.emit(result);
    //   } else {
    //     this.values.emit(false);
    //   }
    // });
  }

  // onFileSelected(event) {
  //   let file = event.target.files[0];

  //   if (file.length === 0) {
  //     console.log("Die Datei ist ungültig");
  //     this.imagePickerForm.reset();
  //     this.picturePreview = null;
  //     // this.pictureName = "...";
  //     return;
  //   }

  //   let mimeType = file.type;
  //   if (mimeType.match(/image\/*/) == null) {
  //     console.log("Nur Bilder sind erlaubt");
  //     this.imagePickerForm.reset();
  //     this.picturePreview = null;
  //     // this.pictureName = "...";
  //     return;
  //   } else {
  //     // this.pictureName = file.name;
  //   }

  //   this.imagePickerForm.patchValue({ fileName: file.name });

  //   let reader = new FileReader();
  //   reader.readAsDataURL(file);
  //   reader.onload = (_event) => {
  //     this.picturePreview = reader.result;
  //     this.pictureData = file;
  //     this.changeDetectorRef.detectChanges();
  //   };
  // }

  // test() {
  //   console.log(this.imagePickerForm.value);
  // }

  public files: any[] = [];

  onFileDropped(files) {
    console.log(files);
    this.prepareFilesList(files);
  }

  fileBrowseHandler(files) {
    console.log(files);
    this.prepareFilesList(files);
  }

  prepareFilesList(files: Array<any>) {
    for (const item of files) {
      this.files.push(item);
    }
  }

  /**
   * format bytes
   * @param bytes (File size in bytes)
   * @param decimals (Decimals point)
   */
  formatBytes(bytes, decimals?) {
    if (bytes === 0) {
      return "0 Bytes";
    }
    const k = 1024;
    const dm = decimals <= 0 ? 0 : decimals || 2;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  }

  /**
   * Delete file from files list
   * @param index (File index)
   */
  deleteFile(index: number) {
    this.files.splice(index, 1);
    if(this.files.length <= 0){
      this.values.emit(false);
    }
  }


}
