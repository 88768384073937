<div class="contentWrapper">
  <div class="damageListingHeader">Schadenskatalog</div>
  <cdk-virtual-scroll-viewport itemSize="44" class="scrollViewport">
    <div
      *cdkVirtualFor="let treeDamageListing of treeDamageListings"
      class="listItem"
    >
      <span>
        <span>[{{ treeDamageListing.number }}] </span
        >{{ treeDamageListing.damage_type }}
      </span>
    </div>
  </cdk-virtual-scroll-viewport>
</div>
