import { NgModule } from "@angular/core";

import { GlobalModulesModule } from "src/app/global-modules.module";

import { DefaultValueCardComponent } from "./default-value-card/default-value-card.component";
import { DefaultValueAddDialogComponent } from "./default-value-add-dialog/default-value-add-dialog.component";
import { DefaultValueAddEditFormComponent } from "./default-value-add-edit-form/default-value-add-edit-form.component";
import { CustomDialogboxModule } from "../dialog-boxes/custom-dialogbox.module";

@NgModule({
  declarations: [
    DefaultValueCardComponent,
    DefaultValueAddEditFormComponent,
    DefaultValueAddDialogComponent,
  ],
  imports: [GlobalModulesModule, CustomDialogboxModule],
  exports: [
    DefaultValueCardComponent,
    DefaultValueAddEditFormComponent,
    DefaultValueAddDialogComponent,
  ],
})
export class DefaultValueCardModule {}
