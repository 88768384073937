import { NgModule } from "@angular/core";
import { GlobalModulesModule } from "src/app/global-modules.module";

import { ColorPickerDialogComponent } from "./color-picker-dialog/color-picker-dialog.component";
import { ColorPickerComponent } from "./color-picker.component";


@NgModule({
  declarations: [
    ColorPickerDialogComponent,
    ColorPickerComponent,
  ],
  imports: [GlobalModulesModule],
  exports: [
    ColorPickerDialogComponent,
    ColorPickerComponent,
  ],
})
export class ColorPickerModule {}
