import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { ColorSketchModule } from 'ngx-color/sketch';


@Component({
  selector: 'app-color-picker-dialog',
  templateUrl: './color-picker-dialog.component.html',
  styleUrls: ['./color-picker-dialog.component.sass']
})
export class ColorPickerDialogComponent {


  initColor = '#213455'
  saveColor: any;


  constructor(private dialogRef: MatDialogRef<ColorPickerDialogComponent>,
    @Inject(MAT_DIALOG_DATA) data) {
      if (data.initColor) {
        this.initColor = data.initColor;
        this.saveColor = data.initColor;      
      } 
    }

  save() {
    this.dialogRef.close(this.initColor);
  }

  close() {
    this.dialogRef.close(this.saveColor);
  }

  changeComplete(event) {
    this.initColor = event.color.hex;
  }


}
