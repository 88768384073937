<div class="playground-photo-container">
  <div class="playground-photo-thumbnail-container">
    <app-dialog-box-trigger
      [formComponent]="imageDiashow"
      [dimensions]="{ width: 'auto', height: 'auto' }"
      [record]="playgroundPhoto"
      (submitValue)="(null)"
      [noButton]="true"
      [imageDiashow]="true"
      class="diashowTrigger"
    >
      <app-show-thumbnail
        noButton
        [thumbnailID]="thumbnailID"
        class="playground-photo-thumbnail"
      >
      </app-show-thumbnail>
    </app-dialog-box-trigger>
  </div>
  <ng-container *ngIf="photoCount !== 0; else noPhotos">
    <div
      class="photoCount"
      matBadge="{{ photoCount }}"
      matBadgeOverlap="true"
    ></div>
  </ng-container>
  <ng-template #noPhotos>
    <div class="playground-photo-thumbnail-nophoto">
      <!-- Keine Fotos vorhanden -->
    </div>
  </ng-template>
</div>
