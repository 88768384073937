// // TODO: 9/9/2020 - Aufräumen; Unnötige Datencontainer, Imports und Codesmell entfernen

// import { Component, OnInit, OnDestroy } from "@angular/core";
// import { PlaygroundDataService } from "src/app/services/playground/playground-data.service";
// import { PlaygroundDefectsService } from "src/app/services/playground/playground-defects.service";

// import { FilterFunctionality } from "src/app/modules/filter-choice/filter-base";
// import { ComponentStateManagement } from "src/app/baseclasses/component-state-management/component-state-management";
// import { ComponentStateService } from "src/app/services/general/component-state/component-state.service";

// @Component({
//   selector: "app-spielplaetze",
//   templateUrl: "./spielplaetze.component.html",
//   styleUrls: ["./spielplaetze.component.sass"],
// })
// export class SpielplaetzeComponent implements OnInit, OnDestroy {
//   public type: string = "component";

//   public disabled: boolean = false;

//   static reloadOnce = true;

//   playgroundSorting = {
//     sortBy: [
//       { value: "number", display: "Spielplatz-Nummer", selected: false },
//       { value: "description", display: "Spielplatz-Name", selected: false },
//       { value: "inventoryCount", display: "Inventar-Anzahl", selected: false },
//       {
//         value: "lastInspection",
//         display: "letzte Überprüfung",
//         selected: false,
//       },
//     ],
//     sortDirection: [
//       { value: "asc", display: "Aufsteigend", selected: false },
//       { value: "desc", display: "Absteigend", selected: false },
//     ],
//   };

//   componentIdentifier = "playgroundLocationTree";

//   playgroundMapData: Array<object>;

//   deactivated_playgrounds = "deactivated_playgrounds";

//   inventory_empty = "inventory_empty";
//   open_defects = "open_defects";

//   openDefects = 0;
//   openDefectsIDs = [];

//   filterObject = {
//     open_defects: {
//       filter: {
//         filterArray: this.openDefectsIDs,
//         filterType: "OR",
//         filterFor: "id",
//         specialFilter: null,
//       },
//       label: "Mit offenen Mängeln",
//     },
//     deactivated_playgrounds: {
//       filter: {
//         filterArray: ["0000-00-00 00:00:00", null],
//         filterType: "AND",
//         filterFor: null,
//         specialFilter: null,
//       },
//     },
//   };

//   checkedShowDecomissionedPlayground = false;

//   playgroundDataCount = 0;

//   playgroundObjectSubscription: any;
//   playgroundDefectsSubscription: any;
//   playgroundDataCountSubscription: any;

//   playgroundData = {
//     Playground: null,
//   };

//   filterFunctions: FilterFunctionality;
//   stateIdentifier = "playgrounds";
//   stateManagement: ComponentStateManagement;

//   // dataIsLoaded: BehaviorSubject<number>;
//   // dataIsLoadedSubscription: any;

//   constructor(
//     private dataService: PlaygroundDataService,
//     private defectService: PlaygroundDefectsService,
//     private componentState: ComponentStateService
//   ) {
//     this.filterFunctions = new FilterFunctionality(
//       dataService,
//       this.filterObject
//     );
//     // this.dataIsLoaded = new BehaviorSubject<number>(0);
//   }

//   ngOnInit() {
//     if (this.componentIdentifier) {
//       this.stateManagement = new ComponentStateManagement(
//         this.stateIdentifier,
//         this.componentState
//       );
//     }

//     let componentState = this.stateManagement.load();
//     if (componentState) {
//       this._toggleSortingSelection(
//         this.playgroundSorting.sortBy,
//         componentState.componentStates.sortBy
//       );
//       this._toggleSortingSelection(
//         this.playgroundSorting.sortDirection,
//         componentState.componentStates.sortDirection
//       );

//       this.filterFunctions.restoreFilterState(
//         componentState.componentStates.filterState
//       );
//     }

//     this.playgroundObjectSubscription =
//       this.dataService.playgroundData.subscribe((result) => {

//         this.playgroundData.Playground = result;
//         this.playgroundMapData = this.playgroundData.Playground.slice(0);
//       });

//     this.playgroundDefectsSubscription =
//       this.defectService.allOpenDefectsCount.subscribe((result) => {
//         if (Array.isArray(result)) {
//           this.openDefectsIDs = result.reduce((accumulator, value) => {
//             if (value.counts.all - value.counts["ohne Beanstandung"] > 0) {
//               accumulator.push(value.playground);
//             }
//             return accumulator;
//           }, []);

//           this.filterObject["open_defects"].filter.filterArray =
//             this.openDefectsIDs;
//           this.openDefects = result.length;
//         }
//       });

//     this.playgroundDataCountSubscription =
//       this.dataService.playgroundDataCount.subscribe((result) => {
//         this.playgroundDataCount = result;
//       });

//     if (this.checkedShowDecomissionedPlayground === false) {
//       this.filterFunctions.addFilter("deactivated_playgrounds");
//     }
//   }

//   ngOnDestroy() {
//     this.playgroundObjectSubscription.unsubscribe();
//     this.playgroundDefectsSubscription.unsubscribe();

//     let filterState = this.filterFunctions.saveFilterState();
//     this.stateManagement.save({
//       componentIdentifier: this.stateIdentifier,
//       componentStates: { filterState: filterState },
//     });
//   }

//   private _toggleSortingSelection(sortingArray, activateValue) {
//     for (let i = 0; i < sortingArray.length; i++) {
//       sortingArray[i].selected = false;
//       if (sortingArray[i].value === activateValue) {
//         sortingArray[i].selected = true;
//       }
//     }
//   }

//   playgroundCount() {
//     return `${this.playgroundData.Playground.length}`;
//     // / ${this.playgroundDataCount}
//   }

//   countEmptyInventory() {
//     let emptyCount = this.playgroundData.Playground.filter((item) => {
//       return item.inventory_items.length === 0;
//     });
//     return emptyCount.length;
//   }

//   countDecomissionedPlaygrounds() {
//     let decomCount = this.playgroundData.Playground.filter((item) => {
//       return (
//         item.decommissioning_date !== "0000-00-00 00:00:00" &&
//         item.decommissioning_date !== null
//       );
//     });
//     return decomCount.length;
//   }

//   sortBy(value) {
//     let sorting = [];
//     switch (value) {
//       case "inventoryCount":
//         sorting = ["inventory_items", this.dataService.lengthSort];
//         break;
//       case "lastInspection":
//         sorting = ["inspection", this.dataService.lastDateSort];
//         break;
//       default:
//         sorting = [value];
//         break;
//     }
//     //console.log("sorting");
//     this.dataService.currentSorting = sorting;
//     this.stateManagement.save({
//       componentIdentifier: this.stateIdentifier,
//       componentStates: { sortBy: value },
//     });
//   }

//   sortDirection(value) {
//     this.dataService.currentDirection = value;
//     this.stateManagement.save({
//       componentIdentifier: this.stateIdentifier,
//       componentStates: { sortDirection: value },
//     });
//   }
// }

import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { Subject, Subscription } from "rxjs";
import { ShowOnMapComponent } from "src/app/modules/functions/show-on-map/show-on-map.component";
import { PlaygroundDataService } from "src/app/services/playground/playground-data.service";
import { AddEditSpielplatzFormComponent } from "./add-edit-spielplatz-form/add-edit-spielplatz-form.component";
import { debounceTime } from "rxjs/operators";
import { PlaygroundAddressesService } from "src/app/services/playground/playground-addresses.service";

@Component({
  selector: "app-spielplaetze",
  templateUrl: "./spielplaetze.component.html",
  styleUrls: ["./spielplaetze.component.sass"],
})
export class SpielplaetzeComponent implements OnInit, OnDestroy {
  @ViewChild("mainView") mainView;

  public playgroundData;

  public mapData;

  public PlaygroundAddressesServiceType = PlaygroundAddressesService;
  private addressFilterSubscription: Subscription;
  private playgroundDataSubscription: Subscription;
  public addressFilterSubject = new Subject();

  public addEditForm = AddEditSpielplatzFormComponent;
  public showOnMap = ShowOnMapComponent;

  constructor(
    private playgroundDataService: PlaygroundDataService,
  ) {}

  ngOnInit(): void {
    this.playgroundDataSubscription = this.playgroundDataService
      .getAllPlaygroundData()
      .subscribe((result) => {
        console.log(result);
        this.playgroundData = result;

        // this.playgroundData.forEach(element => {
        //   console.log(element.coordinate);
        // });
      });

    this.addressFilterSubscription = this.addressFilterSubject
      .pipe(debounceTime(1000))
      .subscribe((filter) => this.addressFilter(filter));
  }

  ngOnDestroy(): void {
    this.playgroundDataSubscription.unsubscribe();
    this.addressFilterSubscription.unsubscribe();
  }

  addressFilter(selectedAddresses) {
    let filter = { address: [] };

    selectedAddresses.forEach((address) => {
      filter.address.push({
        location: address.location,
        district: address.district,
        street: address.item,
      });
    });

    this.mainView.nativeElement.scroll({ top: 0, behavior: "smooth" });
    this.playgroundDataService.setFilter(filter);
  }

  addPlayground(data) {
    // TODO
    console.log("addPlayground TODO", data);
  }
  editPlayground(data) {
    // TODO
    console.log("editPlayground TODO", data);
  }
}
