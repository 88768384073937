// this is the old graphQl service
// this service can be removed when it is no longer needed and the new graphql-service ("new-graphql.service.ts") is fully implemented

import { Injectable } from "@angular/core";
import { Apollo } from "apollo-angular";
import gql from "graphql-tag";
import { LoginService } from "./general/login.service";

@Injectable({
  providedIn: "root",
})
export class GraphqlService {
  constructor(private apollo: Apollo, private login: LoginService) {}

  /**
   * Wird ans DataobjectGraphQL übergeben, um dort beim Einloggen die
   * Synchronisierung beim Laden der Daten zu gewährleisten.
   */
  provideLoginState() {
    return this.login.loginBehaviorSubject;
  }

  /**
   * @param mutationQuery Die Daten, die verändert werden sollen
   * @param mutationVariables Die Variablen zu den Daten, die zu erändern sind. ACHTUNG! Auf key: null testen, sonst gibt es einen Fehler im Backend
   * @param refreshQueries Ein oder mehr gql Queries zum Auffrischen, die die veränderten Daten anzeigen.
   */
  sendMutation(mutationQuery, mutationVariables, filter?, ...refreshQueries: any[]) {
    let refreshQueryObjects = [];

    if (refreshQueries.length) {
      refreshQueries.forEach((query) => {
        refreshQueryObjects.push({
          query: query,
          variables: {
            offset: 0,
            limit: 100,
            args: {
              sort: {
                column: ["address.country", "address.location", "address.district", "address.street", "address.house_number"],
                direction: "ASC",
              },
              filter: null,
            }
          },
        });
      });
    }

    let query = this.apollo
      .mutate({
        mutation: mutationQuery,
        variables: mutationVariables,
        refetchQueries: refreshQueryObjects,
      })
      .subscribe(
        ({ data }) => {
          console.log("got data", data);
          query.unsubscribe();
          return data;
        },
        (error) => {
          console.log("there was an error sending the query", error);
          alert("Anfrage konnte nicht gesendet werden. Grund: " + error);
          query.unsubscribe();
        }
      );
  }

  /**
   * @param mutationQuery Die Daten, die verändert werden sollen
   * @param mutationVariables Die Variablen zu den Daten, die zu erändern sind. ACHTUNG! Auf key: null testen, sonst gibt es einen Fehler im Backend
   * @param refreshQueries Ein oder mehr gql Queries zum Auffrischen, die die veränderten Daten anzeigen.
   */
  asyncMutation(mutationQuery, mutationVariables, filter?, ...refreshQueries: any[]) {
    let refreshQueryObjects = [];

    if (refreshQueries.length) {
      refreshQueries.forEach((query) => {
        refreshQueryObjects.push({
          query: query,
          variables: {
            offset: 0,
            limit: 100,
            args: {
              sort: {
                column: ["address.country", "address.location", "address.district", "address.street", "address.house_number"],
                direction: "ASC",
              },
              filter: filter,
            }
          },
        });
      });
    }

    return this.apollo.mutate({
      mutation: mutationQuery,
      variables: mutationVariables,
      refetchQueries: refreshQueryObjects,
    });
  }

  /**
   * Methode um alle Daten eines Queries zu bekommen
   * @param dataQuery
   */
  getData(dataQuery, offset?, limit?, args?) {
    return this.apollo.watchQuery({ 
      query: dataQuery,
      variables: {
        offset: offset,
        limit: limit,
        args: args
      },
      fetchPolicy: 'network-only',
      nextFetchPolicy: 'cache-first'
    });
  }

  /**
   * Methode um spezifische Daten mittels Variabel abzufragen
   * @param dataQuery
   * @param queryVariables
   */
  getItem(dataQuery, queryVariables) {
    return this.apollo.watchQuery({
      query: dataQuery,
      variables: queryVariables,
    });
  }
  getDataSection(dataQuery, queryVariables) {
    return this.getItem(dataQuery, queryVariables);
  }

  //********************************************************************************************* */
  // Alles ab hier ist im Grund Legacy Zeug, das an einer Stelle mal gebraucht wurde.
  // Kann bei Gelegenheit entsorgt werden. Sprich: Wenn du das hier liest und
  // AidaGeo an einem Punkt ist, dass es prinzipiell läuft
  // ********************************************************************************************

  /**
   *
   * @param queryData
   */
  createQuery(queryData) {
    let defaultValue = "id";

    let gqlData = gql`
      query getAll {
        ${queryData.config.topic} {
          ${
            Object.entries(queryData.elements).length > 0
              ? Object.keys(queryData.elements)
                  .map(function (key) {
                    return key + "\n";
                  })
                  .join("")
              : defaultValue
          }    
        }
      }
    `;

    return gqlData;
  }

  /**
   *
   * @param result The graphQL data from the server, containing everything (so far)
   * @param tableData The defining object, containing information about the current module, it's config and what shall be viewable, sortable and alike
   *
   * @returns An Object, containing a header and a content segment.
   */
  convertQueryData(result, tableData) {
    let returnData = {
      config: tableData.config,
      header: [],
      content: [],
    };

    for (let i = 0; i < result[tableData.config.topic].length; i++) {
      returnData.content[i] = [];
    }

    for (let key in tableData.elements) {
      if (tableData.elements[key].position !== undefined) {
        returnData.header.splice(tableData.elements[key].position, 0, {
          label: tableData.elements[key].label,
          show: tableData.elements[key].show,
          sort: tableData.elements[key].sort,
        });

        for (let i = 0; i < result[tableData.config.topic].length; i++) {
          returnData.content[i].splice(tableData.elements[key].position, 0, {
            id: result[tableData.config.topic][i]["id"],
            value: result[tableData.config.topic][i][key],
            isFiltered: false,
          });
        }
      } else {
        returnData.header.push({
          label: tableData.elements[key].label,
          show: tableData.elements[key].show,
          sort: tableData.elements[key].sort,
        });

        for (let i = 0; i < result[tableData.config.topic].length; i++) {
          returnData.content[i].push(result[tableData.config.topic][i][key]);
        }
      }
    }

    return returnData;
  }

  /**
   * Sorting the whole data by using an insert sort algorithm
   *
   * @param sortData Object containing the data to be sorted
   * @param sortBy String of label (sortData.header) to be sorted to
   * @param isInitRequest Whether the request is the first one for purpose of deciding the initial order
   */
  sortQueryBy(sortData, sortBy, isInitRequest) {
    let sortingPosition;

    if (isInitRequest) {
      sortData.config.currentSort = "DESC";
    }

    for (let i = 0; i < sortData.header.length; i++) {
      if (sortData.header[i].label === sortBy) {
        sortingPosition = i;
        break;
      }
    }
    if (
      !sortData.config.currentSort ||
      sortData.config.currentSort === "DESC"
    ) {
      for (let i = 1; i < sortData.content.length; i++) {
        let insertData = sortData.content[i];
        let j = i;

        while (
          j > 0 &&
          sortData.content[j - 1][sortingPosition].value >
            insertData[sortingPosition].value
        ) {
          [sortData.content[j - 1], sortData.content[j]] = [
            sortData.content[j],
            sortData.content[j - 1],
          ];
          j--;
        }

        [sortData.content[j], insertData] = [insertData, sortData.content[j]];
      }
      sortData.config.currentSort = "ASC";
    } else {
      for (let i = 1; i < sortData.content.length; i++) {
        let insertData = sortData.content[i];
        let j = i;

        while (
          j > 0 &&
          sortData.content[j - 1][sortingPosition].value <
            insertData[sortingPosition].value
        ) {
          [sortData.content[j - 1], sortData.content[j]] = [
            sortData.content[j],
            sortData.content[j - 1],
          ];
          j--;
        }

        [sortData.content[j], insertData] = [insertData, sortData.content[j]];
      }
      sortData.config.currentSort = "DESC";
    }
    return sortData;
  }

  /**
   * Filters the chosen object depending on the given filter object
   *
   * @param filterData Object containing the table data presented to the user
   * @param filterObject Object containing the user's choice via the form
   * @param moduleData Reference object, containing all the information about the current module
   */
  filterList(filterData, filterObject, moduleData) {
    let isVoidFilter = true;
    for (let key in filterObject) {
      if (
        filterObject[key] !== null &&
        filterObject[key] !== undefined &&
        filterObject[key] !== "" &&
        key !== "id"
      ) {
        isVoidFilter = false;
      }
    }

    let filterPositions = [];

    if (!isVoidFilter) {
      for (let key in filterObject) {
        if (
          filterObject[key] !== undefined &&
          filterObject[key] !== null &&
          filterObject[key] !== ""
        ) {
          if (moduleData.elements[key].position) {
            filterPositions.push({
              position: moduleData.elements[key].position,
              value: filterObject[key],
            });
          }
        }
      }

      for (let i = 0; i < filterData.content.length; i++) {
        for (let j = 0; j < filterData.content[i].length; j++) {
          if (filterData.content[i][j].hasOwnProperty("isFiltered")) {
            filterData.content[i][j].isFiltered = true;
          }
        }

        for (let f = 0; f < filterPositions.length; f++) {
          if (
            filterData.content[i][
              filterPositions[f].position
            ].value.toString() === filterPositions[f].value.toString()
          ) {
            filterData.content[i][filterPositions[f].position].isFiltered =
              false;
          }
        }
      }
    } else {
      for (let i = 0; i < filterData.content.length; i++) {
        for (let j = 0; j < filterData.content[i].length; j++) {
          if (filterData.content[i][j].hasOwnProperty("isFiltered")) {
            filterData.content[i][j].isFiltered = false;
          }
        }
      }
    }

    return filterData;
  }
}
