<div class="treeOverviewHeaderWrapper">
  <span>
    <mat-icon svgIcon="baumkontrolle"></mat-icon>
  </span>
  Bauminformationen
</div>
<div class="accordionWrapper">
  <mat-accordion class="accordionTreeOverview" displayMode="flat" multi>
    <mat-expansion-panel *ngFor="let tree of treeSelection">
      <mat-expansion-panel-header>
        <mat-panel-title style="margin: 0;">
          <div class="treeTitleWrapper">
            <span
              class="expansionPanelTreeHeader"
              matTooltipShowDelay="1000"
              matTooltip="{{ tree.id_number }} {{
                this.addressService.addressObjToString(
                  {
                    street: tree.address.street ? tree.address.street : '',
                    house_number: tree.address.house_number
                      ? tree.address.house_number
                      : '',
                    location: tree.address.location ? tree.address.location : ''
                  },
                  true
                )
              }}"
            >
              <span class="treeIdNumber">{{ tree.id_number }}</span>
              {{
                tree.type.name_german
              }}
            </span>
            <div class="treeActions">
              <button
                mat-icon-button
                matTooltipShowDelay="1000"
                matTooltip="Bilder"
              >
                <span class="material-symbols-outlined"> image </span>
              </button>
              <app-dialog-box-trigger
                [dimensions]="{
                  width: '60vw',
                  height: '80vh'
                }"
                [formComponent]="controlMessageManagement"
                [record]="tree"
                [noFooter]="true"
                matTooltipShowDelay="1000"
                matTooltip="Kontrollmeldungen"
              >
                <span class="material-symbols-outlined"> report </span>
              </app-dialog-box-trigger>
              <app-dialog-box-trigger
                [dimensions]="{
                  width: '60vw',
                  height: 'calc(100% - 20vh)'
                }"
                [formComponent]="formComponent"
                [record]="tree"
                (submitValue)="editTree($event)"
                matTooltipShowDelay="1000"
                matTooltip="Baumdaten bearbeiten"
                ><span class="material-symbols-outlined"
                  >edit_document</span
                ></app-dialog-box-trigger
              >
              <button
                mat-icon-button
                (click)="zoomToTree(tree.id); $event.stopPropagation()"
                matTooltipShowDelay="1000"
                matTooltip="Gehe zu Punkt auf Karte"
              >
                <span class="material-symbols-outlined"> pin_drop </span>
              </button>
            </div>
          </div>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <table class="treeInfosTable">
        <tr>
          <th>Nummer</th>
          <td>{{ tree.number ? tree.number : "-" }}</td>
        </tr>
        <tr>
          <th>Art</th>
          <td>{{ tree.type.name_german ? tree.type.name_german : "-" }}</td>
        </tr>
        <tr>
          <th>Flurstück</th>
          <td>{{ tree.plat_number ? tree.plat_number : "-" }}</td>
        </tr>
        <tr>
          <th>Nächste Kontrolle</th>
          <td *ngIf="tree.next_inspection; else noDate">
            {{ tree.next_inspection | date : "dd.MM.yyyy" : "de" }}
          </td>
          <ng-template #noDate>
            <td>-</td>
          </ng-template>
        </tr>
        <tr>
          <th>Schadstufe</th>
          <td>
            <div
              class="customBadge"
              [style]="{
                'background-color': tree.damage_degree.setting.replace(
                  'color=',
                  ''
                )
              }"
            >
              {{ tree.damage_degree.value }}
            </div>
          </td>
        </tr>
      </table>
    </mat-expansion-panel>
  </mat-accordion>
</div>
