import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Inject, Input, OnInit, Output, ViewChild } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-default-value-add-edit-form',
  templateUrl: './default-value-add-edit-form.component.html',
  styleUrls: ['./default-value-add-edit-form.component.sass']
})
export class DefaultValueAddEditFormComponent implements OnInit, AfterViewInit {
  @Input() private record?: object;
  @Output() values = new EventEmitter();

  @ViewChild("textPill") textPill;
  @ViewChild("colorInput") colorInput;

  private defaultDataName;

  public color;
  public colorCode = "#fff";
  public defaultDataForm: FormGroup;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: object,
    private cd: ChangeDetectorRef,
  ) {

    this.defaultDataName = this.data["otherSettings"].defaultDataName;
    this.color = this.data["otherSettings"].color;
    this.defaultDataForm = new FormGroup({
      value: new FormControl("", [Validators.required]),
      sorting: new FormControl(""),
      setting: new FormGroup({
        color: new FormControl("", [this.isHex()]),
      })
    })
  }

  ngOnInit(): void {
    this.defaultDataForm.valueChanges.subscribe(result=>{
      if(this.defaultDataForm.valid){
        this.values.emit(result);
      }else{
        this.values.emit(false);
      }
    })

    this.defaultDataForm.get("setting").get("color").valueChanges.subscribe(result=>{

      this.textPill.nativeElement.innerHTML = result;

      if(!result.match(/^#[0-9A-Fa-f]{6}$/g)){
        this.colorInput.nativeElement.style.color = "black";
        this.textPill.nativeElement.style.background = "unset";
      }else{
        this.textPill.nativeElement.style.background = result;
        
        let brightness = this.brightnessByColor(result);
  
        if(brightness<=127.5){
          this.colorInput.nativeElement.style.color = "white";
          this.colorInput.nativeElement.style.caretColor = "white";
        }else{
          this.colorInput.nativeElement.style.color = "black";
          this.colorInput.nativeElement.style.caretColor = "black";
        }
      }

    })


  }

  ngAfterViewInit(): void {
    if(this.record){
      this.defaultDataForm.patchValue({
        value: this.record["value"],
        sorting: this.record["sorting"],
      })
      if(this.color){
        this.colorCode = this.record["setting"].replace('color=', '')
        this.defaultDataForm.get('setting').patchValue({
          color: this.record["setting"].replace('color=', '')
        })
      }
    }

    this.cd.detectChanges();
  }

  isHex(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if(control.value.match(/^#[0-9A-Fa-f]{6}$/g) || control.value===""){
        return null;
      }else{
        return { isHex: control.value };
      }
    };
  }

  colorChange(color){
    this.defaultDataForm.get("setting").patchValue({color: color.hex});
  }

  /**
   * @param color (String) The color value in HEX
   * @returns (Number) The brightness value (dark) 0 ... 255 (light)
   */
  brightnessByColor (rgb: string) {
    const hasFullSpec = rgb.length == 7;
    var m = rgb.substr(1).match(hasFullSpec ? /(\S{2})/g : /(\S{1})/g);
    if (m) {
      var r = parseInt(m[0] + (hasFullSpec ? '' : m[0]), 16), g = parseInt(m[1] + (hasFullSpec ? '' : m[1]), 16), b = parseInt(m[2] + (hasFullSpec ? '' : m[2]), 16)
    };

    return ((r*299)+(g*587)+(b*114))/1000;
  }

}
