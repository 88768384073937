<mat-toolbar class="controlToolbar">
    <div class="controlHeader">Inventartyp-Katalog</div>
    <div class="controlActions">
        <!-- <app-add-dialog-icon (click)="openDialog('kategorie', 'add')"></app-add-dialog-icon> -->
        <app-pdf tooltip-data="Alle Spielplätze als PDF ausgeben"></app-pdf>
    </div>
</mat-toolbar>

<app-container class="mainContent" mode="fluid">
    <div class="columnFlex">
        <app-column web="3" tablet="12" *ngFor="let inventoryTypeCategory of inventoryTypeValues | keyvalue">
            <div class="inventoryTypeContainer">
                <mat-card>
                    <div class="inventoryTypeHeader">
                        {{inventoryTypeCategory.key}}
                        <span>
                            <!-- <app-edit-dialog-icon
                                (click)="openDialog('kategorieTyp', 'editType', {category: inventoryTypeCategory.key})">
                            </app-edit-dialog-icon> -->
                        </span>
                    </div>
                    <div class="inventoryTypeValues" *ngFor="let inventoryType of inventoryTypeCategory.value">
                        {{inventoryType.description}}
                        <span>
                            <!-- <app-edit-dialog-icon (click)="openDialog('kategorie', 'edit', inventoryType)">
                            </app-edit-dialog-icon> -->
                            <!-- <app-delete-data [deleteData]='{id: inventoryType.id}'
                                (returnInput)=deleteValue($event)>
                            </app-delete-data> -->
                        </span>
                    </div>
                </mat-card>
            </div>
        </app-column>
    </div>
</app-container>

<!-- <div class="scroll-container has-small-top-padding">
    <div class="two-column-flex">
        <div class="inventory-type-container" *ngFor="let inventoryTypeCategory of inventoryTypeValues | keyvalue">
            <mat-card>
                <div class="inventory-type-header">{{inventoryTypeCategory.key}}
                    <span>
                        <app-add-dialog-icon (click)="openDialog('kategorie', 'add')">
                        </app-add-dialog-icon>
                        <app-edit-dialog-icon
                            (click)="openDialog('kategorieTyp', 'editType', {category: inventoryTypeCategory.key})">
                        </app-edit-dialog-icon>
                    </span>
                </div>
                <div class="inventory-type-values" *ngFor="let inventoryType of inventoryTypeCategory.value">
                    {{inventoryType.description}}
                    <span>
                        <app-edit-dialog-icon (click)="openDialog('kategorie', 'edit', inventoryType)">
                        </app-edit-dialog-icon>
                        <app-delete-data [deleteData]='{id: inventoryType.id}'
                            (returnInput)=deleteValue($event)>
                        </app-delete-data>
                    </span>
                </div>
            </mat-card>
        </div>
    </div>
</div> -->