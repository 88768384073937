import { Injectable } from "@angular/core";
import { Query, QueryRef } from "apollo-angular";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { NewGraphQLService } from "../../new-graphql.service";

@Injectable({
  providedIn: "root",
})
export class DefaultValuesService {
  private graphqlObject: string = "default_value";

  private queryRef: QueryRef<Query>;

  private defaultValueDataObservable: Observable<any>;

  constructor(private newGraphQLService: NewGraphQLService) {}

  getAllDefaultValueData() {
    // create only new stream if not already exist
    if(!this.defaultValueDataObservable){
      this.queryRef = this.newGraphQLService.requestAllData(this.graphqlObject);
  
      this.defaultValueDataObservable = this.queryRef.valueChanges
        .pipe(
          map((result: any) => {
            return result.data.Default_Values.filter((defaultValue) => {
              return (
                defaultValue.table == "Baum" ||
                defaultValue.table == "Baum_Schaden" ||
                defaultValue.table == "Baum_Massnahmen"
              );
            });
          })
        )
        .pipe(map((result) => {
          // sort default values
          let sortedDefaultValues: object = {};
          result.forEach(element => {
            !sortedDefaultValues[element.field] ? sortedDefaultValues[element.field] = [] : null;
            sortedDefaultValues[element.field].push(element);
          });
          return sortedDefaultValues;
        }));
    }

    return this.defaultValueDataObservable;
  }

  // TODO
  addDefaultValueData(defaultValueData: object) {
    console.warn("addDefaultValueData TODO");
  }

  // TODO
  updateDefaultValueData(defaultValueData: object) {
    console.warn("updateDefaultValueData TODO");
  }

  // TODO
  deleteDefaultValueData(defaultValueId: number) {
    console.warn("deleteDefaultValueData TODO");
  }
}
