
<div class="modal is-active" *ngIf="!isLoggedIn">
    <div class="modal-background"></div>
    <div class="modal-content">
        <div class="login-text">AidaGeo Login</div>
        <form [formGroup]="loginForm" (ngSubmit)="loginService()">
            <span class="login-input"><label for="username">Username: </label><input type="text" formControlName="username"></span>
            <span class="login-input"><label for="password">Passwort: </label><input type="password" formControlName="password"></span>
            <div class="error-container">
                <ng-container class="login-error" *ngIf="isLoginError">Fehler: {{loginFeedback}}</ng-container>    
            </div>
            <div class="bottom-elements">
                <a href="mailto:#">Passwort vergessen?</a>
                <button class="button is-info login-button">Login</button>
            </div>
        </form>
    </div>
</div>

<app-header *ngIf="!disableHeader" [clientId]=clientId></app-header>
<router-outlet></router-outlet>