<div class="dialog-content" [formGroup]="form">
    <h2 mat-dialog-title>{{head}}</h2>
    <div class="dialog-content-container">

        <div class="content">

            <mat-grid-list cols="1" rowHeight="95px">
                <mat-grid-tile colspan="1">
                    <mat-form-field floatLabel="always" class="fullWidth" appearance="outline">
                        <mat-label for="Name">Name</mat-label>
                        <input type="text" matInput formControlName="name1" />
                        <mat-error *ngIf="form.get('name1').hasError('required')">
                            Erforderlich
                        </mat-error>
                    </mat-form-field>
                </mat-grid-tile>
            </mat-grid-list>

            <mat-grid-list cols="2" rowHeight="95px">
                <mat-grid-tile colspan="1">
                    <mat-form-field floatLabel="always" class="fullWidth" appearance="outline">
                        <mat-label for="Zusatz">Zusatz</mat-label>
                        <input type="text" matInput formControlName="name2" />
                    </mat-form-field>
                </mat-grid-tile>
                <mat-grid-tile colspan="1">
                    <mat-form-field floatLabel="always" class="fullWidth" appearance="outline">
                        <mat-label for="Nummer">Nummer</mat-label>
                        <input type="text" matInput formControlName="number" />
                    </mat-form-field>
                </mat-grid-tile>
            </mat-grid-list>

            <app-address-map-picker [submitAddress]="submitAddress" [addressInput]="addressObjectMapPicker"></app-address-map-picker>

            <mat-grid-list cols="1" rowHeight="105px">
                <mat-grid-tile colspan="1">
                    <mat-form-field floatLabel="always" class="fullWidth" appearance="outline">
                        <mat-label for="Notizen">Notizen</mat-label>
                        <textarea matInput formControlName="note"></textarea>
                    </mat-form-field>
                </mat-grid-tile>
            </mat-grid-list>

        </div>


    </div>

</div>


<mat-dialog-actions style="padding: 24px 0 24px 0;">
    <div class="row">
        <button class="submit" mat-flat-button (click)="save()">OK</button>
        <button class="cancel" mat-flat-button mat-dialog-close>Abrechen</button>
    </div>
</mat-dialog-actions>



<!-- <h2 mat-dialog-title>{{head}}</h2>
<div class="dialog-content" [formGroup]="form">
    <div class="dialog-content-container">
        <input autofocus class="focus-catcher">
        <div class="field dialog-field">
            <label class="has-text-danger is-required" for="Name">Name</label>
            <input type="text" class="input supplier-name-field" id="Name" formControlName="name1" value="" />
            <span class="supplier-row-container">
                <span>
                    <label class="" for="Zusatz">Zusatz</label>
                    <input type="text" class="input supplier-name2-field" formControlName="name2">
                </span>
                <span>
                    <label class="" for="Nummer">Nummer</label>
                    <input type="text" class="input supplier-number-field" formControlName="number">
                </span>
            </span>
            <label class="" for="Addresse">Adresse</label> -->
            <!--Noch durch die korrekte Komponente zu ersetzen--> 
            <!--<input type="text" class="input supplier-address-field" formControlName="address">-->
           <!-- <app-pick-address class="supplier-address-field" formControlName="address"></app-pick-address>
            <label class="" for="Notizen">Notizen</label>
            <textarea type="textarea" class="input supplier-note-field" rows="5" formControlName="note"></textarea>
        </div>
    </div>
</div>
<div class="dialog-actions">
    <button class="button" (click)="close()">Abbrechen</button>
    <button class="button is-primary" (click)="save()" [disabled]="!form.valid">{{okButtonLabel}}</button>
</div> -->