import { Component, Inject, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { DialogBase } from 'src/app/baseclasses/dialog-base/dialog-base';
import {
  FormGroup,
  FormControl,
  Validators
} from "@angular/forms";
import { BehaviorSubject } from "rxjs";


@Component({
  selector: 'app-inventorytype-entry-dialog',
  templateUrl: './inventorytype-entry-dialog.component.html',
  styleUrls: ['./inventorytype-entry-dialog.component.sass']
})
export class InventorytypeEntryDialogComponent extends DialogBase implements OnInit {

  form: FormGroup;
  setSelectData: BehaviorSubject<Array<string>> = new BehaviorSubject<Array<string>>([]);
  selectData: string[];

  constructor( 
    dialogRef: MatDialogRef<InventorytypeEntryDialogComponent>,
    @Inject(MAT_DIALOG_DATA) data) {
      super(dialogRef, data);
      this.selectData= data.selectValues;
  }

  ngOnInit() {
    this.form = new FormGroup({
      category: new FormControl((this.currentValues? this.currentValues["category"] : null), [Validators.required]),
      description: new FormControl((this.currentValues? this.currentValues["description"] : null)),
      
      
    });

    if (this.currentValues) {
      this.form.addControl("id", new FormControl(this.currentValues.id));
    }
    this.pushData();
  }
  
  pushData() {
      this.setSelectData.next(this.selectData);
  }

}