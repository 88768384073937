<form [formGroup]="form" class="form-container">
    <!-- <span class="input-list-container"> -->
        <span class="instruction-text">(Bitte Wert eingeben oder auswählen)</span>
  <ul class="list-unstyled inputFilterSelectAdd" #inputFilterSelectAddContainer>
      <li>
          <svg class="svg-24-l is-active-symbol" viewBox="0 0 24 24" fill="currentColor" width="24px" height="24px"
              (click)="listActivation(false);">
              <path d="M0 0h24v24H0z" fill="none"/><path d="M10 18h4v-2h-4v2zM3 6v2h18V6H3zm3 7h12v-2H6v2z"/>
          </svg>
          <input class="input inputFilterSelectAdd" #inputFilterSelectAddInput type="text" placeholder="Eingabe" id="eingabe" name="eingabe" size="50"
              (change)="inputUpdate($event.target);"
              (keypress)="inputUpdate($event.target);"
              (paste)="inputUpdate($event.target);"
              (input)="inputUpdate($event.target);"
              (focus)="inputUpdate($event.target);"

              (blur)="listActivation(false);"
              formControlName="dataInput"
              autocomplete="off">

          <!--Plus-Zeichen im Eingabefeld. Laut Arndt erst einmal obsolet geworden-->
          <!-- <svg class="svg-24-r" viewBox="0 0 24 24" fill="black" width="24px" height="24px">
              <path d="M0 0h24v24H0z" fill="none"/>
              <path d="M19 3H5c-1.11 0-2 .9-2 2v14c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm-2 10h-4v4h-2v-4H7v-2h4V7h2v4h4v2z"/>
          </svg> -->
      </li>
      <li *ngIf="showElementsList">
          <ul class="list-unstyled d-none" #inputFilterSelectAddElementsList (mousedown)="falseEvent($event);">
              <li *ngFor="let element of matchedListElements"><span (mousedown)="listSelect($event.target);">{{element}}</span></li>
          </ul>
      </li>
  </ul>
<!-- </span> -->
</form>
