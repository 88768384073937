<!-- <div class="filter-choice-container">
    <mat-chip-list aria-label="Filter selection">
        <mat-chip *ngFor="let filter of filterNameArray" [removable]=true (removed)="filterRemoval(filter)">
            {{filter}}
            <mat-icon matChipRemove>cancel</mat-icon>
        </mat-chip>
    </mat-chip-list>

    <div class="filter-container">
        <button mat-icon-button (click)="openFilterPopup()">
            <mat-icon fontSet="material-symbols-outlined">add</mat-icon>
        </button>

        <div class="filter-popup scale-up-tl"
            [ngClass]="isNotDisplayingFilterList ? 'hideFilterList' : 'displayFilterList'">
            <span class="filter-entry" *ngFor="let filter of filterObject | keyvalue"> -->
<!-- (click)="choseFilter(filter.key)" -->
<!-- <span *ngIf="filter.value.filter.filterFor === 'date'; else noDate">
                    <span [ngSwitch]="filter.key">
                        <span *ngSwitchCase="'timevalues'" (click)="openMonthsDialog($event)">
                            <span class="filterLink">Nach Monaten filtern</span>
                            <div class="months-popup" #testing
                                [ngClass]="isNotDisplayingMonths ? 'hideMonths' : 'displayMonths'"
                                click-stop-propagation>
                                <form [formGroup]="monthForm">
                                    <mat-form-field appearance="fill">
                                        <mat-label>Zeitraum auswählen</mat-label>
                                        <mat-select formControlName="frame" class="month-control">
                                            <mat-option value="1">1 Monat</mat-option>
                                            <mat-option value="3">3 Monate</mat-option>
                                            <mat-option value="6">6 Monate</mat-option>
                                            <mat-option value="9">9 Monate</mat-option>
                                            <mat-option value="12">12 Monate</mat-option>
                                            <mat-option value="24">24 Monate</mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                    <span id="button-container">
                                        <button class="submit" mat-flat-button (click)="setMonths(filter.key)">Übernehmen</button>
                                    </span>
                                </form>
                            </div>
                        </span>
                        <span *ngSwitchCase="'timeframe'" (click)="openTimeframeDialog($event)">
                            <span class="filterLink">Nach Zeitraum filtern</span>

                            <div class="timeframe-popup" #testing
                                [ngClass]="isNotDisplayingTimeframe ? 'hideTimeframe' : 'displayTimeframe'"
                                click-stop-propagation>

                                <form [formGroup]="form">
                                    <mat-form-field floatLabel="always" class="fullWidth" appearance="outline">
                                        <mat-label>Startdatum</mat-label>
                                        <input
                                          matInput
                                          [matDatepicker]="dp_begin"
                                          formControlName="begin"
                                        />
                                        <mat-datepicker-toggle matSuffix [for]="dp_begin"></mat-datepicker-toggle>
                                        <mat-datepicker #dp_begin></mat-datepicker>
                                    </mat-form-field>
                                    <mat-form-field floatLabel="always" class="fullWidth" appearance="outline">
                                        <mat-label>Enddatum</mat-label>
                                        <input
                                          matInput
                                          [matDatepicker]="dp_end"
                                          formControlName="end"
                                        />
                                        <mat-datepicker-toggle matSuffix [for]="dp_end"></mat-datepicker-toggle>
                                        <mat-datepicker #dp_end></mat-datepicker>
                                    </mat-form-field>
                                    <span id="button-container">
                                        <button class="submit" mat-flat-button (click)="setTimeframe(filter.key)">Übernehmen</button>
                                    </span>
                                </form>
                            </div>
                        </span>
                        <span *ngSwitchCase="'years'" (click)="openYearsDialog($event)">
                            <span class="filterLink">Nach einzelnen Jahren filtern</span>

                            <div class="years-popup" #testing
                                [ngClass]="isNotDisplayingYears ? 'hideYears' : 'displayYears'"
                                click-stop-propagation>                               
                                <form [formGroup]="yearsForm">
                                    <mat-form-field appearance="fill">
                                        <mat-label>Jahr</mat-label>
                                        <mat-select formControlName="year" class="month-control">
                                            <mat-option value="1">1 Monat</mat-option>
                                            <mat-option value="3">3 Monate</mat-option>
                                            <mat-option value="6">6 Monate</mat-option>
                                            <mat-option value="9">9 Monate</mat-option>
                                            <mat-option value="12">12 Monate</mat-option>
                                            <mat-option value="24">24 Monate</mat-option>
                                        </mat-select>
                                    </mat-form-field>

                                    <mat-form-field appearance="fill">
                                        <mat-label>Monat</mat-label>
                                        <mat-select formControlName="month" class="month-control">
                                            <mat-option value="1">1 Monat</mat-option>
                                            <mat-option value="3">3 Monate</mat-option>
                                            <mat-option value="6">6 Monate</mat-option>
                                            <mat-option value="9">9 Monate</mat-option>
                                            <mat-option value="12">12 Monate</mat-option>
                                            <mat-option value="24">24 Monate</mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                
                                    <span id="button-container">
                                        <button class="submit" mat-flat-button (click)="setTimeframe(filter.key)">Übernehmen</button>
                                    </span>
                                </form>
                            </div>
                        </span>
                    </span>

                </span>
                <ng-template #noDate>
                        <span (click)="choseFilter(filter.key)">
                            {{filter.value.label}}
                        </span>
                    </ng-template>
            </span>
        </div>
    </div>
</div> -->

<!-- =========NEW============ -->

<div class="chipContainer">
  <mat-chip-list #filterChipList>
    <mat-chip *ngFor="let filter of filterArray">
      {{ filter.name }}
      <button matChipRemove (click)="removeFilter(filter)">
        <mat-icon>cancel</mat-icon>
      </button>
    </mat-chip>
  </mat-chip-list>
</div>

<button
  #menuTrigger
  mat-icon-button
  [matMenuTriggerFor]="menu"
  aria-label="Example icon-button with a menu"
  (click)="clearInputs()"
>
  <span class="material-symbols-outlined">add</span>
</button>
<mat-menu #menu="matMenu">
  <button mat-menu-item [matMenuTriggerFor]="dateRange">
    <span>Zeitraum</span>
  </button>
  <button mat-menu-item [matMenuTriggerFor]="month">
    <span>Monat</span>
  </button>
  <button mat-menu-item [matMenuTriggerFor]="year">
    <span>Jahr</span>
  </button>
</mat-menu>

<mat-menu #dateRange="matMenu" class="noPadding">
  <mat-form-field
    class="noBorder noError"
    click-stop-propagation
    appearance="fill"
    floatLabel="always"
  >
    <mat-label>Enter a date range</mat-label>
    <mat-date-range-input [formGroup]="range" [rangePicker]="rangePicker">
      <input matStartDate formControlName="start" placeholder="TT.MM.JJJJ" />
      <input matEndDate formControlName="end" placeholder="TT.MM.JJJJ" />
    </mat-date-range-input>
    <mat-datepicker-toggle
      matSuffix
      [for]="rangePicker"
    ></mat-datepicker-toggle>
    <mat-date-range-picker #rangePicker></mat-date-range-picker>
  </mat-form-field>
</mat-menu>

<mat-menu #month="matMenu">
  <button mat-menu-item (click)="setMonth(1)">Januar</button>
  <button mat-menu-item (click)="setMonth(2)">Februar</button>
  <button mat-menu-item (click)="setMonth(3)">März</button>
  <button mat-menu-item (click)="setMonth(4)">April</button>
  <button mat-menu-item (click)="setMonth(5)">Mai</button>
  <button mat-menu-item (click)="setMonth(6)">Juni</button>
  <button mat-menu-item (click)="setMonth(7)">Juli</button>
  <button mat-menu-item (click)="setMonth(8)">August</button>
  <button mat-menu-item (click)="setMonth(9)">September</button>
  <button mat-menu-item (click)="setMonth(10)">Oktober</button>
  <button mat-menu-item (click)="setMonth(11)">November</button>
  <button mat-menu-item (click)="setMonth(12)">Dezember</button>
</mat-menu>

<mat-menu #year="matMenu" class="noPadding">
  <mat-form-field
    click-stop-propagation
    appearance="fill"
    floatLabel="always"
    class="noBorder noError"
  >
    <mat-label>Choose a year</mat-label>
    <input
      [formControl]="filterYear"
      matInput
      [matDatepicker]="pyy"
      placeholder="JJJJ"
    />
    <mat-datepicker-toggle matSuffix [for]="pyy"></mat-datepicker-toggle>
    <mat-datepicker #pyy type="year" [yearsPerPage]="12" [yearsPerRow]="2">
    </mat-datepicker>
  </mat-form-field>
</mat-menu>
