<mat-toolbar class="controlToolbar">
    <div class="controlHeader">Lieferant</div>
    <div class="controlActions">
        <!-- <app-add-dialog-icon (click)="openDialog('lieferant', 'add')"></app-add-dialog-icon> -->
    </div>
</mat-toolbar>

<div class="mainContent">
    <mat-drawer-container class="example-container" style="height: 100%; width: 100%">
      <mat-drawer-content >
        <div id="mainView" class="mainView">
          <table>
            <thead>
              <tr>
                <th class="thNumber">
                    Nummer
                </th>
                <th class="thSupplierName">
                    Zulieferer Name
                </th>
                <th class="thAddress">
                    Adresse
                </th>
                <th class="thNote">Notiz</th>
                <th class="thActions"></th>
              </tr>
            </thead>
          </table>
          <cdk-virtual-scroll-viewport id="viewport" itemSize="60" class="virtualScrollViewport">
            <table matSort>
              <tbody>
                <tr
                    *ngFor="let entry of valueObject[currentEntry]"
                >
                  <td class="tdNumber">{{entry.number}}</td>
                  <td class="tdSupplierName">{{entry.name1}} {{entry.name2}}</td>
                  <td class="tdAddress">
                    <ng-container *ngIf="entry.address; else noAddress">
                        {{entry.address.postcode}}
                        {{entry.address.location}}, {{entry.address.street}} {{entry.address.house_number}}
                    </ng-container>
                    <ng-template #noAddress>
                        Keine Adresse zugeordnet
                    </ng-template>
                  </td>
  
                  <td class="tdNote">{{entry.note}}</td>
                  <td class="tdActions">
                    <!-- <app-edit-dialog-icon 
                        (click)="openDialog('lieferant', 'edit', entry)">
                    </app-edit-dialog-icon> -->
                    <!-- <app-delete-data [deleteData]='{id: entry.id}'
                        (returnInput)=deleteSupplier($event)>
                    </app-delete-data> -->
                  </td>
                </tr>
            
              </tbody>
            </table>
          </cdk-virtual-scroll-viewport>
        </div>
      </mat-drawer-content>
  
      <mat-drawer class="alphabetical-scrollbar" style="height: 100%; width: 50px;" #alphabetSideNav mode="side" opened="true" position="end">
        <div #alphabetical class="alphabetical content-frame">
          <ul>
            <li *ngFor="let item of valueObject | keyvalue"><button mat-icon-button (click)="setActive(item.key)">{{item.key}}</button></li>
          </ul>
      </div>
      </mat-drawer>
    </mat-drawer-container>
  </div>



<!-- <div class="has-huge-horizontal-padding flexbox-container">
    <div class="tabs is-boxed supplier-bar">
        <ul>
            <li *ngFor="let item of valueObject | keyvalue"><a (click)="setActive(item.key)">{{item.key}}</a></li>
            <li>
                <app-add-dialog-icon (click)="openDialog('lieferant', 'add')">
                </app-add-dialog-icon>
            </li>
        </ul>
    </div>
    <div class="content-frame">
        <div class="content-container">
            <div>
                <table class="table is-hoverable is-striped is-narrow">
                    <thead class="supplier-head">
                        <td>Nummer</td>
                        <td>Zulieferer Name</td>
                        <td>Adresse</td>
                        <td>Notiz</td>
                        <td></td>
                    </thead>
                    <tbody class="flex-table">
                        <ng-container *ngFor="let entry of valueObject[currentEntry]">
                            <tr class="supplier-body">
                                <td>{{entry.number}}</td>
                                <td>{{entry.name1}} {{entry.name2}}</td>
                                <td *ngIf="entry.address; else noAddress">{{entry.address.postcode}}
                                    {{entry.address.location}}, {{entry.address.street}} {{entry.address.house_number}}
                                </td>
                                <ng-template #noAddress>
                                    <td>
                                        Keine Adresse zugeordnet
                                    </td>
                                </ng-template>
                                <td>{{entry.note}}</td>
                                <td>
                                    <div class="stammdaten-actions-content">
                                        <app-edit-dialog-icon (click)="openDialog('lieferant', 'edit', entry)">
                                        </app-edit-dialog-icon>
                                        <app-delete-data [deleteData]='{id: entry.id}'
                                            (returnInput)=deleteSupplier($event)>
                                        </app-delete-data>
                                    </div>
                                </td>
                            </tr>
                        </ng-container>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</div> -->