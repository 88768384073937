<mat-accordion
  #accordionEl
  multi
  [@.disabled]="disableAnimation"
  [formGroup]="treeForm"
  class="form"
>
  <mat-expansion-panel
    id="0"
    (opened)="onOpenItem(0, accordionEl)"
    (afterCollapse)="onCloseItem(0, accordionEl)"
    [expanded]="true"
  >
    <mat-expansion-panel-header>
      <mat-panel-title> Primärdaten </mat-panel-title>
    </mat-expansion-panel-header>
    <app-row web style="height: 100%">
      <app-column web="4" class="paddingBottom">
        <mat-form-field floatLabel="always" class="fullWidth" appearance="fill">
          <mat-label>Baum-ID</mat-label>
          <input matInput formControlName="id_number" />
          <mat-error *ngIf="treeForm.get('id_number').hasError('typeNumber')">
            Zahl erforderlich
          </mat-error>
          <button
            *ngIf="treeForm.get('id_number').value"
            matSuffix
            mat-icon-button
            aria-label="Clear"
            (click)="this.treeForm.patchValue({ id_number: '' })"
          >
            <mat-icon>close</mat-icon>
          </button>
        </mat-form-field>
      </app-column>
      <app-column web="4" class="paddingBottom">
        <mat-form-field
          floatLabel="always"
          class="fullWidth"
          appearance="fill"
          (click)="this.subscribe('control_group')"
        >
          <mat-label>Kontrollgruppe</mat-label>
          <mat-select
            formControlName="control_group"
            [value]="0"
            disableOptionCentering
            panelClass="disableOptionCentering"
          >
            <mat-option [value]="0">--</mat-option>
            <mat-option
              *ngFor="let controlGroup of controlGroups"
              [value]="controlGroup['id']"
            >
              {{ controlGroup["Control_Group_String"] }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </app-column>
      <app-column web="4" class="paddingBottom">
        <app-dialog-box-trigger
          style="padding: 0"
          class="fullWidth"
          [dimensions]="{ width: '50vw', maxHeight: '95vh' }"
          submitTitle="Auswahl übernehmen"
          [formComponent]="selectTreeType"
          [noButton]="true"
          (submitValue)="setTreeType($event)"
        >
          <mat-form-field
            noButton
            floatLabel="always"
            class="fullWidth"
            appearance="fill"
            formGroupName="treeType"
            openDialog
          >
            <mat-label>Art (deutsch)</mat-label>
            <input
              #nameGermanInput
              title=""
              matInput
              formControlName="name_german"
              required
              readonly
            />
            <mat-error
              *ngIf="
                treeForm.get('treeType').get('name_german').hasError('required')
              "
            >
              Erforderlich
            </mat-error>
            <div matSuffix style="display: flex">
              <button
                matSuffix
                *ngIf="treeForm.get('treeType').get('name_german').value"
                mat-icon-button
                aria-label="Clear"
                (click)="
                  $event.stopPropagation();
                  this.treeForm
                    .get('treeType')
                    .patchValue({ name_german: '', name_botanic: '' })
                "
              >
                <mat-icon>close</mat-icon>
              </button>
              <div class="dialogIcon">
                <span class="material-symbols-outlined"> article </span>
              </div>
            </div>
          </mat-form-field>
        </app-dialog-box-trigger>
      </app-column>
      <app-column web="4" class="paddingBottom">
        <mat-form-field floatLabel="always" class="fullWidth" appearance="fill">
          <mat-label>Baum-Nummer</mat-label>
          <input matInput formControlName="number" />
          <mat-error *ngIf="treeForm.get('number').hasError('typeNumber')">
            Zahl erforderlich
          </mat-error>
          <button
            *ngIf="treeForm.get('number').value"
            matSuffix
            mat-icon-button
            aria-label="Clear"
            (click)="this.treeForm.patchValue({ number: '' })"
          >
            <mat-icon>close</mat-icon>
          </button>
        </mat-form-field>
      </app-column>
      <app-column web="4" class="paddingBottom">
        <mat-form-field floatLabel="always" class="fullWidth" appearance="fill">
          <mat-label>Nächste Kontrolle</mat-label>
          <input
            matInput
            [matDatepicker]="dp"
            formControlName="next_inspection"
          />
          <mat-datepicker-toggle matSuffix [for]="dp"></mat-datepicker-toggle>
          <mat-datepicker #dp></mat-datepicker>
        </mat-form-field>
      </app-column>
      <app-column web="4" class="paddingBottom">
        <app-dialog-box-trigger
          style="padding: 0"
          class="fullWidth"
          [dimensions]="{ width: '50vw', height: '95vh' }"
          submitTitle="Auswahl übernehmen"
          [formComponent]="selectTreeType"
          [noButton]="true"
          (submitValue)="setTreeType($event)"
        >
          <mat-form-field
            noButton
            floatLabel="always"
            class="fullWidth"
            appearance="fill"
            formGroupName="treeType"
          >
            <mat-label>Art (botanisch)</mat-label>
            <input
              #nameBotanicInput
              matInput
              formControlName="name_botanic"
              title=""
              readonly
              required
            />
            <mat-error
              *ngIf="
                treeForm
                  .get('treeType')
                  .get('name_botanic')
                  .hasError('required')
              "
            >
              Erforderlich
            </mat-error>
            <div matSuffix style="display: flex">
              <button
                *ngIf="treeForm.get('treeType').get('name_botanic').value"
                matSuffix
                mat-icon-button
                aria-label="Clear"
                (click)="
                  $event.stopPropagation();
                  this.treeForm
                    .get('treeType')
                    .patchValue({ name_german: '', name_botanic: '' })
                "
              >
                <mat-icon>close</mat-icon>
              </button>
              <div class="dialogIcon">
                <span class="material-symbols-outlined"> article </span>
              </div>
            </div>
          </mat-form-field>
        </app-dialog-box-trigger>
      </app-column>
      <app-column web="4">
        <mat-form-field floatLabel="always" class="fullWidth" appearance="fill">
          <mat-label>Flurstück-Nr.</mat-label>
          <input matInput formControlName="plat_number" />
          <mat-error *ngIf="treeForm.get('plat_number').hasError('typeNumber')">
            Zahl erforderlich
          </mat-error>
          <button
            *ngIf="treeForm.get('plat_number').value"
            matSuffix
            mat-icon-button
            aria-label="Clear"
            (click)="this.treeForm.patchValue({ plat_number: '' })"
          >
            <mat-icon>close</mat-icon>
          </button>
        </mat-form-field>
      </app-column>
      <app-column web="4">
        <mat-form-field
          floatLabel="always"
          class="fullWidth"
          appearance="fill"
          (click)="this.subscribe('supplier')"
        >
          <mat-label>Lieferant</mat-label>
          <mat-select
            formControlName="supplier"
            [value]="0"
            disableOptionCentering
            panelClass="disableOptionCentering"
          >
            <mat-option [value]="0">--</mat-option>
            <mat-option *ngFor="let supplier of suppliers" [value]="supplier['id']">
              {{ supplier["Supplier_String"] }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </app-column>
      <app-column web="4">
        <mat-form-field floatLabel="always" class="fullWidth" appearance="fill">
          <mat-label>Erziehungsform</mat-label>
          <mat-select
            formControlName="training_method"
            [value]="0"
            disableOptionCentering
            panelClass="disableOptionCentering"
          >
            <mat-option [value]="0">--</mat-option>
            <mat-option
              *ngFor="let BAUErziehungsform of Erziehungsform"
              [value]="BAUErziehungsform['value']"
            >
              {{ BAUErziehungsform["value"] }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </app-column>
    </app-row>
  </mat-expansion-panel>
  <mat-expansion-panel
    id="1"
    (opened)="onOpenItem(1, accordionEl)"
    (afterCollapse)="onCloseItem(1, accordionEl)"
    [expanded]="true"
  >
    <mat-expansion-panel-header>
      <mat-panel-title> Standort </mat-panel-title>
    </mat-expansion-panel-header>
    <app-row web style="height: 100%">
      <app-column web="12" class="paddingBottom">
        <app-dialog-box-trigger
          style="padding: 0"
          class="fullWidth"
          mapHeader="Baumstandort"
          [dimensions]="{ width: '70vw', minHeight: '10vh' }"
          [formComponent]="addressMapPicker"
          [noButton]="true"
          (submitValue)="setAddress($event)"
        >
          <mat-form-field
            noButton
            floatLabel="always"
            class="fullWidth"
            appearance="fill"
            openDialog
            formGroupName="address_obj"
          >
            <mat-label>Adresse</mat-label>
            <input
              #addressInput
              title=""
              matInput
              formControlName="address_string"
              required
              readonly
            />
            <mat-error
              *ngIf="
                treeForm
                  .get('address_obj')
                  .get('address_string')
                  .hasError('required')
              "
            >
              Erforderlich
            </mat-error>
            <div matSuffix style="display: flex">
              <button
                matSuffix
                *ngIf="treeForm.get('address_obj').get('address_string').value"
                mat-icon-button
                aria-label="Clear"
                (click)="
                  $event.stopPropagation();
                  this.treeForm
                    .get('address_obj')
                    .patchValue({ address_string: '' })
                "
              >
                <mat-icon>close</mat-icon>
              </button>
              <div class="dialogIcon">
                <span class="material-symbols-outlined"> pin_drop </span>
              </div>
            </div>
          </mat-form-field>
        </app-dialog-box-trigger>
      </app-column>
      <app-column web="4">
        <mat-form-field
          floatLabel="always"
          class="fullWidth"
          appearance="fill"
          (click)="this.subscribe('defaultValues')"
        >
          <mat-label>Pflanzanlage</mat-label>
          <mat-select
            formControlName="plantation"
            value=""
            disableOptionCentering
            panelClass="disableOptionCentering"
          >
            <mat-option value="">--</mat-option>
            <mat-option
              *ngFor="let Pflanzanlage of Pflanzanlage"
              [value]="Pflanzanlage['value']"
            >
              {{ Pflanzanlage["value"] }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </app-column>
      <app-column web="4">
        <mat-form-field
          floatLabel="always"
          class="fullWidth"
          appearance="fill"
          (click)="this.subscribe('defaultValues')"
        >
          <mat-label>Pflanzungsart</mat-label>
          <mat-select
            formControlName="planting_Type"
            value=""
            disableOptionCentering
            panelClass="disableOptionCentering"
          >
            <mat-option value="">--</mat-option>
            <mat-option
              *ngFor="let plantingType of plantingType"
              [value]="plantingType['value']"
            >
              {{ plantingType["value"] }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </app-column>
      <app-column web="4">
        <mat-form-field
          floatLabel="always"
          class="fullWidth"
          appearance="fill"
          (click)="this.subscribe('defaultValues')"
        >
          <mat-label>Standortbedingungen</mat-label>
          <mat-select
            formControlName="location_condition"
            value=""
            disableOptionCentering
            panelClass="disableOptionCentering"
          >
            <mat-option value="">--</mat-option>
            <mat-option
              *ngFor="let locationCondition of locationCondition"
              [value]="locationCondition['value']"
            >
              {{ locationCondition["value"] }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </app-column>
    </app-row>
  </mat-expansion-panel>
  <mat-expansion-panel
    id="2"
    (opened)="onOpenItem(2, accordionEl)"
    (afterCollapse)="onCloseItem(2, accordionEl)"
    [expanded]="false"
  >
    <mat-expansion-panel-header>
      <mat-panel-title> Größenangaben </mat-panel-title>
    </mat-expansion-panel-header>
    <app-row web style="height: 100%">
      <app-column web="4" class="paddingBottom">
        <mat-form-field floatLabel="always" class="fullWidth" appearance="fill">
          <mat-label>Höhe</mat-label>
          <input title="" type="number" matInput formControlName="height" />
          <mat-error *ngIf="treeForm.get('height').hasError('typeNumber')">
            Zahl erforderlich
          </mat-error>
          <button
            *ngIf="treeForm.get('height').value"
            matSuffix
            mat-icon-button
            aria-label="Clear"
            (click)="this.treeForm.patchValue({ height: '' })"
          >
            <mat-icon>close</mat-icon>
          </button>
        </mat-form-field>
      </app-column>
      <app-column web="4" class="paddingBottom">
        <mat-form-field floatLabel="always" class="fullWidth" appearance="fill">
          <mat-label>Kronen-Durchmesser</mat-label>
          <input title="" type="number" matInput formControlName="crown_diameter" />
          <mat-error
            *ngIf="treeForm.get('crown_diameter').hasError('typeNumber')"
          >
            Zahl erforderlich
          </mat-error>
          <button
            *ngIf="treeForm.get('crown_diameter').value"
            matSuffix
            mat-icon-button
            aria-label="Clear"
            (click)="this.treeForm.patchValue({ crown_diameter: '' })"
          >
            <mat-icon>close</mat-icon>
          </button>
        </mat-form-field>
      </app-column>
      <app-column web="4" class="paddingBottom">
        <mat-form-field floatLabel="always" class="fullWidth" appearance="fill">
          <mat-label>Kronenansatz</mat-label>
          <input title="" type="number" matInput formControlName="crown_roots" />
          <mat-error *ngIf="treeForm.get('crown_roots').hasError('typeNumber')">
            Zahl erforderlich
          </mat-error>
          <button
            *ngIf="treeForm.get('crown_roots').value"
            matSuffix
            mat-icon-button
            aria-label="Clear"
            (click)="this.treeForm.patchValue({ crown_roots: '' })"
          >
            <mat-icon>close</mat-icon>
          </button>
        </mat-form-field>
      </app-column>
      <app-column web="4">
        <mat-form-field floatLabel="always" class="fullWidth" appearance="fill">
          <mat-label>Stammhöhe</mat-label>
          <input title="" type="number" matInput formControlName="trunk_height" />
          <mat-error
            *ngIf="treeForm.get('trunk_height').hasError('typeNumber')"
          >
            Zahl erforderlich
          </mat-error>
          <button
            *ngIf="treeForm.get('trunk_height').value"
            matSuffix
            mat-icon-button
            aria-label="Clear"
            (click)="this.treeForm.patchValue({ trunk_height: '' })"
          >
            <mat-icon>close</mat-icon>
          </button>
        </mat-form-field>
      </app-column>
      <app-column web="4">
        <mat-form-field floatLabel="always" class="fullWidth" appearance="fill">
          <mat-label>Stamm-Durchmesser</mat-label>
          <input title="" type="number" matInput formControlName="trunk_diameter" />
          <mat-error
            *ngIf="treeForm.get('trunk_diameter').hasError('typeNumber')"
          >
            Zahl erforderlich
          </mat-error>
          <button
            *ngIf="treeForm.get('trunk_diameter').value"
            matSuffix
            mat-icon-button
            aria-label="Clear"
            (click)="this.treeForm.patchValue({ trunk_diameter: '' })"
          >
            <mat-icon>close</mat-icon>
          </button>
        </mat-form-field>
      </app-column>
      <app-column web="4">
        <mat-form-field floatLabel="always" class="fullWidth" appearance="fill">
          <mat-label>Stammanzahl</mat-label>
          <input title="" type="number" matInput formControlName="trunk_amount" />
          <mat-error
            *ngIf="treeForm.get('trunk_amount').hasError('typeNumber')"
          >
            Zahl erforderlich
          </mat-error>
          <button
            *ngIf="treeForm.get('trunk_amount').value"
            matSuffix
            mat-icon-button
            aria-label="Clear"
            (click)="this.treeForm.patchValue({ trunk_amount: '' })"
          >
            <mat-icon>close</mat-icon>
          </button>
        </mat-form-field>
      </app-column>
    </app-row>
  </mat-expansion-panel>
  <mat-expansion-panel
    id="3"
    (opened)="onOpenItem(3, accordionEl)"
    (afterCollapse)="onCloseItem(3, accordionEl)"
    [expanded]="false"
  >
    <mat-expansion-panel-header>
      <mat-panel-title> Sonstige </mat-panel-title>
    </mat-expansion-panel-header>
    <app-row web style="height: 100%">
      <app-column web="3">
        <mat-form-field
          floatLabel="always"
          class="fullWidth"
          appearance="fill"
          (click)="subscribe('defaultValues')"
        >
          <mat-label>Schadstufe</mat-label>
          <mat-select
            formControlName="damage_degree"
            value=""
            disableOptionCentering
            panelClass="disableOptionCentering"
          >
            <mat-option value="">--</mat-option>
            <mat-option
              *ngFor="let damageDegree of damageDegrees"
              [value]="damageDegree['value']"
            >
              {{ damageDegree["value"] }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </app-column>
      <app-column web="3">
        <mat-form-field floatLabel="always" class="fullWidth" appearance="fill">
          <mat-label>Pflanzdatum</mat-label>
          <input
            matInput
            [matDatepicker]="plant_date_dp"
            formControlName="plant_date"
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="plant_date_dp"
          ></mat-datepicker-toggle>
          <mat-datepicker #plant_date_dp></mat-datepicker>
        </mat-form-field>
      </app-column>
      <app-column web="3">
        <mat-form-field floatLabel="always" class="fullWidth" appearance="fill">
          <mat-label>Alterserwartung</mat-label>
          <input type="number" matInput formControlName="age_expectation" />
          <mat-error
            *ngIf="treeForm.get('age_expectation').hasError('typeNumber')"
          >
            Zahl erforderlich
          </mat-error>
          <button
            *ngIf="treeForm.get('age_expectation').value"
            matSuffix
            mat-icon-button
            aria-label="Clear"
            (click)="this.treeForm.patchValue({ age_expectation: '' })"
          >
            <mat-icon>close</mat-icon>
          </button>
        </mat-form-field>
      </app-column>
      <app-column web="3">
        <mat-form-field floatLabel="always" class="fullWidth" appearance="fill">
          <mat-label>Fälldatum</mat-label>
          <input
            matInput
            [matDatepicker]="log_date_dp"
            formControlName="log_date"
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="log_date_dp"
          ></mat-datepicker-toggle>
          <mat-datepicker #log_date_dp></mat-datepicker>
        </mat-form-field>
      </app-column>
    </app-row>
  </mat-expansion-panel>
  <mat-expansion-panel
    id="4"
    (opened)="onOpenItem(4, accordionEl)"
    (afterCollapse)="onCloseItem(4, accordionEl)"
    [expanded]="false"
  >
    <mat-expansion-panel-header>
      <mat-panel-title> Baumscheibe </mat-panel-title>
    </mat-expansion-panel-header>
    <app-row web style="height: 100%">
      <app-column web="6" class="paddingBottom">
        <mat-form-field floatLabel="always" class="fullWidth" appearance="fill">
          <mat-label>Breite</mat-label>
          <input title="" type="number" matInput formControlName="disc_width" />
          <mat-error *ngIf="treeForm.get('disc_width').hasError('typeNumber')">
            Zahl erforderlich
          </mat-error>
          <button
            *ngIf="treeForm.get('disc_width').value"
            matSuffix
            mat-icon-button
            aria-label="Clear"
            (click)="this.treeForm.patchValue({ disc_width: '' })"
          >
            <mat-icon>close</mat-icon>
          </button>
        </mat-form-field>
      </app-column>
      <app-column web="6" class="paddingBottom">
        <mat-form-field floatLabel="always" class="fullWidth" appearance="fill">
          <mat-label>Unterpflanzung</mat-label>
          <input title="" matInput formControlName="underplanting" />
          <button
            *ngIf="treeForm.get('underplanting').value"
            matSuffix
            mat-icon-button
            aria-label="Clear"
            (click)="this.treeForm.patchValue({ underplanting: '' })"
          >
            <mat-icon>close</mat-icon>
          </button>
        </mat-form-field>
      </app-column>
      <app-column web="6">
        <mat-form-field floatLabel="always" class="fullWidth" appearance="fill">
          <mat-label>Länge</mat-label>
          <input title="" type="number" matInput formControlName="disc_length" />
          <mat-error *ngIf="treeForm.get('disc_length').hasError('typeNumber')">
            Zahl erforderlich
          </mat-error>
          <button
            *ngIf="treeForm.get('disc_length').value"
            matSuffix
            mat-icon-button
            aria-label="Clear"
            (click)="this.treeForm.patchValue({ disc_length: '' })"
          >
            <mat-icon>close</mat-icon>
          </button>
        </mat-form-field>
      </app-column>
      <app-column web="6">
        <mat-form-field floatLabel="always" class="fullWidth" appearance="fill">
          <mat-label>Oberflächenbefestigung</mat-label>
          <input title="" matInput formControlName="surface_stabilisation" />
          <button
            *ngIf="treeForm.get('surface_stabilisation').value"
            matSuffix
            mat-icon-button
            aria-label="Clear"
            (click)="
              this.treeForm.patchValue({
                surface_stabilisation: ''
              })
            "
          >
            <mat-icon>close</mat-icon>
          </button>
        </mat-form-field>
      </app-column>
    </app-row>
  </mat-expansion-panel>
  <mat-expansion-panel
    id="5"
    (opened)="onOpenItem(5, accordionEl)"
    (afterCollapse)="onCloseItem(5, accordionEl)"
    [expanded]="false"
  >
    <mat-expansion-panel-header>
      <mat-panel-title> Bemerkungen </mat-panel-title>
    </mat-expansion-panel-header>
    <app-row web style="height: 100%">
      <app-column web="4" class="paddingBottom">
        <mat-form-field floatLabel="always" class="fullWidth" appearance="fill">
          <mat-label>Gefahrenpotenzial</mat-label>
          <textarea matInput formControlName="danger_potential"></textarea>
          <button
            *ngIf="treeForm.get('danger_potential').value"
            matSuffix
            mat-icon-button
            aria-label="Clear"
            (click)="this.treeForm.patchValue({ danger_potential: '' })"
          >
            <mat-icon>close</mat-icon>
          </button>
        </mat-form-field>
      </app-column>
      <app-column web="4" class="paddingBottom">
        <mat-form-field floatLabel="always" class="fullWidth" appearance="fill">
          <mat-label>Gefahren Nahbereich</mat-label>
          <textarea matInput formControlName="vincinity_danger"></textarea>
          <button
            *ngIf="treeForm.get('vincinity_danger').value"
            matSuffix
            mat-icon-button
            aria-label="Clear"
            (click)="this.treeForm.patchValue({ vincinity_danger: '' })"
          >
            <mat-icon>close</mat-icon>
          </button>
        </mat-form-field>
      </app-column>
      <app-column web="4" class="paddingBottom">
        <mat-form-field floatLabel="always" class="fullWidth" appearance="fill">
          <mat-label>Schutzvorschriften</mat-label>
          <textarea matInput formControlName="safety_precautions"></textarea>
          <button
            *ngIf="treeForm.get('safety_precautions').value"
            matSuffix
            mat-icon-button
            aria-label="Clear"
            (click)="this.treeForm.patchValue({ safety_precautions: '' })"
          >
            <mat-icon>close</mat-icon>
          </button>
        </mat-form-field>
      </app-column>
      <app-column web="12">
        <mat-form-field floatLabel="always" class="fullWidth" appearance="fill">
          <mat-label>Allgemeine Bemerkungen</mat-label>
          <textarea matInput formControlName="notice"></textarea>
          <button
            *ngIf="treeForm.get('notice').value"
            matSuffix
            mat-icon-button
            aria-label="Clear"
            (click)="this.treeForm.patchValue({ notice: '' })"
          >
            <mat-icon>close</mat-icon>
          </button>
        </mat-form-field>
      </app-column>
    </app-row>
  </mat-expansion-panel>
  <mat-expansion-panel
    id="6"
    (opened)="onOpenItem(6, accordionEl)"
    (afterCollapse)="onCloseItem(6, accordionEl)"
    [expanded]="false"
  >
    <mat-expansion-panel-header>
      <mat-panel-title> Abstand </mat-panel-title>
    </mat-expansion-panel-header>
    <app-row web style="height: 100%">
      <app-column web="4">
        <mat-card header="Allgemein (cm)">
          <app-row web style="height: 100%">
            <app-column web="6" class="paddingBottom">
              <mat-form-field
                floatLabel="always"
                class="fullWidth"
                appearance="fill"
              >
                <mat-label>Gebäude</mat-label>
                <input title="" type="number" matInput formControlName="distance_buildings" />
                <mat-error
                  *ngIf="
                    treeForm.get('distance_buildings').hasError('typeNumber')
                  "
                >
                  Zahl erforderlich
                </mat-error>
                <button
                  *ngIf="treeForm.get('distance_buildings').value"
                  matSuffix
                  mat-icon-button
                  aria-label="Clear"
                  (click)="this.treeForm.patchValue({ distance_buildings: '' })"
                >
                  <mat-icon>close</mat-icon>
                </button>
              </mat-form-field>
            </app-column>
            <app-column web="6" class="paddingBottom">
              <mat-form-field
                floatLabel="always"
                class="fullWidth"
                appearance="fill"
              >
                <mat-label>Nutzfläche</mat-label>
                <input
                  title=""
                  type="number"
                  matInput
                  formControlName="distance_available_surface"
                />
                <mat-error
                  *ngIf="
                    treeForm
                      .get('distance_available_surface')
                      .hasError('typeNumber')
                  "
                >
                  Zahl erforderlich
                </mat-error>
                <button
                  *ngIf="treeForm.get('distance_available_surface').value"
                  matSuffix
                  mat-icon-button
                  aria-label="Clear"
                  (click)="
                    this.treeForm.patchValue({
                      distance_available_surface: ''
                    })
                  "
                >
                  <mat-icon>close</mat-icon>
                </button>
              </mat-form-field>
            </app-column>
            <app-column web="6" class="paddingBottom">
              <mat-form-field
                floatLabel="always"
                class="fullWidth"
                appearance="fill"
              >
                <mat-label>Bordstein</mat-label>
                <input title="" type="number" matInput formControlName="distance_curb" />
                <mat-error
                  *ngIf="treeForm.get('distance_curb').hasError('typeNumber')"
                >
                  Zahl erforderlich
                </mat-error>
                <button
                  *ngIf="treeForm.get('distance_curb').value"
                  matSuffix
                  mat-icon-button
                  aria-label="Clear"
                  (click)="this.treeForm.patchValue({ distance_curb: '' })"
                >
                  <mat-icon>close</mat-icon>
                </button>
              </mat-form-field>
            </app-column>
            <app-column web="6" class="paddingBottom">
              <mat-form-field
                floatLabel="always"
                class="fullWidth"
                appearance="fill"
              >
                <mat-label>Grenze</mat-label>
                <input title="" type="number" matInput formControlName="distance_border" />
                <mat-error
                  *ngIf="treeForm.get('distance_border').hasError('typeNumber')"
                >
                  Zahl erforderlich
                </mat-error>
                <button
                  *ngIf="treeForm.get('distance_border').value"
                  matSuffix
                  mat-icon-button
                  aria-label="Clear"
                  (click)="this.treeForm.patchValue({ distance_border: '' })"
                >
                  <mat-icon>close</mat-icon>
                </button>
              </mat-form-field>
            </app-column>
            <app-column web="6" class="paddingBottom">
              <mat-form-field
                floatLabel="always"
                class="fullWidth"
                appearance="fill"
              >
                <mat-label>Fahrbahn</mat-label>
                <input title="" type="number" matInput formControlName="distance_street" />
                <mat-error
                  *ngIf="treeForm.get('distance_street').hasError('typeNumber')"
                >
                  Zahl erforderlich
                </mat-error>
                <button
                  *ngIf="treeForm.get('distance_street').value"
                  matSuffix
                  mat-icon-button
                  aria-label="Clear"
                  (click)="this.treeForm.patchValue({ distance_street: '' })"
                >
                  <mat-icon>close</mat-icon>
                </button>
              </mat-form-field>
            </app-column>
            <app-column web="6" class="paddingBottom">
              <mat-form-field
                floatLabel="always"
                class="fullWidth"
                appearance="fill"
              >
                <mat-label>Gehölz</mat-label>
                <input title="" type="number" matInput formControlName="distance_grove" />
                <mat-error
                  *ngIf="treeForm.get('distance_grove').hasError('typeNumber')"
                >
                  Zahl erforderlich
                </mat-error>
                <button
                  *ngIf="treeForm.get('distance_grove').value"
                  matSuffix
                  mat-icon-button
                  aria-label="Clear"
                  (click)="this.treeForm.patchValue({ distance_grove: '' })"
                >
                  <mat-icon>close</mat-icon>
                </button>
              </mat-form-field>
            </app-column>
            <app-column web="6">
              <mat-form-field
                floatLabel="always"
                class="fullWidth"
                appearance="fill"
              >
                <mat-label>Weg</mat-label>
                <input title="" type="number" matInput formControlName="distance_path" />
                <mat-error
                  *ngIf="treeForm.get('distance_path').hasError('typeNumber')"
                >
                  Zahl erforderlich
                </mat-error>
                <button
                  *ngIf="treeForm.get('distance_path').value"
                  matSuffix
                  mat-icon-button
                  aria-label="Clear"
                  (click)="this.treeForm.patchValue({ distance_path: '' })"
                >
                  <mat-icon>close</mat-icon>
                </button>
              </mat-form-field>
            </app-column>
            <app-column web="6">
              <mat-form-field
                floatLabel="always"
                class="fullWidth"
                appearance="fill"
              >
                <mat-label>Gewässer</mat-label>
                <input title="" type="number" matInput formControlName="distance_waters" />
                <mat-error
                  *ngIf="treeForm.get('distance_waters').hasError('typeNumber')"
                >
                  Zahl erforderlich
                </mat-error>
                <button
                  *ngIf="treeForm.get('distance_waters').value"
                  matSuffix
                  mat-icon-button
                  aria-label="Clear"
                  (click)="this.treeForm.patchValue({ distance_waters: '' })"
                >
                  <mat-icon>close</mat-icon>
                </button>
              </mat-form-field>
            </app-column>
          </app-row>
        </mat-card>
      </app-column>
      <app-column web="4">
        <mat-card header="Erdleitung (cm)">
          <app-row web style="height: 100%">
            <app-column web="6" class="paddingBottom">
              <mat-form-field
                floatLabel="always"
                class="fullWidth"
                appearance="fill"
              >
                <mat-label>Strom</mat-label>
                <input
                  title=""
                  type="number"
                  matInput
                  formControlName="distance_earth_wire_power"
                />
                <mat-error
                  *ngIf="
                    treeForm
                      .get('distance_earth_wire_power')
                      .hasError('typeNumber')
                  "
                >
                  Zahl erforderlich
                </mat-error>
                <button
                  *ngIf="treeForm.get('distance_earth_wire_power').value"
                  matSuffix
                  mat-icon-button
                  aria-label="Clear"
                  (click)="
                    this.treeForm.patchValue({
                      distance_earth_wire_power: ''
                    })
                  "
                >
                  <mat-icon>close</mat-icon>
                </button>
              </mat-form-field>
            </app-column>
            <app-column web="6" class="paddingBottom">
              <mat-form-field
                floatLabel="always"
                class="fullWidth"
                appearance="fill"
              >
                <mat-label>Wasser</mat-label>
                <input
                  title=""
                  type="number"
                  matInput
                  formControlName="distance_earth_wire_water"
                />
                <mat-error
                  *ngIf="
                    treeForm
                      .get('distance_earth_wire_water')
                      .hasError('typeNumber')
                  "
                >
                  Zahl erforderlich
                </mat-error>
                <button
                  *ngIf="treeForm.get('distance_earth_wire_water').value"
                  matSuffix
                  mat-icon-button
                  aria-label="Clear"
                  (click)="
                    this.treeForm.patchValue({
                      distance_earth_wire_water: ''
                    })
                  "
                >
                  <mat-icon>close</mat-icon>
                </button>
              </mat-form-field>
            </app-column>
            <app-column web="6" class="paddingBottom">
              <mat-form-field
                floatLabel="always"
                class="fullWidth"
                appearance="fill"
              >
                <mat-label>Telefon</mat-label>
                <input
                  title=""
                  type="number"
                  matInput
                  formControlName="distance_earth_wire_phone"
                />
                <mat-error
                  *ngIf="
                    treeForm
                      .get('distance_earth_wire_phone')
                      .hasError('typeNumber')
                  "
                >
                  Zahl erforderlich
                </mat-error>
                <button
                  *ngIf="treeForm.get('distance_earth_wire_phone').value"
                  matSuffix
                  mat-icon-button
                  aria-label="Clear"
                  (click)="
                    this.treeForm.patchValue({
                      distance_earth_wire_phone: ''
                    })
                  "
                >
                  <mat-icon>close</mat-icon>
                </button>
              </mat-form-field>
            </app-column>
            <app-column web="6" class="paddingBottom">
              <mat-form-field
                floatLabel="always"
                class="fullWidth"
                appearance="fill"
              >
                <mat-label>Abwasser</mat-label>
                <input
                  title=""
                  type="number"
                  matInput
                  formControlName="distance_earth_wire_sewage"
                />
                <mat-error
                  *ngIf="
                    treeForm
                      .get('distance_earth_wire_sewage')
                      .hasError('typeNumber')
                  "
                >
                  Zahl erforderlich
                </mat-error>
                <button
                  *ngIf="treeForm.get('distance_earth_wire_sewage').value"
                  matSuffix
                  mat-icon-button
                  aria-label="Clear"
                  (click)="
                    this.treeForm.patchValue({
                      distance_earth_wire_sewage: ''
                    })
                  "
                >
                  <mat-icon>close</mat-icon>
                </button>
              </mat-form-field>
            </app-column>
            <app-column web="6" class="paddingBottom">
              <mat-form-field
                floatLabel="always"
                class="fullWidth"
                appearance="fill"
              >
                <mat-label>Beleuchtung</mat-label>
                <input
                  title=""
                  type="number"
                  matInput
                  formControlName="distance_earth_wire_lighting"
                />
                <mat-error
                  *ngIf="
                    treeForm
                      .get('distance_earth_wire_lighting')
                      .hasError('typeNumber')
                  "
                >
                  Zahl erforderlich
                </mat-error>
                <button
                  *ngIf="treeForm.get('distance_earth_wire_lighting').value"
                  matSuffix
                  mat-icon-button
                  aria-label="Clear"
                  (click)="
                    this.treeForm.patchValue({
                      distance_earth_wire_lighting: ''
                    })
                  "
                >
                  <mat-icon>close</mat-icon>
                </button>
              </mat-form-field>
            </app-column>
            <app-column web="6" class="paddingBottom">
              <mat-form-field
                floatLabel="always"
                class="fullWidth"
                appearance="fill"
              >
                <mat-label>Gas</mat-label>
                <input
                  title=""
                  type="number"
                  matInput
                  formControlName="distance_earth_wire_gas"
                />
                <mat-error
                  *ngIf="
                    treeForm
                      .get('distance_earth_wire_gas')
                      .hasError('typeNumber')
                  "
                >
                  Zahl erforderlich
                </mat-error>
                <button
                  *ngIf="treeForm.get('distance_earth_wire_gas').value"
                  matSuffix
                  mat-icon-button
                  aria-label="Clear"
                  (click)="
                    this.treeForm.patchValue({ distance_earth_wire_gas: '' })
                  "
                >
                  <mat-icon>close</mat-icon>
                </button>
              </mat-form-field>
            </app-column>
            <app-column web="6">
              <mat-form-field
                floatLabel="always"
                class="fullWidth"
                appearance="fill"
              >
                <mat-label>TV</mat-label>
                <input
                  title=""
                  type="number"
                  matInput
                  formControlName="distance_earth_wire_tv"
                />
                <mat-error
                  *ngIf="
                    treeForm
                      .get('distance_earth_wire_tv')
                      .hasError('typeNumber')
                  "
                >
                  Zahl erforderlich
                </mat-error>
                <button
                  *ngIf="treeForm.get('distance_earth_wire_tv').value"
                  matSuffix
                  mat-icon-button
                  aria-label="Clear"
                  (click)="
                    this.treeForm.patchValue({ distance_earth_wire_tv: '' })
                  "
                >
                  <mat-icon>close</mat-icon>
                </button>
              </mat-form-field>
            </app-column>
            <app-column web="6">
              <mat-form-field
                floatLabel="always"
                class="fullWidth"
                appearance="fill"
              >
                <mat-label>Fernwärme</mat-label>
                <input
                  title=""
                  type="number"
                  matInput
                  formControlName="distance_earth_wire_teleheating"
                />
                <mat-error
                  *ngIf="
                    treeForm
                      .get('distance_earth_wire_teleheating')
                      .hasError('typeNumber')
                  "
                >
                  Zahl erforderlich
                </mat-error>
                <button
                  *ngIf="treeForm.get('distance_earth_wire_teleheating').value"
                  matSuffix
                  mat-icon-button
                  aria-label="Clear"
                  (click)="
                    this.treeForm.patchValue({
                      distance_earth_wire_teleheating: ''
                    })
                  "
                >
                  <mat-icon>close</mat-icon>
                </button>
              </mat-form-field>
            </app-column>
          </app-row>
        </mat-card>
      </app-column>
      <app-column web="4">
        <mat-card header="Freileitung (cm)">
          <app-row web style="height: 100%">
            <app-column web="6" class="paddingBottom">
              <mat-form-field
                floatLabel="always"
                class="fullWidth"
                appearance="fill"
              >
                <mat-label>Strom</mat-label>
                <input
                  title=""
                  type="number"
                  matInput
                  formControlName="distance_landline_power"
                />
                <mat-error
                  *ngIf="
                    treeForm
                      .get('distance_landline_power')
                      .hasError('typeNumber')
                  "
                >
                  Zahl erforderlich
                </mat-error>
                <button
                  *ngIf="treeForm.get('distance_landline_power').value"
                  matSuffix
                  mat-icon-button
                  aria-label="Clear"
                  (click)="
                    this.treeForm.patchValue({ distance_landline_power: '' })
                  "
                >
                  <mat-icon>close</mat-icon>
                </button>
              </mat-form-field>
            </app-column>
            <app-column web="6" class="paddingBottom">
              <mat-form-field
                floatLabel="always"
                class="fullWidth"
                appearance="fill"
              >
                <mat-label>Beleuchtung</mat-label>
                <input
                  title=""
                  type="number"
                  matInput
                  formControlName="distance_earth_wire_lighting"
                />
                <mat-error
                  *ngIf="
                    treeForm
                      .get('distance_earth_wire_lighting')
                      .hasError('typeNumber')
                  "
                >
                  Zahl erforderlich
                </mat-error>
                <button
                  *ngIf="treeForm.get('distance_earth_wire_lighting').value"
                  matSuffix
                  mat-icon-button
                  aria-label="Clear"
                  (click)="
                    this.treeForm.patchValue({
                      distance_earth_wire_lighting: ''
                    })
                  "
                >
                  <mat-icon>close</mat-icon>
                </button>
              </mat-form-field>
            </app-column>
            <app-column web="6">
              <mat-form-field
                floatLabel="always"
                class="fullWidth"
                appearance="fill"
              >
                <mat-label>Telefon</mat-label>
                <input
                  title=""
                  type="number"
                  matInput
                  formControlName="distance_landline_phone"
                />
                <mat-error
                  *ngIf="
                    treeForm
                      .get('distance_landline_phone')
                      .hasError('typeNumber')
                  "
                >
                  Zahl erforderlich
                </mat-error>
                <button
                  *ngIf="treeForm.get('distance_landline_phone').value"
                  matSuffix
                  mat-icon-button
                  aria-label="Clear"
                  (click)="
                    this.treeForm.patchValue({ distance_landline_phone: '' })
                  "
                >
                  <mat-icon>close</mat-icon>
                </button>
              </mat-form-field>
            </app-column>
          </app-row>
        </mat-card>
      </app-column>
    </app-row>
  </mat-expansion-panel>
  <mat-expansion-panel
    id="7"
    (opened)="onOpenItem(7, accordionEl)"
    (afterCollapse)="onCloseItem(7, accordionEl)"
    [expanded]="false"
  >
    <mat-expansion-panel-header>
      <mat-panel-title> Foto / Audio der Ersterfassung </mat-panel-title>
    </mat-expansion-panel-header>
    <app-row web style="height: 100%">
      <app-column web="6">
        <mat-card header="Fotos">
          <app-row web style="height: 100%">
            <app-column web="4" style="display: flex; justify-content: center">
              <span class="material-symbols-outlined"> photo_library </span>
            </app-column>
            <app-column web="4" style="display: flex; justify-content: center">
              <app-dialog-box-trigger
                submitTitle="Bestätigen"
                [dimensions]="{ width: '25vw' }"
                [formComponent]="imagePicker"
                (submitValue)="addImage($event)"
              >
                <span class="material-symbols-outlined">
                  add_photo_alternate
                </span>
              </app-dialog-box-trigger>
            </app-column>
            <app-column web="4" style="display: flex; justify-content: center">
              <span class="material-symbols-outlined"> delete </span>
            </app-column>
          </app-row>
        </mat-card>
      </app-column>
      <app-column web="6">
        <mat-card header="Audio">
          <app-row web style="height: 100%">
            <app-column web="4" style="display: flex; justify-content: center">
              <span class="material-symbols-outlined">
                library_music
              </span>
            </app-column>
            <app-column web="4" style="display: flex; justify-content: center">
              <mat-icon svgIcon="addAudio"></mat-icon>
            </app-column>
            <app-column web="4" style="display: flex; justify-content: center">
              <span class="material-symbols-outlined"> delete </span>
            </app-column>
          </app-row>
        </mat-card>
      </app-column>
    </app-row>
  </mat-expansion-panel>
  <mat-expansion-panel
    id="8"
    (opened)="onOpenItem(8, accordionEl)"
    (afterCollapse)="onCloseItem(8, accordionEl)"
    [expanded]="false"
  >
    <mat-expansion-panel-header>
      <mat-panel-title> In welchen Baumlisten ist der Baum </mat-panel-title>
    </mat-expansion-panel-header>
    <p>Content</p>
  </mat-expansion-panel>
</mat-accordion>
