import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute} from '@angular/router';
import { Routes, RouterModule } from '@angular/router';

import { 
  trigger,
  state,
  style,
  animate,
  transition
} from '@angular/animations';
import {slideSubmenu} from 'src/animations';
import { MediatorService } from '../services/mediator/mediator.service';


@Component({
  selector: 'app-fahrzeugverfolgung',
  animations: [slideSubmenu],
  templateUrl: './fahrzeugverfolgung.component.html',
  styleUrls: ['./fahrzeugverfolgung.component.sass']
})


export class FahrzeugverfolgungComponent implements OnInit {

  sidenavState: any = false;

  isOpen1 = false;
  isOpen2 = false;

  clientId: string;
  constructor(private route: ActivatedRoute, private mediator: MediatorService) { }

  ngOnInit() {
    this.clientId = this.route.snapshot.paramMap.get('client');
    console.log(this.clientId);

    this.sidenavState = this.mediator.getSideNavState();
  }

  onClick(part) {
    console.log("changing");
    if (part == 1) {
      this.isOpen1 = !this.isOpen1;
      this.isOpen2 = false;
    } else if (part == 2) {
      this.isOpen2 = !this.isOpen2;
      this.isOpen1 = false;
    }
  }
}
