<mat-accordion
  #accordionEl
  multi
  [@.disabled]="disableAnimation"
  [formGroup]="playgroundForm"
>
  <mat-expansion-panel
    id="0"
    (opened)="onOpenItem(0, accordionEl)"
    (afterCollapse)="onCloseItem(0, accordionEl)"
    [expanded]="true"
    class="test"
  >
    <mat-expansion-panel-header>
      <mat-panel-title> Primärdaten </mat-panel-title>
    </mat-expansion-panel-header>
    <app-row web style="height: 100%">
      <app-column web="6" class="paddingBottom">
        <mat-form-field floatLabel="always" class="fullWidth" appearance="fill">
          <mat-label>Nummer</mat-label>
          <input matInput formControlName="number" required />
          <mat-error>Zahl erforderlich</mat-error>
          <button
            *ngIf="playgroundForm.get('number').value"
            matSuffix
            mat-icon-button
            aria-label="Clear"
            (click)="this.playgroundForm.patchValue({ number: '' })"
          >
            <mat-icon>close</mat-icon>
          </button>
        </mat-form-field>
      </app-column>
      <app-column web="6" class="paddingBottom">
        <mat-form-field floatLabel="always" class="fullWidth" appearance="fill">
          <mat-label>Bezeichnung</mat-label>
          <input matInput formControlName="description" required />
          <mat-error>Erforderlich</mat-error>
          <button
            *ngIf="playgroundForm.get('description').value"
            matSuffix
            mat-icon-button
            aria-label="Clear"
            (click)="this.playgroundForm.patchValue({ description: '' })"
          >
            <mat-icon>close</mat-icon>
          </button>
        </mat-form-field>
      </app-column>
      <app-column web="4" class="paddingBottom">
        <mat-form-field floatLabel="always" class="fullWidth" appearance="fill">
          <mat-label>Kategorie</mat-label>
          <input matInput formControlName="category" />
          <button
            *ngIf="playgroundForm.get('category').value"
            matSuffix
            mat-icon-button
            aria-label="Clear"
            (click)="this.playgroundForm.patchValue({ category: '' })"
          >
            <mat-icon>close</mat-icon>
          </button>
        </mat-form-field>
      </app-column>
      <app-column web="4" class="paddingBottom">
        <mat-form-field floatLabel="always" class="fullWidth" appearance="fill">
          <mat-label>Produkt Doppik</mat-label>
          <input matInput formControlName="double_entry" />
          <button
            *ngIf="playgroundForm.get('double_entry').value"
            matSuffix
            mat-icon-button
            aria-label="Clear"
            (click)="this.playgroundForm.patchValue({ double_entry: '' })"
          >
            <mat-icon>close</mat-icon>
          </button>
        </mat-form-field>
      </app-column>
      <app-column web="4" class="paddingBottom">
        <mat-form-field
          floatLabel="always"
          class="fullWidth"
          appearance="fill"
          (click)="this.subscribe('supplier')"
        >
          <mat-label>Lieferant</mat-label>
          <mat-select
            formControlName="supplier"
            [value]="0"
            disableOptionCentering
            panelClass="disableOptionCentering"
          >
            <mat-option [value]="0">--</mat-option>
            <mat-option
              *ngFor="let supplier of suppliers"
              [value]="supplier.id"
            >
              {{ supplier["Supplier_String"] }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </app-column>
      <app-column web="6" class="paddingBottom">
        <mat-form-field floatLabel="always" class="fullWidth" appearance="fill">
          <mat-label>Inbetriebnahme</mat-label>
          <input
            matInput
            [matDatepicker]="commissioning_date"
            formControlName="commissioning_date"
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="commissioning_date"
          ></mat-datepicker-toggle>
          <mat-datepicker #commissioning_date></mat-datepicker>
        </mat-form-field>
      </app-column>
      <app-column web="6" class="paddingBottom">
        <mat-form-field floatLabel="always" class="fullWidth" appearance="fill">
          <mat-label>Außerbetriebnahme</mat-label>
          <input
            matInput
            [matDatepicker]="decommissioning_date"
            formControlName="decommissioning_date"
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="decommissioning_date"
          ></mat-datepicker-toggle>
          <mat-datepicker #decommissioning_date></mat-datepicker>
        </mat-form-field>
      </app-column>
      <app-column web="12">
        <mat-form-field floatLabel="always" class="fullWidth" appearance="fill">
          <mat-label>Bemerkung</mat-label>
          <textarea matInput formControlName="note"></textarea>
          <button
            *ngIf="playgroundForm.get('note').value"
            matSuffix
            mat-icon-button
            aria-label="Clear"
            (click)="this.playgroundForm.patchValue({ note: '' })"
          >
            <mat-icon>close</mat-icon>
          </button>
        </mat-form-field>
      </app-column>
    </app-row>
  </mat-expansion-panel>
  <mat-expansion-panel
    id="1"
    (opened)="onOpenItem(1, accordionEl)"
    (afterCollapse)="onCloseItem(1, accordionEl)"
    [expanded]="true"
  >
    <mat-expansion-panel-header>
      <mat-panel-title> Standort </mat-panel-title>
    </mat-expansion-panel-header>
    <app-row web>
      <app-column web="12" class="paddingBottom">
        <app-dialog-box-trigger
          style="padding: 0"
          class="fullWidth"
          mapHeader="Spielplatz-Standort"
          [dimensions]="{ width: '70vw', minHeight: '10vh' }"
          [formComponent]="addressMapPicker"
          [noButton]="true"
          (submitValue)="setAddress($event)"
        >
          <mat-form-field
            noButton
            floatLabel="always"
            class="fullWidth"
            appearance="fill"
            openDialog
            [formGroup]="addressField"
          >
            <mat-label>Adresse</mat-label>
            <input
              #addressInput
              formControlName="address_string"
              title=""
              matInput
              required
              readonly
            />
            <mat-error>Erforderlich</mat-error>
            <div matSuffix style="display: flex">
              <button
                matSuffix
                *ngIf="addressField.get('address_string').value"
                mat-icon-button
                aria-label="Clear"
                (click)="
                  $event.stopPropagation();
                  this.playgroundForm.patchValue({ address: '' });
                  this.addressField.patchValue({ address_string: '' })
                "
              >
                <mat-icon>close</mat-icon>
              </button>
              <div class="dialogIcon">
                <span class="material-symbols-outlined"> pin_drop </span>
              </div>
            </div>
          </mat-form-field>
        </app-dialog-box-trigger>
      </app-column>
      <app-column web="4" class="paddingBottom">
        <mat-form-field floatLabel="always" class="fullWidth" appearance="fill">
          <mat-label>Lage</mat-label>
          <input matInput formControlName="location" />
          <button
            *ngIf="playgroundForm.get('location').value"
            matSuffix
            mat-icon-button
            aria-label="Clear"
            (click)="this.playgroundForm.patchValue({ location: '' })"
          >
            <mat-icon>close</mat-icon>
          </button>
        </mat-form-field>
      </app-column>
      <app-column web="4" class="paddingBottom">
        <mat-form-field floatLabel="always" class="fullWidth" appearance="fill">
          <mat-label>Fläche</mat-label>
          <input placeholder="m²" matInput formControlName="area" />
          <mat-error>Zahl erforderlich</mat-error>
          <button
            *ngIf="playgroundForm.get('area').value"
            matSuffix
            mat-icon-button
            aria-label="Clear"
            (click)="this.playgroundForm.patchValue({ area: '' })"
          >
            <mat-icon>close</mat-icon>
          </button>
        </mat-form-field>
      </app-column>
      <app-column web="4" class="paddingBottom">
        <mat-form-field floatLabel="always" class="fullWidth" appearance="fill">
          <mat-label>Einfriedung</mat-label>
          <input matInput formControlName="enclosure" />
          <mat-error>Zahl erforderlich</mat-error>
          <button
            *ngIf="playgroundForm.get('enclosure').value"
            matSuffix
            mat-icon-button
            aria-label="Clear"
            (click)="this.playgroundForm.patchValue({ enclosure: '' })"
          >
            <mat-icon>close</mat-icon>
          </button>
        </mat-form-field>
      </app-column>
      <app-column web="6">
        <mat-form-field floatLabel="always" class="fullWidth" appearance="fill">
          <mat-label>Boden</mat-label>
          <input matInput formControlName="soil" />
          <button
            *ngIf="playgroundForm.get('soil').value"
            matSuffix
            mat-icon-button
            aria-label="Clear"
            (click)="this.playgroundForm.patchValue({ soil: '' })"
          >
            <mat-icon>close</mat-icon>
          </button>
        </mat-form-field>
      </app-column>
      <app-column web="6">
        <mat-form-field floatLabel="always" class="fullWidth" appearance="fill">
          <mat-label>Bepflanzung</mat-label>
          <input matInput formControlName="planting" />
          <button
            *ngIf="playgroundForm.get('planting').value"
            matSuffix
            mat-icon-button
            aria-label="Clear"
            (click)="this.playgroundForm.patchValue({ planting: '' })"
          >
            <mat-icon>close</mat-icon>
          </button>
        </mat-form-field>
      </app-column>
    </app-row>
  </mat-expansion-panel>
  <mat-expansion-panel
    id="2"
    (opened)="onOpenItem(2, accordionEl)"
    (afterCollapse)="onCloseItem(2, accordionEl)"
    [expanded]="false"
  >
    <mat-expansion-panel-header>
      <mat-panel-title> Fotos </mat-panel-title>
    </mat-expansion-panel-header>
    TODO
  </mat-expansion-panel>
</mat-accordion>
