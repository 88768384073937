import { Injectable } from "@angular/core";
import {
  HttpClient,
  HttpRequest,
  HttpEvent,
  HttpHeaders,
  HttpParams,
} from "@angular/common/http";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class HttpService {
  constructor(private http: HttpClient) {}

  uploadPicture(pictureData) {
    let formData: FormData = new FormData();
    formData.append("photo", pictureData.file);
    formData.append("comment", pictureData.comment);

    let params = new HttpParams();

    return this.http.request("POST", environment.dataApiUrl + "photo", {
      body: formData,
    });
  }

  requestBigData(type) {
    console.log("requestAllData (http)", type);
    return this.http.request(
      "GET",
      environment.dataApiUrl + "get_big_data?type=" + type
    );
  }

  buildImageLink(imageId: number, thumbnail: boolean = false){
    return `${environment.dataApiUrl}photo?id=${imageId}${thumbnail ? '&thumbnail' : ''}`;
  }
}
