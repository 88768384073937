import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { APOLLO_OPTIONS } from 'apollo-angular';
import { HttpLink } from 'apollo-angular/http';
import { InMemoryCache } from '@apollo/client/core';
import { environment } from "src/environments/environment";
import { offsetLimitPagination } from '@apollo/client/utilities';


//const uri = 'http://srv-aida-geo-angular.logicway.net/api/graphql'; // <-- add the URL of the GraphQL server here
//const uri = 'https://aidageo-angular2.logicway.de/api/graphql'; // <-- add the URL of the GraphQL server here
//const uri = 'http://localhost:4200/api/graphql'; // <-- add the URL of the GraphQL server here
export function createApollo(httpLink: HttpLink) {
  const uri = environment.dataApiUrl + 'graphql';
  return {
    link: httpLink.create({ uri }),
    cache: new InMemoryCache({
      typePolicies: {
        Query: {
          fields: {
            Tree: offsetLimitPagination(),
          },
        },
      },
    }),
  };
}

@NgModule({
  imports: [
    HttpClientModule
  ],
  providers: [
    {
      provide: APOLLO_OPTIONS,
      useFactory: createApollo,
      deps: [HttpLink],
    },
  ],
})
export class GraphQLModule { }
