import { Injectable } from '@angular/core';
import { HttpParams, HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from "src/environments/environment";
import { BehaviorSubject } from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class LoginService {
  loginBehaviorSubject: BehaviorSubject<Object>;

  constructor(private http: HttpClient) {
    this.loginBehaviorSubject = new BehaviorSubject<Object>({state: false, message: null});
  }

  /**
   * Gibt das BehaviorSubject mit dem Login-Status zurück.
   */
  get getLoginStatus() {
    return this.loginBehaviorSubject;
  }

  /**
   * Überprüft die Verbindung zum Server und gibt den Status via BehaviorSubject weiter.
   */
  checkLogin() {
    this.http.request("GET", environment.dataApiUrl + 'login')
    .subscribe(
      data => {
        if (data["info"]) {
          this.loginBehaviorSubject.next({state: true, message: null});
        }
      },
      error => {console.log(error)}
    );
  }


  /**
   * Sendet die Daten via REST Anfrage zum Server, um einen Session Cookie zu bekommen.
   * @param loginData
   */
  login(loginData) {
    this.http.request("POST", environment.dataApiUrl + 'login', loginData).subscribe(
      data => {
        if (data["info"]) {
          this.loginBehaviorSubject.next({state: true, message: null});
        } else {
          this.loginBehaviorSubject.next({state: false, message: data["error"]});
        }
      },
      error => {console.log(error)}
    );
  }
}
