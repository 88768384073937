import { NgModule } from "@angular/core";
import { GlobalModulesModule } from "../global-modules.module";
import { CustomGlobalModules } from "../custom-global-modules.module";

import { BaumkontrolleComponent } from "./baumkontrolle.component";
import { BaumartenComponent } from "./baumarten/baumarten.component";
import { BaumuebersichtComponent } from "./baumuebersicht/baumuebersicht.component";
import { AddEditFormComponent } from "./baumarten/add-edit-form/add-edit-form.component";
import { AddEditTreeFormComponent } from "./baumuebersicht/add-edit-form/add-edit-tree-form.component";
import { BaumVorgabewerteComponent } from "./baumVorgabewerte/baumVorgabewerte.component";
import { NoticesComponent } from "./meldungen/notices.component";
import { SelectTreeTypeFormComponent } from "./baumuebersicht/select-tree-type-form/select-tree-type-form.component";
import { TreeMapSiteModule } from "./tree-map/tree-map-site.module";


@NgModule({
  declarations: [
    BaumkontrolleComponent,
    BaumartenComponent,
    BaumuebersichtComponent,
    AddEditFormComponent,
    AddEditTreeFormComponent,
    BaumVorgabewerteComponent,
    NoticesComponent,
    SelectTreeTypeFormComponent,
  ],
  imports: [GlobalModulesModule, CustomGlobalModules, TreeMapSiteModule],
  exports: [],
})
export class BaumkontrolleModule {}
