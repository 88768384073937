import { Component, OnInit, Input, Pipe, ViewChild } from "@angular/core";
import { MatDialog, MatDialogConfig } from "@angular/material/dialog";
import { PictureDialogComponent } from "./picture-dialog/picture-dialog.component";
import { GraphqlService } from "src/app/services/graphql.service";

@Component({
  selector: "app-show-pictures",
  templateUrl: "./show-pictures.component.html",
  styleUrls: ["./show-pictures.component.sass"]
})
export class ShowPicturesComponent implements OnInit {
  pictureCount = 0;
  allPictures: any;

  @Input() pictures: any;
  @Input() photoCount: number;
  @Input() elementID: number;
  @Input() getPhotosQuery: any;

  constructor(
    public dialog: MatDialog,
    private graphQLService: GraphqlService
  ) {}

  ngOnInit() {
    if (this.pictures) {
      if (this.pictures.length > 0) {
        this.pictureCount = this.pictures.length;
        this.pictureCount = this.photoCount;
      }
    }
  }

  photoMenu() {
    const dialogConfig = new MatDialogConfig();

    if (this.getPhotosQuery) {
      this.graphQLService
        .getItem(this.getPhotosQuery, { id: this.elementID })
        .valueChanges.subscribe(result => {
          console.log(result.data);

          let resultData: any = result.data;

          if (resultData) {
            for (let key in resultData) {
              this.allPictures = result.data[key][0].photo;
            }
          }

          console.log(this.allPictures);

          dialogConfig.disableClose = false;
          dialogConfig.autoFocus = true;
          dialogConfig.width = "780px";

          dialogConfig.data = {
            pictures: this.allPictures
          };

          this.dialog.open(PictureDialogComponent, dialogConfig);
        });
    }
  }
}
