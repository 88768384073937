import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Output,
  EventEmitter,
  OnDestroy,
} from "@angular/core";

@Component({
  selector: "app-add-edit-spielplatz-form",
  templateUrl: "./add-edit-spielplatz-form.component.html",
  styleUrls: ["./add-edit-spielplatz-form.component.sass"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddEditSpielplatzFormComponent implements OnInit, OnDestroy {

  @Output() values = new EventEmitter();

  constructor() {}

  ngOnInit(): void {}

  ngOnDestroy(): void {
  }

  submit(event){
    // this.submitSubject.next(event);
    this.values.emit(event);
  }
}
