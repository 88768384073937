import { AfterViewInit, ChangeDetectorRef, Component, ComponentFactory, ComponentFactoryResolver, ComponentRef, EventEmitter, Inject, Input, OnDestroy, OnInit, Output, ViewChild, ViewContainerRef } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-dialog-box',
  templateUrl: './dialog-box.component.html',
  styleUrls: ['./dialog-box.component.sass']
})
export class DialogBoxComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild("form", { read: ViewContainerRef }) form;

  public componentRef: ComponentRef<any>;
  public values = new Subject();
  private factory: ComponentFactory<any>;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    private cd: ChangeDetectorRef,
    private resolver: ComponentFactoryResolver,
  ) {
  }

  ngOnInit(): void {
  }
  
  ngAfterViewInit(): void {
    this.createComponent()
    
    this.cd.detectChanges();
  }

  ngOnDestroy() {
    this.componentRef.destroy(); 
  }

  createComponent() {
    this.form.clear(); 
    this.factory = this.resolver.resolveComponentFactory(this.data.formComponent);
    this.componentRef = this.form.createComponent(this.factory);
    this.componentRef.instance.mapHeader = this.data.mapHeader;

    if(this.data.record){
      this.componentRef.instance.record = this.data.record;
    }

    if(!this.data.imageDiashow && !this.data.noFooter){
      this.componentRef.instance.values.subscribe(result=>{
        if(this.data.record.id && result){
          result.id = this.data.record.id;
          this.values.next(result);
        }else{
          this.values.next(result);
        }
      })
    }
  }
}