import { Component, forwardRef, Input, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ManageAddressDialogComponent } from '../manage-address/manage-address-dialog/manage-address-dialog.component';



@Component({
  selector: 'app-pick-address-simple',
  templateUrl: './pick-address-simple.component.html',
  styleUrls: ['./pick-address-simple.component.sass'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => PickAddressSimpleComponent),
    multi: true
  }]
})
export class PickAddressSimpleComponent implements ControlValueAccessor, OnInit {

  address: any;
  displayedAddress: string;
  initialAddress: any;

  @Input('value') val: string;


  constructor(public dialog: MatDialog) {}

  ngOnInit() {
    this.initialAddress = this.val;
    if (this.val) {
      this.displayedAddress = this.val;
    }
  }

  onChange: any = () => {};
  onTouched: any = () => {};

  get value() {
    return this.val;
  }

  set value(val) {
    this.val = val;
    this.onChange(val);
    this.onTouched();
  }


  writeValue(address) {
    this.address = address;
    this.value = address;    
    if (address) {
      let district = this.address.district || "";
      this.displayedAddress = `${this.address.street} ${this.address.house_number}, ${this.address.postcode} ${this.address.location} ${district}`;
    }
  }

  registerOnChange(fn) {
    this.onChange = fn;
  }

  registerOnTouched(fn) {
    this.onTouched = fn;
  }

  openAddressDialog() {
    // const dialogConfig = new MatDialogConfig();

    // dialogConfig.disableClose = false;
    // dialogConfig.autoFocus = false;
    // dialogConfig.height = "600px";
    // dialogConfig.width = "800px";

    // dialogConfig.data = {

    // }

    // const dialogRef = this.dialog.open(PickAddressDialogComponent, dialogConfig);

    // dialogRef.afterClosed().subscribe(result => {
    //   this.writeValue(result);
    // })

    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = false;
    dialogConfig.height = "1000px";
    dialogConfig.width = "1200px";

    dialogConfig.data = {
      dataTransfer: this.address
    }

    const dialogRef = this.dialog.open(ManageAddressDialogComponent, dialogConfig);
    
    dialogRef.afterClosed().subscribe(result => {
      console.log(result);
      this.writeValue(result);
    })
  }

}
