import { Component, OnInit, OnDestroy,Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { GraphqlService } from 'src/app/services/graphql.service';
import { environment } from "src/environments/environment";
import { DeletePictureComponent } from 'src/app/modules/functions/delete-picture/delete-picture.component';



@Component({
  selector: 'app-edit-pictures-dialog',
  templateUrl: './edit-pictures-dialog.component.html',
  styleUrls: ['./edit-pictures-dialog.component.sass']
})
export class EditPicturesDialogComponent implements OnInit, OnDestroy {

  baseQuery: any;
  picturesQuery: any;
  updateQuery: any;
  editQuery: any;
  deleteQuery: any;
  playgroundID: any;
  playgroundDescription: any;

  playgroundData: any;
  refinedPlaygroundData: any;

  graphQlUnsubscribe: any;

  constructor(
    private graphQLService: GraphqlService,
    private dialogRef: MatDialogRef<EditPicturesDialogComponent>,
    @Inject(MAT_DIALOG_DATA) data ) {
      this.baseQuery = data.baseQuery;
      this.picturesQuery = data.picturesQuery;
      this.editQuery = data.editQuery;
      this.updateQuery = data.updateQuery;
      this.deleteQuery = data.deleteQuery;
      this.playgroundID = data.playgroundID;
      this.playgroundDescription = data.playgroundDescription
    }

  ngOnInit() {
    this.graphQlUnsubscribe = this.graphQLService.getItem(this.picturesQuery, {id: this.playgroundID}).valueChanges.subscribe(result => {
      this.playgroundData = result.data["Playground"][0].photo;

      this.refinedPlaygroundData = [];

      this.playgroundData.forEach( value => {
        this.refinedPlaygroundData.push({
          id: value.id,
          thumbnail: environment.dataApiUrl + 'photo?id=' + value.id + '&thumbnail',
          note: value.note,
          creationDate: value.creation_date,
          modificationDate: value.modify_date,
          file: value.file
        })
      });
    });

  }


  save() {
    this.dialogRef.close(true);
  }

  close() {
    this.dialogRef.close(false);
  }

  ngOnDestroy() {
    this.graphQlUnsubscribe.unsubscribe();
  }
}
