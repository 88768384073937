<h2 mat-dialog-title>{{head}}</h2>
<div class="dialog-content" [formGroup]="form">
    <div class="dialog-content-container">
        <input autofocus class="focus-catcher">
        <div class="field dialog-field">
            <label class="has-text-danger is-required" for="Name">Erledigung Zeiteinheiten</label>
            <input type="text" class="input" id="Name" formControlName="value" value="" />
            <label class="" for="Sortierung">Sortierung</label>
            <input type="number" class="input number-counter" formControlName="sorting">
        </div>
    </div>
</div>
<div class="dialog-actions">
    <button class="button" (click)="close()">Abbrechen</button>
    <button class="button is-primary" (click)="save()" [disabled]="!form.valid">{{okButtonLabel}}</button>
</div>