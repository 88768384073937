import { Injectable } from "@angular/core";
import { cloneDeep } from "@apollo/client/utilities";
import { Query, QueryRef } from "apollo-angular";
import { Observable } from "rxjs";
import { first, map } from "rxjs/operators";
import { NewGraphQLService } from "../new-graphql.service";

@Injectable({
  providedIn: "root",
})
export class AddressService {
  private graphqlObject: string = "address";
  private queryRef: QueryRef<Query>;
  private addressDataObservable: Observable<any>;

  constructor(private newGraphQlService: NewGraphQLService) {}

  getAllAddressData() {
    // create only new stream if not already exist
    if (!this.addressDataObservable) {
      this.queryRef = this.newGraphQlService.requestAllData(
        this.graphqlObject
      );

      this.addressDataObservable = this.queryRef.valueChanges.pipe(
        map((result: any) => {
          return result.data.Address;
        })
      );
    }

    return this.addressDataObservable;
  }

  addAddressData(addressData: object) {
    let queryRef = this.newGraphQlService.addData(
      this.graphqlObject,
      addressData
    );

    return queryRef.pipe(
      first(),
      map((result: any) => {
        return result.data.add_Address;
      })
    );
  }

  updateAddressData(addressData: object) {
    let queryRef = this.newGraphQlService.updateData(
      this.graphqlObject,
      addressData
    );

    return queryRef.pipe(
      first(),
      map((result: any) => {
        return result.data.update_Tree;
      })
    );
  }

  deleteAddressData(addressId: number) {
    let queryRef = this.newGraphQlService.deleteData(
      this.graphqlObject,
      addressId
    );

    return queryRef.pipe(
      first(),
      map((result: any) => {
        return result.data.delete_Address;
      })
    );
  }

  addressObjToString(addressObj, returnOnlyString?) {
    let addressCopy = cloneDeep(addressObj);

    let string = "";
    string += `${addressObj["street"]}`;
    if (addressObj["house_number"]) {
      string += ` ${addressObj["house_number"]}`;
    }
    if (
      (addressObj["street"] || addressObj["house_number"]) &&
      (addressObj["district"] ||
        addressObj["postcode"] ||
        addressObj["location"] ||
        addressObj["country"])
    ) {
      string += ", ";
    }
    if (addressObj["district"]) string += `${addressObj["district"]}, `;
    if (addressObj["postcode"]) string += `${addressObj["postcode"]}`;
    if (addressObj["location"]) {
      string += ` ${addressObj["location"]}`;
    }
    if (
      (addressObj["postcode"] || addressObj["location"]) &&
      addressObj["country"]
    ) {
      string += ", ";
    }
    if (addressObj["country"]) string += `${addressObj["country"]}`;

    addressCopy["addressString"] = string;

    if (returnOnlyString) {
      return string;
    } else {
      return addressCopy;
    }
  }
}
