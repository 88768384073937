import { AfterViewInit, Directive, ElementRef, EventEmitter, Host, HostListener, OnDestroy, Output } from "@angular/core";

@Directive({
  selector: "[enterTheViewportNotifier]"
})
export class EnterTheViewportNotifierDirective implements AfterViewInit, OnDestroy {
  @Output() visibilityChange: EventEmitter<Array<string>> = new EventEmitter<Array<string>>();

  private _observer: IntersectionObserver;
  private screenHeight: any;

  private options: object;


  constructor(@Host() private _elementRef: ElementRef) {
  }

  @HostListener('window:resize', ['$event'])
  onResize(event){
    this._observer.disconnect();
    if(document.getElementById("viewport")){
      this.screenHeight = document.getElementById("viewport").offsetHeight-3;
  
      this.options = {
        root: document.getElementsByClassName("viewport")[0],
        rootMargin: "-2px 0px -"+(this.screenHeight>=0 ? this.screenHeight : 0)+"px 0px",
        threshold: 0.0
      };
  
      this._observer = new IntersectionObserver(this._callback, this.options);
      
      this._observer.observe(this._elementRef.nativeElement);
    }
  }

  ngAfterViewInit(): void {
    if(document.getElementById("viewport")){
      this.screenHeight = document.getElementById("viewport").offsetHeight-3;

      this.options = {
        root: document.getElementById("viewport"),
        rootMargin: "-2px 0px -"+(this.screenHeight>=0 ? this.screenHeight : 0)+"px 0px",
        threshold: 0.0
      };

      this._observer = new IntersectionObserver(this._callback, this.options);
      
      this._observer.observe(this._elementRef.nativeElement);
    }
  }

  ngOnDestroy() {
    this._observer.disconnect();
  }

  private _callback = (entries, observer) => {
    entries.forEach(entry => {
      this.visibilityChange.emit(entry.isIntersecting ? ["VISIBLE", this._elementRef.nativeElement] : ["HIDDEN", this._elementRef.nativeElement]);
    });
  };
}