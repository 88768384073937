import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import gql from "graphql-tag";

export const getAllPlaygroundAddresses = gql `
    query getAll {
        get_playground_addresses{
            id,
            country,
            postcode,
            location,
            district,
            street,
            house_number,
        }
    }
`

@NgModule({
    declarations: [],
    imports: [CommonModule]
  })
export class PlaygroundAddressQueriesModule {}