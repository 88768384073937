import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import gql from "graphql-tag";

// TODO: Wenn nicht mehr gebraucht: Entfernen ^^
export const getAddressData = gql`
  query Address {
    Address {
      id
      country
      postcode
      location
      district
      street
      house_number
      latitude
      longitude
      note
      creation_date
      creation_user
      modify_date
      modify_user
    }
  }
`;

export const getAllAddressData = gql`
  query Address {
    Address {
      id
      country
      postcode
      location
      district
      street
      house_number
      latitude
      longitude
      note
      creation_date
      creation_user
      modify_date
      modify_user
      canDelete
    }
  }
`;

export const getSingleAddressData = gql`
  query Address($id: Int!) {
    Address(id: $id) {
      id
      country
      postcode
      location
      district
      street
      house_number
      latitude
      longitude
      note
      creation_date
      creation_user
      modify_date
      modify_user
      canDelete
    }
  }
`;

export const addAddressData = gql`
  mutation add_Address(
    $country: String
    $postcode: String
    $location: String
    $district: String
    $street: String
    $house_number: String
    $coordinate: Int
    $note: String
  ) {
    add_Address(
      country: $country
      postcode: $postcode
      location: $location
      district: $district
      street: $street
      house_number: $house_number
      coordinate: $coordinate
      note: $note
    ){
      result,
      error
    }
  }
`;

export const updateAddressData = gql`
  mutation update_Address(
    $id: Int!
    $country: String
    $postcode: String
    $location: String
    $district: String
    $street: String
    $house_number: String
    $coordinate: Int
    $note: String
  ) {
    update_Address(
      id: $id
      country: $country
      postcode: $postcode
      location: $location
      district: $district
      street: $street
      house_number: $house_number
      coordinate: $coordinate
      note: $note
    ){
      result,
      error
    }
  }
`;

export const deleteAddressData = gql`
  mutation delete_Address($id: Int!) {
    delete_Address(id: $id){
      result,
      error
    }
  }
`;

export const updatePhotoQuery = gql`
  mutation update_Photo(
    $id: Int!
    $thumbnail: String
    $path: String
    $file: String
    $timestamp: String
    $note: String
  ) {
    update_Photo(
      id: $id
      thumbnail: $thumbnail
      path: $path
      file: $file
      timestamp: $timestamp
      note: $note
    ){
      result,
      error
    }
  }
`;

// Spezielle Queries

export const addressToCoordinate = gql`
  query address_to_coordinate($country: String, $postcode: String, $city: String, $street: String, $house_number: String) {
    address_to_coordinate(country: $country, postcode: $postcode, city: $city, street: $street, house_number: $house_number) {
      id
      longitude
      latitude
      altitude
    }
  }
`;

export const coordinateToAddress = gql`
  query coordinate_to_address($lat: Float!, $lon: Float!) {
    coordinate_to_address(lat: $lat, lon: $lon) {
      id
      country
      postcode
      location
      district
      street
      house_number
    }
  }
`;


export const getAllPhotos = gql`
  query Photo {
    Photo {
      id
      thumbnail
      path
      file 
      timestamp
      note
      creation_date 
      creation_user 
      modify_date 
      modify_user 
      canDelete 
    }
  }
`;

export const addPhoto = gql`
  mutation add_Photo($thumbnail: String!, $path: String!, $file: String!, $timestamp: String, $note: String) {
    add_Photo(thumbnail: $thumbnail, path: $path, file: $file, timestamp: $timestamp, note: $note) {
      result,
      error
    }
  }
`;

export const updatePhoto = gql`
  mutation update_Photo($id: Int!, $thumbnail: String, $path: String, $file: String, $timestamp: String, $note: String) {
    update_Photo(id: $id, thumbnail: $thumbnail, path: $path, file: $file, timestamp: $timestamp, note: $note){
      result,
      error
    }
  }
`;

export const deletePhoto = gql`
  mutation delete_Photo($id: Int!) {
    delete_Photo(id: $id){
      result,
      error
    }
  }
`;

@NgModule({
  declarations: [],
  imports: [CommonModule]
})
export class GenericQueriesModule {}
