<div class="mapWrapper">
    <div id="map" class="map"></div>
    <ng-container *ngIf="address">
        <div *ngIf="showAddressTakeOver" class="address">
            {{address}}
            <!-- <button mat-icon-button (click)="takeAddress();"><mat-icon>done</mat-icon></button> -->
            <div class="takeAddressIcon" (click)="takeAddress();" matRipple [matRippleCentered]="true" [matRippleUnbounded]="true" [matRippleColor]="'#1e22991a'" matTooltip="Vorgeschlagene Adresse Übernehmen" matTooltipShowDelay="1000">
                <span class="material-symbols-outlined">
                    where_to_vote
                </span>
            </div>
        </div>
    </ng-container>
    <ng-container *ngIf="mapHeader">
        <div class="mapHeader">
            {{mapHeader}}
        </div>
    </ng-container>
</div>