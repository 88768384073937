import { Component, OnInit } from "@angular/core";
import { DataIndex } from "src/app/baseclasses/dataobject/dataobject";
import { SuppliersService } from "src/app/services/general/suppliers.service";
import { DialogHandler } from "src/app/baseclasses/dialog-handler/dialog-handler";
import { MatDialog, MatDialogConfig } from "@angular/material/dialog";
import { LieferantDialogComponent } from "./lieferant-dialog/lieferant-dialog.component";

const valueObjects = {
  lieferant: {
    component: LieferantDialogComponent,
    config: {
      add: {
        id: 1,
        title: "Lieferant anlegen",
        okButtonLabel: "Anlegen",
      },
      edit: {
        id: 1,
        title: "Lieferant bearbeiten",
        okButtonLabel: "Bearbeiten",
      },
    },
  },
};

@Component({
  selector: "app-lieferant",
  templateUrl: "./lieferant.component.html",
  styleUrls: ["./lieferant.component.sass"],
})
export class LieferantComponent implements OnInit {
  valueObject: any = {};
  currentEntry = "A";
  lastEntry = null;

  constructor(
    private suppliersDataService: SuppliersService,
    public matDialog: MatDialog
  ) {}

  ngOnInit() {
    this.suppliersDataService.getAllSupplierData().subscribe((result) => {
      if (result !== null) {
        let listObject;

        if (Array.isArray(result)) {
          listObject = result.reduce((accumulator, item) => {
            let group = item.name1.substring(0, 1).toUpperCase();
            if (!accumulator[group]) {
              accumulator[group] = [item];
            } else {
              accumulator[group].push(item);
            }
            return accumulator;
          }, {});
        }

        let DataObject = new DataIndex(null);
        for (let group of Object.entries(listObject)) {
          DataObject.sortData(group[1], "asc", "name1");
        }

        let allEntries = Object.keys(listObject);
        allEntries.sort();

        if (!this.lastEntry) {
          this.currentEntry = allEntries[0];
        } else {
          if (allEntries.includes(this.lastEntry)) {
            this.currentEntry = this.lastEntry;
          } else {
            this.currentEntry = allEntries[0];
          }
        }
        this.valueObject = listObject;
      } else {
        this.valueObject = {};
      }
    });
  }

  setActive(char) {
    this.currentEntry = char;
  }

  addSupplier(supplierData) {
    this.lastEntry = supplierData.name1.substring(0, 1).toUpperCase();

    // Redundanz weil Funktion als Callback übergeben wird, welcher den Kontext this verliert
    let addressID = null;
    if (supplierData.address) {
      addressID = supplierData.address.id;
    }

    let processedData = {};
    Object.keys(supplierData).forEach((key) => {
      if (key !== "address") {
        processedData[key] = supplierData[key];
      } else {
        processedData[key] = addressID;
      }
    });

    // this.suppliersDataService.addSupplier(processedData);
  }

  updateSupplier(supplierData) {
    this.lastEntry = supplierData.name1.substring(0, 1).toUpperCase();

    // Redundanz weil Funktion als Callback übergeben wird, welcher den Kontext this verliert
    let addressID = supplierData.address.id;
    let processedData = {};
    Object.keys(supplierData).forEach((key) => {
      if (key !== "address") {
        processedData[key] = supplierData[key];
      } else {
        processedData[key] = addressID;
      }
    });

    // this.suppliersDataService.updateSupplier(processedData);
  }

  deleteSupplier(supplierData) {
    this.lastEntry = this.currentEntry;
    // this.suppliersDataService.deleteSupplier(supplierData);
  }

  openDialog(dialog, usage, dataObject?) {
    const callbacks = {
      add: this.addSupplier,
      edit: this.updateSupplier,
    };

    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = valueObjects[dialog].config[usage];
    dialogConfig.data.currentValues = dataObject;

    const testDialog = new DialogHandler(
      this.matDialog,
      valueObjects[dialog].component,
      dialogConfig,
      callbacks[usage],
      this.suppliersDataService
    );
    let tester = testDialog.openDialog();
  }
}
