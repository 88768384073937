<h1 mat-dialog-title>
    Fotos zum Spielplatz "{{playgroundDescription}}"
</h1>
<div mat-dialog-content>

    <ng-container *ngFor="let entry of refinedPlaygroundData">
        <div>
            <div>Hochgeladen am {{entry.creationDate}}</div>
            <img [src]=entry.thumbnail>

            <app-edit-note [baseQuery]=baseQuery [picturesQuery]=picturesQuery [updateQuery]=updateQuery [pictureID]=entry.id [noteValue]=entry.note></app-edit-note>
            <app-delete-picture [baseQuery]=baseQuery [picturesQuery]=picturesQuery [deleteQuery]=deleteQuery [pictureID]=entry.id>
            </app-delete-picture>
        </div>
    </ng-container>
</div>
<div mat-dialog-actions class="custom-actions">
    <button class="button" (click)="close()">Abbrechen</button>
    <button class="button" (click)="save()">Speichern</button>
</div>