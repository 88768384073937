import { Directive, ElementRef, Input, OnInit } from '@angular/core';

@Directive({
  selector: 'mat-card[header]'
})
export class AmCardHeaderDirective implements OnInit {

  @Input() header = '';

  constructor(private el: ElementRef) {}

  ngOnInit(): void {
    let headerDom = document.createElement("div");
    headerDom.appendChild(document.createTextNode(this.header));
    headerDom.style.paddingBottom = "10px";
    headerDom.classList.add("cardHeader");

    this.el.nativeElement.prepend(headerDom);
  }

}
