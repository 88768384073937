import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-input-events',
  templateUrl: './input-events.component.html',
  styleUrls: ['./input-events.component.sass']
})
export class InputEventsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }


  elemClick() {
    console.log("click");
  }

  elemFocus() {
    console.log("focus");
  }

  elemChange() {
    console.log("change");
  }

  elemCueChange() {
    console.log("cue change");
  }

  elemEnded() {
    console.log("ended");
  }  

  elemSelect() {
    console.log("select");
  }
}

