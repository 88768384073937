import { Component, Inject, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { DialogBase } from 'src/app/baseclasses/dialog-base/dialog-base';
import {
  FormGroup,
  FormControl,
  Validators
} from "@angular/forms";
import { Subject } from "rxjs";


@Component({
  selector: 'app-lieferant-dialog',
  templateUrl: './lieferant-dialog.component.html',
  styleUrls: ['./lieferant-dialog.component.sass']
})
export class LieferantDialogComponent extends DialogBase implements OnInit{

  form: FormGroup;
  defaultValue = "";
  defaultSorting = 0;

  public submitAddress: Subject<void> = new Subject;

  public mapPickerAddressInput = new Subject();

  public addressObjectMapPicker: object;

  constructor( 
    dialogRef: MatDialogRef<LieferantDialogComponent>,
    @Inject(MAT_DIALOG_DATA) data) {
      super(dialogRef, data);
      console.log(this.currentValues);
  }

  ngOnInit() {
    this.form = new FormGroup({
      name1: new FormControl((this.currentValues? this.currentValues["name1"] : this.defaultValue), [Validators.required]),
      name2: new FormControl((this.currentValues? this.currentValues["name2"] : null)),
      number: new FormControl((this.currentValues? this.currentValues["number"] : null)),
      address: new FormControl((this.currentValues? this.currentValues["address"] : null)),
      note: new FormControl((this.currentValues? this.currentValues["note"] : null)),
    });

    if (this.currentValues) {
      this.form.addControl("id", new FormControl(this.currentValues.id));
    }
  }
}
