<div class="toolbar">
  <span class="toolbarHeader"
    >Baumkatalog <span class="count">{{ treeTypeCount() }} </span>
  </span>

  <mat-form-field
    class="search-bar mat-form-field-appearance-fill mat-form-field-appearance-no-padding"
    appearance="fill"
    style="flex: 1 1 auto"
  >
    <input
      matInput
      type="text"
      [(ngModel)]="value"
      (input)="searchBar($event.target.value)"
      placeholder="Baumsuche"
    />

    <div matSuffix style="display: flex">
      <button
        *ngIf="value"
        mat-icon-button
        aria-label="Clear"
        (click)="value = ''; searchBar('')"
      >
        <mat-icon>close</mat-icon>
      </button>
      <div class="searchIcon">
        <span class="material-symbols-outlined"> search </span>
      </div>
    </div>
  </mat-form-field>
</div>
<div class="mainContent">
  <div id="mainView" class="mainView">
    <table
      matSort
      (matSortChange)="sortData($event)"
      matSortActive="name_german"
      matSortDirection="asc"
      matSortDisableClear
    >
      <thead>
        <tr>
          <th class="thNameGerman" mat-sort-header="name_german">
            Deutscher Name
          </th>
          <th class="thNameBotanic" mat-sort-header="name_botanic">
            Botanischer Name
          </th>
        </tr>
      </thead>
    </table>
    <cdk-virtual-scroll-viewport
      id="viewport"
      itemSize="44"
      class="virtualScrollViewport"
    >
      <table
        matSort
        selectableTable
        [multiSelection]="false"
        [outerClickExcepions]="[dialogActions, alphabeticalScrollWrapper]"
        [dataArray]="this.tree_typeData.sortedData"
        (selectedItemsOutput)="setSelectedItem($event)"
      >
        <tbody #tbody class="noselect">
          <tr
            *cdkVirtualFor="
              let item of this.tree_typeData.sortedData;
              let i = index;
              let odd = even;
            "
            [id]="firstLetter(this.tree_typeData.sortedData[i][sorting])"
            [attr.selectable-table-data-target]="item.id"
            enterTheViewportNotifier
            (visibilityChange)="inViewport($event)"
            [class]="selectedItem.includes(item) ? 'selected' : ''"
            [ngClass]="{'trOdd': odd}"
          >
            <td class="tdNameGerman">{{ item.name_german }}</td>
            <td class="tdNameBotanic">{{ item.name_botanic }}</td>
          </tr>
        </tbody>
      </table>
    </cdk-virtual-scroll-viewport>
  </div>
  <div #alphabeticalScrollWrapper class="alphabeticalScrollWrapper">
    <app-alphabetical-scrollbar
      [disabledLetters]="this.disabledLetters"
      [activeLetter]="this.activeLetter"
      (scroll)="scrollTo($event)"
    ></app-alphabetical-scrollbar>
  </div>
</div>
