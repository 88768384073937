import { NgModule } from "@angular/core";
import { GlobalModulesModule } from "src/app/global-modules.module";
import { LocationTreeComponent } from "./location-tree.component";

@NgModule({
  declarations: [LocationTreeComponent],
  imports: [GlobalModulesModule,],
  exports: [LocationTreeComponent],
})
export class LocationTreeModule {}
