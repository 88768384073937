<ng-template *ngIf="canBeDeleted; else noDelete">
    <span (click)="deletePicture()" title="Löschen">
        <svg class="is-active-symbol dialog-symbol-big" viewBox="0 0 24 24">
            <path 
                d="M19,4H15.5L14.5,3H9.5L8.5,4H5V6H19M6,19A2,2 0 0,0 8,21H16A2,2 0 0,0 18,19V7H6V19Z" />
        </svg>>
    </span>
</ng-template>
<ng-template #noDelete>
    <span title="Löschen nicht möglich">
        <svg class="is-inactive-symbol dialog-symbol-big" viewBox="0 0 24 24">
            <path
                d="M19,4H15.5L14.5,3H9.5L8.5,4H5V6H19M6,19A2,2 0 0,0 8,21H16A2,2 0 0,0 18,19V7H6V19Z" />
        </svg>
    </span>
</ng-template>