import { Component, OnInit } from "@angular/core";
import { DataIndex } from "src/app/baseclasses/dataobject/dataobject";
import { InventoryService } from "src/app/services/playground/administration/inventory.service";
import { MatDialog, MatDialogConfig } from "@angular/material/dialog";
import { DialogHandler } from "src/app/baseclasses/dialog-handler/dialog-handler";
import { InventorytypeEntryDialogComponent } from "./inventorytype-entry-dialog/inventorytype-entry-dialog.component";
import { InventorytypeCategoryDialogComponent } from "./inventorytype-category-dialog/inventorytype-category-dialog.component";


const valueObjects = {
  kategorie: {
    component: InventorytypeEntryDialogComponent,
    config: {
      add: {
        id: 1,
        title: "Inventartyp anlegen",
        okButtonLabel: "Anlegen"
      },
      edit: {
        id: 1,
        title: "Inventartyp bearbeiten",
        okButtonLabel: "Bearbeiten"
      }
    }
  },
  kategorieTyp: {
    component: InventorytypeCategoryDialogComponent,
    config: {
      editType: {
        id: 1,
        title: "Inventartypnamen bearbeiten",
        okButtonLabel: "Bearbeiten"
      }
    }
  }
};

@Component({
  selector: "app-inventartypkatalog",
  templateUrl: "./inventartypkatalog.component.html",
  styleUrls: ["./inventartypkatalog.component.sass"]
})
export class InventartypkatalogComponent implements OnInit {
  inventoryTypeValues: any;
  categoryObject = [];

  constructor(
    private dataService: InventoryService,
    public serviceObject: InventoryService,
    public matDialog: MatDialog
  ) {}

  ngOnInit() {
    this.dataService.inventoryType.subscribe(result => {
      let listObject;

      if (Array.isArray(result)) {
        listObject = result.reduce((accumulator, item) => {
          let group = item.category;
          if (!accumulator[group]) {
            accumulator[group] = [item];
          } else {
            accumulator[group].push(item);
          }
          return accumulator;
        }, {});

        for (let i = 0; i < result.length; i++) {
          if (!this.categoryObject.includes(result[i]["category"])) {
            this.categoryObject.push(result[i]["category"]);
          }
        }
      }

      const sortingString = "description";
      let DataObject = new DataIndex(null);
      for (let group of Object.entries(listObject)) {
        if (this.checkGroup(group[1], sortingString)) {
          DataObject.sortData(group[1], "asc", sortingString);
        }
      }

      let allEntries = Object.keys(listObject);
      allEntries.sort();

      this.inventoryTypeValues = listObject;

    });
  }

  checkGroup(group, checkFor) {
    if (Array.isArray(group)) {
      for (let i = 0; i < group.length; i++) {
        if (!group[i][checkFor]) {
          return false;
        }
      }
    }
    return true;
  }

  addValue(value) {
    this.dataService.addInventoryType(value);
  }

  updateValues(values) {
    this.dataService.updateInventoryType(values);
  }

  updateTypeValue(value) {
    this.dataService.updateInventoryTypeCategory(value);
  }

  deleteValue(value) {
    this.dataService.deleteInventoryType(value);
  }

  openDialog(dialog, usage, dataObject?) {
   
    const callbacks = {
      add: this.addValue,
      edit: this.updateValues,
      editType: this.updateTypeValue
    };

    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = valueObjects[dialog].config[usage];
    dialogConfig.data.currentValues = dataObject;
    dialogConfig.data.selectValues = this.categoryObject;

    const testDialog = new DialogHandler(
      this.matDialog,
      valueObjects[dialog].component,
      dialogConfig,
      callbacks[usage],
      this.dataService
    );
    let tester = testDialog.openDialog();
  }
}
