import { Component, forwardRef, Input, OnInit } from "@angular/core";
import { MatDialog, MatDialogConfig } from "@angular/material/dialog";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";
import { SimpleDatachoiceDialogComponent } from "./simple-datachoice-dialog/simple-datachoice-dialog.component";
import { GraphqlService } from "src/app/services/graphql.service";
import {
  FormGroup,
  FormControl,
  Validators,
  FormBuilder,
} from "@angular/forms";
// import { getDefaultValuesQuery } from "src/app/gql-queries/default-value-queries/default-value-queries.module";

@Component({
  selector: "app-simple-datachoice",
  templateUrl: "./simple-datachoice.component.html",
  styleUrls: ["./simple-datachoice.component.sass"],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SimpleDatachoiceComponent),
      multi: true,
    },
  ],
})
export class SimpleDatachoiceComponent implements ControlValueAccessor, OnInit {
  // Eigentlich müsste diese Komponente anders heißen und auf die Default Values einzahlen
  // Bei Gelegenheit und Zeit kann das irgendeine Gute Seele mal ändern

  form: FormGroup;
  requestQuery: any;
  dataArray: any;
  dialogRef: any;

  @Input() dataType: any;
  @Input("value") val: any;

  constructor(
    private formBuilder: FormBuilder,
    private graphQLService: GraphqlService,
    public dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.form = new FormGroup({
      dataInput: new FormControl(),
    });
  }

  onChange: any = () => {};
  onTouched: any = () => {};

  get value() {
    return this.val;
  }

  set value(val) {
    this.val = val;
    this.onChange(val);
    this.onTouched();
  }

  // Ok, seien wir ehlich: Ich habe an dieser Stelle noch nicht verstanden, wie das genau funktioniert mit dem Setzen von 'value'.
  // Es kann sein, dass das eine oder andere redundant ist, aber ich hab an dieser Gülle vier Tage rumgebastelt und jetzt funktioniert es endlich. - No questions asked.

  writeValue(inputValue) {
    this.value = inputValue;
    this.form.controls.dataInput.setValue(inputValue);
  }

  registerOnChange(fn) {
    this.onChange = fn;
  }

  registerOnTouched(fn) {
    this.onTouched = fn;
  }

  refineData(dataSource, field) {
    let returnArray = [];

    dataSource.data["Default_Values"].forEach((object) => {
      if (object.field === field) {
        returnArray.push(object.value);
      }
    });

    return returnArray;
  }

  selectData() {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = false;
    dialogConfig.autoFocus = false;
    dialogConfig.height = "500px";
    dialogConfig.width = "400px";

    // this.requestQuery = this.graphQLService
    //   .getData(getDefaultValuesQuery)
    //   .valueChanges.subscribe((result) => {
    //     console.log(result);

    //     switch (this.dataType) {
    //       case "ident_carrier_type":
    //         this.dataArray = this.refineData(result, "INV_Identtraegerart");
    //         break;

    //       case "acquisition_type":
    //         this.dataArray = this.refineData(result, "INV_Erwerbsart");
    //         break;

    //       case "condition":
    //         this.dataArray = this.refineData(result, "IVK_Status");
    //         break;
    //     }

    //     dialogConfig.data = {
    //       dataArray: this.dataArray,
    //     };

    //     dialogConfig.panelClass = "custom-dialog";

    //     this.dialogRef = this.dialog.open(
    //       SimpleDatachoiceDialogComponent,
    //       dialogConfig
    //     );

    //     this.dialogRef.afterClosed().subscribe((result) => {
    //       // UNsubscribe + writevalue
    //       /*
    //   if (result) {
    //     console.log(result);
    //     //this.writeValue(xXx);
    //   }
    //   */
    //     });
    //   });
  }
}
