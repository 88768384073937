import { Component, Inject, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { GraphqlService } from "src/app/services/graphql.service";
import { FormGroup, FormBuilder, FormControl } from "@angular/forms";

@Component({
  selector: 'app-simple-datachoice-dialog',
  templateUrl: './simple-datachoice-dialog.component.html',
  styleUrls: ['./simple-datachoice-dialog.component.sass']
})
export class SimpleDatachoiceDialogComponent implements OnInit {
  form: FormGroup;

  dataArray: any;


  constructor(
    private graphQLService: GraphqlService,
    private formBuilder: FormBuilder,
    private dialogRef: MatDialogRef<SimpleDatachoiceDialogComponent>,
    @Inject(MAT_DIALOG_DATA) data
  ) {
    
    this.dataArray = data.dataArray
  }

  ngOnInit(): void {
  }

  save() {
    this.dialogRef.close(null);
  }

  close() {
    this.dialogRef.close(null);
  }
}
