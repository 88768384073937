<div>
    <div class="playground-dialog-container">
        <!-- <h2>Kontrollen zum Spielplatz {{currentPlayground}}</h2> -->
        <!-- TODO -->
        <h2 style="color: red">Beispieldaten (TODO)</h2>
        <table class="main-content-table">
            <thead>
                <tr>
                    <th class="thPos">Pos.</th>
                    <th class="thDate">Datum</th>
                    <th class="thControl">Kontrolle</th>
                    <th class="thState">Rückmeldungen</th>
                    <th class="thPhotos">Fotos</th>
                    <th class="thActions">
                        <!-- <app-add-spielplatz-kontrolle></app-add-spielplatz-kontrolle> -->
                        <app-pdf tooltip-data="Alle Spielplätze als PDF ausgeben"></app-pdf>
                    </th> 
                </tr>
            </thead>

            <!-- <ng-container *ngFor="let inspection of mergedInventoryInspection; let i = index" class="test024">
                <tbody>
                    <tr>
                        <td>#{{i+1}} #1</td>
                        <td>{{inspection.date | date: 'EE, dd.MM.yyyy'}}
                            {{inspection.time}} Uhr</td>
                        <td>{{inspection.type}}</td>
                        <td>
                            <ng-container *ngFor="let state of inspection.merged_inventory_inspection | keyvalue">
                                <div class="stateItem">
                                    <div [ngStyle]="{'background-color': getColorCode(state.key)}" class="show-color-code">
                                    </div>
                                    <div>{{state.key}}: {{state.value}}</div>
                                </div>
                            </ng-container>
                        </td>
                        <td>
                            <app-inspection-photos [inspectionID]=inspection.id
                            [inspectionPhoto]="inspection.inspection_photos" class="table-flex-container-photos">
                            </app-inspection-photos>
                        </td>
                        <td>
                            <app-edit-spielplatz-kontrolle [inspectionData]="inspection" [playground]="currentPlaygroundName"
                            [playgroundNumber]="currentPlaygroundNumber"></app-edit-spielplatz-kontrolle>
                            <button mat-icon-button>
                                <mat-icon fontSet="material-symbols-outlined">delete</mat-icon>
                            </button>
                        </td>
                    </tr>
                </tbody>
            </ng-container> -->

            <ng-container>
                <tbody>
                    <tr>
                        <td style="text-align: center">#1</td>
                        <td>Mi, 07.10.2020<br> um 11:25:40</td>
                        <td class="tdType"><span>Sichtkontrolle</span> <br> Zu <span>3</span> Gegenständen <span>3</span> Rückmeldungen</td>
                        <ng-container>
                            <td class="stateItem">
                                <tr>
                                    <td>ohne neue Beanstandung</td>
                                    <td class="itemBadge"><span class="greenBadge" matBadge="99" matBadgeOverlap="false"></span></td>
                                </tr>
                                <tr>
                                    <td>Handlungsbedarf</td>
                                    <td class="itemBadge"><span class="yellowBadge" matBadge="5" matBadgeOverlap="false"></span></td>
                                </tr>
                                <tr>
                                    <td>Gefahrbeseitigung</td>
                                    <td class="itemBadge"><span class="orangeBadge" matBadge="555" matBadgeOverlap="false"></span></td>
                                </tr>
                                <tr>
                                    <td>Außerbetriebnahme</td>
                                    <td class="itemBadge"><span class="redBadge hide-text" matBadge="0" matBadgeOverlap="false"></span></td>
                                </tr>
                            </td>
                        </ng-container>
                        <td style="text-align: center">
                            <div class="noImage">
                              <span class="material-symbols-outlined no-thumbnail-icon">
                                hide_image
                              </span>
                            </div>
                        </td>
                        <td>
                            <div class="singleActions">
                                <!-- <app-edit-spielplatz-kontrolle></app-edit-spielplatz-kontrolle> -->
                                <button mat-icon-button>
                                    <mat-icon fontSet="material-symbols-outlined">delete</mat-icon>
                                </button>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td style="text-align: center">#2</td>
                        <td>Mi, 07.10.2020<br> um 11:25:40</td>
                        <td class="tdType"><span>Sichtkontrolle</span> <br> Zu <span>3</span> Gegenständen <span>3</span> Rückmeldungen</td>
                        <ng-container>
                            <td class="stateItem">
                                <tr>
                                    <td>ohne neue Beanstandung</td>
                                    <td class="itemBadge"><span class="greenBadge" matBadge="9" matBadgeOverlap="false"></span></td>
                                </tr>
                                <tr>
                                    <td>Handlungsbedarf</td>
                                    <td class="itemBadge"><span class="yellowBadge hide-text" matBadge="0" matBadgeOverlap="false"></span></td>
                                </tr>
                                <tr>
                                    <td>Gefahrbeseitigung</td>
                                    <td class="itemBadge"><span class="orangeBadge" matBadge="5" matBadgeOverlap="false"></span></td>
                                </tr>
                                <tr>
                                    <td>Außerbetriebnahme</td>
                                    <td class="itemBadge"><span class="redBadge hide-text" matBadge="0" matBadgeOverlap="false"></span></td>
                                </tr>
                            </td>
                        </ng-container>
                        <td style="text-align: center">
                            <div class="noImage">
                              <span class="material-symbols-outlined no-thumbnail-icon">
                                hide_image
                              </span>
                            </div>
                        </td>
                        <td>
                            <div class="singleActions">
                                <!-- <app-edit-spielplatz-kontrolle></app-edit-spielplatz-kontrolle> -->
                                <button mat-icon-button>
                                    <mat-icon fontSet="material-symbols-outlined">delete</mat-icon>
                                </button>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td style="text-align: center">#3</td>
                        <td>Mi, 07.10.2020<br> um 11:25:40</td>
                        <td class="tdType"><span>Sichtkontrolle</span> <br> Zu <span>3</span> Gegenständen <span>3</span> Rückmeldungen</td>
                        <ng-container>
                            <td class="stateItem">
                                <tr>
                                    <td>ohne neue Beanstandung</td>
                                    <td class="itemBadge"><span class="greenBadge" matBadge="1" matBadgeOverlap="false"></span></td>
                                </tr>
                                <tr>
                                    <td>Handlungsbedarf</td>
                                    <td class="itemBadge"><span class="yellowBadge hide-text" matBadge="0" matBadgeOverlap="false"></span></td>
                                </tr>
                                <tr>
                                    <td>Gefahrbeseitigung</td>
                                    <td class="itemBadge"><span class="orangeBadge hide-text" matBadge="0" matBadgeOverlap="false"></span></td>
                                </tr>
                                <tr>
                                    <td>Außerbetriebnahme</td>
                                    <td class="itemBadge"><span class="redBadge hide-text" matBadge="0" matBadgeOverlap="false"></span></td>
                                </tr>
                            </td>
                        </ng-container>
                        <td style="text-align: center">
                            <div class="noImage">
                              <span class="material-symbols-outlined no-thumbnail-icon">
                                hide_image
                              </span>
                            </div>
                        </td>
                        <td>
                            <div class="singleActions">
                                <!-- <app-edit-spielplatz-kontrolle></app-edit-spielplatz-kontrolle> -->
                                <button mat-icon-button>
                                    <mat-icon fontSet="material-symbols-outlined">delete</mat-icon>
                                </button>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td style="text-align: center">#4</td>
                        <td>Mi, 07.10.2020<br> um 11:25:40</td>
                        <td class="tdType"><span>Sichtkontrolle</span> <br> Zu <span>3</span> Gegenständen <span>3</span> Rückmeldungen</td>
                        <ng-container>
                            <td class="stateItem">
                                <tr>
                                    <td>ohne neue Beanstandung</td>
                                    <td class="itemBadge"><span class="greenBadge" matBadge="9" matBadgeOverlap="false"></span></td>
                                </tr>
                                <tr>
                                    <td>Handlungsbedarf</td>
                                    <td class="itemBadge"><span class="yellowBadge" matBadge="5" matBadgeOverlap="false"></span></td>
                                </tr>
                                <tr>
                                    <td>Gefahrbeseitigung</td>
                                    <td class="itemBadge"><span class="orangeBadge" matBadge="15" matBadgeOverlap="false"></span></td>
                                </tr>
                                <tr>
                                    <td>Außerbetriebnahme</td>
                                    <td class="itemBadge"><span class="redBadge" matBadge="333" matBadgeOverlap="false"></span></td>
                                </tr>
                            </td>
                        </ng-container>
                        <td style="text-align: center">
                            <div class="noImage">
                              <span class="material-symbols-outlined no-thumbnail-icon">
                                hide_image
                              </span>
                            </div>
                        </td>
                        <td>
                            <div class="singleActions">
                                <!-- <app-edit-spielplatz-kontrolle></app-edit-spielplatz-kontrolle> -->
                                <button mat-icon-button>
                                    <mat-icon fontSet="material-symbols-outlined">delete</mat-icon>
                                </button>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td style="text-align: center">#5</td>
                        <td>Mi, 07.10.2020<br> um 11:25:40</td>
                        <td class="tdType"><span>Sichtkontrolle</span> <br> Zu <span>3</span> Gegenständen <span>3</span> Rückmeldungen</td>
                        <ng-container>
                            <td class="stateItem">
                                <tr>
                                    <td>ohne neue Beanstandung</td>
                                    <td class="itemBadge"><span class="greenBadge" matBadge="123" matBadgeOverlap="false"></span></td>
                                </tr>
                                <tr>
                                    <td>Handlungsbedarf</td>
                                    <td class="itemBadge"><span class="yellowBadge" matBadge="456" matBadgeOverlap="false"></span></td>
                                </tr>
                                <tr>
                                    <td>Gefahrbeseitigung</td>
                                    <td class="itemBadge"><span class="orangeBadge" matBadge="789" matBadgeOverlap="false"></span></td>
                                </tr>
                                <tr>
                                    <td>Außerbetriebnahme</td>
                                    <td class="itemBadge"><span class="redBadge hide-text" matBadge="0" matBadgeOverlap="false"></span></td>
                                </tr>
                            </td>
                        </ng-container>
                        <td style="text-align: center">
                            <div class="noImage">
                              <span class="material-symbols-outlined no-thumbnail-icon">
                                hide_image
                              </span>
                            </div>
                        </td>
                        <td>
                            <div class="singleActions">
                                <!-- <app-edit-spielplatz-kontrolle></app-edit-spielplatz-kontrolle> -->
                                <button mat-icon-button>
                                    <mat-icon fontSet="material-symbols-outlined">delete</mat-icon>
                                </button>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td style="text-align: center">#6</td>
                        <td>Mi, 07.10.2020<br> um 11:25:40</td>
                        <td class="tdType"><span>Sichtkontrolle</span> <br> Zu <span>3</span> Gegenständen <span>3</span> Rückmeldungen</td>
                        <ng-container>
                            <td class="stateItem">
                                <tr>
                                    <td>ohne neue Beanstandung</td>
                                    <td class="itemBadge"><span class="greenBadge" matBadge="99" matBadgeOverlap="false"></span></td>
                                </tr>
                                <tr>
                                    <td>Handlungsbedarf</td>
                                    <td class="itemBadge"><span class="yellowBadge" matBadge="5" matBadgeOverlap="false"></span></td>
                                </tr>
                                <tr>
                                    <td>Gefahrbeseitigung</td>
                                    <td class="itemBadge"><span class="orangeBadge" matBadge="555" matBadgeOverlap="false"></span></td>
                                </tr>
                                <tr>
                                    <td>Außerbetriebnahme</td>
                                    <td class="itemBadge"><span class="redBadge hide-text" matBadge="0" matBadgeOverlap="false"></span></td>
                                </tr>
                            </td>
                        </ng-container>
                        <td style="text-align: center">
                            <div class="noImage">
                              <span class="material-symbols-outlined no-thumbnail-icon">
                                hide_image
                              </span>
                            </div>
                        </td>
                        <td>
                            <div class="singleActions">
                                <!-- <app-edit-spielplatz-kontrolle></app-edit-spielplatz-kontrolle> -->
                                <button mat-icon-button>
                                    <mat-icon fontSet="material-symbols-outlined">delete</mat-icon>
                                </button>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td style="text-align: center">#7</td>
                        <td>Mi, 07.10.2020<br> um 11:25:40</td>
                        <td class="tdType"><span>Sichtkontrolle</span> <br> Zu <span>3</span> Gegenständen <span>3</span> Rückmeldungen</td>
                        <ng-container>
                            <td class="stateItem">
                                <tr>
                                    <td>ohne neue Beanstandung</td>
                                    <td class="itemBadge"><span class="greenBadge" matBadge="99" matBadgeOverlap="false"></span></td>
                                </tr>
                                <tr>
                                    <td>Handlungsbedarf</td>
                                    <td class="itemBadge"><span class="yellowBadge" matBadge="5" matBadgeOverlap="false"></span></td>
                                </tr>
                                <tr>
                                    <td>Gefahrbeseitigung</td>
                                    <td class="itemBadge"><span class="orangeBadge" matBadge="555" matBadgeOverlap="false"></span></td>
                                </tr>
                                <tr>
                                    <td>Außerbetriebnahme</td>
                                    <td class="itemBadge"><span class="redBadge hide-text" matBadge="0" matBadgeOverlap="false"></span></td>
                                </tr>
                            </td>
                        </ng-container>
                        <td style="text-align: center">
                            <div class="noImage">
                              <span class="material-symbols-outlined no-thumbnail-icon">
                                hide_image
                              </span>
                            </div>
                        </td>
                        <td>
                            <div class="singleActions">
                                <!-- <app-edit-spielplatz-kontrolle></app-edit-spielplatz-kontrolle> -->
                                <button mat-icon-button>
                                    <mat-icon fontSet="material-symbols-outlined">delete</mat-icon>
                                </button>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </ng-container>
        </table>
    </div>
</div>
