import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';
import { PlaygroundPhotosService } from 'src/app/services/playground/playground-photos.service';
import { ImageDiashowComponent } from './image-diashow/image-diashow.component';

@Component({
  selector: 'app-image-view',
  templateUrl: './image-view.component.html',
  styleUrls: ['./image-view.component.sass'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ImageViewComponent implements OnInit {

  @Input() playgroundID: number;
  @Input() playgroundPhoto: any;
  @Input() photoCount: number;

  public thumbnailID = undefined;

  public imageDiashow = ImageDiashowComponent

  constructor(private playgroundPhotosService: PlaygroundPhotosService) {}

  ngOnInit(): void {
    if (
      this.playgroundPhoto !== null &&
      this.playgroundPhoto !== undefined &&
      this.playgroundPhoto.length > 0
    ) {
      // console.log(this.playgroundPhoto);
      this.thumbnailID = this.playgroundPhoto[0].id;
    }
  }


}
