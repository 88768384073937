import { Component, OnInit, Renderer2, ElementRef, ViewChild, Input, Output, EventEmitter } from '@angular/core';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';


@Component({
  selector: 'app-drag-drop-sorting',
  templateUrl: './drag-drop-sorting.component.html',
  styleUrls: ['./drag-drop-sorting.component.sass']
})
export class DragDropSortingComponent implements OnInit {

  @ViewChild('sortingArea') sortingArea: ElementRef;
  @Input('sortingArray') sortingArray: Array<any>;
  @Output() processedSortingArray = new EventEmitter<Array<any>>(); 

  sortingAreaIsVisible = false;
  sortingArraySave: any;


  constructor(private renderer: Renderer2, private element: ElementRef) { }

  ngOnInit(): void {
    // Eigentlich (derzeit) überflüssig, bleibt aber (erstmal) drin
    this.sortingArraySave = [...this.sortingArray];
  }

  /**
   * 
   */
  toggleSortingArea() {
    if (this.sortingAreaIsVisible) {
      this.sortingAreaIsVisible = false;
      this.renderer.addClass(this.sortingArea.nativeElement, 'scale-out-tl');
      this.renderer.removeClass(this.sortingArea.nativeElement, 'scale-in-tl');      

    } else {
      this.sortingAreaIsVisible = true;
      this.renderer.removeClass(this.sortingArea.nativeElement, 'scale-out-tl');
      this.renderer.addClass(this.sortingArea.nativeElement, 'scale-in-tl');
    }
  }

  /**
   * 
   * @param event 
   */
  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.sortingArray, event.previousIndex, event.currentIndex);
  }

  /**
   * 
   */
  abort() {
    this.toggleSortingArea();
  }

  /**
   * 
   */
  apply() {
    this.toggleSortingArea();    
    this.processedSortingArray.emit(this.sortingArray);
  }
}
