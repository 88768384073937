<div *ngIf="noButton" (click)="openDialog($event)" style="width: 100%; height: 100%;">
  <ng-content select="[noButton]"></ng-content>
</div>
<button
  *ngIf="!noButton"
  mat-icon-button
  (click)="openDialog($event)"
  class="clickable"
>
  <ng-content></ng-content>
</button>
