<div *ngIf="record.length > 0; else emptySelection">
    <span class="header">Einträge Löschen?</span>
    <div class="tableWrapper">
        <table class="dataTable">
            <thead>
                <tr>
                    <th>
                        Deutscher Name
                    </th>
                    <th>
                        Botanischer Name
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let row of record">
                    <td>{{row.name_german}}</td>
                    <td>{{row.name_botanic}}</td>
                </tr>
            </tbody>
        </table>
    </div>
</div>
<ng-template #emptySelection>
    <span class="header">Keine Einträge ausgewählt</span>
</ng-template>
