import { Component, OnInit, ElementRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { LoginService } from 'src/app/services/general/login.service'
import { DataobjectRestAPI } from '../baseclasses/dataobject-restapi/dataobject-restapi';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.sass'],
})

export class LoginComponent implements OnInit {

  loginForm: FormGroup;

  clientId: string;
  isLoggedIn = false;
  isScrolled = false;

  isLoginError = false;
  loginFeedback = "";

  disableHeader = false;

  constructor(private route: ActivatedRoute, private router: Router, public el: ElementRef, private formBuilder: FormBuilder, private login: LoginService, private http: HttpClient) { }

  ngOnInit() {

    // TODO: remove the following line after testing is complete
    this.clientId = this.route.snapshot.paramMap.get('client');
    // this.isLoggedIn = LoginComponent._isLoggedIn;
    // this.http.request("GET",`{baseUrl}/api/login`)
    // .subscribe(
    //   data => {
    //     if (data["info"]) {
    //       this.isLoggedIn = true;
    //     }
    //   },
    //   error => {console.log(error)}
    // );

    if(this.router.url === `/${this.clientId}/baumkontrolle/karte`){
      this.disableHeader = true;
    }

    this.login.loginBehaviorSubject.subscribe(state => {
      this.isLoggedIn = state["state"];
    })

    this.login.checkLogin();

    if (!this.isLoggedIn) {
      this.login.getLoginStatus.subscribe(state => {
        if (state["state"]) {
          // this.router.navigate(['/' + this.clientId + '/dashboard']);
          this.isLoggedIn = true
        } else {
          if (state["message"]) {
            this.isLoginError = true;
            this.loginFeedback = state["message"];
          }
        }
      })
    }

    this.loginForm = this.formBuilder.group({
      username: ['', Validators.required],
      password: ['', Validators.required]
    });
  }

  loginService() {
    console.log("login");
    this.login.login({
      body: {
        "username": this.loginForm.controls.username.value,
        "password": this.loginForm.controls.password.value,
        "mandator": this.clientId
      }
    })

    //this.http.request("GET","aidageo-angular.logicway.de/api/login", {params})
    // this.http.request("POST",`{baseUrl}/api/login`, {
    //   body: {
    //     "username": this.loginForm.controls.username.value,
    //     "password": this.loginForm.controls.password.value,
    //     "mandator": this.clientId
    //   }
    // })
    //   .subscribe(
    //     data => {
    //       console.log(data);

    //       if (data["info"]) {
    //         this.isLoggedIn = true;
    //         this.router.navigate(['/'+this.clientId+'/dashboard']);
    //       } else {
    //         this.isLoginError = true;
    //         this.loginFeedback = data["error"];
    //       }
    //     },
    //     error => {console.log(error)}
    //   );


  }
}
