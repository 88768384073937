<!-- <div class="imagePreviewWrapper">
  <img
    [src]="picturePreview"
    *ngIf="picturePreview; else imagePlaceholder"
    class="picture-preview"
  />
  <ng-template #imagePlaceholder>
    <div class="image-placeholder">
      <span class="material-symbols-outlined"> image_not_supported </span>
    </div>
  </ng-template>
</div>

<div [formGroup]="imagePickerForm">
  <mat-form-field
    floatLabel="always"
    appearance="fill"
    (click)="fileName.click()"
    style="cursor: pointer; width: 100%; margin-bottom: 24px"
  >
    <mat-label>Bild auswählen</mat-label>
    <input
      matInput
      formControlName="fileName"
      readonly
      style="cursor: pointer; margin: 0"
    />
    <mat-error *ngIf="imagePickerForm.get('fileName').hasError('required')">
      Kein Bild Ausgewählt!
    </mat-error>
    <div matSuffix style="display: flex">
      <button
        matSuffix
        *ngIf="imagePickerForm.get('fileName').value"
        mat-icon-button
        aria-label="Clear"
        (click)="
          $event.stopPropagation();
          this.imagePickerForm.patchValue({ fileName: '', fileInput: '' });
          picturePreview = null
        "
      >
        <mat-icon>close</mat-icon>
      </button>
      <div class="dialogIcon" style="cursor: pointer">
        <span class="material-symbols-outlined" style="cursor: pointer">
          upload
        </span>
      </div>
      <input
        hidden
        #fileName
        formControlName="fileInput"
        type="file"
        accept="image/*"
        (change)="onFileSelected($event)"
      />
    </div>
  </mat-form-field>

  <mat-form-field floatLabel="always" appearance="fill" style="width: 100%">
    <mat-label>Bemerkung</mat-label>
    <textarea formControlName="notice" matInput style="resize: none; height: 80px"></textarea>
  </mat-form-field>
</div> -->

<!-- <button (click)="test()">test</button> -->
<div>
  <div class="dropzone" fileDragAndDrop="image" (fileDropped)="onFileDropped($event)">
    <input
      id="imageInput"
      #imageInput
      type="file"
      multiple
      accept="image/*"
      title=""
      (change)="fileBrowseHandler($event.target.files)"
    />
    <span class="uploadIcon material-symbols-outlined"> upload </span>
    <span>Bild hierher ziehen</span>
    <span>oder</span>
    <label class="imageInputLabel" for="imageInput">Nach Bild suchen</label>
  </div>

  <div class="filesList">
    <div class="singleFile" *ngFor="let file of files; let i = index" [ngClass]="{'noImage': file.noImage}" [matTooltip]="file.noImage ? 'Datei ist kein Bild' : null">
      <span
        style="font-size: 50px; overflow: hidden"
        class="material-symbols-outlined"
      >
        draft
      </span>
      <div class="detailWrapper">
        <div class="fileName">{{ file.name }}</div>
        <div class="fileSize">{{ formatBytes(file.size) }}</div>
      </div>
      <button mat-icon-button (click)="deleteFile(i)" class="deleteButton">
        <span class="material-symbols-outlined"> delete </span>
      </button>
    </div>
  </div>
</div>
