import {
  Component,
  OnInit,
  Inject,
  ViewChild,
  AfterViewInit,
  ElementRef,
  Renderer2
} from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { environment } from "src/environments/environment";
import { fromEvent } from "rxjs";
import { DOCUMENT } from "@angular/common";
import { takeUntil } from "rxjs/operators";

@Component({
  selector: "app-picture-dialog",
  templateUrl: "./picture-dialog.component.html",
  styleUrls: ["./picture-dialog.component.sass"]

})
export class PictureDialogComponent implements OnInit, AfterViewInit {

  private currentThumbElementIndex = 0;
  private galleryIndex = 0;

  pictures: any;
  galleryImages = [];
  thumbImages = [];

  public show: boolean = true;
  private currentPanning = 0;
  public type: string = "component";

  @ViewChild("thumbGallery") thumbGallery: ElementRef;
  @ViewChild("thumbSlider") thumbSlider: ElementRef;

  constructor(
    private renderer: Renderer2,
    private dialogRef: MatDialogRef<PictureDialogComponent>,
    @Inject(MAT_DIALOG_DATA) data,
    @Inject(DOCUMENT) private document: any
  ) {
    this.pictures = data.pictures;
  }

  ngAfterViewInit() {}

  ngOnInit(): void {
    for (let i = 0; i < this.pictures.length; i++) {
      if (this.pictures[i].photo) {
        this.galleryImages.push(
          environment.dataApiUrl + "photo?id=" + this.pictures[i].photo
        );
        this.thumbImages.push(
          environment.dataApiUrl +
            "photo?id=" +
            this.pictures[i].photo +
            "&thumbnail"
        );
      }
    }
  }


  currentImage() {
    return this.galleryImages[this.galleryIndex];
  }


  /**
   *
   * @param eventData {event: event, index: index}
   */
  thumbClicked(eventData) {
    let initialX = eventData.event.clientX;

    const mouseUp$ = fromEvent<MouseEvent>(this.document, "mouseup");
    let mouseUpSub = mouseUp$.subscribe((event: MouseEvent) => {
      if (event.clientX === initialX) {
        this.changeIndex(eventData.index);
      }

      mouseUpSub.unsubscribe();
    });
  }

  /**
   *
   * @param index
   */
  changeIndex(index) {
    this.galleryIndex = index;
  }

  /**
   * 
   */
  getCurrentTranslate() {
    let matchString = /(-?\d+)/;
    let translateString = this.thumbSlider.nativeElement.style.transform.substring(
      11
    );
    let currentTranslate = parseInt(translateString.match(matchString), 10);
    if (isNaN(currentTranslate)) {
      return 0;
    }
    return currentTranslate;
  }

  /**
   * 
   */
  panThumbsLeft() {
    let currentTranslate = this.getCurrentTranslate();

    let checkIndex = this.thumbSlider.nativeElement.children.length -1; 

    if (
      this.thumbSlider.nativeElement.offsetWidth +
        currentTranslate -
        this.thumbSlider.nativeElement.children[checkIndex]
          .offsetWidth >=
      this.thumbGallery.nativeElement.offsetWidth
    ) {
      this.currentPanning =
        currentTranslate -
        this.thumbSlider.nativeElement.children[this.currentThumbElementIndex++]
          .offsetWidth;
      this.renderer.setStyle(
        this.thumbSlider.nativeElement,
        "transform",
        "translate3d(" + this.currentPanning + "px, 0, 0)"
      );
    } 
  }

  /**
   * 
   */
  panThumbsRight() {
    let currentTranslate = this.getCurrentTranslate();

    if (
      currentTranslate +
        this.thumbSlider.nativeElement.children[this.currentThumbElementIndex]
          .offsetWidth <=
      0
    ) {
      this.currentPanning =
        currentTranslate +
        this.thumbSlider.nativeElement.children[this.currentThumbElementIndex--]
          .offsetWidth;
      this.renderer.setStyle(
        this.thumbSlider.nativeElement,
        "transform",
        "translate3d(" + this.currentPanning + "px, 0, 0)"
      );
    }
  }

  /**
   * 
   */
  lastGalleryImage() {
    if (this.galleryIndex > 0) {
      this.changeIndex(--this.galleryIndex);
    }
  }

  /**
   * 
   */
  nextGalleryImage() {
    if (this.galleryIndex < (this.galleryImages.length - 1)) {
      this.changeIndex(++this.galleryIndex);
    }
  }



  save() {
    this.dialogRef.close();
  }

  close() {
    this.dialogRef.close();
  }
}
