import { CdkDrag, CdkDragDrop, CdkDropList, moveItemInArray } from '@angular/cdk/drag-drop';
import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { MatExpansionPanel } from '@angular/material/expansion';
import { Sort } from '@angular/material/sort';
import { BehaviorSubject, Observable, Subject, Subscription } from 'rxjs';
import { DefaultValuesService } from 'src/app/services/tree/administration/default-values.service';
import { DefaultValueAddEditFormComponent } from '../default-value-add-edit-form/default-value-add-edit-form.component';

@Component({
  selector: 'app-default-value-card',
  templateUrl: './default-value-card.component.html',
  styleUrls: ['./default-value-card.component.sass']
})
export class DefaultValueCardComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input() cardContentSubject = new BehaviorSubject(["", []]);
  @Input() color: boolean = false;
  @Input() opened: boolean = false;

  @Output() addData: EventEmitter<any> = new EventEmitter();
  @Output() updateData: EventEmitter<any> = new EventEmitter();
  @Output() deleteData: EventEmitter<any> = new EventEmitter();

  @ViewChild('expansionTitle') expansionTitle;
  @ViewChild('tableTitle') tableTitle;
  @ViewChild('arrowStat') arrowStat;
  @ViewChild('tableWrapper') tableWrapper;
  @ViewChild('dataTable') dataTable;
  @ViewChild('expansionPanel') expansionPanel;
  
  private cardContentSubscription: Subscription;

  private observer;

  public cardContent = ["", []];
  public sortedData:any = [];
  public dragDisabled = false;

  public formComponent = DefaultValueAddEditFormComponent;

  constructor(
    private dataService: DefaultValuesService,
    private cd: ChangeDetectorRef,
  ) {
  }

  ngOnInit(): void {
    this.cardContentSubscription = this.cardContentSubject.subscribe(result=>{
      this.cardContent = result;
      this.sortedData = this.cardContent[1];

      this.sortData({active: 'sorting', direction: 'asc'});
    })
    
  }
  
  ngAfterViewInit(): void {
    
    if(this.opened){
      this.arrowStat["_elementRef"]["nativeElement"].classList.add("rotateUp");
      this.expansionTitle["nativeElement"].style.visibility = "hidden";
      this.tableTitle["nativeElement"].style.visibility = "visible";  
    }

    if(this.dataTable){
      let height = this.dataTable["nativeElement"].offsetHeight;
      height = height - 65;
      this.tableWrapper["nativeElement"].style.height = height + "px";
    }

    this.observer = new MutationObserver(mutations=>{

      let height = this.dataTable["nativeElement"].offsetHeight;
      height = height - 65;
      this.tableWrapper["nativeElement"].style.height = height + "px";

    })
    let config = { attributes: false, childList: true, characterData: false };
    this.observer.observe(this.dataTable.nativeElement, config);
    
    this.cd.detectChanges();
  }

  ngOnDestroy(): void {
    this.cardContentSubscription.unsubscribe();
  }

  
  onExpand(){
    if(this.arrowStat){
      this.arrowStat["_elementRef"]["nativeElement"].classList.add("rotateUp");
    }
  }

  afterExpand(){
    this.expansionTitle["nativeElement"].style.visibility = "hidden";
    this.tableTitle["nativeElement"].style.visibility = "visible";
  }

  onCollapse(){
    this.expansionTitle["nativeElement"].style.visibility = "visible";
    this.tableTitle["nativeElement"].style.visibility = "hidden";
    this.arrowStat["_elementRef"]["nativeElement"].classList.remove("rotateUp");
  }

  sortData(sort: Sort) {
    const data: any = this.cardContent[1].slice();
    if (!sort.active || sort.direction === '') {
      this.sortedData = data;
      return;
    }

    this.sortedData = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'value':
          return compare(a['value'], b['value'], isAsc);
        case 'sorting':
          return compare(a['sorting'], b['sorting'], isAsc);
        default:
          return 0;
      }
    });
  }

  checkSorting(sort: Sort){

    let elements = Array.from(this.dataTable.nativeElement.children).slice(1);

    if(sort.active==="sorting" && sort.direction!==""){
      this.dragDisabled=false;
      elements.forEach((element: Element) => {
        element.classList.remove('defaultCursor');
      });
    }else{
      this.dragDisabled=true;
      elements.forEach((element: Element) => {
        element.classList.add('defaultCursor');
      });
    }
  }

  addDefaultData(value){
    this.addData.emit(value);
  }

  editDefaultData(value){
    this.updateData.emit(value);
  }

  deleteDefaultData(id: number){
    this.deleteData.emit(id);
  }

}

function compare(a: number | string, b: number | string, isAsc: boolean) {
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}