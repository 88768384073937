<mat-tab-group>
  <mat-tab label="Spielplatz">
    <app-add-edit-spielplatz-data-form
      (values)="submit($event)"
    ></app-add-edit-spielplatz-data-form>
  </mat-tab>
  <mat-tab label="Inventar">
    <app-add-edit-spielplatz-inventar-form></app-add-edit-spielplatz-inventar-form>
  </mat-tab>
  <mat-tab label="Kontrollen">
    <app-add-edit-spielplatz-kontrollen-form></app-add-edit-spielplatz-kontrollen-form>
  </mat-tab>
</mat-tab-group>
