import { NgModule } from "@angular/core";
import { GlobalModulesModule } from "../global-modules.module";

import { IndexComponent } from "./index.component";

@NgModule({
  declarations: [
    IndexComponent
  ],
  imports: [GlobalModulesModule],
  exports: [

  ],
})
export class IndexModule {}
