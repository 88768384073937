import { NgModule } from "@angular/core";
import { GlobalModulesModule } from "../global-modules.module";

import { DashboardComponent } from "./dashboard.component";

@NgModule({
  declarations: [
    DashboardComponent,
  ],
  imports: [GlobalModulesModule],
  exports: [
    DashboardComponent,
  ],
})
export class DashboardModule {}
