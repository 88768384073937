<mat-toolbar class="header">
  <div style="display: flex; flex-direction: row">
    <button
      mat-icon-button
      class="burgerIcon"
      (click)="treeOverview.toggle()"
      matTooltipShowDelay="1000" 
      matTooltip="Bauminformationen"
    >
      <span class="material-symbols-outlined"> list </span>
    </button>
    <a class="navIcon">
      <img src="assets/img/geoDummy-logo.png" />
    </a>
  </div>

  <button
    mat-icon-button
    (click)="addressSearch.toggle()"
    matTooltipShowDelay="1000" 
    matTooltip="Adress-Suche"
  >
    <span class="material-symbols-outlined outerSearchIcon">
      location_searching
    </span>
    <span class="material-symbols-outlined innerSearchIcon"> search </span>
  </button>
</mat-toolbar>

<mat-drawer-container>
  <mat-drawer #treeOverview mode="over" opened>
    <app-tree-overview [treeSelection]="treeSelectionSubject.value" (zoomToTreeId)="zoomToTree($event)" ></app-tree-overview>
  </mat-drawer>
  <mat-drawer-content>
    <mat-drawer-container>
      <mat-drawer
        #addressSearch
        mode="over"
        class="heightFitContent"
        position="end"
        [formGroup]="addressSearchForm"
      >
        <div class="addressSearchContentWrapper">
          <div class="addressSearchHeader">
            <span class="material-symbols-outlined innerSearchIcon">
              search
            </span>
            Adress-Suche
          </div>

          <mat-form-field floatLabel="always" appearance="fill">
            <mat-label>Straße</mat-label>
            <input title="" matInput formControlName="street" (keydown.enter)="onAddressSearch(addressSearchForm.value)"/>
            <button
              *ngIf="addressSearchForm.get('street').value"
              matSuffix
              mat-icon-button
              aria-label="Clear"
              tabindex="-1"
              (click)="
                $event.stopPropagation();
                this.addressSearchForm.patchValue({ street: '' })
              "
            >
              <mat-icon>close</mat-icon>
            </button>
          </mat-form-field>
          <mat-form-field floatLabel="always" appearance="fill">
            <mat-label>Hausnr.</mat-label>
            <input title="" matInput formControlName="house_number" (keydown.enter)="onAddressSearch(addressSearchForm.value)" />
            <button
              *ngIf="addressSearchForm.get('house_number').value"
              matSuffix
              mat-icon-button
              aria-label="Clear"
              tabindex="-1"
              (click)="
                $event.stopPropagation();
                this.addressSearchForm.patchValue({ house_number: '' })
              "
            >
              <mat-icon>close</mat-icon>
            </button>
          </mat-form-field>
          <mat-form-field floatLabel="always" appearance="fill">
            <mat-label>Postleitzahl</mat-label>
            <input title="" matInput formControlName="postcode" (keydown.enter)="onAddressSearch(addressSearchForm.value)" />
            <button
              *ngIf="addressSearchForm.get('postcode').value"
              matSuffix
              mat-icon-button
              aria-label="Clear"
              tabindex="-1"
              (click)="
                $event.stopPropagation();
                this.addressSearchForm.patchValue({ postcode: '' })
              "
            >
              <mat-icon>close</mat-icon>
            </button>
          </mat-form-field>
          <mat-form-field floatLabel="always" appearance="fill">
            <mat-label>Ort</mat-label>
            <input title="" matInput formControlName="city" (keydown.enter)="onAddressSearch(addressSearchForm.value)" required/>
            <mat-error>
              Erforderlich
            </mat-error>
            <button
              *ngIf="addressSearchForm.get('city').value"
              matSuffix
              mat-icon-button
              aria-label="Clear"
              tabindex="-1"
              (click)="
                $event.stopPropagation();
                this.addressSearchForm.patchValue({ city: '' })
              "
            >
              <mat-icon>close</mat-icon>
            </button>
          </mat-form-field>
          <mat-form-field floatLabel="always" appearance="fill">
            <mat-label>Land</mat-label>
            <input title="" matInput formControlName="country" (keydown.enter)="onAddressSearch(addressSearchForm.value)"/>
            <button
              *ngIf="addressSearchForm.get('country').value"
              matSuffix
              mat-icon-button
              aria-label="Clear"
              tabindex="-1"
              (click)="
                $event.stopPropagation();
                this.addressSearchForm.patchValue({ country: '' })
              "
            >
              <mat-icon>close</mat-icon>
            </button>
          </mat-form-field>

          <div class="searchButtonWrapper">
            <button
              class="submit"
              mat-flat-button
              [disabled]="!addressSearchForm.valid"
              (click)="onAddressSearch(addressSearchForm.value)"
            >
              Suchen
            </button>
          </div>
        </div>
      </mat-drawer>
      <mat-drawer-content>
        <div id="map" class="map"></div>
      </mat-drawer-content>
    </mat-drawer-container>
  </mat-drawer-content>
</mat-drawer-container>
