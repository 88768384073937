import { Component, OnInit, Input } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { GraphqlService } from 'src/app/services/graphql.service';
import { EditPicturesDialogComponent } from './edit-pictures-dialog/edit-pictures-dialog.component';

@Component({
  selector: 'app-edit-pictures',
  templateUrl: './edit-pictures.component.html',
  styleUrls: ['./edit-pictures.component.sass']
})
export class EditPicturesComponent implements OnInit {

  @Input() baseQuery: any;
  @Input() picturesQuery: any;
  @Input() editQuery: any;
  @Input() updateQuery: any;
  @Input() deleteQuery: any;
  @Input() playGroundID: any;
  @Input() playgroundDescription: any;

  constructor(public dialog: MatDialog, private graphQLService: GraphqlService) { }

  ngOnInit(): void {
  }

  editPictures() {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = false;
    dialogConfig.autoFocus = true;
    dialogConfig.height = '90vh';
    dialogConfig.width = '600px';

    dialogConfig.data = {
      baseQuery: this.baseQuery,
      picturesQuery: this.picturesQuery,
      editQuery: this.editQuery,
      updateQuery: this.updateQuery,
      deleteQuery: this.deleteQuery,
      playgroundID: this.playGroundID,
      playgroundDescription: this.playgroundDescription
    }

    dialogConfig.panelClass = 'custom-dialog';

    const dialogRef = this.dialog.open(EditPicturesDialogComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result => {

      console.log(result);
      /*
      if (result) {
        this.httpService.uploadPicture(result).subscribe(
          data => {
            let variableObject = {
              playground: this.playGroundID,
              photo: Number(data["file"])
            }
            this.graphQLService.sendMutation(this.addQuery, variableObject, this.baseQuery);
          },
          error => {
            console.error(error);
          }
        );
      }
      */
    })
  }

}
