import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import {
  FormGroup,
  FormBuilder,
  FormControl,
  Validators
} from "@angular/forms";


@Component({
  selector: 'app-pick-acquisition-dialog',
  templateUrl: './pick-acquisition-dialog.component.html',
  styleUrls: ['./pick-acquisition-dialog.component.sass']
})
export class PickAcquisitionDialogComponent implements OnInit {

  currentValue: any;
  valueArray: any;
  filteredValueArray: any;

  currentFilter = "";
  filterForm: FormGroup;


  constructor( private dialogRef: MatDialogRef<PickAcquisitionDialogComponent>,
    @Inject(MAT_DIALOG_DATA) data) {
      this.currentValue = data.currentValue;
      this.valueArray = data.valueArray;
      this.filteredValueArray = [...this.valueArray];
    }

  ngOnInit(): void {
    this.filterForm = new FormGroup({
      filter: new FormControl("")
    });
  }

  choseValue(value) {
    this.currentValue = value;
  }

  filterValueArray(event) {
    switch(event.key) {
      case "Backspace":
        this.currentFilter = this.currentFilter.substring(0, this.currentFilter.length - 1);
        break;
      default:
        this.currentFilter = this.currentFilter + event.key;
        break;
    }
    this.filteredValueArray = this.applyCurrentFilter(this.valueArray, this.currentFilter);
  }

  applyCurrentFilter(baseData, filter) {
    let filterResult;
    if (Array.isArray(baseData)) {
      filterResult = baseData.filter( element => {
        return element.value.toLowerCase().includes(filter.toLowerCase());
      })
    }
    return filterResult;
  }
  
  removeCurrentFilter() {
    this.currentFilter = "";
    this.filteredValueArray = this.applyCurrentFilter(this.valueArray, this.currentFilter);
    this.filterForm.controls["filter"].setValue(this.currentFilter);
  }


  save() {
    this.dialogRef.close(this.currentValue);
  }

  close() {
    this.dialogRef.close(false);
  }
}
