import { NgModule } from "@angular/core";
// Frequently-used modules
import { CommonModule } from "@angular/common";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { AppRoutingModule } from "./app-routing.module";
import { HttpClientModule } from "@angular/common/http";

// =====Material Modules=====
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { MatBadgeModule } from "@angular/material/badge";
import { MatBottomSheetModule } from "@angular/material/bottom-sheet";
import { MatButtonModule } from "@angular/material/button";
import { MatButtonToggleModule } from "@angular/material/button-toggle";
import { MatCardModule } from "@angular/material/card";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatChipsModule } from "@angular/material/chips";
import { MatDialogModule } from "@angular/material/dialog";
import { MatDividerModule } from "@angular/material/divider";
import { MatExpansionModule } from "@angular/material/expansion";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatGridListModule } from "@angular/material/grid-list";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { MatListModule } from "@angular/material/list";
import { MatMenuModule } from "@angular/material/menu";
import { MatPaginatorModule } from "@angular/material/paginator";
import { MatProgressBarModule } from "@angular/material/progress-bar";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatRadioModule } from "@angular/material/radio";
import { MatRippleModule} from "@angular/material/core";
import { MatSelectModule } from "@angular/material/select";
import { MatSidenavModule } from "@angular/material/sidenav";
import { MatSliderModule } from "@angular/material/slider";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import {
  MatSnackBarModule,
  MAT_SNACK_BAR_DEFAULT_OPTIONS,
} from "@angular/material/snack-bar";
import { MatSortModule } from "@angular/material/sort";
import { MatStepperModule } from "@angular/material/stepper";
import { MatTableModule } from "@angular/material/table";
import { MatTabsModule } from "@angular/material/tabs";
import { MatToolbarModule } from "@angular/material/toolbar";
import { MatTooltipModule } from "@angular/material/tooltip";
import { MatTreeModule } from "@angular/material/tree";

// Date Time Modules
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
} from "@angular/material/core";
import { MomentDateAdapter } from "@angular/material-moment-adapter";
import { LOCALE_ID } from "@angular/core";
import localeDe from "@angular/common/locales/de";
import localeDeExtra from "@angular/common/locales/extra/de";
import { registerLocaleData } from "@angular/common";
import { DatePipe } from "@angular/common";

// Other
import { ScrollingModule } from "@angular/cdk/scrolling";
import { ColorSketchModule } from "ngx-color/sketch";
// import { MatDatepickerModule } from "@angular/material/datepicker";

import { MatDatepickerModule } from '@matheo/datepicker';
import { MatNativeDateModule } from '@matheo/datepicker/core';

registerLocaleData(localeDe, localeDeExtra);

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    AppRoutingModule,
    HttpClientModule,

    MatCheckboxModule,
    MatDialogModule,
    MatExpansionModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatIconModule,
    MatTreeModule,
    MatTabsModule,
    MatSortModule,
    MatChipsModule,
    MatCardModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    // MatNativeDateModule,
    MatProgressSpinnerModule,
    MatSelectModule,
    MatMenuModule,
    MatProgressBarModule,
    MatAutocompleteModule,
    MatBadgeModule,
    MatGridListModule,
    MatListModule,
    MatToolbarModule,
    MatSidenavModule,
    MatRippleModule,
    MatTooltipModule,

    ScrollingModule,
    ColorSketchModule,

    MatNativeDateModule,
    MatDatepickerModule,
  ],
  exports: [
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    AppRoutingModule,
    HttpClientModule,

    MatCheckboxModule,
    MatDialogModule,
    MatExpansionModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatIconModule,
    MatTreeModule,
    MatTabsModule,
    MatSortModule,
    MatChipsModule,
    MatCardModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    // MatDatepickerModule,
    // MatNativeDateModule,
    MatProgressSpinnerModule,
    MatSelectModule,
    MatMenuModule,
    MatProgressBarModule,
    MatAutocompleteModule,
    MatBadgeModule,
    MatGridListModule,
    MatListModule,
    MatToolbarModule,
    MatSidenavModule,
    MatRippleModule,
    MatTooltipModule,

    ScrollingModule,
    ColorSketchModule,

    MatNativeDateModule,
    MatDatepickerModule,

  ],
  providers: [
    { provide: LOCALE_ID, useValue: "de" },
    { provide: MAT_SNACK_BAR_DEFAULT_OPTIONS, useValue: { duration: 4000 } },
  ],
})
export class GlobalModulesModule {}
