import { Component, OnInit, ChangeDetectionStrategy, Input, Output, EventEmitter } from '@angular/core';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-notices',
  templateUrl: './notices.component.html',
  styleUrls: ['./notices.component.sass'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NoticesComponent implements OnInit {
  @Input() private submitForm: Subject<object> = new Subject();
  @Input() private record: object;
  @Output() values = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
    console.log(this.record);
  }

}
