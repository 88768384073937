import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { treetypeDataService } from "../../services/tree/tree-type-data.service";
import { AlphabeticalScrollbarComponent } from "../../modules/alphabetical-scrollbar/alphabetical-scrollbar.component";
import { DeleteGroupFormComponent } from "src/app/modules/functions/delete-group-form/delete-group-form.component";
import { AddEditFormComponent } from "./add-edit-form/add-edit-form.component";
import { Sort, SortDirection } from "@angular/material/sort";
import { CdkVirtualScrollViewport } from "@angular/cdk/scrolling";
import { ShareSearchDataService } from "src/app/services/share-search-data/share-search-data.service";
import { Subscription } from "rxjs";

@Component({
  selector: "app-baumarten",
  templateUrl: "./baumarten.component.html",
  styleUrls: ["./baumarten.component.sass"],
})
export class BaumartenComponent implements OnInit, OnDestroy {
  @ViewChild(AlphabeticalScrollbarComponent, { static: true })
  alphabeticalScrollbarComponent: AlphabeticalScrollbarComponent;

  @ViewChild(CdkVirtualScrollViewport) viewPort: CdkVirtualScrollViewport;
  @ViewChild("alphabetSideNav") alphabetSideNav;
  @ViewChild("tbody") tbody;
  @ViewChild("toolbarActions") toolbarActions;

  public value = "";
  public sorting = "name_german";
  private direction: SortDirection = "asc";

  public firstLetterIndex = {};

  public currentSortSelection: string;
  public disabledLetters: object = {};
  public activeLetter: Array<any>;
  public formComponent = AddEditFormComponent;
  public deleteGroupForm = DeleteGroupFormComponent;
  private searchbarValueSubscription: Subscription;
  public selectedItems = [];

  private treetypeObjectSubscription: any;

  tree_typeData = {
    tree_type: null,
    filteredData: null,
    sortedData: null,
  };

  constructor(private treeTypeDataService: treetypeDataService, private shareSearchData: ShareSearchDataService) {
    this.shareSearchData.searchbarPlaceholder.emit("Baumsuche");
  }

  ngOnInit() {
    this.currentSortSelection = "name_german";

    this.treetypeObjectSubscription = this.treeTypeDataService.getAllTreeData().subscribe(
      result => {
        // sets disabled letters for alphabetical scrollbar
        // disables all letters for which no matching treetype was found
        this.disabledLetters = {
          SpecialChar: false,
          A: false,
          B: false,
          C: false,
          D: false,
          E: false,
          F: false,
          G: false,
          H: false,
          I: false,
          J: false,
          K: false,
          L: false,
          M: false,
          N: false,
          O: false,
          P: false,
          Q: false,
          R: false,
          S: false,
          T: false,
          U: false,
          V: false,
          W: false,
          X: false,
          Y: false,
          Z: false,
        };

        result.forEach((element) => {
          this.disabledLetters[
            element[this.currentSortSelection]
              .charAt(0)
              .replace("Ü", "U")
              .replace("Ä", "A")
              .replace("Ö", "O")
              .toUpperCase()
              .replace(/^$|[^a-zA-Z]/, "SpecialChar")
          ] = true;
        });

        // sets/resets Data
        this.tree_typeData.tree_type = result;
        this.tree_typeData.sortedData = result;

        // resets searchbar 
        this.searchBar("");
      }
    );

    // filters data by search value on search value change
    this.searchbarValueSubscription = this.shareSearchData.searchbarValue.subscribe(result=>{
      this.searchBar(result);
    })
  }

  ngOnDestroy(): void {
    this.treetypeObjectSubscription.unsubscribe();
    this.searchbarValueSubscription.unsubscribe();
  }

  //returns number of tree types
  treeTypeCount() {
    return this.tree_typeData.filteredData ? this.tree_typeData.filteredData.length : 0;
  }

  // deletes single tree type
  deleteTreeType(value) {
    const subscription = this.treeTypeDataService.deleteTreeTypeData(value.id).subscribe(result=>{
      console.log(result);
      subscription.unsubscribe();
    });
  }

  // deletes group of tree types
  deleteTreeTypeSelection(value){
    value.forEach(element => {
      this.deleteTreeType(element);
    });
  }

  // filters tree types by search value
  searchBar(searchValue) {
    this.tree_typeData.filteredData = this.tree_typeData.tree_type.filter(
      (treeType) => {
        return (
          treeType.name_german
            .toUpperCase()
            .includes(searchValue.toUpperCase()) ||
          treeType.name_botanic
            .toUpperCase()
            .includes(searchValue.toUpperCase())
        );
      }
    );

    // sorts tree types after search value change
    this.sortData({ active: this.sorting, direction: this.direction });
  };

  // set disabled letters for alphabetical scrollbar
  // disables all letters for which no matching treetype of filtered Data was found
  disableLetters(){
    this.disabledLetters = {
      SpecialChar: false,
      A: false,
      B: false,
      C: false,
      D: false,
      E: false,
      F: false,
      G: false,
      H: false,
      I: false,
      J: false,
      K: false,
      L: false,
      M: false,
      N: false,
      O: false,
      P: false,
      Q: false,
      R: false,
      S: false,
      T: false,
      U: false,
      V: false,
      W: false,
      X: false,
      Y: false,
      Z: false,
    };

    this.tree_typeData.filteredData.forEach(treeType => {
      this.disabledLetters[
        treeType[this.sorting]
          .charAt(0)
          .toUpperCase()
          .replace("Ü", "U")
          .replace("Ä", "A")
          .replace("Ö", "O")
          .replace(/^$|[^a-zA-Z]/, "SpecialChar")
      ] = true;
    });
  };

  // sets the active letter of alphabetical scrollbar
  inViewport(event) {
    this.activeLetter = event;
  }

  // adds/edits tree types
  submitted(value) {
    if (value.id) {
      console.log("edit", value);
      const subscription = this.treeTypeDataService.updateTreeTypeData(value).subscribe(result=>{
        console.log(result);
        subscription.unsubscribe();
      });
    } else {
      console.log("add", value);
      const subscription = this.treeTypeDataService.addTreeTypeData(value).subscribe(result=>{
        console.log(result);
        subscription.unsubscribe();
      });;
    }
  }

  // sorts tree types by table headings
  sortData(sort: Sort) {
    if(this.viewPort){
      this.viewPort.scrollToOffset(0);
    }
    this.sorting = sort.active;
    this.direction = sort.direction;

    const data = this.tree_typeData.filteredData.slice();
    if (!sort.active || sort.direction === "") {
      this.tree_typeData.sortedData = data;
      return;
    }

    this.tree_typeData.sortedData = data.sort((a, b) => {
      const isAsc = sort.direction === "asc";
      switch (sort.active) {
        case "name_german":
          return compare(a.name_german, b.name_german, isAsc);
        case "name_botanic":
          return compare(a.name_botanic, b.name_botanic, isAsc);
        case "modify_date":
          return compare(a.modify_date, b.modify_date, isAsc);
        case "creation_date":
          return compare(a.creation_date, b.creation_date, isAsc);
        default:
          return 0;
      }
    });

    // adjust alphabetical scrollbar to sorting
    if (sort.active == "name_german" || sort.active == "name_botanic") {
      // set disabled letters of scrollbar by new sorting
      this.disableLetters();
      if (this.alphabetSideNav) {
        this.alphabetSideNav.open();
      }
      switch (sort.direction) {
        case "asc":
          this.alphabeticalScrollbarComponent.alphabeticalScrollbarOrder("asc");
          break;
        case "desc":
          this.alphabeticalScrollbarComponent.alphabeticalScrollbarOrder(
            "desc"
          );
          break;
        default:
          this.alphabeticalScrollbarComponent.disable();
          break;
      }
      // initializes active letter of scrollbar by first tree type
      if (this.tree_typeData.sortedData[0]) {
        this.activeLetter = [
          "VISIBLE",
          {
            id: this.tree_typeData.sortedData[0][this.sorting]
              .charAt(0)
              .toUpperCase()
              .replace("Ü", "U")
              .replace("Ä", "A")
              .replace("Ö", "O")
              .replace(/^$|[^a-zA-Z]/, "SpecialChar"),
          },
        ];
      }
      this.setFirstLetterIndex();
    } else {
      // hide if not sorted by tree type names because scrollbar not work on dates
      if (this.alphabetSideNav) {
        this.alphabetSideNav.close();
      }
    }
  }

  // returns first letter of string and replaces umlauts and special characters for alphabetical scrollbar
  firstLetter(value) {
    if (value === null) {
      return "";
    }
    return value
      .charAt(0)
      .toUpperCase()
      .replace("Ü", "U")
      .replace("Ä", "A")
      .replace("Ö", "O")
      .replace(/^$|[^a-zA-Z]/, "SpecialChar");
  }

  // saves the indices of the first tree types with the same first letter to calculate the scroll offset ( scrollTo() )
  setFirstLetterIndex() {
    this.firstLetterIndex = {};
    this.tree_typeData.sortedData.forEach((element, index) => {
      if (!this.firstLetterIndex[this.firstLetter(element[this.sorting])]) {
        this.firstLetterIndex[this.firstLetter(element[this.sorting])] = index;
      }
    });
  }

  // scrolls to tree types with specific first letter
  scrollTo(fragment) {
    if (this.firstLetterIndex[fragment] === 1) {
      this.viewPort.scrollToOffset(0, "smooth");
    } else {
      this.viewPort.scrollToOffset(
        this.firstLetterIndex[fragment] * 60,
        "smooth"
      );
    }
  }

  // sets the tree types that are selected
  // at the moment only needed to delete multiple tree types ( deleteTreeTypeSelection() )
  setSelectedItems(items){
    this.selectedItems=items;
  }
}

// compare function for sort algorithm
function compare(a: number | string, b: number | string, isAsc: boolean) {
  if (typeof a == "string") {
    a = a
      .replaceAll("Ä", "A")
      .replaceAll("ä", "a")
      .replaceAll("Ö", "O")
      .replaceAll("ö", "o")
      .replaceAll("Ü", "U")
      .replaceAll("ü", "ü");
  }
  if (typeof b == "string") {
    b = b
      .replaceAll("Ä", "A")
      .replaceAll("ä", "a")
      .replaceAll("Ö", "O")
      .replaceAll("ö", "o")
      .replaceAll("Ü", "U")
      .replaceAll("ü", "ü");
  }

  if (a == null) {
    a = "0000-00-00 00:00:00";
  }
  if (b == null) {
    b = "0000-00-00 00:00:00";
  }

  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}
