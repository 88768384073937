<mat-sidenav-container>
    <mat-sidenav #sidenav mode="overlay" opened="{{sidenavState | async }}" fullscreen>

        <mat-nav-list>
            <mat-list-item [routerLink]="'/'+clientId+'/spielplatzkontrolle/spielplätze'"
                routerLinkActive="is-active">Spielplätze<span>123</span></mat-list-item>
            <mat-list-item [routerLink]="'/'+clientId+'/spielplatzkontrolle/kontrollen'"
                routerLinkActive="is-active">Kontrollen<span>123</span></mat-list-item>
        </mat-nav-list>

        <mat-nav-list class="lastChild">
            <mat-list-item [routerLink]="'/'+clientId+'/spielplatzkontrolle/stammdaten/vorgabewerte'"
                routerLinkActive="is-active">Vorgabewerte</mat-list-item>
            <mat-list-item [routerLink]="'/'+clientId+'/spielplatzkontrolle/stammdaten/lieferant'"
                routerLinkActive="is-active">Lieferant</mat-list-item>
            <mat-list-item [routerLink]="'/'+clientId+'/spielplatzkontrolle/stammdaten/inventartypkatalog'"
                routerLinkActive="is-active">Inventartyp-Katalog</mat-list-item>
        </mat-nav-list>

        <div class="poweredBy">
            Powered by<img src="assets/img/logo-aida.png">
        </div>
        
    </mat-sidenav>
    <mat-sidenav-content>
        <router-outlet></router-outlet>
    </mat-sidenav-content>
</mat-sidenav-container>

<!-- <div class="modul-container">
    <div class="modul-menu">
        <menu class="menu">
            <p class="menu-label">
                Spielplätze & Kontrollen
            </p>
            <ul class="menu-list">
                <li><a [routerLink]="'/'+clientId+'/spielplatzkontrolle/spielplätze'"
                        routerLinkActive="is-active">Spielplätze</a></li>
                <li><a [routerLink]="'/'+clientId+'/spielplatzkontrolle/kontrollen'"
                        routerLinkActive="is-active">Kontrollen</a></li>
                <li><a [routerLink]="'/'+clientId+'/spielplatzkontrolle/uitestseite'"
                    routerLinkActive="is-active">TEST</a></li>
            </ul>
            <p class="menu-label">
                Administration
            </p>
            <ul class="menu-list">
                <li><a [routerLink]="'/'+clientId+'/spielplatzkontrolle/stammdaten/vorgabewerte'"
                        routerLinkActive="is-active">Vorgabewerte</a></li>
                <li><a [routerLink]="'/'+clientId+'/spielplatzkontrolle/stammdaten/lieferant'"
                        routerLinkActive="is-active">Lieferant</a></li>
                <li><a [routerLink]="'/'+clientId+'/spielplatzkontrolle/stammdaten/inventartypkatalog'"
                        routerLinkActive="is-active">Inventartyp-Katalog</a></li> -->

                <!-- // Altes Submenue, wird (vorerst) gegen ein starres ausgetauscht

            <li>
                <a (click)="onClick(1)" [routerLink]="'/'+clientId+'/spielplatzkontrolle/stammdaten'" routerLinkActive="is-active">Stammdaten</a>
            </li>
            <ul [@submenuOpenClose]="isOpen1? 'open':'close'">
                <li><a [routerLink]="'/'+clientId+'/spielplatzkontrolle/stammdaten/vorgabewerte'" routerLinkActive="is-active">Vorgabewerte</a></li>
                <li><a [routerLink]="'/'+clientId+'/spielplatzkontrolle/stammdaten/lieferant'" routerLinkActive="is-active">Lieferant</a></li>
                <li><a [routerLink]="'/'+clientId+'/spielplatzkontrolle/stammdaten/inventartypkatalog'" routerLinkActive="is-active">Inventartyp-Katalog</a></li>
            </ul>
            -->

            <!-- </ul>
        </menu>

    </div>
    <div class="modul-content">
        <router-outlet></router-outlet>
    </div>
</div> -->
