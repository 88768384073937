import { Injectable } from "@angular/core";
import { map } from "rxjs/operators";
import { NewGraphQLService } from "../new-graphql.service";

@Injectable({
  providedIn: "root",
})
export class TreeMeasuresService {
  private graphqlObject: string = "tree_measures";

  private treeMeasuresByDamageObservables: object = {};
  private treeMeasuresByDamageQueryRefs: object = {};

  constructor(private newGraphQLService: NewGraphQLService) {}

  getTreeMeasuresByDamage(treeDamageId){
    // create only new stream if not already exist
    if (!this.treeMeasuresByDamageObservables[treeDamageId]) {
      this.treeMeasuresByDamageQueryRefs[treeDamageId] = this.newGraphQLService.requestAllData(
        this.graphqlObject,
        {
          args: { filter: { id: { column: "damage", value: treeDamageId } } },
        }
      );

      this.treeMeasuresByDamageObservables[treeDamageId] = this.treeMeasuresByDamageQueryRefs[treeDamageId].valueChanges.pipe(
        map((result: any) => {
          return result.data.Tree_Measures;
        })
      );
    }

    return this.treeMeasuresByDamageObservables[treeDamageId];
  }
}
