import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import {
  FormGroup,
  FormBuilder,
  FormControl,
  Validators
} from "@angular/forms";
import { InventoryService } from "src/app/services/playground/administration/inventory.service";
import { DataIndex } from "src/app/baseclasses/dataobject/dataobject";
import { MatDialog, MatDialogConfig } from "@angular/material/dialog";
import { DialogHandler } from "src/app/baseclasses/dialog-handler/dialog-handler";
import { InventorytypeEntryDialogComponent } from "../../../../spielplatzkontrolle/inventartypkatalog/inventorytype-entry-dialog/inventorytype-entry-dialog.component";
import { InventorytypeCategoryDialogComponent } from "../../../../spielplatzkontrolle/inventartypkatalog/inventorytype-category-dialog/inventorytype-category-dialog.component";


const valueObjects = {
  kategorie: {
    component: InventorytypeEntryDialogComponent,
    config: {
      add: {
        id: 1,
        title: "Inventartyp anlegen",
        okButtonLabel: "Anlegen"
      },
      edit: {
        id: 1,
        title: "Inventartyp bearbeiten",
        okButtonLabel: "Bearbeiten"
      }
    }
  },
  kategorieTyp: {
    component: InventorytypeCategoryDialogComponent,
    config: {
      editType: {
        id: 1,
        title: "Inventartypnamen bearbeiten",
        okButtonLabel: "Bearbeiten"
      }
    }
  }
};

@Component({
  selector: 'app-pick-inventory-dialog',
  templateUrl: './pick-inventory-dialog.component.html',
  styleUrls: ['./pick-inventory-dialog.component.sass']
})
export class PickInventoryDialogComponent implements OnInit {

  currentInventoryID: any;
  currentTypeDescription: any;

  currentFilter = "";
  filterForm: FormGroup;

  inventoryTypeArray: any;
  inventoryTypeArrayStorage: any;

  inventoryTypeValues: any;
  categoryObject = [];

  selectedInventoryRef: any;
  

  constructor(private dataService: InventoryService, public matDialog: MatDialog, private dialogRef: MatDialogRef<PickInventoryDialogComponent>,
    @Inject(MAT_DIALOG_DATA) data) { 
      this.currentInventoryID = data.currentInventory;
    }

  ngOnInit(): void {
    this.filterForm = new FormGroup({
      filter: new FormControl("")
    });

    this.dataService.inventoryType.subscribe(result => {

      this.inventoryTypeArray = result;
      if (Array.isArray(result)) {
        this.inventoryTypeArrayStorage = [...result];
      }

      let filteredList = this.applyCurrentFilter(result, this.currentFilter);
      this.inventoryTypeValues = this.createSortedList(filteredList);

      this.setDescriptionFromID();

    });
  }

  checkGroup(group, checkFor) {
    if (Array.isArray(group)) {
      for (let i = 0; i < group.length; i++) {
        if (!group[i][checkFor]) {
          return false;
        }
      }
    }
    return true;
  }

  setDescriptionFromID() {
    console.log(this.currentInventoryID);

    for (let i = 0; i < this.inventoryTypeArray.length; i++) {
      if (this.inventoryTypeArray[i].id === this.currentInventoryID) {
        this.currentTypeDescription = this.inventoryTypeArray[i].description;
        break;
      }
    }
  }

  choseInventoryType(data) {
    console.log(data);
    this.currentInventoryID = data.type.id;
    this.currentTypeDescription = data.type.description;
  }

  filterInventoryTypes(event) {
    switch(event.key) {
      case "Backspace":
        this.currentFilter = this.currentFilter.substring(0, this.currentFilter.length - 1);
        break;
      default:
        this.currentFilter = this.currentFilter + event.key;
        break;
    }

    let filteredList = this.applyCurrentFilter(this.inventoryTypeArrayStorage, this.currentFilter);
    this.inventoryTypeValues = this.createSortedList(filteredList);
  }

  applyCurrentFilter(baseData, filter) {
    let filterResult;
    if (Array.isArray(baseData)) {
      filterResult = baseData.filter( element => {
        return element.description.toLowerCase().includes(filter.toLowerCase());
      })
    }
    return filterResult;
  }


  createSortedList(listData) {

    let listObject;

    if (Array.isArray(listData)) {
      listObject = listData.reduce((accumulator, item) => {
        let group = item.category;
        if (!accumulator[group]) {
          accumulator[group] = [item];
        } else {
          accumulator[group].push(item);
        }
        return accumulator;
      }, {});

      for (let i = 0; i < listData.length; i++) {
        if (!this.categoryObject.includes(listData[i]["category"])) {
          this.categoryObject.push(listData[i]["category"]);
        }
      }
    }

    const sortingString = "description";
    let DataObject = new DataIndex(null);
    for (let group of Object.entries(listObject)) {
      if (this.checkGroup(group[1], sortingString)) {
        DataObject.sortData(group[1], "asc", sortingString);
      }
    }

    let allEntries = Object.keys(listObject);
    allEntries.sort();

    return listObject;
  }

  removeCurrentFilter() {
    this.currentFilter = "";
    let filteredList = this.applyCurrentFilter(this.inventoryTypeArrayStorage, this.currentFilter);
    this.inventoryTypeValues = this.createSortedList(filteredList);
    this.filterForm.controls["filter"].setValue(this.currentFilter);
  }

  addValue(value) {
    this.dataService.addInventoryType(value);
  }

  updateValues(values) {
    this.dataService.updateInventoryType(values);
  }

  updateTypeValue(value) {
    this.dataService.updateInventoryTypeCategory(value);
  }

  deleteValue(value) {
    this.dataService.deleteInventoryType(value);
  }

  openDialog(dialog, usage, dataObject?) {
   
    const callbacks = {
      add: this.addValue,
      edit: this.updateValues,
      editType: this.updateTypeValue
    };

    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = valueObjects[dialog].config[usage];
    dialogConfig.data.currentValues = dataObject;
    dialogConfig.data.selectValues = this.categoryObject;

    const testDialog = new DialogHandler(
      this.matDialog,
      valueObjects[dialog].component,
      dialogConfig,
      callbacks[usage],
      this.dataService
    );
    let tester = testDialog.openDialog();
  }

  save() {
    console.log(this.currentInventoryID)
    this.dialogRef.close(this.currentInventoryID);
  }

  close() {
    this.dialogRef.close(false);
  }
}
