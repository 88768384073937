<div mat-dialog-content>

    <div class="dialog-header">
        <span class="dialog-header-title">XX Inventarstammdaten</span>
    </div>

    <div class="dialog-content">
        <form [formGroup]="form">
            <div class="edit-playground-data dialog-four-column-grid">
                <span class="dialog-image-functions">

                    <app-show-pictures></app-show-pictures><!-- [getPhotosQuery]=getInventoryPhotoQuery [elementID]=item.id></app-show-pictures>-->

                    <app-edit-pictures></app-edit-pictures>
                    <!--[baseQuery]=[picturesQuery]=[editQuery]=[updateQuery]=[deleteQuery]=[playGroundID]=[playgroundDescription]=-->

                    <app-add-picture></app-add-picture>
                    <!--[baseQuery]=[picturesQuery]=[addQuery]=[playGroundID]=></app-add-picture>-->

                </span>


                Inventartyp
                <!--validator-->
                <app-pick-inventory-type formControlName="inventory_type"></app-pick-inventory-type>
                Inventar-Nummer
                <!--validator--> <input type="input" class="input" formControlName="inventory_number">
                Modell <input type="input" class="input" formControlName="model">
                Identträgerart <app-data-select formControlName="ident_carrier_type" ></app-data-select>
                Material <input type="input" class="input" formControlName="material">
                Inventar-ID <input type="input" class="input" formControlName="inventory_id">
                Altergruppe <input type="input" class="input" formControlName="age_class">
                Allgemeinzustand <app-simple-datachoice formControlName="condition" [dataType]="'condition'"></app-simple-datachoice>
                Erwerbsart <app-simple-datachoice formControlName="acquisition_type" [dataType]="'acquisition_type'">
                </app-simple-datachoice>
                Lieferant <app-select-supplier formControlName="supplier"></app-select-supplier>
                Zugang <app-single-datepicker formControlName="inclusion_date"></app-single-datepicker>
                Hersteller <input type="input" class="input" formControlName="manufacturer">
                Garantieende <app-single-datepicker formControlName="warranty_end"></app-single-datepicker>
                Artikelnummer <input type="input" class="input" formControlName="article_number">
                Nutzungsende <app-single-datepicker formControlName="usage_end"></app-single-datepicker>
                Seriennummer <input type="input" class="input" formControlName="serial_number">
                Abgang <app-single-datepicker formControlName="exclusion_date"></app-single-datepicker>
                Baujahr <input type="input" class="input" formControlName="date_build">
                <span>Abmaße (HxBxL [cm])</span> <input type="input" class="input" formControlName="dimension">
                <span>Gewicht [kg]</span> <input type="input" class="input" formControlName="weight">
                <span>Fläche [m²]</span> <input type="input" class="input" formControlName="area">
                <span>Fallhöhe [cm]</span> <input type="input" class="input" formControlName="fall_height">
                Fallschutz <input type="input" class="input" formControlName="fall_protection">
                <span>Fallschutzdicke [cm]</span> <input type="input" class="input"
                    formControlName="fall_protection_thickness">
                Bemerkung <input type="textfield" class="input long-row" formControlName="note">
                Kontrollinfo <input type="textfield" class="input long-row" formControlName="inspection_info">
            </div>
        </form>
    </div>
    <div class="dialog-footer">
        <div class="dialog-footer-buttons">
            <button class="button" (click)="close()">Abbrechen</button>
            <button class="button" (click)="save()">Speichern</button>
        </div>
    </div>
</div>