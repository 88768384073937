<div class="toolbar">
  <span class="toolbarHeader"
    >Baumkatalog <span class="count">{{ treeTypeCount() }} </span>
  </span>
  <div #toolbarActions class="toolbarActions">
    <app-dialog-box-trigger
      [formComponent]="formComponent"
      [dimensions]="{ width: '500px', height: 'auto' }"
      (submitValue)="submitted($event)"
      ><mat-icon class="material-symbols-outlined"
        >note_add</mat-icon
      ></app-dialog-box-trigger
    >
    <app-dialog-box-trigger
      [formComponent]="deleteGroupForm"
      [record]="selectedItems"
      [dimensions]="{ width: '700px', height: 'auto' }"
      submitTitle="OK"
      (submitValue)="deleteTreeTypeSelection($event)"
      ><mat-icon class="material-symbols-outlined"
        >delete</mat-icon
      ></app-dialog-box-trigger
    >
  </div>
</div>

<div class="mainContent">
  <mat-drawer-container
    class="example-container"
    style="height: 100%; width: 100%"
  >
    <mat-drawer-content>
      <div id="mainView" class="mainView">
        <table
          matSort
          (matSortChange)="sortData($event)"
          matSortActive="name_german"
          matSortDirection="asc"
          matSortDisableClear
        >
          <thead>
            <tr>
              <th class="thNameGerman" mat-sort-header="name_german">
                Deutscher Name
              </th>
              <th class="thNameBotanic" mat-sort-header="name_botanic">
                Botanischer Name
              </th>
              <th class="thLastModify" mat-sort-header="modify_date">
                Letzte Bearbeitung
              </th>
              <th class="thCreation" mat-sort-header="creation_date">
                Erstellt
              </th>
              <th class="thActions"></th>
            </tr>
          </thead>
        </table>
        <cdk-virtual-scroll-viewport
          id="viewport"
          itemSize="60"
          class="virtualScrollViewport"
        >
          <table
            matSort
            selectableTable
            [dataArray]="this.tree_typeData.sortedData"
            (selectedItemsOutput)="setSelectedItems($event)"
            [outerClickExcepions]="[toolbarActions]"
          >
            <tbody #tbody class="noselect">
              <tr
                *cdkVirtualFor="
                  let item of this.tree_typeData.sortedData;
                  let i = index;
                  let odd = even;
                "
                [id]="firstLetter(this.tree_typeData.sortedData[i][sorting])"
                [attr.selectable-table-data-target]="item.id"
                enterTheViewportNotifier
                (visibilityChange)="inViewport($event)"
                [class]="selectedItems.includes(item) ? 'selected' : ''"
                [ngClass]="{'trOdd': odd}"
              >
                <td class="tdNameGerman">{{ item.name_german }}</td>
                <td class="tdNameBotanic">{{ item.name_botanic }}</td>
                <td class="tdLastModify">
                  <ng-container
                    *ngIf="item.modify_date !== '0000-00-00 00:00:00'"
                  >
                    {{
                      item.modify_date | date : "EE, dd.MM.yyyy hh:mm" : "de"
                    }}
                  </ng-container>
                </td>

                <td class="tdCreation">
                  <ng-container *ngIf="item.creation_date">
                    {{
                      item.creation_date | date : "EE, dd.MM.yyyy hh:mm" : "de"
                    }}
                  </ng-container>
                </td>
                <td class="tdActions">
                  <app-dialog-box-trigger
                    [formComponent]="formComponent"
                    [dimensions]="{ width: '500px', height: 'auto' }"
                    [record]="item"
                    (submitValue)="submitted($event)"
                  >
                    <span class="material-symbols-outlined">edit_document</span>
                  </app-dialog-box-trigger>
                  <!-- <app-delete-data
                    class="placeholder"
                    [deleteData]="item"
                    (returnInput)="deleteTreeType($event)"
                  ></app-delete-data> -->
                </td>
              </tr>
            </tbody>
          </table>
        </cdk-virtual-scroll-viewport>
      </div>
    </mat-drawer-content>

    <mat-drawer
      class="alphabetical-scrollbar"
      style="height: 100%; width: 50px"
      #alphabetSideNav
      [disableClose]="true"
      mode="side"
      opened
      position="end"
    >
      <app-alphabetical-scrollbar
        [disabledLetters]="this.disabledLetters"
        [activeLetter]="this.activeLetter"
        (scroll)="scrollTo($event)"
      ></app-alphabetical-scrollbar>
    </mat-drawer>
  </mat-drawer-container>
</div>
