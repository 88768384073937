import { NgModule } from "@angular/core";
import { GlobalModulesModule } from "../global-modules.module";
import { HeaderModule } from "../header/header.module";
import { LoginComponent } from "./login.component";


@NgModule({
  declarations: [
    LoginComponent
  ],
  imports: [GlobalModulesModule, HeaderModule],
  exports: [
    LoginComponent
  ],
})
export class LoginModule {}
