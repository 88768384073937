import { NgModule } from "@angular/core";
import { GlobalModulesModule } from "src/app/global-modules.module";
import { FilterChoiceComponent } from "./filter-choice.component";
import { SharedDirectivesModule } from "src/app/directives/shared-directives.module";

@NgModule({
    declarations: [
        FilterChoiceComponent,
        
    ],
    imports: [GlobalModulesModule, SharedDirectivesModule],
    exports: [
        FilterChoiceComponent,

    ],
  })
  export class FilterChoiceModule {}