import { Injectable } from "@angular/core";
import { Query, QueryRef } from "apollo-angular";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { NewGraphQLService } from "../new-graphql.service";

@Injectable({
  providedIn: "root",
})
export class TreeAddressesService {
  private graphqlObject: string = "treeAdresses";
  private queryRef: QueryRef<Query>;
  private treeAddressDataObservable: Observable<any>;

  constructor(private newGraphQLService: NewGraphQLService) {}

  getAllAddressData() {
    // create only new stream if not already exist
    if (!this.treeAddressDataObservable) {
      this.queryRef = this.newGraphQLService.requestAllData(
        this.graphqlObject
      );

      this.treeAddressDataObservable = this.queryRef.valueChanges.pipe(
        map((result: any) => {
          return result.data.get_tree_addresses;
        })
      );
    }

    return this.treeAddressDataObservable;
  }
}
