import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {slideSubmenu} from "../../animations"

import { 
  trigger,
  state,
  style,
  animate,
  transition
} from '@angular/animations';
import { MediatorService } from '../services/mediator/mediator.service';


@Component({
  selector: 'app-spielplatzkontrolle',
  templateUrl: './spielplatzkontrolle.component.html',
  animations: [slideSubmenu],
  styleUrls: ['./spielplatzkontrolle.component.sass']
})
export class SpielplatzkontrolleComponent implements OnInit {

  sidenavState: any = false;

  isOpen1 = false;
  isOpen2 = false;

  clientId: string;

  @ViewChild("sidenav") sidenavElement;

  @HostListener('document:mousedown', ['$event'])
  onGlobalClick(event): void {
    let clickedElement = event.target;
    let sideNavToggleButton = this.mediator.sideNavToggleButton._elementRef.nativeElement;
    let sideNavElement = this.sidenavElement._elementRef.nativeElement;

    if (!sideNavToggleButton.contains(clickedElement) && !sideNavElement.contains(clickedElement)) {
      this.mediator.setSideNavState(false);
    }
  }

  constructor(
    private route: ActivatedRoute,
    private router: Router, 
    private mediator: MediatorService
  ) { 
    
  }

  ngOnInit() {
    this.clientId = this.route.snapshot.paramMap.get('client');
    this.sidenavState = this.mediator.getSideNavState();
  }

  onClick(part) {
    if (part == 1) {
      this.isOpen1 = !this.isOpen1;
      this.isOpen2 = false;
    } else if (part == 2) {
      this.isOpen2 = !this.isOpen2;
      this.isOpen1 = false;
    }
  }
}
