<div [formGroup]="defaultDataForm">
  <mat-form-field floatLabel="always" class="fullWidth" appearance="outline">
    <mat-label>Wert</mat-label>
    <input matInput formControlName="value" />
    <button      
      *ngIf="defaultDataForm.get('value').value"
      matSuffix
      mat-icon-button
      aria-label="Clear"
      (click)="this.defaultDataForm.patchValue({ value: '' })"
    >
      <mat-icon>close</mat-icon>
    </button>
    <mat-error *ngIf="defaultDataForm.get('value').hasError('required')">
      Erforderlich
    </mat-error>
  </mat-form-field>

  <mat-form-field floatLabel="always" class="fullWidth" appearance="outline">
    <mat-label>Sortierung</mat-label>
    <input matInput formControlName="sorting" />
    <button      
      *ngIf="defaultDataForm.get('sorting').value"
      matSuffix
      mat-icon-button
      aria-label="Clear"
      (click)="this.defaultDataForm.patchValue({ sorting: '' })"
    >
      <mat-icon>close</mat-icon>
    </button>
  </mat-form-field>

  <div *ngIf="color" class="pillInput" formGroupName="setting">
    <mat-form-field floatLabel="always" class="fullWidth" appearance="outline">
      <mat-label>Farbe</mat-label>
      <input
        #colorInput
        matInput
        formControlName="color"
        style="position: relative; z-index: 2"
      />
      <p #textPill class="customBadge textPill"></p>
      <div matSuffix style="display: flex">
        <button          
          *ngIf="defaultDataForm.get('setting').get('color').value"
          mat-icon-button
          aria-label="Clear"
          (click)="this.defaultDataForm.get('setting').patchValue({ color: '' })"
        >
          <mat-icon>close</mat-icon>
        </button>
        <button mat-icon-button [matMenuTriggerFor]="menu">
          <mat-icon>color_lens</mat-icon>
        </button>
        <mat-menu #menu="matMenu">
          <div
            (click)="$event.stopPropagation()"
            (keydown)="$event.stopPropagation()"
          >
            <div style="width: 280px">
              <color-sketch
                [color]="colorCode"
                (onChange)="colorChange($event.color)"
                [disableAlpha]="true"
              ></color-sketch>
            </div>
          </div>
        </mat-menu>
      </div>
      <mat-error *ngIf="defaultDataForm.get('setting').get('color').hasError('isHex')">
        Kein Hex-Wert
      </mat-error>
    </mat-form-field>
  </div>
</div>
