<!--<link rel="stylesheet" href="https://openlayers.org/en/v6.2.1/css/ol.css" type="text/css">-->

<div *ngIf="dataTransfer; else addDataTitle">
    <h2 mat-dialog-title>Adresse verwalten</h2>
</div>
<ng-template #addDataTitle>
    <h2 mat-dialog-title>Adresse angelegen</h2>
</ng-template>
<mat-dialog-content>
    <div class="manage-address">
        <span class="manage-address-wrapper">
            <!--
            <span class="manage-address-instruction-label">Bitte wählen Sie:</span>
            <div class="manage-address-functions">
                <span class="manage-address-functions-instruction">
                    <em>Marker in der Mitte des aktuellen Kartenausschnitts anzeigen und mit der Maus auf den gewünschten
                    Ort ziehen</em>
                </span>
                <button class="button is-primary" (click)="displayCoordsLocationPointer()">Marker anzeigen</button>
            </div>
            <span class="manage-address-instruction-label">Oder</span>
            -->
            <div class="manage-address-field">
                <!--
                <div class="manage-address-field-instruction">
                    <em>Adresse direkt eingeben</em>
                </div>
                -->
                <div class="manage-address-field-form">
                    <form [formGroup]="location">
                        <label class="has-text-danger is-required">Land:</label>
                        <div>
                            <div class="select">
                                <select name="country" formControlName="country" (change)="resetTimeoutAndCheckForm()"
                                    (blur)="resetTimeoutAndCheckForm()">
                                    <option value="Deutschland">Deutschland</option>
                                    <option value="Österreich">Österreich</option>
                                    <option value="Schweiz">Schweiz</option>
                                </select>
                            </div>
                        </div>
                        <span>
                            <label class="has-text-danger is-required">PLZ:</label>
                            <input class="input input-postcode" type="text" formControlName="postcode"
                                (keypress)="checkAddressTimeout()" (blur)="resetTimeoutAndCheckForm()">
                            <label class="has-text-danger is-required input-location">Ort:</label>
                            <input class="input location-input" type="text" formControlName="location"
                                (keypress)="checkAddressTimeout()" (blur)="resetTimeoutAndCheckForm()">
                        </span>
                        <label>Ortsteil:</label>
                        <input class="input long-input" type="text" formControlName="district"
                            (keypress)="checkAddressTimeout()" (blur)="resetTimeoutAndCheckForm()">
                        <label class="has-text-danger is-required">Straße / Standort-Bezeichnung:</label>
                        <input class="input long-input" type="text" formControlName="street"
                            (keypress)="checkAddressTimeout()" (blur)="resetTimeoutAndCheckForm()">
                        <label>Hausnummer:</label>
                        <input class="input long-input" type="text" formControlName="house_number"
                            (keypress)="checkAddressTimeout()" (blur)="resetTimeoutAndCheckForm()">
                        <div class="address-button-container"
                            [ngClass]="{'show-button': formIsLocked, 'hide-button': !formIsLocked}">
                            <button class="button is-primary address-button" (click)="unlockForm()">Adresse
                                bearbeiten</button>
                        </div>
                        <div *ngIf="addressCoords.latitude">
                            <div class="display-coords">
                                <p>Koordinaten Adresse:</p>
                                <span>Latitude: {{addressCoords.latitude}}</span>
                                <span>Longitude: {{addressCoords.longitude}}</span>
                            </div>
                        </div>
                        <div *ngIf="pointerCoords.latitude">
                            <div class="display-coords">
                                <p>Koordinaten Zeiger:</p>
                                <span>Latitude: {{addressCoords.latitude}}</span>
                                <span>Longitude: {{addressCoords.longitude}}</span>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </span>
        <div class="manage-address-coords">
            <div id="addressMap" class="address-map"></div>
            <div #locationPointer class="marker" (mousedown)="liftLocationMarker()" (mouseup)="dropLocationMarker()">
                <svg viewBox="0 0 24 24">
                    <path
                        d="M12,11.5A2.5,2.5 0 0,1 9.5,9A2.5,2.5 0 0,1 12,6.5A2.5,2.5 0 0,1 14.5,9A2.5,2.5 0 0,1 12,11.5M12,2A7,7 0 0,0 5,9C5,14.25 12,22 12,22C12,22 19,14.25 19,9A7,7 0 0,0 12,2Z" />
                </svg>
            </div>
            <div #locationPointerButton [ngClass]="{'show-button': markerIsMoved, 'hide-button': !markerIsMoved}">
                <button class="button location-pointer-button" (click)="setPointerToMapCenter()">Positionsmarkierung in
                    die Mitte der Karte setzen</button></div>
        </div>
    </div>
</mat-dialog-content>
<mat-dialog-actions class="huge-dialog-actions">
    <button class="button" (click)="close()">Abbrechen</button>
    <button [disabled]="checkFormValidation()" class="button" (click)="save()">Speichern</button>
</mat-dialog-actions>