import {Component, OnInit} from '@angular/core';
import { Apollo, gql } from 'apollo-angular';

@Component({
  selector: "app-test",
  templateUrl: "./test.component.html",
  styleUrls: ["./test.component.sass"],
  providers: [
  ],
})
export class TestComponent implements OnInit{
  constructor(private apollo: Apollo) {}

  ngOnInit(): void {
    this.apollo
      .watchQuery({
        query: gql`
        {
          Tree(id: 10724) {
            id,
            control_group{
                        id,
                        subject {
                            id,
                            shortcut,
                            description,
                            note,
                            creation_date,
                            creation_user,
                            modify_date,
                            modify_user,
                            canDelete,
                        }
                        number,
                        name,
                        creation_date,
                        creation_user,
                        modify_date,
                        modify_user,
                        canDelete
                    }
          }
        }
        `,
      })
      .valueChanges.subscribe((result: any) => {
        console.log(result);
      });
  }
}
