/*
Logic Way GmbH, Arndt Kritzner
Version 3.5.2021:   Fokuswechsel durch Mausereignis auf aufgeklappte Liste verhindert
Version 22.3.2021:  Initialversion

Elternkomponente.ts (Testanwendung, Beispiel):
  setTestData: Subject<void> = new Subject<void>();
  pushData() {
      console.log("AppComponent.pushData");
      this.setTestData.next();
  }

Elternkomponente.html (Testanwendung, Beispiel)
      <hr>
      <app-input-filter-select-add #inputFilterSelectAdd [setTestData]="setTestData.asObservable()"></app-input-filter-select-add>
      <hr>
      <input type="text" placeholder="Testfeld für Fokuswechsel" id="test" name="test" size="50">
      <button type="button" (click)="pushData();">XXX</button>

*/

import { Component, OnInit, Inject, Input, ViewChild, ElementRef, forwardRef, ChangeDetectorRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";
import { Observable, Subscription } from 'rxjs';
import { FormGroup, FormControl } from "@angular/forms";

@Component({
  selector: 'app-input-filter-select-add',
  templateUrl: './input-filter-select-add.component.html',
  styleUrls: ['./input-filter-select-add.component.sass'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputFilterSelectAddComponent),
      multi: true
    }
  ]
})
export class InputFilterSelectAddComponent implements ControlValueAccessor, OnInit {
  private eventsSubscription: Subscription;
  selector: 'matchedListElements';
  listElements: string[];
  matchedListElements: string[];
  showElementsList: boolean;
  form: FormGroup;
  @Input("value") val: any;
  @Input() setSelectData: Observable<void>;
  @ViewChild('inputFilterSelectAddContainer') containerList: ElementRef;
  @ViewChild('inputFilterSelectAddInput') input: ElementRef;
  @ViewChild('inputFilterSelectAddElementsList') elementsList: ElementRef;

  constructor(@Inject(ChangeDetectorRef) private changeDetectorRef: ChangeDetectorRef) { }

  ngOnInit(): void {
      this.eventsSubscription = this.setSelectData.subscribe( result => {
        this.listFill(result);
      });   //() => this.test());
      this.form = new FormGroup({
        dataInput: new FormControl()
      });
  }
  ngOnDestroy() {
      this.eventsSubscription.unsubscribe();
  }

  onChange: any = () => {};
  onTouched: any = () => {};

  get value() {
    return this.val;
  }

  set value(val) {
    this.val = val;
    this.onChange(val);
    this.onTouched();
  }

  writeValue(inputValue) {
    this.value = inputValue;
    this.form.controls.dataInput.setValue(inputValue);
  }

  registerOnChange(fn) {
    this.onChange = fn;
  }

  registerOnTouched(fn) {
    this.onTouched = fn;
  }



  // Event-Handler für Listenauswahl
  public listSelect(sender) {
      this.input.nativeElement.value = sender.innerText;
      this.listFilterMatch(sender.innerText);
      this.writeValue(sender.innerText);
  }
  // Event-Handler für Listenanzeige oder -ausblendung
  public listActivation(display: boolean) {
      this.showElementsList = display;
  }
  // Event-Handler für Input-Inhaltsänderungen
  public inputUpdate(sender) {
      this.writeValue(sender.value);
      this.showElementsList = this.listFilterMatch(sender.value) > 0;
      if (this.showElementsList) {
          this.changeDetectorRef.detectChanges();
          this.elementsList.nativeElement.style.maxWidth = (this.input.nativeElement.getBoundingClientRect().width - parseInt(window.getComputedStyle(this.input.nativeElement, null)["padding-left"]) - parseInt(window.getComputedStyle(this.input.nativeElement, null)["padding-right"])) + "px";
      }
  }
  // Event-Handler für unterdrückte Ereignisse
  public falseEvent(event) {
      return false;
  }
  // Filterkriterium
  public stringMatch(value: string, test: string) {
      return (value.length == 0) || test.toLowerCase().startsWith(value.toLowerCase());
  }
  // Prüfung der Listeneinträge nach Filterkriterien
  public listFilterMatch(value: string) {
      const result: string[] = [];
      if (typeof this.listElements !== 'undefined') {
          for (var entry of this.listElements) {
              if (this.stringMatch(value, entry)) {
                  result.push(entry);
              }
          }
      }
      this.matchedListElements = result;
      return result.length;
  }
  // Datenbeschickung in die Auswahlliste
  public listFill(data) {
      this.listElements = data;
  }
  // Testdatensatz
  // public test() {
  //     const data = ['leden', 'únor', 'březen', 'duben', 'květen', 'červen', 'červenec', 'srpen', 'září', 'říjen', 'listopad', 'prosinec'];
  //     console.log("listFillTestData: " + JSON.stringify(data));
  //     this.listFill(data);
  // }
}

