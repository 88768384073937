import { GraphqlService } from "../../services/graphql.service";
import { DataobjectGraphQL } from "../dataobject-graphql/dataobject-graphql";
import { ObservableDataobject } from "../dataobject/observable-dataobject";

export class DataobjectPlaygroundInspectionPhotos extends ObservableDataobject  {
    private inspectionPhotoObject: any;

    /**
   *
   * @param graphQLService
   * @param singleItem
   * @param itemID
   * @param onlyNewest
   * @param dataSource
   */
  constructor(
    graphQLService: GraphqlService,
    singleItem?,
    itemID?,
    onlyNewest?,
    dataSource?
  ) {   
    super(dataSource);

    this.inspectionPhotoObject = new DataobjectGraphQL(
        "playground_inspection_photo",
        graphQLService,
        singleItem,
        itemID,
        onlyNewest
      );
  
      this.inspectionPhotoObject.dataObserver.subscribe(
        result => {
          this.dataStore = result;
          this.dataObserver.next(this.dataStore);
        }
      );
  }
  
  /**
   * Getter um auf die grundlegenden Methoden des erstellten Objekts zugreifen zu können (add/update/delete).
   * Ohne dieses stehen zwar die Methoden zur Verfügung, wissen aber nicht, zu welchem Query sie gehören
   */
  get baseObject() {
    return this.inspectionPhotoObject;
  }
}
