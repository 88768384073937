import { NgModule } from "@angular/core";

import { GlobalModulesModule } from "../global-modules.module";
import { StrassenkontrolleComponent } from "./strassenkontrolle.component";

@NgModule({
  declarations: [StrassenkontrolleComponent],
  imports: [GlobalModulesModule],
  exports: [],
})
export class StrassenkontrolleModule {}
