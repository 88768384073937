import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'app-add-edit-spielplatz-inventar-form',
  templateUrl: './add-edit-spielplatz-inventar-form.component.html',
  styleUrls: ['./add-edit-spielplatz-inventar-form.component.sass'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AddEditSpielplatzInventarFormComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
