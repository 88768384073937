// import { Injectable } from "@angular/core";
// import { BehaviorSubject, combineLatest } from "rxjs";
// import { GraphqlService } from "../graphql.service";
// import { DataobjectPlaygroundInspection } from "../../baseclasses/dataobject-playground/dataobject-playground-inspection";
// import { PlaygroundAddressesService } from "./playground-addresses.service";
// import { DataobjectPlayground } from '../../baseclasses/dataobject-playground/dataobject-playground';
// import { HttpClient } from "@angular/common/http";
// import { FilterServiceObject } from 'src/app/baseclasses/filter-service-object/filter-service-object';
// import { DataobjectRestAPI } from "src/app/baseclasses/dataobject-restapi/dataobject-restapi";

// // NOTE: Hier passiert einiges was obsolet geworden ist und an die kontrollen.component geschickt wird.active
// // Beispielsweise das Erstellen und senden der Zeiträume. Hier könnte bei Gelegenheit aufgeräumt werden.

// @Injectable({
//   providedIn: 'root'
// })
// export class PlaygroundInspectionService extends FilterServiceObject {

//   private _timeFrame = 6;
//   private _playgroundInspectionObject;
//   private _playgroundDataObject;
//   private _playgroundInspectionData;
//   private _playgroundAddresses;
//   private _playgroundInspectionSubject: BehaviorSubject<object>;
//   private _dataSubject: BehaviorSubject<object>;

//   private _timeframesWithInspectionCount;
//   private _timeframesWithInspectionCountSubject: BehaviorSubject<any>;

//   private _timeframeSubject: BehaviorSubject<object>;

//   private _rawInspectionDataSubject: BehaviorSubject<object>;
//   private _rawInspectionDataSubscription: any;

//   private _playgroundInspectionDataCountSubject: BehaviorSubject<number>;

//   constructor(
//     private graphQl: GraphqlService,
//     private addressService: PlaygroundAddressesService,
//     private http: HttpClient
//   ) {

//     super();

//     this._timeframeSubject = new BehaviorSubject<any>(this._timeFrame);
//     this._playgroundInspectionSubject = new BehaviorSubject<object>([]);
//     this._dataSubject = new BehaviorSubject<object>([]);
//     this._timeframesWithInspectionCountSubject = new BehaviorSubject<object>({});
//     this._rawInspectionDataSubject = new BehaviorSubject<object>([]);
//     this._playgroundInspectionDataCountSubject = new BehaviorSubject<number>(0);

//     this._playgroundInspectionObject = new DataobjectRestAPI("playground_inspection", http);
//     this._playgroundDataObject = new DataobjectPlayground(this.graphQl, http);

//     this.dataSubject = this._dataSubject;
//     this.serviceStateSubject = this._playgroundInspectionDataCountSubject;
//     this.baseDataObject = this._playgroundInspectionObject;

//     combineLatest(this._playgroundInspectionObject.dataObserver, addressService.currentAddressSelection, this._timeframeSubject, this._playgroundDataObject.dataObserver).subscribe( result => {
//       this._playgroundAddresses = this._playgroundDataObject.extractPlaygroundAddresses(result[3]);

//       this._playgroundInspectionData = result[0];

//       if (Array.isArray(this._playgroundInspectionData)) {
//         this._playgroundInspectionDataCountSubject.next(this._playgroundInspectionData.length);
//       }

//       this._timeframesWithInspectionCount = this._createTimeframesWithInspectionCount(this._playgroundInspectionData);
//       this._timeframesWithInspectionCountSubject.next(this._timeframesWithInspectionCount);

//       // Okay, aus irgendeinem Grund wird durch diese Funktion _playgroundInspectionData mit den Addressen ausgestattet,
//       // obwohl durch die Funktion eigentlich ein neues Array zurück gegeben wird, welches dann genutzt werden soll. => RxJs?
//       let playgroundInspectionWithAddresses = this._addAddressDataToInspections(this._playgroundInspectionData, this._playgroundAddresses);

//       this.baseData = [...playgroundInspectionWithAddresses];

//       if (Object.keys(this.currentFilterObject).length !== 0) {
//         let processingData = [...this.baseData];
//         let filteredData = this.applyFilterObject(processingData);
//         this._playgroundInspectionSubject.next(filteredData);
//       } else {
//         this._playgroundInspectionSubject.next([]);
//       }
//     });

//     this.dataSubject.subscribe( result => {
//       if (Object.keys(this.currentFilterObject).length !== 0) {
//         this._playgroundInspectionSubject.next(result);
//       } else {
//         this._playgroundInspectionSubject.next([]);
//       }
//     })

//     this._rawInspectionDataSubscription = this._playgroundInspectionObject.dataObserver.subscribe( result => {
//       this._rawInspectionDataSubject.next(result);
//     });

//   }

//   get playgroundInspectionData() {
//     return this._playgroundInspectionSubject;
//   }

//   get rawPlaygroundInspectionData() {
//     return this._rawInspectionDataSubject;
//   }

//   get timeframeData() {
//     return this._timeframesWithInspectionCountSubject;
//   }

//   get methodObject() {
//     return this._playgroundInspectionObject;
//   }

//   set timeFrame(timeFrame) {
//     this._timeFrame = timeFrame;
//     this._timeframeSubject.next(timeFrame)
//   }

//   /**
//    *
//    * @param inspectionArray
//    * @param addressArray
//    */
//   private _addAddressDataToInspections(inspectionArray, addressArray) {
//     let combinedArray = inspectionArray.map( item => {
//       let address = addressArray.filter( value => {
//         if (value.playground === item.playground) {
//           return value.address;
//         }
//       });

//       // copy object so its mutable
//       item = Object.assign([], item);
//       if (address.length > 0) {
//         item.number = address[0].number;
//         item.address = address[0].address;
//       } else {
//         item.address = null;
//         item.number = null;
//       }
//       return item;
//     });
//     return combinedArray;
//   }

//   /**
//    *
//    * @param inspectionData
//    */
//   private _createTimeframesWithInspectionCount(inspectionData) {

//     let inspData = Object.assign(inspectionData); // Kopie der Daten um das Original nicht zu verändern.
//     // Eigentlich soll reduce ein neues Objekt zurückgeben, aber das Verhalten ist in der console.log
//     // nicht zu erkennen. Bis geklärt ist, ob das mit RxJs zu tun hat, bleibe ich vorsichtig und kopiere.

//     let dateArray = inspData.reduce( (accumulator, item) => {
//       let year = new Date(item.date).getFullYear();
//       let month = new Date(item.date);
//       let monthName = month.toLocaleString('de', {month: 'long'});

//       if (accumulator[year]) {
//         if (accumulator[year][monthName]) {
//           accumulator[year][monthName]++;
//         } else {
//           accumulator[year][monthName] = 1;
//         }
//       } else {
//         accumulator[year] = {[monthName]: 1};
//       }
//       return accumulator;
//     }, {});
//     return dateArray;
//   }
// }

import { Injectable } from "@angular/core";
import * as moment from "moment";
import { Observable } from "rxjs";
import {  map, shareReplay } from "rxjs/operators";
import { HttpService } from "../http.service";

@Injectable({
  providedIn: "root",
})
export class PlaygroundInspectionService {
  private type: string = "playground_inspection";

  private httpCache;

  constructor(private httpService: HttpService) {}

  getAllInspectionData() {
    // create only new stream if not already exist
    if (!this.httpCache) {
      this.httpCache = this.httpService.requestBigData(this.type).pipe(
        map((result: any) => {
          return result.data;
        }),
        shareReplay(1)
      );
    }
    return this.httpCache;
  }

  refetch(){
    this.httpCache = null;
  }

  getLastInspections(inspections: Array<object>) {
    let lastInspections: object = {};
    inspections.forEach((inspection: any) => {
      let inspectionTime = moment(
        `${inspection.date} ${inspection.date}`,
        "YYYY-MM-DD hh:mm:ss"
      );

      if (
        !lastInspections[inspection.type] ||
        lastInspections[inspection.type] < inspectionTime
      ) {
        lastInspections[inspection.type] = inspectionTime;
      }
    });

    return lastInspections;
  }
}
