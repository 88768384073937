// /**
//  * TODO: Die einzelnen Address-Punkte außerhalb des Clusters müssen klickbar werden.
//  * Über die im Code vorhandene Interaction ist das realisierbar, stößt durch das Cluster aber
//  * an seine Grenzen. Nach derzeitigem Stand wirkt sich die Interaction auch immer auf ein geclustertes
//  * Element auf, was nicht sein soll. Es lässt sich nach meiner Erkenntnis aber auch nicht filtern,
//  * wie viele Features in dem geklickten Element vorhanden sind, was eine Unterscheidung
//  * möglich machen würde.
//  * Ohne Cluster wäre all das ohne weiteres möglich.
//  */

// import {
//   Component,
//   Inject,
//   OnInit,
//   ViewChildren,
//   QueryList,
//   AfterViewInit,
//   ElementRef,
//   AfterContentInit,
//   NgZone,
// } from "@angular/core";
// import { mapTilesUrl } from "src/app/settings/settings.module";
// import { GraphqlService } from "src/app/services/graphql.service";
// // import { DataobjectSpecialCases } from "src/app/baseclasses/dataobject-generic/dataobject-special-cases";
// import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
// import { Map } from "ol";
// import XYZ from "ol/source/XYZ";
// import TileLayer from "ol/layer/Tile";
// import View from "ol/View";

// import { ZoomSlider, ScaleLine, ZoomToExtent, Rotate } from "ol/control";
// import Feature from "ol/Feature";
// import { Point } from "ol/geom";
// import { Cluster } from "ol/source";
// import { Circle, Fill, Stroke, Style, Text } from "ol/style";
// import { fromLonLat, transform, toLonLat } from "ol/proj";
// import VectorLayer from "ol/layer/Vector";
// import Overlay from "ol/Overlay";
// import { Source, Vector } from "ol/source";
// import { Select, defaults } from "ol/interaction";
// import { click } from "ol/events/condition";
// import { MatGridTileText } from "@angular/material/grid-list";
// import { CoordinateService } from "src/app/services/general/coordinate.service";
// import { combineLatest } from "rxjs";

// @Component({
//   selector: "app-show-on-map",
//   templateUrl: "./show-on-map.component.html",
//   styleUrls: ["./show-on-map.component.sass"],
// })
// export class ShowOnMapComponent
//   implements OnInit, AfterViewInit, AfterContentInit
// {
//   @ViewChildren("markers") components: QueryList<any>;
//   // private _dataObject: DataobjectSpecialCases;

//   showSpinner = true;

//   mapMarkerData = [];
//   mapMarkerDataArray = [1]; //null;

//   public map: Map;
//   public source: XYZ;
//   public layer: any; //TileLayer;
//   public view: View;
//   public marker: any; //Feature;
//   public markerLayer: any; //VectorLayer;
//   public markerOverlayArray = []; //: Array<any>;
//   markerElementsArray: Array<ElementRef>;

//   markerPointArray: any; //Array<Feature>;

//   // Koordinaten für das Schweriner Schloß
//   xCoord = 11.418242;
//   yCoord = 53.624704;
//   zoomFactor = 13;

//   constructor(
//     private graphQLService: GraphqlService,
//     private dialogRef: MatDialogRef<ShowOnMapComponent>,
//     @Inject(MAT_DIALOG_DATA) data,
//     private graphQl: GraphqlService,
//     private zone: NgZone
//   ) {
//     // this._dataObject = new DataobjectSpecialCases(this.graphQLService);
//     if (Array.isArray(data.dataArray)) {
//       this.mapMarkerData = data.dataArray;
//     }

//     this.markerPointArray = new Array(this.mapMarkerData.length);
//   }

//   ngOnInit(): void {}

//   // setClusterStyle(feature) {
//   //   console.log(feature);
//   // }

//   // getFeatureText(feature) {
//   //   console.log(feature);
//   //   return "tester";
//   // }

//   ngAfterContentInit() {
//     /*
//     console.log(this.mapMarkerData);

//     this.mapMarkerDataArray = new Array();
//     console.log(this.mapMarkerDataArray);

//     this.mapMarkerDataArray = this.mapMarkerData.forEach(entry => {
//       console.log(entry);
//       if(entry.lonlat) {
//         console.log(entry);
//         this.mapMarkerDataArray.push(entry);
//       }
//     });
//     console.log(this.mapMarkerDataArray);
// */
//   }

//   test(event) {
//     // const x1 = this.renderer.createElement('div');
//     // const x2 = this.renderer.createText('tester');
//     // this.renderer.appendChild(x1, x2);
//     // this.renderer.addClass(x1, "tester");
//     // this.renderer.appendChild(event.srcElement.parentElement.parentElement.parentElement, x1);
//     console.log(event);
//   }

//   ngAfterViewInit() {
//     // this.markerElementsArray = this.components.toArray();
//     // for (let i = 0; i < this.mapMarkerData.length; i++) {
//     //   let pointerOverlay = this.markerElementsArray[i].nativeElement;
//     //   let overlayContainer = new Overlay({
//     //     element: pointerOverlay,
//     //     positioning: "center-center",//"bottom-left",
//     //     position: fromLonLat([this.mapMarkerData[i].address.longitude, this.mapMarkerData[i].address.latitude])
//     //   });
//     //   // pointerOverlay.click(() => {console.log("passt")})//.on("mouseover", event => {console.log("passt")});
//     //   // pointerOverlay//.on("click", event => {console.log("passt2")});
//     //   const x1 = this.renderer.createElement('div');
//     //   const x2 = this.renderer.createText('tester');
//     //   this.renderer.appendChild(x1, x2);
//     //   this.renderer.addClass(x1, "tester");
//     //   this.renderer.appendChild(pointerOverlay.parentElement, x1);
//     //   this.markerOverlayArray.push(overlayContainer);
//     //   this.map.addOverlay(this.markerOverlayArray[i]);
//     // }
//     // this.map.on("click", event => {console.log("test")});
//     // this.map.on("mouseover", event => {console.log("test")});
//     // console.log(this.markerOverlayArray);
//     // let pointerOverlay = this.markerElementsArray[0].nativeElement;
//     // let overlayContainer = new Overlay({
//     //   element: pointerOverlay,
//     //   positioning: "center-center",
//     //   position: fromLonLat([this.xCoord, this.yCoord])
//     // });
//     // this.markerOverlayArray.push(overlayContainer);
//     // this.map.addOverlay(this.markerOverlayArray[0]);
//     /*
//     this.markerElementsArray = this.components.toArray();
//     console.log(this.markerElementsArray);

//     console.log(this.mapMarkerDataArray);

//     this.markerOverlayArray = new Array();
//     for (let i = 0; i < this.mapMarkerDataArray.length; i++) {
//       let pointerOverlay = this.markerElementsArray[i].nativeElement;
//       this.markerOverlayArray[i] = new Overlay({
//         element: pointerOverlay,
//         positioning: "center-center"
//       });

//       this.markerOverlayArray[i].setPosition(
//         fromLonLat(this.mapMarkerDataArray[i].lonlat)
//       );
//       this.map.addOverlay(this.markerOverlayArray[i]);
//     }
//     console.log(this.markerOverlayArray);
// */

//     if (!this.map) {
//       this.zone.runOutsideAngular(() => {
//         let subjectArray = [];
//         // this.mapMarkerData.forEach((element) => {
//         //   let subject = new CoordinateService(
//         //     this.graphQl,
//         //     true,
//         //     element.coordinateId
//         //   ).coordinate;

//         //   subjectArray.push(subject);
//         // });

//         combineLatest(subjectArray).subscribe((result) => {
//           this.mapMarkerData.forEach((element) => {
//             let coordinate = result.filter((e: any) => {
//               return e.id == element.coordinateId;
//             })[0];

//             if(coordinate){
//               element.coordinate = {
//                 longitude: coordinate["longitude"],
//                 latitude: coordinate["latitude"],
//               };
//             }
//           });

//           console.log(this.mapMarkerData);

//           if(this.mapMarkerData[this.mapMarkerData.length - 1].coordinate){

//             //url: 'http://www.aidageo.de/hot/{z}/{x}/{y}.png'
//             //url: 'http://tile.osm.org/{z}/{x}/{y}.png'

//             this.source = new XYZ({
//               url: mapTilesUrl,
//             });

//             this.view = new View({
//               center: fromLonLat([
//                 this.mapMarkerData[0].coordinate.longitude,
//                 this.mapMarkerData[0].coordinate.latitude,
//               ]),
//               zoom: this.zoomFactor,
//             });

//             this.map = new Map({
//               target: "playgroundMap",
//               layers: [
//                 new TileLayer({
//                   source: this.source,
//                 }),
//               ],
//               view: this.view,
//             });

//             // ** Dieses Element aktivieren, um die Interaktion wieder zu re-integrieren
//             //this.map.addInteraction(selectElement);

//             var zoomslider = new ZoomSlider();
//             this.map.addControl(zoomslider);

//             var scaleline = new ScaleLine();
//             this.map.addControl(scaleline);

//             var zoomToExtent = new ZoomToExtent();
//             this.map.addControl(zoomToExtent);

//             var rotate = new Rotate();
//             this.map.addControl(rotate);

//             for (let i = 0; i < this.mapMarkerData.length; i++) {
//               this.markerPointArray[i] = new Feature({
//                 geometry: new Point(
//                   fromLonLat([
//                     this.mapMarkerData[i].coordinate.longitude,
//                     this.mapMarkerData[i].coordinate.latitude,
//                   ])
//                 ),
//               });
//               this.markerPointArray[i].setId(
//                 this.mapMarkerData[i]["description"]
//               );
//             }

//             var source = new Vector({
//               features: this.markerPointArray,
//             });

//             var clusterSource = new Cluster({
//               distance: 50,
//               source: source,
//             });

//             function styleTest(feature) {
//               let featureArray = feature.get("features");
//               let featureID = featureArray[0].getId();

//               var text = new Text({
//                 text: featureID,
//                 font: "bold 12px",
//                 //offsetX: 50,
//                 offsetY: -28,
//                 //padding: [1, 5, 0, 5],
//                 //padding: [5, 10, 1, 10],
//                 padding: [8, 15, 6, 15],
//                 backgroundFill: new Fill({
//                   color: "rgba(255, 255, 255,0.7)",
//                 }),
//                 backgroundStroke: new Stroke({
//                   color: "rgba(255,255,255,0.75)",
//                   width: 1,
//                 }),
//               });
//               return text;
//             }

//             function styleFunction(feature) {
//               var style;
//               var size = feature.get("features").length;
//               if (size > 1) {
//                 style = new Style({
//                   image: new Circle({
//                     radius: 10,
//                     stroke: new Stroke({
//                       color: "#fff",
//                     }),
//                     fill: new Fill({
//                       color: "#1e2399",
//                     }),
//                   }),
//                   text: new Text({
//                     text: size.toString(),
//                     fill: new Fill({
//                       color: "#fff",
//                     }),
//                   }),
//                 });
//               } else {
//                 style = new Style({
//                   image: new Circle({
//                     radius: 10,
//                     stroke: new Stroke({
//                       color: "#fff",
//                     }),
//                     fill: new Fill({
//                       color: "#1e2399",
//                     }),
//                   }),
//                   text: styleTest(feature),
//                 });
//               }
//               return style;
//             }

//             let testMarkerLayer = new VectorLayer({
//               source: clusterSource,
//               style: styleFunction,
//             });

//             this.map.addLayer(testMarkerLayer);
//           }
//         });
//       });
//     }
//   }

//   closeDialog() {
//     this.close();
//   }

//   save() {
//     this.dialogRef.close(null);
//   }

//   close() {
//     this.dialogRef.close(null);
//   }
// }

import {
  AfterContentInit,
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  NgZone,
  OnInit,
  Output,
} from "@angular/core";
import { Map, View } from "ol";
import TileLayer from "ol/layer/Tile";
import { fromLonLat } from "ol/proj";
import { XYZ } from "ol/source";
import { mapTilesUrl } from "src/app/settings/settings.module";

@Component({
  selector: "app-show-on-map",
  templateUrl: "./show-on-map.component.html",
  styleUrls: ["./show-on-map.component.sass"],
})
export class ShowOnMapComponent implements OnInit, AfterViewInit, AfterContentInit {

  @Input() private record?: [];

  private map: Map;
  public source: XYZ;

  private view;

  constructor(private zone: NgZone){

    this.view = new View({
      center: fromLonLat([11.418946666667, 53.601618333333]),
      zoom: 15,
    })

    this.source = new XYZ({
      url: mapTilesUrl
    });
  }

  ngOnInit(): void {
    console.log(this.record)
  }

  ngAfterViewInit(): void {
    if (! this.map) {
      this.zone.runOutsideAngular(() => {
        this.map = new Map({
          target: "map",
          layers: [
            new TileLayer({
              source: this.source,
            }),
          ],
          view: this.view,
        });
      })
    } 
  }

  ngAfterContentInit(): void {
    
  }

  
}
