import { Component, HostListener, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { MediatorService } from "../services/mediator/mediator.service";

@Component({
  selector: "app-baumkontrolle",
  templateUrl: "./baumkontrolle.component.html",
  styleUrls: ["./baumkontrolle.component.sass"],
})
export class BaumkontrolleComponent implements OnInit {
  clientId: string;
  sidenavState: any = false;

  @ViewChild("sidenav") sidenavElement;

  @HostListener('document:mousedown', ['$event'])
  onGlobalClick(event): void {
    let clickedElement = event.target;
    let sideNavToggleButton = this.mediator.sideNavToggleButton._elementRef.nativeElement;
    let sideNavElement = this.sidenavElement._elementRef.nativeElement;

    // closes SideNav when clicked outside
    if (!sideNavToggleButton.contains(clickedElement) && !sideNavElement.contains(clickedElement)) {
      this.mediator.setSideNavState(false);
    }
  }

  constructor(
    private route: ActivatedRoute,
    private mediator: MediatorService,
  ) {

  }

  ngOnInit() {
    this.clientId = this.route.snapshot.paramMap.get("client");
    this.sidenavState = this.mediator.getSideNavState();    
  }
}
