import { MatDialog, MatDialogConfig } from '@angular/material/dialog';

export class DialogHandler {
  constructor(public dialog: MatDialog, public DialogComponent, public dialogConfig: MatDialogConfig, public callback, public dataService) {}

  openDialog() {
    const dialogRef = this.dialog.open(this.DialogComponent, this.dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
      //   if (result.newValueID) {
      //     // Nein, Update funktionierte an dieser Stelle seltsamerweise nicht
      //     this.serviceObject.addInventoryType(result.form);
      //     this.serviceObject.deleteInventoryType({id: result.newValueID});
      //   } else {  
      //     this.serviceObject.addInventoryType(result.form);
      //   }
      // }
        console.log(result);
        this.callback(result);
      }
    })
  }
}
