import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-edit-complex-data',
  templateUrl: './edit-complex-data.component.html',
  styleUrls: ['./edit-complex-data.component.sass']
})
export class EditComplexDataComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  editData() {
    console.log("funzt");
  }
}
