import { NgModule } from "@angular/core";
import { GlobalModulesModule } from "src/app/global-modules.module";
import { ShowThumbnailComponent } from "./show-thumbnail.component";


@NgModule({
  declarations: [
    ShowThumbnailComponent
  ],
  imports: [GlobalModulesModule],
  exports: [
    ShowThumbnailComponent
  ],
})
export class ShowThumbnailModule {}
