import { Injectable } from "@angular/core";
import { Query, QueryRef } from "apollo-angular";
import { Observable } from "rxjs";
import { first, map } from "rxjs/operators";
import { NewGraphQLService } from "../new-graphql.service";

@Injectable({
  providedIn: "root",
})
export class treetypeDataService {
  private graphqlObject: string = "tree_type";
  private queryRef: QueryRef<Query>;
  private treeTypeDataObservable: Observable<any>;

  constructor(private newGraphQLService: NewGraphQLService) {}

  getAllTreeData() {
    // create only new stream if not already exist
    if(!this.treeTypeDataObservable){
      this.queryRef = this.newGraphQLService.requestAllData(
        this.graphqlObject,
      );
  
      this.treeTypeDataObservable = this.queryRef.valueChanges.pipe(
        map((result: any) => {
          return result.data.Tree_Type;
        })
      );
    }

    return this.treeTypeDataObservable;
  }

  addTreeTypeData(treeTypeData: object) {
    let queryRef = this.newGraphQLService.addData(
      this.graphqlObject,
      treeTypeData
    );

    return queryRef.pipe(
      first(),
      map((result: any) => {
        return result.data.add_Tree_Type;
      })
    );
  }

  updateTreeTypeData(treeTypeData: object) {
    let queryRef = this.newGraphQLService.updateData(
      this.graphqlObject,
      treeTypeData,
    );

    return queryRef.pipe(
      first(),
      map((result: any) => {
        return result.data.update_Tree_Type;
      })
    );
  }

  deleteTreeTypeData(treeTypeId: number) {
    console.log(treeTypeId);
    let queryRef = this.newGraphQLService.deleteData(
      this.graphqlObject,
      treeTypeId
    );

    return queryRef.pipe(
      map((result: any) => {
        return result.data.delete_Tree_Type;
      })
    );
  }
}
