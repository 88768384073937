// import { Injectable, OnDestroy } from "@angular/core";
// import { Subject, BehaviorSubject } from "rxjs";
// import { GraphqlService } from "../graphql.service";
// import { DataobjectAddress } from "../../baseclasses/dataobject-generic/dataobject-address";
// import { DataobjectPlayground } from "../../baseclasses/dataobject-playground/dataobject-playground";
// import { combineLatest } from "rxjs";
// import { HttpClient } from "@angular/common/http";
// import { DataobjectRestAPI } from "src/app/baseclasses/dataobject-restapi/dataobject-restapi";

// @Injectable({
//   providedIn: "root"
// })
// export class PlaygroundAddressesService implements OnDestroy {
//   private _addressObject;
//   private _addressObjectSubscription;
//   private _addressData;
//   private _sortedAddressData;

//   private _playgroundObject;

//   private _currentAddressSelection = [];
//   private _currentAddressSelectionSubject: BehaviorSubject<object>;

//   private _playgroundAddresses;
//   private _playgroundAddressIDArray;
//   private _addressIDSubscription;

//   private _addressDataSubject: BehaviorSubject<object>;
//   private _sortedAddressDataSubject: BehaviorSubject<object>;
//   private _playgroundAddressesSubject: BehaviorSubject<object>;


//   constructor(private graphQl: GraphqlService, private http: HttpClient) {
//     this._addressDataSubject = new BehaviorSubject<object>([]);
//     this._sortedAddressDataSubject = new BehaviorSubject<object>([]);
//     //this._playgroundAddressIDSubject = new BehaviorSubject<object>([]);
//     this._playgroundAddressesSubject = new BehaviorSubject<object>([]);
//     this._currentAddressSelectionSubject = new BehaviorSubject<object>([]);

//     this._addressObject = new DataobjectRestAPI("address", http);
//     this._addressObjectSubscription = this._addressObject.dataObserver.subscribe(
//       result => {
//         this._addressData = result;
//         this._addressDataSubject.next(this._addressData);
//         let sortedData = this._buildSortedList(this._addressData);
//         this._sortedAddressData = sortedData;
//         this._sortedAddressDataSubject.next(this._sortedAddressData);
//       }
//     );

//     this._playgroundObject = new DataobjectPlayground(this.graphQl, http);
//     combineLatest([
//       this._playgroundObject.dataObserver,
//       this._addressDataSubject
//     ]).subscribe(result => {
//       let playgroundAddressIDs = this._playgroundObject.extractPlaygroundAddressIDs(result[0]);
//       let filteredData = this._filterPlaygroundAddresses(playgroundAddressIDs, result[1]);
//       this._playgroundAddresses = filteredData;
//       let sortedPlaygroundAddresses = this._buildSortedList(filteredData);
//       this._playgroundAddressesSubject.next(sortedPlaygroundAddresses);
//     });
//   }

//   ngOnDestroy() {
//     this._addressObjectSubscription.unsubscribe();
//   }

//   get allAddresses() {
//     return this._addressDataSubject;
//   }

//   get sortedAddresses() {
//     return this._sortedAddressDataSubject;
//   }

//   get playgroundAddresses() {
//     return this._playgroundAddressesSubject;
//   }

//   get currentAddressSelection() {
//     return this._currentAddressSelectionSubject;
//   }




//   /*
//   set playgroundAddresses(addressesArray) {

//     console.log(addressesArray);

//     this._playgroundAddressIDArray = addressesArray;
//     this._playgroundAddressIDSubject.next(addressesArray);
//   }
//   */

//   set setCurrentAddressSelection(addressID) {
//     this._toggleAddressIDArray(addressID);
//   }

//   /**
//    * Group items by given property.
//    * objectArray is 
//    * 
//    * @param objectArray 2-dimensional array, each item is an address, build like this:
//    * {"city":"MyCity", "street":"GroveStreet"}
//    * @param {string} property key, whose value will be used as key in the return array
//    * @returns array with items grouped by given property, so if for example the property is
//    * "city", each key will be a different city and the arrays will be addresses with said city
//    */
//   _groupBy(objectArray, property: string) {
//     return objectArray.reduce((accumulator, item) => {
//       let key = item[property];

//       if (key === undefined || key === null) {
//         key = "";
//       }

//       if (accumulator[key] === undefined) {
//         accumulator[key] = [];
//       }

//       accumulator[key].push(item);
//       return accumulator;
//     }, {});
//   }

//   _mapNextGroup(itemGroup, item, groupCriteria) {
//     return itemGroup[item] = this._groupBy(itemGroup[item], groupCriteria[1]);
//   }

//   _mapInnerGroupingFirstLevel() {

//   }

//   _mapInnerGroupingSecondLevel(itemGroup, item, value, groupCriteria) {
//     return itemGroup[item][value] = this._groupBy(
//       itemGroup[item][value],
//       groupCriteria[2]
//     );
//   }

//   _reduceFinalGrouping(accumulator, address) {
//     accumulator.push({
//       id: address.id,
//       address: `${address.street} ${address.house_number}`
//     });
//     return accumulator;
//   }

//   /**
//    * Das hier ist ohne Frage die schlechteste Funktion, die ich je geschrieben habe.
//    * Und wenn ich (oder du) bei Gelegenheit auch nur einen Funken Hirnschmalz dafür
//    * aufwenden magst, das zu verbessern, würdest du der Menschheit einen großen
//    * Gefallen tun.
//    * @param addressData
//    */
//   _buildSortedList(addressData) {

//     if (!addressData) {
//       return;
//     }

//     //console.log(addressData);
//     const sortedList = {};

//     let groupCriteria = [
//       "country",
//       "location",
//       "district",
//       "postcode",
//       "street" // Lasse ich erstmal außen vor
//     ];
//     let initialGroup = this._groupBy(addressData, groupCriteria[0]);
//     let nextGroup = Object.keys(initialGroup);
//     let finalGroup = initialGroup;
//     nextGroup.map(item => {
//       this._mapNextGroup(finalGroup, item, groupCriteria)
//     });
//     // nextGroup.map(item => {
//     //   finalGroup[item] = this._groupBy(initialGroup[item], groupCriteria[1]);
//     // });

//     nextGroup.map(item => {
//       let innerGrouping = Object.keys(finalGroup[item]);
//       innerGrouping.map(value => this._mapInnerGroupingSecondLevel(finalGroup, item, value, groupCriteria));
//       // innerGrouping.map(value => {
//       //   finalGroup[item][value] = this._groupBy(
//       //     finalGroup[item][value],
//       //     groupCriteria[2]
//       //   );
//       // });
//     });

//     nextGroup.map(item => {
//       let innerGrouping = Object.keys(finalGroup[item]);
//       innerGrouping.map(value => {
//         let deepGrouping = Object.keys(finalGroup[item][value]);
//         deepGrouping.map(result => {
//           finalGroup[item][value][result] = this._groupBy(
//             finalGroup[item][value][result],
//             groupCriteria[3]
//           );
//           let deeperGrouping = Object.keys(finalGroup[item][value][result]);
//           deeperGrouping.map(entry => {
//             let deepestGrouping = finalGroup[item][value][result][entry];
//             let deepestGroupingResult = deepestGrouping.reduce(
//               this._reduceFinalGrouping, []
//               // (accumulator, ding) => {
//               //   accumulator.push({
//               //     id: ding.id,
//               //     address: `${ding.street} ${ding.house_number}`
//               //   });
//               //   return accumulator;
//               // },
//               // []
//             );
//             finalGroup[item][value][result][entry] = deepestGroupingResult;
//           });
//         });
//       });
//     });
//     console.log(finalGroup);
//     return finalGroup;
//   }


//   /**
//    * 
//    * @param addressIDs 
//    * @param addressData 
//    */

//   // TODO: Kann gelöscht werden -> Teil von manage-address-data
//   _filterPlaygroundAddresses(addressIDs, addressData) {
//     if (addressData.length > 0) {
//       let filteredAddresses = addressData.filter(data => {
//         return addressIDs.includes(data.id);
//       });
//       return filteredAddresses;
//     }
//   }

//   /**
//    * 
//    * @param addressID 
//    */
//   _toggleAddressIDArray(addressID) {
//     if (addressID && addressID !== (null || undefined)) {
//       if (Array.isArray(addressID)) {
//         this._currentAddressSelection = [];
//         if (addressID.length !== 0) {
//           addressID.map(node => {
//             if (node.locationID !== undefined) {
//               this._currentAddressSelection.push(node.locationID);
//             }
//           });
//         }
//       } else {
//         if (!this._currentAddressSelection.includes(addressID)) {
//           this._currentAddressSelection.push(addressID);
//         } else {
//           this._currentAddressSelection.splice(
//             this._currentAddressSelection.indexOf(addressID),
//             1
//           );
//         }
//       }
//       this._currentAddressSelectionSubject.next(this._currentAddressSelection);
//     }
//   }

// }


import { Injectable } from "@angular/core";
import { Query, QueryRef } from "apollo-angular";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { NewGraphQLService } from "../new-graphql.service";

@Injectable({
  providedIn: "root",
})
export class PlaygroundAddressesService {
  // TODO edit graphQlObject when backend function ready 
  private graphqlObject: string = "playgroundAdresses";
  private queryRef: QueryRef<Query>;
  private playgroundAddressDataObservable: Observable<any>;

  constructor(private newGraphQLService: NewGraphQLService) {}

  getAllAddressData() {
    // create only new stream if not already exist
    if (!this.playgroundAddressDataObservable) {
      this.queryRef = this.newGraphQLService.requestAllData(
        this.graphqlObject
      );

      this.playgroundAddressDataObservable = this.queryRef.valueChanges.pipe(
        map((result: any) => {
          return result.data.get_playground_addresses/*.get_playground_addresses*/;
        })
      );
    }

    return this.playgroundAddressDataObservable;
  }
}