import { Directive, EventEmitter, HostBinding, HostListener, Input, OnInit, Output } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

@Directive({
  selector: '[fileDragAndDrop]'
})
export class FileDragAndDropDirective implements OnInit{

  @Input()
  fileDragAndDrop: string;

  @Output()
  fileDropped: EventEmitter<any>;
  
  private fileType: string;

  private snackbarOpened: boolean = false;

  constructor(private _snackBar: MatSnackBar) {
    this.fileDropped = new EventEmitter;
  }

  ngOnInit(): void {
    this.fileType = this.fileDragAndDrop
  }

  @HostBinding('class.fileOver') fileOver: boolean;
  @HostBinding('class.invalidFileType') invalidFileType: boolean;

  @HostListener('dragover', ['$event'])
  onDragOver(event){
    event.preventDefault();
    event.stopPropagation();
    this.fileOver = true;

    for (const item of event.dataTransfer.items) {
      if(!this.checkFileType(item, this.fileType)){
        this.invalidFileType = true;
        if(!this.snackbarOpened){
          this.openSnackBar("Auswahl enthält Datei mit ungültiger Dateiendung!", null);
        }
      }
    }


    // console.log('Drag Over');
  }

  @HostListener('dragleave', ['$event'])
  onDragLeave(event){
    event.preventDefault();
    event.stopPropagation();
    this.fileOver = false;
    this.invalidFileType = false;
    this.closeSnackbar();

    // console.log('Drag Leave');
  }

  @HostListener('drop', ['$event'])
  onDrop(event){
    event.preventDefault();
    event.stopPropagation();

    this.fileOver = false;
    this.closeSnackbar();
    const files = event.dataTransfer.files;

    if(files.length > 0){
      
      if(!this.invalidFileType){
        this.fileDropped.emit(files);
      }

      // console.log(`You dropped ${files.length} files`);
    }
    this.invalidFileType = false;
  }

  checkFileType(file, fileType){
    switch (fileType) {
      case "image":
        if (file.type.match(/image\/*/) == null) {
          return false;
        }else{
          return true;
        }
      default:
        console.error("Invalid file type!", fileType);
        break;
    }

  }

  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action, {duration: null});
    this.snackbarOpened = true;
  }
  closeSnackbar(){
    this._snackBar.dismiss();
    this.snackbarOpened = false;
  }

}
