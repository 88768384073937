import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-manage-inspection-dialog',
  templateUrl: './manage-inspection-dialog.component.html',
  styleUrls: ['./manage-inspection-dialog.component.sass']
})
export class ManageInspectionDialogComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
