<mat-toolbar class="controlToolbar">
    <div class="controlHeader">Vorgabewerte</div>
    <div class="controlActions">
        <button mat-icon-button>
            <mat-icon class="material-symbols-outlined"
              >expand_more</mat-icon
            >
        </button>
        <button mat-icon-button>
            <mat-icon class="material-symbols-outlined"
              >expand_less</mat-icon
            >
        </button>
    </div>
</mat-toolbar>

<div class="mainContent">
    <app-row web>
    <app-column web="4" tablet="12">
        <div class="contentGroup">
            <div class="contentLabel">Inventar</div>
            <div class="content">
        
                <app-row>
                    <app-column class="column">
                        <app-default-value-card [cardContentSubject]="cardContentErwerbsart" [opened]="opened.Erwerbsart"></app-default-value-card>
                        <app-default-value-card [cardContentSubject]="cardContentIdenttraegerart" [opened]="opened.Identtraegerart"></app-default-value-card>
                    </app-column>
                </app-row>
        
            </div>
        </div>
    </app-column>
    <app-column web="4" tablet="12">
        <div class="contentGroup">
            <div class="contentLabel">Spielplatzkontrolle</div>
            <div class="content">
      
                <app-row>
                    <app-column class="column">
                        <app-default-value-card [cardContentSubject]="cardContentKontrollart" [opened]="opened.Kontrollart"></app-default-value-card>
                        <app-default-value-card [cardContentSubject]="cardContentKontrollergebnis" [opened]="opened.Kontrollergebnis"></app-default-value-card>
                    </app-column>
                </app-row>
            </div>
        </div>
    </app-column>
    <app-column web="4" tablet="12">
        <div class="contentGroup">
            <div class="contentLabel">Kontrollart</div>
            <div class="content">
      
                <app-row>
                    <app-column class="column">
                        <app-default-value-card [cardContentSubject]="cardContentStatus" [opened]="opened.Status" [color]="true"></app-default-value-card>

                        <app-default-value-card [cardContentSubject]="cardContentAllgemeinzustand" [opened]="opened.Allgemeinzustand" [color]="true"></app-default-value-card>

                        <app-default-value-card [cardContentSubject]="cardContentErledigungZeiteinheiten" [opened]="opened.ErledigungZeiteinheiten"></app-default-value-card>
                    </app-column>
                </app-row>
      
            </div>
        </div>
    </app-column>
    </app-row>
</div>

<!--Jetzt lässt sich das hier definitiv stark verbessern, bedingt durch den neuen Aufbau des valueObjects. Kommt dann in einem nächsten Schritt-->

<!-- <div class="box expanse-buttons is-inline-block has-background-light">
    <button class="button tooltip" (click)="expandCollapseAll(true)">[ + ]<span class="tooltiptext">alle
            ausklappen</span></button>
    <button class="button tooltip" (click)="expandCollapseAll(false)">[ - ]<span class="tooltiptext">alle
            einklappen</span></button>
</div>
<div class="scroll-container has-huge-horizontal-padding has-small-top-padding"> -->
    <!-- <perfect-scrollbar class="expanse-bar"> -->
    <!-- <mat-accordion #expandCollapse="matAccordion" [multi]="true">
        <mat-expansion-panel>
            <mat-expansion-panel-header>
                <mat-panel-title>
                    Inventar
                </mat-panel-title>
            </mat-expansion-panel-header>
            <div class="expansion-table">
                <mat-card>
                    <mat-card-content>
                        <table class="table is-hoverable is-striped is-narrow stammdaten-table"> -->
                            <!-- stammdaten-table ist in table.sass -->

                            <!-- <thead>
                                <td class="stammdaten-label"><a class="table-sorting"
                                        (click)="sortBy('erwerbsart','value')"
                                        [ngClass]="getSortingState('erwerbsart', 'value')">Erwerbsart</a></td>
                                <td class="stammdaten-short-value"><a class="table-sorting"
                                        (click)="sortBy('erwerbsart','sorting')"
                                        [ngClass]="getSortingState('erwerbsart', 'sorting')">Sortierung</a></td>
                                <td class="stammdaten-actions">
                                    <app-add-dialog-icon (click)="openDialog('erwerbsart', 'add')">
                                    </app-add-dialog-icon>
                                </td>
                            </thead>
                            <tbody>
                                <ng-container *ngFor="let entry of valueObject['INV_Erwerbsart']">
                                    <tr *ngIf="entry.field == 'INV_Erwerbsart'" (click)="selectEntry($event)">
                                        <td>{{entry.value}}</td>
                                        <td><span class="sorting">{{entry.sorting}}</span></td>
                                        <td>
                                            <div class="stammdaten-actions-content">
                                                <app-edit-dialog-icon (click)="openDialog('erwerbsart', 'edit', entry)">
                                                </app-edit-dialog-icon>
                                                <app-delete-data [deleteData]='{deleteID: entry.id}'
                                                    (returnInput)="deleteValue($event)"></app-delete-data>
                                            </div>
                                        </td>
                                    </tr>
                                </ng-container>
                            </tbody>
                        </table>
                    </mat-card-content>
                </mat-card>
                <mat-card>
                    <mat-card-content>
                        <table class="table is-hoverable is-striped is-narrow stammdaten-table">
                            <thead>
                                <td class="stammdaten-label"><a class="table-sorting"
                                        (click)="sortBy('identtraegerart','value')"
                                        [ngClass]="getSortingState('identtraegerart', 'value')">Identträgerart</a></td>
                                <td class="stammdaten-short-value"><a class="table-sorting"
                                        (click)="sortBy('identtraegerart','sorting')"
                                        [ngClass]="getSortingState('identtraegerart', 'sorting')">Sortierung</a></td>
                                <td class="stammdaten-actions">
                                    <app-add-dialog-icon (click)="openDialog('identtraegerart', 'add')">
                                    </app-add-dialog-icon>
                                </td>
                            </thead>
                            <tbody>
                                <ng-container *ngFor="let entry of valueObject['INV_Identtraegerart']">
                                    <tr *ngIf="entry.field == 'INV_Identtraegerart'" (click)="selectEntry($event)">
                                        <td>{{entry.value}}</td>
                                        <td><span class="sorting">{{entry.sorting}}</span></td>
                                        <td>
                                            <div class="stammdaten-actions-content">
                                                <app-edit-dialog-icon
                                                    (click)="openDialog('identtraegerart', 'edit', entry)">
                                                </app-edit-dialog-icon>
                                                <app-delete-data [deleteData]='{deleteID: entry.id}'
                                                    (returnInput)="deleteValue($event)"></app-delete-data>
                                            </div>
                                        </td>
                                    </tr>
                                </ng-container>
                            </tbody>
                        </table>
                    </mat-card-content>
                </mat-card>
            </div>
        </mat-expansion-panel>
        <mat-expansion-panel>
            <mat-expansion-panel-header>
                <mat-panel-title>
                    Spielplatzkontrolle
                </mat-panel-title>
            </mat-expansion-panel-header>
            <div class="expansion-table">
                <mat-card>
                    <mat-card-content>
                        <table class="table is-hoverable is-striped is-narrow stammdaten-table">
                            <thead>
                                <td class="stammdaten-label"><a class="table-sorting"
                                        (click)="sortBy('kontrollart','value')"
                                        [ngClass]="getSortingState('kontrollart', 'value')">Kontrollart</a></td>
                                <td class="stammdaten-short-value"><a class="table-sorting"
                                        (click)="sortBy('kontrollart','sorting')"
                                        [ngClass]="getSortingState('kontrollart', 'sorting')">Sortierung</a></td>
                                <td class="stammdaten-actions">
                                    <app-add-dialog-icon (click)="openDialog('kontrollart', 'add')">
                                    </app-add-dialog-icon>
                                </td>
                            </thead>
                            <tbody>
                                <ng-container *ngFor="let entry of valueObject['SPK_Kontrollart']">
                                    <tr *ngIf="entry.field == 'SPK_Kontrollart'" (click)="selectEntry($event)">
                                        <td>{{entry.value}}</td>
                                        <td><span class="sorting">{{entry.sorting}}</span></td>
                                        <td>
                                            <div class="stammdaten-actions-content">
                                                <app-edit-dialog-icon
                                                    (click)="openDialog('kontrollart', 'edit', entry)">
                                                </app-edit-dialog-icon>
                                                <app-delete-data [deleteData]='{deleteID: entry.id}'
                                                    (returnInput)="deleteValue($event)"></app-delete-data>
                                            </div>
                                        </td>
                                    </tr>
                                </ng-container>
                            </tbody>
                        </table>
                    </mat-card-content>
                </mat-card>
                <mat-card>
                    <mat-card-content>
                        <table class="table is-hoverable is-striped is-narrow stammdaten-table ">
                            <thead>
                                <td class="stammdaten-label"><a class="table-sorting"
                                        (click)="sortBy('kontrollergebnis','value')"
                                        [ngClass]="getSortingState('kontrollergebnis', 'value')">Kontrollergebnis</a>
                                </td>
                                <td class="stammdaten-short-value"><a class="table-sorting"
                                        (click)="sortBy('kontrollergebnis','sorting')"
                                        [ngClass]="getSortingState('kontrollergebnis', 'sorting')">Sortierung</a></td>
                                <td class="stammdaten-actions">
                                    <app-add-dialog-icon (click)="openDialog('kontrollergebnis', 'add')">
                                    </app-add-dialog-icon>
                                </td>
                            </thead>
                            <tbody>
                                <ng-container *ngFor="let entry of valueObject['SPK_Ergebnis']">
                                    <tr *ngIf="entry.field == 'SPK_Ergebnis'" (click)="selectEntry($event)">
                                        <td>{{entry.value}}</td>
                                        <td><span class="sorting">{{entry.sorting}}</span></td>
                                        <td>
                                            <div class="stammdaten-actions-content">
                                                <app-edit-dialog-icon
                                                    (click)="openDialog('kontrollergebnis', 'edit', entry)">
                                                </app-edit-dialog-icon>
                                                <app-delete-data [deleteData]='{deleteID: entry.id}'
                                                    (returnInput)="deleteValue($event)"></app-delete-data>
                                            </div>
                                        </td>
                                    </tr>
                                </ng-container>
                            </tbody>
                        </table>
                    </mat-card-content>
                </mat-card>
            </div>
        </mat-expansion-panel>
        <mat-expansion-panel>
            <mat-expansion-panel-header>
                <mat-panel-title>
                    Kontrollart
                </mat-panel-title>
            </mat-expansion-panel-header>
            <div class="expansion-table">
                <mat-card>
                    <mat-card-content>
                        <table class="table is-hoverable is-striped is-narrow stammdaten-table ">
                            <thead>
                                <td class="stammdaten-label"><a class="table-sorting" (click)="sortBy('status','value')"
                                        [ngClass]="getSortingState('status', 'value')">Status</a></td>
                                <td class="stammdaten-short-value"><a class="table-sorting"
                                        (click)="sortBy('status','sorting')"
                                        [ngClass]="getSortingState('status', 'sorting')">Sortierung</a></td>
                                <td class="stammdaten-short-value"><a class="table-sorting"
                                        (click)="sortBy('status','setting')"
                                        [ngClass]="getSortingState('status', 'setting')">Farbcode</a></td>
                                <td class="stammdaten-actions">
                                    <app-add-dialog-icon (click)="openDialog('status', 'add')"></app-add-dialog-icon>
                                </td>
                            </thead>
                            <tbody>
                                <ng-container *ngFor="let entry of valueObject['IVK_Status']">
                                    <tr *ngIf="entry.field == 'IVK_Status'" (click)="selectEntry($event)">
                                        <td>{{entry.value}}</td>
                                        <td><span class="sorting">{{entry.sorting}}</span></td>
                                        <td>
                                            <span class="color-code">
                                                <app-show-color-code [colorCode]=entry.setting></app-show-color-code>
                                            </span>
                                        </td>
                                        <td>
                                            <div class="stammdaten-actions-content">
                                                <app-edit-dialog-icon (click)="openDialog('status', 'edit', entry)">
                                                </app-edit-dialog-icon>
                                                <app-delete-data [deleteData]='{deleteID: entry.id}'
                                                    (returnInput)="deleteValue($event)"></app-delete-data>
                                            </div>
                                        </td>
                                    </tr>
                                </ng-container>
                            </tbody>
                        </table>
                    </mat-card-content>
                </mat-card>
                <mat-card>
                    <mat-card-content>
                        <table class="table is-hoverable is-striped is-narrow stammdaten-table ">
                            <thead>
                                <td class="stammdaten-label"><a class="table-sorting"
                                        (click)="sortBy('allgemeinzustand','value')"
                                        [ngClass]="getSortingState('allgemeinzustand', 'value')">Allgemeinzustand</a>
                                </td>
                                <td class="stammdaten-short-value"><a class="table-sorting"
                                        (click)="sortBy('allgemeinzustand','sorting')"
                                        [ngClass]="getSortingState('allgemeinzustand', 'sorting')">Sortierung</a></td>
                                <td class="stammdaten-actions">
                                    <app-add-dialog-icon (click)="openDialog('allgemeinzustand', 'add')">
                                    </app-add-dialog-icon>

                                </td>
                            </thead>
                            <tbody>
                                <ng-container *ngFor="let entry of valueObject['IVK_Allgemeinzustand']">
                                    <tr *ngIf="entry.field == 'IVK_Allgemeinzustand'" (click)="selectEntry($event)">
                                        <td>{{entry.value}}</td>
                                        <td><span class="sorting">{{entry.sorting}}</span></td>
                                        <td>
                                            <div class="stammdaten-actions-content">
                                                <app-edit-dialog-icon
                                                    (click)="openDialog('allgemeinzustand', 'edit', entry)">
                                                </app-edit-dialog-icon>
                                                <app-delete-data [deleteData]='{deleteID: entry.id}'
                                                    (returnInput)="deleteValue($event)"></app-delete-data>
                                            </div>
                                        </td>
                                    </tr>
                                </ng-container>
                            </tbody>
                        </table>
                    </mat-card-content>
                </mat-card>
                <mat-card>
                    <mat-card-content>
                        <table class="table is-hoverable is-striped is-narrow stammdaten-table ">
                            <thead>
                                <td class="stammdaten-label"><a class="table-sorting"
                                        (click)="sortBy('erledigung_zeiteinheiten','value')"
                                        [ngClass]="getSortingState('erledigung_zeiteinheiten', 'value')">Erledigung
                                        Zeiteinheiten</a></td>
                                <td class="stammdaten-short-value"><a class="table-sorting"
                                        (click)="sortBy('erledigung_zeiteinheiten','sorting')"
                                        [ngClass]="getSortingState('erledigung_zeiteinheiten', 'sorting')">Sortierung</a>
                                </td>
                                <td class="stammdaten-actions">
                                    <app-add-dialog-icon (click)="openDialog('erledigung_zeiteinheiten', 'add')">
                                    </app-add-dialog-icon>

                                </td>
                            </thead>
                            <tbody>
                                <ng-container *ngFor="let entry of valueObject['IVK_Erledigung_Zeiteinheit']">
                                    <tr *ngIf="entry.field == 'IVK_Erledigung_Zeiteinheit'"
                                        (click)="selectEntry($event)">
                                        <td>{{entry.value}}</td>
                                        <td><span class="sorting">{{entry.sorting}}</span></td>
                                        <td>
                                            <div class="stammdaten-actions-content">
                                                <app-edit-dialog-icon
                                                    (click)="openDialog('erledigung_zeiteinheiten', 'edit', entry)">
                                                </app-edit-dialog-icon>

                                                <app-delete-data [deleteData]='{deleteID: entry.id}'
                                                    (returnInput)="deleteValue($event)"></app-delete-data>
                                            </div>
                                        </td>
                                    </tr>
                                </ng-container>
                            </tbody>
                        </table>
                    </mat-card-content>
                </mat-card>
            </div>
        </mat-expansion-panel>
    </mat-accordion> -->

    <!-- </perfect-scrollbar> -->
<!-- </div> -->