import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import gql from "graphql-tag";

export const getAllCoordinates = gql`
  query getAll($offset: Int, $limit: Int) {
    Coordinate(offset: $offset, limit: $limit) {
      id
      longitude
      latitude
      altitude
      timestamp
      creation_date
      creation_user
      modify_date
      modify_user
      canDelete
    }
  }
`;

export const getSingleCoordinate = gql`
  query getSingleCoordinate($id: Int) {
    Coordinate(id: $id) {
      id
      longitude
      latitude
      altitude
      timestamp
      creation_date
      creation_user
      modify_date
      modify_user
      canDelete
    }
  }
`;

export const addCoordinate = gql`
  mutation add_Coordinate(
    $longitude: Float
    $latitude: Float
    $altitude: Float
    $timestamp: String
  ){
    add_Coordinate(
      longitude: $longitude
      latitude: $latitude
      altitude: $altitude
      timestamp: $timestamp
    ){
      result,
      error
    }
  }
`

export const deleteCoordinate = gql`
  mutation delete_Coordinate($id: Int!) {
    delete_Coordinate(id: $id){
      result,
      error
    }
  }
`

@NgModule({
  declarations: [],
  imports: [CommonModule],
})
export class GenericQueriesModule {}
