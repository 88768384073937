import { Component, forwardRef, Input } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ColorPickerDialogComponent } from 'src/app/modules/color-picker/color-picker-dialog/color-picker-dialog.component';

@Component({
  selector: 'app-color-picker',
  templateUrl: './color-picker.component.html',
  styleUrls: ['./color-picker.component.sass'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => ColorPickerComponent),
    multi: true
  }]
})
export class ColorPickerComponent implements ControlValueAccessor {

colorValue: any;

@Input('value') val: string;

  constructor(public dialog: MatDialog) {}

  onChange: any = () => {};
  onTouched: any = () => {};

  get value() {
    return this.val;
  }

  set value(val) {
    this.val = val;
    this.onChange(val);
    this.onTouched();
  }

  // Ok, seien wir ehlich: Ich habe an dieser Stelle noch nicht verstanden, wie das genau funktioniert mit dem Setzen von 'value'.
  // Es kann sein, dass das eine oder andere redundant ist, aber ich hab an dieser Gülle vier Tage rumgebastelt und jetzt funktioniert es endlich. - No questions asked.

  writeValue(colorValue) {
    this.colorValue = colorValue;
    this.value = colorValue;    
  }

  registerOnChange(fn) {
    this.onChange = fn;
  }

  registerOnTouched(fn) {
    this.onTouched = fn;
  }

  openColorPicker() {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = false;
    dialogConfig.autoFocus = false;

    dialogConfig.data = {
      id: 1,
      initColor: this.colorValue
    }

    console.log(this.colorValue);

    const dialogRef = this.dialog.open(ColorPickerDialogComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result => {
      this.writeValue(result);
    })
  }
}
