import {
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
} from "@angular/core";
import { TreeDataService } from "src/app/services/tree/tree-data.service";
import { KeyValue } from "@angular/common";
import { combineLatest, Observable, Subject, Subscription } from "rxjs";
import { cloneDeep } from "@apollo/client/utilities";
import { debounceTime } from "rxjs/operators";
import { AddEditTreeFormComponent } from "./add-edit-form/add-edit-tree-form.component";
import { TreeAddressesService } from "src/app/services/tree/tree-addresses.service";
import { AddressService } from "src/app/services/general/address.service";
import { CoordinateService } from "src/app/services/general/coordinate.service";
import { DefaultValuesService } from "src/app/services/tree/administration/default-values.service";
import { NoticesComponent } from "../meldungen/notices.component";
import { ActivatedRoute } from "@angular/router";

@Component({
  selector: "app-baumuebersicht",
  templateUrl: "./baumuebersicht.component.html",
  styleUrls: ["./baumuebersicht.component.sass"],
})
export class BaumuebersichtComponent
  implements OnInit, OnDestroy
{
  @ViewChild("mainContentTable") mainContentTable;
  @ViewChild("mainView") mainView;

  public TreeAddressesServiceType = TreeAddressesService;
  public currentSortSelection: string;
  public sortedLocationTreeRecords = {};
  private loadMoreSubscription: Subscription;
  private addressFilterSubscription: Subscription;
  public loadMoreSubject = new Subject();
  public addressFilterSubject = new Subject();
  public ctrlGrp: Object = {};
  public formComponent = AddEditTreeFormComponent;
  private combineLatestSubscription: Subscription;

  public noticesComponent = NoticesComponent

  public clientId: string;
  public checkedTrees = [];

  constructor(
    private route: ActivatedRoute,
    private treeDataService: TreeDataService,
    private defaultDataService: DefaultValuesService,
    private addressDataService: AddressService,
    private coordinateDataService: CoordinateService
  ) {}

  resizeObservable: Observable<Event>;
  resizeSubscription: Subscription;

  ngOnInit(): void {
    this.clientId = this.route.snapshot.paramMap.get("client");

    this.combineLatestSubscription = combineLatest([
      // treeDataService uses query variables to load tree data dynamically and to sort tree by address
      this.treeDataService.getAllTreeData(),
      this.defaultDataService.getAllDefaultValueData(),
    ]).subscribe((result: any) => {
      this.sortedLocationTreeRecords = {};
      let treeData = cloneDeep(result[0]);

      let defaultValuesTree = {
        // Erziehungsform: result["training_method"],
        // Pflanzanlage: result["BAU_Pflanzanlage"],
        // PlantingType: result["BAU_Pflanzungsart"],
        damageDegree: result[1]["BAU_Schadstufe"],
        // LocationCondition: result["BAU_Standortbedingungen"],
      };

      treeData.forEach((elem: any) => {
        let locationIdentifier =
          elem.address.country +
          "|" +
          elem.address.location +
          "|" +
          elem.address.district +
          "|" +
          elem.address.street;

        // sets the checkboxes for trees and locations
        if (!this.allComplete) {
          if (!this.locationComplete[locationIdentifier]) {
            this.locationComplete[locationIdentifier] = false;
          }
        } else {
          this.locationComplete[locationIdentifier] = true;
        }
        elem["completed"] = this.locationComplete[locationIdentifier];

        // groups data by location 
        if (!this.sortedLocationTreeRecords[locationIdentifier]) {
          this.sortedLocationTreeRecords[locationIdentifier] = [];
        }
        this.sortedLocationTreeRecords[locationIdentifier].push(elem);

        // updates damage degree string of tree to damage degree object of default values (eg to get damage degree color)
        let damageDegree = defaultValuesTree.damageDegree.filter((result) => {
          return result["value"] == elem.damage_degree;
        });
        if (damageDegree.length == 1) {
          elem.damage_degree = damageDegree[0];
        } else {
          elem.damage_degree = null;
        }

      });
    });

    // use subject with debounce Time to reduce loadMore calls on scroll
    this.loadMoreSubscription = this.loadMoreSubject
      .pipe(debounceTime(100))
      .subscribe(() => this.loadMore());
    // use subject with debounce Time to reduce graphQl requests
    this.addressFilterSubscription = this.addressFilterSubject
      .pipe(debounceTime(1000))
      .subscribe((filter) => this.addressFilter(filter));
  }

  ngOnDestroy(): void {
    this.loadMoreSubscription.unsubscribe();
    this.addressFilterSubscription.unsubscribe();
    this.combineLatestSubscription.unsubscribe();
  }

  // pipe to maintain order of sortedLocationTreeRecords ( used in: baumuebersicht.component.html)
  originalOrder = (
    a: KeyValue<string, string>,
    b: KeyValue<string, string>
  ): number => {
    return 0;
  };

  // returns filtered trees / all trees
  // TODO needs backend function to get number of all trees and filtered trees (not limit, offset filter)
  treeCount() {
    return "123/123";
  }

  // ====================================Tabellen Checkboxen=============================================
  public locationComplete: Object = {};
  public allComplete: boolean;

  // set all checkboxes of Location
  setTreesFromLocation(completed: boolean, locationId) {
    if (completed) {
      this.locationComplete[locationId] = true;
    } else {
      this.locationComplete[locationId] = false;
    }
    this.sortedLocationTreeRecords[locationId].forEach((treeRecord) => {
      treeRecord["completed"] = completed;
    });
  }

  // checks if some trees of a location are checked 
  someComplete(locationId): boolean {
    return (
      this.sortedLocationTreeRecords[locationId].filter((t) => t.completed)
        .length > 0 && !this.locationComplete[locationId]
    );
  }

  // updates Locations Checkbox
  updateLocationComplete(locationId) {
    this.locationComplete[locationId] = this.sortedLocationTreeRecords[
      locationId
    ].every((t) => t.completed);
  }

  // checks/unchecks all checkboxes
  setAllTrees(completed: boolean) {
    this.allComplete = completed;
    for (let key in this.sortedLocationTreeRecords) {
      if (this.locationComplete[key] == completed && !this.someComplete(key)) {
        continue;
      }
      this.locationComplete[key] = completed;
      this.sortedLocationTreeRecords[key].forEach((treeRecord) => {
        treeRecord["completed"] = completed;
      });
    }
  }

  // checks if some locations of all locations are checked 
  someLocationComplete(): boolean {
    let someComplete = false;
    for (let key in this.sortedLocationTreeRecords) {
      if (someComplete) {
        break;
      }
      for (let treeKey in this.sortedLocationTreeRecords[key]) {
        if (this.sortedLocationTreeRecords[key][treeKey].completed) {
          someComplete = true;
          break;
        }
      }
    }
    return someComplete && !this.allComplete;
  }

  // updates allLocations checkbox
  updateComplete() {
    const asArray = Object.entries(this.locationComplete);
    this.allComplete = asArray.every(([key, value]) => value);
  }
  // ==========================================================================================================

  // create array of all selected trees ( at the moment only for openMap() )
  // TODO needs to be extended, only works for single selection of trees, not for street selection or select all
  setcheckedTrees() {
    this.checkedTrees = [];
    for (let key in this.sortedLocationTreeRecords) {
      this.checkedTrees = this.checkedTrees.concat(
        this.sortedLocationTreeRecords[key].filter((element) => {
          return element.completed;
        })
      );
    }
  }

  // loads next trees if scrolled to bottom of tree view
  loadMore() {
    if (
      !this.mainContentTable.nativeElement ||
      !this.mainView.nativeElement ||
      Object.keys(this.sortedLocationTreeRecords).length < 1
    ) {
      return;
    }
    let outerElementHeight = this.mainView.nativeElement.clientHeight;
    let innerElementHeight = this.mainContentTable.nativeElement.clientHeight;
    let elementYOffset = this.mainView.nativeElement.scrollTop;
    let scrollBottom = innerElementHeight - outerElementHeight - elementYOffset;

    if (scrollBottom < 2000) {
      this.treeDataService.loadMore();
    }
  }

  // trackBy function for baumuebersicht.component.html
  trackByStreet(index: number, street) {
    return index;
  }
  // trackBy function for baumuebersicht.component.html
  trackByTree(index: number, tree) {
    return tree.id;
  }

  // function to add new trees
  addtree(value) {
    console.log("addTree()");

    delete value.coordinate_id;

    // sets empty values to null
    if (value.control_group == "") {
      value.control_group = null;
    }
    if (value.damage_degree == "") {
      value.damage_degree = null;
    }
    if (value.supplier == "") {
      value.supplier = null;
    }
    if (value.log_date == "") {
      value.log_date = null;
    }
    if (value.next_inspection == "") {
      value.next_inspection = null;
    }
    if (value.plant_date == "") {
      value.plant_date = null;
    }

    let coordinate = {
      longitude: value.coordinate.longitude,
      latitude: value.coordinate.latitude,
    };

    // adds tree coordinates
    let coordinateSubject: Observable<object> =
      this.coordinateDataService.addCoordinateData(coordinate);
    let coordinateSubscription: Subscription = coordinateSubject.subscribe(
      (result) => {
        // sets tree coordinate to coordinate id of new created coordinate
        value.coordinate = result["data"]["add_Coordinate"].result;

        // checks if address new
        if (value.address) {
          // known address

          delete value.address_obj;
          //adds tree
          this.treeDataService.addTree(value);
        } else {
          // new address

          let address = {
            country: value.address_obj.country,
            postcode: value.address_obj.postcode,
            location: value.address_obj.location,
            district: value.address_obj.district,
            street: value.address_obj.street,
            house_number: value.address_obj.house_number,
            coordinate: value.address_obj.coordinate,
            note: "",
          };
          if (address.coordinate.longitude && address.coordinate.latitude) {
            let coordinate2Subject: Observable<object> =
              this.coordinateDataService.addCoordinateData(address.coordinate);
            let coordinate2Subscription: Subscription =
              coordinate2Subject.subscribe((result) => {
                // sets address coordinate to cordinate id of new created coordinate
                address.coordinate = result["data"]["add_Coordinate"].result;

                let addressSubject: Observable<object> =
                  this.addressDataService.addAddressData(address);
                let addressSubscription: Subscription =
                  addressSubject.subscribe((result) => {
                    // sets tree address to address id of new created address
                    value.address = result["result"];

                    delete value.address_obj;
                    //adds tree
                    this.treeDataService.addTree(value);
                    addressSubscription.unsubscribe();
                  });

                coordinate2Subscription.unsubscribe();
              });
          } else {
            // if no address coordinates

            address.coordinate = null;
            let addressSubject: Observable<object> =
              this.addressDataService.addAddressData(address);
            let addressSubscription: Subscription = addressSubject.subscribe(
              (result) => {
                // sets tree address to address id of new created address
                value.address = result["data"]["add_Address"].result;

                delete value.address_obj;
                //adds tree
                this.treeDataService.addTree(value);
                addressSubscription.unsubscribe();
              }
            );
          }
        }

        coordinateSubscription.unsubscribe();
      }
    );
  }

  editTree(value) {
    console.warn("editTree() TODO", value);

    if (value.control_group == "") {
      value.control_group = null;
    }
    if (value.damage_degree == "") {
      value.damage_degree = null;
    }
    if (value.supplier == "") {
      value.supplier = null;
    }
    if (value.log_date == "") {
      value.log_date = null;
    }
    if (value.next_inspection == "") {
      value.next_inspection = null;
    }
    if (value.plant_date == "") {
      value.plant_date = null;
    }

    if (value.coordinate_id) {
      // known coordinate

      value.coordinate = value.coordinate_id;
      delete value.coordinate_id;

      if (value.address) {
        // known address
        delete value.address_obj;

        // TODO Refresh record without refresh all records
        this.treeDataService.updateTreeData(value).subscribe((result) => {
          if (result.result === 1) {
            this.mainView.nativeElement.scroll({ top: 0, behavior: "smooth" });
          }
        });
      } else {
        // new address
        // erst wichtig wenn address-picker edit funktion hat
        console.warn("new address TODO");
      }
    } else {
      // new coordinate

      console.warn("new coordinate");

      if (value.address) {
        // known address
        delete value.address_obj;

        let coordinateSubject: Observable<object> =
          this.coordinateDataService.addCoordinateData(value.coordinate);
        let coordinateSubscription: Subscription = coordinateSubject.subscribe(
          (result) => {
            value.coordinate = result["data"]["add_Coordinate"].result;

            this.treeDataService.updateTreeData(value);

            coordinateSubscription.unsubscribe();
          }
        );
      } else {
        let address = {
          country: value.address_obj.country,
          postcode: value.address_obj.postcode,
          location: value.address_obj.location,
          district: value.address_obj.district,
          street: value.address_obj.street,
          house_number: value.address_obj.house_number,
          coordinate: value.address_obj.coordinate,
          note: "",
        };

        let coordinateSubject: Observable<object> =
          this.coordinateDataService.addCoordinateData(address.coordinate);
        let coordinateSubscription: Subscription = coordinateSubject.subscribe(
          (result) => {
            address.coordinate = result["data"]["add_Coordinate"].result;

            let addressSubject: Observable<object> =
              this.addressDataService.addAddressData(address);
            let addressSubscription: Subscription = addressSubject.subscribe(
              (result) => {
                value.address = result["data"]["add_Address"].result;
                delete value.address_obj;

                let coordinate2Subject: Observable<object> =
                  this.coordinateDataService.addCoordinateData(
                    value.coordinate
                  );
                let coordinate2Subscription: Subscription =
                  coordinate2Subject.subscribe((result) => {
                    value.coordinate = result["data"]["add_Coordinate"].result;

                    this.treeDataService.updateTreeData(value);

                    coordinate2Subscription.unsubscribe();
                  });

                addressSubscription.unsubscribe();
              }
            );

            coordinateSubscription.unsubscribe();
          }
        );
      }
    }
  }

  //sets address filter
  addressFilter(selectedAddresses) {
    let filter = { address: [] };

    selectedAddresses.forEach((address) => {
      filter.address.push({
        location: address.location,
        district: address.district,
        street: address.item,
      });
    });

    this.mainView.nativeElement.scroll({ top: 0, behavior: "smooth" });
    this.treeDataService.setFilter(filter);
  }

  // deletes Tree
  deleteTree(value) {
    let queryRef = this.treeDataService.deleteTree(value.id);
    let subscription = queryRef.subscribe((result) => {
      if (result.result === 1) {
        if (typeof value.coordinate == "number") {
          // deletes coordinate of tree
          this.coordinateDataService
            .deleteCoordinateData(value.coordinate)
            .subscribe();
        }

        // removes tree from view 
        if (document.getElementById(value.id)) {
          document.getElementById(value.id).remove();
        }
      } else {
        console.error("delete failed");
      }
      subscription.unsubscribe();
    });
  }

  // opens map-view in new tab
  openMap() {
    const mapPage = window.open("/" + this.clientId + "/baumkontrolle/karte");

    mapPage.onload = () => {
      // passes tree selection to new tab
      mapPage.postMessage(
        { indicator: "treeSelection", data: this.checkedTrees },
        "*"
      );
    };
  }
}
