import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import gql from "graphql-tag";

export const getAllDefaultValues = gql`
  query Default_Values {
    Default_Values {
      id
      table
      subject {
        id,
        shortcut,
        description,
        note,
        creation_date,
        creation_user,
        modify_date,
        modify_user,
        canDelete,
      }
      field
      value
      sorting
      setting
    }
  }
`;

export const getSingleDefaultValue = gql`
  query Default_Values($id: Int!) {
    Default_Values(id: $id) {
      id
      table
      subject {
        id,
        shortcut,
        description,
        note,
        creation_date,
        creation_user,
        modify_date,
        modify_user,
        canDelete,
      }
      field
      value
      sorting
      setting
    }
  }
`;

export const addDefaultValue = gql`
  mutation add_Default_Value(
    $type: Default_Value_Types!
    $value: String!
    $sorting: Int
    $setting: String
  ) {
    add_Default_Value(
      type: $type
      value: $value
      sorting: $sorting
      setting: $setting
    ){
      result,
      error
    }
  }
`;

export const updateDefaultValue = gql`
  mutation update_Default_Value(
    $id: Int!
    $value: String
    $sorting: Int
    $setting: String
  ) {
    update_Default_Value(
      id: $id
      value: $value
      sorting: $sorting
      setting: $setting
    ){
      result,
      error
    }
  }
`;

export const deleteDefaultValue = gql`
  mutation delete_Default_Values($deleteID: Int!) {
    delete_Default_Values(id: $deleteID){
      result,
      error
    }
  }
`;

@NgModule({
  declarations: [],
  imports: [CommonModule]
})
export class DefaultValueQueriesModule {}
