import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatDialogConfig } from "@angular/material/dialog";


//export const baseUrl = "aidageo-angular2.logicway.de"
//export const baseUrl = "http://localhost:4200"

export const mapTilesUrl = "https://www.aidageo.de/hot/{z}/{x}/{y}.png";

export const MATERIAL_DATEPICKER_FORMATS = {
  parse: {
    dateInput: "LL"
  },
  display: {
    dateInput: "DD.MM.YYYY",
    monthYearLabel: "YYYY",
    dateAllyLabel: "LL",
    monthYearAllyLabel: "YYYY"
  }
};


// export const smallDialog = new MatDialogConfig();

// smallDialog.disableClose = true;
// smallDialog.autoFocus = true;
// smallDialog.height = "500px";
// smallDialog.width = "500px";


// export const hugeDialog = new MatDialogConfig();

// hugeDialog.disableClose = true;
// hugeDialog.autoFocus = true;
// hugeDialog.height = "800px";
// hugeDialog.width = "1200px";




@NgModule({
  declarations: [],
  imports: [
    CommonModule
  ]
})
export class SettingsModule { }
