import { Injectable } from "@angular/core";
import { Query, QueryRef } from "apollo-angular";
import { Observable, Subject, merge } from "rxjs";
import { first, map } from "rxjs/operators";
import { NewGraphQLService } from "../new-graphql.service";

@Injectable({
  providedIn: "root",
})
export class TreeDataService {
  private graphqlObject: string = "tree";

  private defaultQueryVariables = {
    limit: 100,
    offset: 0,
    args: {
      sort: {
        column: [
          "address.country",
          "address.location",
          "address.district",
          "address.street",
          "address.house_number",
        ],
        direction: "ASC",
      },
      filter: null,
    },
  };

  private queryVariables = {
    limit: 100,
    offset: 0,
    args: {
      sort: {
        column: [
          "address.country",
          "address.location",
          "address.district",
          "address.street",
          "address.house_number",
        ],
        direction: "ASC",
      },
      filter: null,
    },
  };
  
  private offset: number = this.queryVariables.offset;
  private queryRef: QueryRef<Query>;
  private treeDataObservable: Observable<any>;
  private manuallyUpdate = new Subject();


  constructor(private newGraphQLService: NewGraphQLService) {}

  setFilter(filter: {
    address: { location: string; district: string; street: string }[];
  }) {
    if (filter.address.length === 0) {
      this.manuallyUpdate.next([]);
      this.queryRef.resetLastResults();
    } else {
      this.queryVariables.args.filter = filter;
      // refetch tree query to update view
      this.queryRef.refetch();
    }
  }

  getAllTreeData() {
    // create only new stream if not already exist
    if(!this.treeDataObservable){
      this.queryRef = this.newGraphQLService.requestAllData(
        this.graphqlObject,
        this.queryVariables
      );
  
      this.treeDataObservable = this.queryRef.valueChanges.pipe(
        map((result: any) => {
          this.offset = result.data.Tree.length;
          return result.data.Tree;
        })
      );
    }

    return merge(this.treeDataObservable, this.manuallyUpdate);
  }

  // getSingleTreeData(treeId) {
  //   console.log("getSingleTreeData()");
  // }

  addTree(treeData: object) {
    let queryRef = this.newGraphQLService.addData(
      this.graphqlObject,
      treeData,
      this.defaultQueryVariables
    );

    let subscription = queryRef.subscribe((result) => {
      subscription.unsubscribe();
    });
  }

  updateTreeData(treeData: object) {
    let queryRef = this.newGraphQLService.updateData(
      this.graphqlObject,
      treeData,
      this.defaultQueryVariables
    );

    return queryRef.pipe(
      first(),
      map((result: any) => {
        return result.data.update_Tree;
      })
    );
  }

  deleteTree(treeId: number) {
    let queryRef = this.newGraphQLService.deleteData(
      this.graphqlObject,
      treeId,
      null,
      false
    );

    return queryRef.pipe(
      first(),
      map((result: any) => {
        return result.data.delete_Tree;
      })
    );
  }

  loadMore() {
    this.newGraphQLService.loadmoreData(this.queryRef, this.offset);
  }
}
