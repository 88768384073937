import { NgModule } from "@angular/core";
import { SelectableTableDirective } from "./selectable-table.directive";
import { EnterTheViewportNotifierDirective } from "./in-viewport-notifier.directive";
import { StopPropagationDirective } from "./stop-propagation.directive";
import { FileDragAndDropDirective } from "./file-drag-and-drop.directive";
import { AmDirectivesModule } from "src/ui/am-directives/am-directives.module";

@NgModule({
  declarations: [
    EnterTheViewportNotifierDirective,
    SelectableTableDirective,
    StopPropagationDirective,
    FileDragAndDropDirective,
  ],
  imports: [AmDirectivesModule],
  exports: [
    EnterTheViewportNotifierDirective,
    SelectableTableDirective,
    StopPropagationDirective,
    FileDragAndDropDirective,
    AmDirectivesModule,
  ],
})
export class SharedDirectivesModule {}
