import { Component, Inject, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { DialogBase } from 'src/app/baseclasses/dialog-base/dialog-base';
import {
  FormGroup,
  FormControl,
  Validators
} from "@angular/forms";
import { ColorPickerComponent } from "src/app/modules/color-picker/color-picker.component";


@Component({
  selector: 'app-status-dialog',
  templateUrl: './status-dialog.component.html',
  styleUrls: ['./status-dialog.component.sass']
})
export class StatusDialogComponent extends DialogBase implements OnInit {

  form: FormGroup;
  setting: any;
  defaultValue = "";
  defaultSorting = 0;
  defaultSetting = null;


  constructor( 
    dialogRef: MatDialogRef<StatusDialogComponent>,
    @Inject(MAT_DIALOG_DATA) data) {
      super(dialogRef, data);
      console.log(this.currentValues);
  }

  ngOnInit() {
    this.form = new FormGroup({
      value: new FormControl((this.currentValues? this.currentValues["value"] : this.defaultValue), [Validators.required]),
      sorting: new FormControl((this.currentValues? this.currentValues["sorting"] : this.defaultSorting)),
      setting: new FormControl((this.currentValues? this.currentValues["setting"] : this.defaultSetting)),
      type: new FormControl("Inventory_Inspection__Status")
    });

    if (this.currentValues) {
      this.form.addControl("id", new FormControl(this.currentValues.id));
    }
  }


}
