<!-- <span *ngIf="canBeDeleted; else noDelete">
    <button mat-icon-button (click)="deleteItem()">
        <mat-icon fontSet="material-symbols-outlined">delete</mat-icon>
    </button>
</span>
<ng-template #noDelete>
    <button mat-icon-button title="Löschen nicht möglich">
        <mat-icon fontSet="material-symbols-outlined">delete_forever</mat-icon>
    </button>
</ng-template> -->

<div *ngIf="canNotDeleted; else canDelete">
    Löschen nicht möglich
</div>
<ng-template #canDelete>
    <div>
        {{record[0]}} wirklich Löschen?
    </div>
</ng-template>