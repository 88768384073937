import { Component, OnInit, Input } from '@angular/core';
import { GraphqlService } from 'src/app/services/graphql.service';

@Component({
  selector: 'app-delete-picture',
  templateUrl: './delete-picture.component.html',
  styleUrls: ['./delete-picture.component.sass']
})
export class DeletePictureComponent implements OnInit {

  @Input() baseQuery: any;
  @Input() picturesQuery: any; 
  @Input() deleteQuery: any;
  @Input() pictureID: any;
  @Input() deleteFlag: any;

  canBeDeleted = true;

  constructor(private graphQLService: GraphqlService) { }

  ngOnInit(): void {
    if (!this.deleteFlag) {
      this.canBeDeleted = false;
    }
  }

  deletePicture() {
    console.log("delete");
    let result = confirm("Möchten Sie das Bild wirklich löschen? Dieser Schritt kann nicht wieder rückgängig gemacht werden!"); // TODO: Eines Tages einmal darüber nachdenken, ob das nicht hübscher geht. 
    if (result) {
      let variableObject = {
        id: this.pictureID
      }
      this.graphQLService.sendMutation(this.deleteQuery, variableObject, this.baseQuery, this.picturesQuery);
    }
  }

}
