<div class="controlReportsHeaderWrapper">
  <span class="controlReportsHeader">
    <span> Kontrollmeldungen </span> {{ record.id_number }}
    {{ record.type.name_german }}
  </span>
  <app-dialog-box-trigger
    [dimensions]="{
      width: '20vw',
      height: 'auto'
    }"
    [formComponent]="addEditControlMessage"
    (submitValue)="addTreeDamage($event)"
    style="margin: -10px 0"
  >
    <span class="material-symbols-outlined"> note_add </span>
  </app-dialog-box-trigger>
</div>
<div class="controlReportsTableWrapper">
  <table
    class="controlReportsTable"
    matSort
    (matSortChange)="sortData($event)"
    matSortActive="date"
    matSortDirection="asc"
    matSortDisableClear
  >
    <thead>
      <tr>
        <th class="thDate" mat-sort-header="date">Datum</th>
        <th class="thType" mat-sort-header="type">Art</th>
        <th class="thTreePart" mat-sort-header="treePart">Baumorgan</th>
        <th class="thPosition" mat-sort-header="position">Position</th>
        <th class="thDamage" mat-sort-header="damage">Schaden</th>
        <th class="thImage">Foto</th>
        <th class="thMeasures">Maßnahmen</th>
        <th class="thActions"></th>
      </tr>
    </thead>
    <tbody>
      <tr
        *ngFor="
          let treeDamage of sortedTreeDamages;
          let isOdd = odd;
          let i = index
        "
        [ngClass]="{ trOdd: isOdd }"
      >
        <td>
          {{
            treeDamage.damage_date
              ? (treeDamage.damage_date | date : "dd.MM.yyyy HH:mm" : "de")
              : "--"
          }}
        </td>
        <td>
          <container-element [ngSwitch]="treeDamage.okay">
            <ng-container *ngSwitchCase="1">
              <div class="customBadge" style="background-color: #4caf50">
                <span style="transform: translateY(1px)">i.O.</span>
              </div>
            </ng-container>
            <ng-container *ngSwitchCase="2">
              <div class="customBadge" style="background-color: #d50000">
                <span style="transform: translateY(1px)"> SM </span>
              </div>
            </ng-container>
            <ng-container *ngSwitchDefault>--</ng-container>
          </container-element>
        </td>
        <td>{{ treeDamage.tree_part ? treeDamage.tree_part : "--" }}</td>
        <td>{{ treeDamage.position ? treeDamage.position : "--" }}</td>
        <td>
          {{
            treeDamage.tree_damage_listing &&
            treeDamage.tree_damage_listing.number &&
            treeDamage.tree_damage_listing.damage_type
              ? "[" +
                treeDamage.tree_damage_listing.number +
                "] " +
                treeDamage.tree_damage_listing.damage_type
              : "--"
          }}
        </td>
        <td style="color: red">
          <div class="noImage">
            <span class="material-symbols-outlined no-thumbnail-icon">
              hide_image
            </span>
          </div>
        </td>
        <td>
          <ng-container *ngIf="treeDamage.measures; else noMeasures">
            <div class="customBadge defaultBadge measuresBadge">
              <span style="transform: translateY(1px)">{{
                treeDamage.measures.length
              }}</span>
            </div>
          </ng-container>
          <ng-template #noMeasures>
            <div class="customBadge defaultBadge emptyCustomBadge"></div>
          </ng-template>
        </td>
        <td>
          <div class="singleActions">
            <app-dialog-box-trigger
              [dimensions]="{
                width: '20vw',
                height: 'auto'
              }"
              [formComponent]="addEditControlMessage"
              (submitValue)="updateTreeDamage($event)"
              [record]="treeDamage"
            >
              <span class="material-symbols-outlined"> edit_document </span>
            </app-dialog-box-trigger>

            <app-dialog-box-trigger
              [dimensions]="{
                width: 'auto',
                height: 'auto'
              }"
              [formComponent]="deleteData"
              (submitValue)="deleteTreeDamage($event)"
              [record]="['Kontrollmeldung', treeDamage]"
              [submitTitle]="'Löschen'"
            >
              <span class="material-symbols-outlined"> delete </span>
            </app-dialog-box-trigger>
          </div>
        </td>
      </tr>
    </tbody>
  </table>
</div>
