// import { Injectable, OnDestroy } from "@angular/core";
// import { BehaviorSubject, combineLatest } from "rxjs";
// import { GraphqlService } from "../../graphql.service";
// import { DataobjectDefaultValues } from "../../../baseclasses/dataobject-playground/administration/dataobject-default-values";

// @Injectable({
//   providedIn: "root"
// })
// export class DefaultValuesService implements OnDestroy {
//   private _sortingElements = ["value", "sorting"];

//   private _sortingObjects = {
//     default: {
//       sortByValue: "value",
//       sortDirection: "asc"
//     }
//   };

//   private _filteredValues: Array<object>;
//   private _categorizedValues: object;
//   private _sortedValues: object;

//   private _defaultValueObject;
//   private _defaultValueObjectSubscription;

//   private _defaultValueSubject: BehaviorSubject<object>;

//   constructor(private graphQl: GraphqlService) {
//     this._defaultValueSubject = new BehaviorSubject<object>([]);
//     this._defaultValueObject = new DataobjectDefaultValues(this.graphQl);

//     this._defaultValueObjectSubscription = this._defaultValueObject.dataObserver.subscribe(
//       result => {
//         this._filteredValues = this._defaultValueObject.filterData(
//           result,
//           ["Inventar", "Inventarkontrolle", "Spielplatzkontrolle"],
//           "table"
//         );

//         this._categorizedValues = this.makeCategorizedArray(
//           this._filteredValues
//         );

//         this._sortedValues = this.sortAllValues();
//         this._defaultValueSubject.next(this._sortedValues);
//       }
//     );
//   }

//   ngOnDestroy() {
//     this._defaultValueObjectSubscription.unsubscribe();
//   }

//   get defaultValues() {
//     return this._defaultValueSubject;
//   }

//   set currentSorting(sortBy: Array<any>) {

//     const sortObject = sortBy.slice(0,1).join();
//     const sortValue = sortBy.slice(1,2).join();
//     const sortDirection = sortBy.slice(2,3).join();

//     if (!this._sortingObjects[sortObject]) {
//       this._sortingObjects[sortObject] = Object.create(null);
//     }

//     if (this._sortingObjects["default"].sortByValue === sortValue) {
//       if (sortDirection === "asc") {
//         this._sortingObjects[sortObject].sortDirection = "desc";
//       } else {
//         this._sortingObjects[sortObject].sortDirection = "asc";
//       }
//     } else {
//       this._sortingObjects[sortObject].sortDirection = sortDirection;
//     }

//     this._sortingObjects[sortObject].sortByValue = sortValue;

//     this._sortedValues = this.sortAllValues();
//     this._defaultValueSubject.next(this._sortedValues);
//   }

//   makeCategorizedArray(filteredObject) {
//     let categorizedValues = {};
//     for (let i = 0; i < filteredObject.length; i++) {
//       if (!categorizedValues[filteredObject[i]["field"]]) {
//         categorizedValues[filteredObject[i]["field"]] = [];
//         categorizedValues[filteredObject[i]["field"]].push(filteredObject[i]);
//       } else {
//         categorizedValues[filteredObject[i]["field"]].push(filteredObject[i]);
//       }
//     }
//     return categorizedValues;
//   }

//   createSortingOrder(value) {
//     let sortingOrder = this._sortingElements.slice(0);
//     if (value) {
//       const index = sortingOrder.indexOf(value);
//       if (index > -1) {
//         sortingOrder.splice(index, 1);
//       }
//       sortingOrder.unshift(value);
//     }
//     return sortingOrder;
//   }

//   sortAllValues() {
//     let sortedContainer = {};

//     Object.keys(this._categorizedValues).forEach(category => {

//       if (this._sortingObjects[category]) {
//         let sortingOrder = this.createSortingOrder(
//           this._sortingObjects[category].sortByValue
//         );
//         let sortingContainer = this._categorizedValues[category];
//         for (let i = sortingOrder.length-1; i >= 0; i--) {

//           console.log(this._sortingObjects[category].sortDirection);

//           sortingContainer = this._defaultValueObject.sortData(
//             sortingContainer,
//             this._sortingObjects[category].sortDirection,
//             sortingOrder[i]
//           );
//         }
//         sortedContainer[category] = sortingContainer;
//       } else {
//         let sortingOrder = this.createSortingOrder(
//           this._sortingObjects["default"].sortByValue
//         );
//         let sortingContainer = this._categorizedValues[category];
//         for (let i = sortingOrder.length-1; i >= 0; i--) {
//           sortingContainer = this._defaultValueObject.sortData(
//             sortingContainer,
//             this._sortingObjects.default.sortDirection,
//             sortingOrder[i]
//           );
//         }
//         sortedContainer[category] = sortingContainer;
//       }
//     });

//     return sortedContainer;
//   }

//   addDefaultValue(values) {
//     console.log(values);
//     this._defaultValueObject.baseObject.addData(values);
//   }

//   updateDefaultValue(values) {
//     this._defaultValueObject.baseObject.updateData(values);
//   }

//   deleteDefaultValue(value) {
//     this._defaultValueObject.baseObject.deleteData(value);
//   }
// }

import { Injectable } from "@angular/core";
import { Query, QueryRef } from "apollo-angular";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { NewGraphQLService } from "../../new-graphql.service";

@Injectable({
  providedIn: "root",
})
export class DefaultValuesService {
  private graphqlObject: string = "default_value";

  private queryRef: QueryRef<Query>;

  private defaultValueDataObservable: Observable<any>;

  constructor(private newGraphQLService: NewGraphQLService) {}

  getAllDefaultValueData() {
    // create only new stream if not already exist
    if (!this.defaultValueDataObservable) {
      this.queryRef = this.newGraphQLService.requestAllData(
        this.graphqlObject
      );

      this.defaultValueDataObservable = this.queryRef.valueChanges
        .pipe(
          map((result: any) => {
            return result.data.Default_Values.filter((defaultValue) => {
              return (
                defaultValue.table == "Inventar" ||
                defaultValue.table == "Inventarkontrolle" ||
                defaultValue.table == "Spielplatzkontrolle"
              );
            });
          })
        )
        .pipe(
          map((result) => {
            // sort default values
            let sortedDefaultValues: object = {};
            result.forEach((element) => {
              !sortedDefaultValues[element.field]
                ? (sortedDefaultValues[element.field] = [])
                : null;
              sortedDefaultValues[element.field].push(element);
            });
            return sortedDefaultValues;
          })
        );
    }

    return this.defaultValueDataObservable;
  }

  // TODO
  addDefaultValueData(defaultValueData: object) {
    console.warn("addDefaultValueData TODO");
  }

  // TODO
  updateDefaultValueData(defaultValueData: object) {
    console.warn("updateDefaultValueData TODO");
  }

  // TODO
  deleteDefaultValueData(defaultValueId: number) {
    console.warn("deleteDefaultValueData TODO");
  }
}
