import { GraphqlService } from "../../../services/graphql.service";
import { DataobjectGraphQL } from "../../dataobject-graphql/dataobject-graphql";

export class DataobjectInventoryType extends DataobjectGraphQL {
    private _inventoryTypeObject: any;
  
    constructor(
      graphQLService: GraphqlService,
      singleItem?,
      itemID?,
      onlyNewest?,
      dataSource?
    ) {
      super(dataSource);
  
      this._inventoryTypeObject = new DataobjectGraphQL(
        "inventory_type",
        graphQLService,
        singleItem,
        itemID,
        onlyNewest
      );
  
      this._inventoryTypeObject.dataObserver.subscribe(
        result => {
          this.dataStore = result;
          this.dataObserver.next(this.dataStore);
        }
      );
    }
  
    /**
     * Getter um auf die grundlegenden Methoden des erstellten Objekts zugreifen zu können (add/update/delete).
     * Ohne dieses stehen zwar die Methoden zur Verfügung, wissen aber nicht, zu welchem Query sie gehören
     */
    get baseObject() {
      return this._inventoryTypeObject;
    }
  }