export const fahrzeugverfolgungIcon = `<svg width="18" height="16" viewBox="0 0 18 16" xmlns="http://www.w3.org/2000/svg"><path d="M2 6L3.5 1.5H14.5L16 6M14.5 11C14.1022 11 13.7206 10.842 13.4393 10.5607C13.158 10.2794 13 9.89782 13 9.5C13 9.10218 13.158 8.72064 13.4393 8.43934C13.7206 8.15804 14.1022 8 14.5 8C14.8978 8 15.2794 8.15804 15.5607 8.43934C15.842 8.72064 16 9.10218 16 9.5C16 9.89782 15.842 10.2794 15.5607 10.5607C15.2794 10.842 14.8978 11 14.5 11ZM3.5 11C3.10218 11 2.72064 10.842 2.43934 10.5607C2.15804 10.2794 2 9.89782 2 9.5C2 9.10218 2.15804 8.72064 2.43934 8.43934C2.72064 8.15804 3.10218 8 3.5 8C3.89782 8 4.27936 8.15804 4.56066 8.43934C4.84196 8.72064 5 9.10218 5 9.5C5 9.89782 4.84196 10.2794 4.56066 10.5607C4.27936 10.842 3.89782 11 3.5 11ZM15.92 1C15.72 0.42 15.16 0 14.5 0H3.5C2.84 0 2.28 0.42 2.08 1L0 7V15C0 15.2652 0.105357 15.5196 0.292893 15.7071C0.48043 15.8946 0.734784 16 1 16H2C2.26522 16 2.51957 15.8946 2.70711 15.7071C2.89464 15.5196 3 15.2652 3 15V14H15V15C15 15.2652 15.1054 15.5196 15.2929 15.7071C15.4804 15.8946 15.7348 16 16 16H17C17.2652 16 17.5196 15.8946 17.7071 15.7071C17.8946 15.5196 18 15.2652 18 15V7L15.92 1Z"/></svg>`;
export const straßenkontrolleIcon = `<svg width="18" height="16" viewBox="0 0 18 16" xmlns="http://www.w3.org/2000/svg"><path d="M15.1 0.8C15 0.3 14.6 0 14.1 0H9.99999L10.2 3H7.79999L7.99999 0H3.79999C3.29999 0 2.89999 0.4 2.79999 0.8L0.0999943 14.8C-5.72205e-06 15.4 0.499994 16 1.09999 16H6.99999L7.29999 11H10.7L11 16H16.8C17.4 16 17.9 15.4 17.8 14.8L15.1 0.8ZM7.39999 9L7.59999 5H10.2L10.4 9H7.39999Z"/></svg>`;
export const baumkontrolleIcon = `<svg width="16" height="19" viewBox="0 0 16 19" xmlns="http://www.w3.org/2000/svg"><path d="M6.45833 18.875V14.4375C5.96874 14.6146 5.44791 14.7083 4.89583 14.7083C2.29166 14.7083 0.208328 12.625 0.208328 10.0208C0.208328 8.69792 0.729162 7.51042 1.62499 6.65625C1.38541 6.09375 1.24999 5.46875 1.24999 4.8125C1.24999 2.20833 3.33333 0.125 5.93749 0.125C7.56249 0.125 8.99999 0.958333 9.84374 2.20833C9.92708 2.20833 10.0104 2.20833 10.1042 2.20833C10.8565 2.20833 11.6015 2.35652 12.2966 2.64444C12.9917 2.93236 13.6233 3.35437 14.1553 3.88637C14.6873 4.41837 15.1093 5.04995 15.3972 5.74504C15.6851 6.44014 15.8333 7.18513 15.8333 7.9375C15.8333 8.68986 15.6851 9.43486 15.3972 10.13C15.1093 10.8251 14.6873 11.4566 14.1553 11.9886C13.6233 12.5206 12.9917 12.9426 12.2966 13.2306C11.6015 13.5185 10.8565 13.6667 10.1042 13.6667C9.58333 13.6667 9.06249 13.5937 8.54166 13.4479V18.875H6.45833Z"/></svg>`;
export const spielplatzkontrolleIcon = `<svg width="21" height="21" viewBox="0 0 21 21" xmlns="http://www.w3.org/2000/svg"><path d="M0.833328 11.9167H2.66666V13.75H4.49999V11.9167H6.33333V13.75H8.16666V11.9167H10V13.75H11.8333V9.16667L14.5833 6.41667V0.916666H16.4167L20.0833 2.75L16.4167 4.58333V6.41667L19.1667 9.16667V20.1667H9.08333V17.4167C9.08333 16.9304 8.89017 16.4641 8.54636 16.1203C8.20254 15.7765 7.73623 15.5833 7.25 15.5833C6.76376 15.5833 6.29745 15.7765 5.95363 16.1203C5.60982 16.4641 5.41666 16.9304 5.41666 17.4167V20.1667H0.833328V11.9167ZM15.5 9.16667C14.9958 9.16667 14.5833 9.66167 14.5833 10.2667V11.9167H16.4167V10.2667C16.4167 9.66167 16.0042 9.16667 15.5 9.16667Z"/></svg>`;

export const csvIcon = `<svg width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M4.5 17C4.1 17 3.75 16.85 3.45 16.55C3.15 16.25 3 15.9 3 15.5V1.5C3 1.1 3.15 0.75 3.45 0.45C3.75 0.15 4.1 0 4.5 0H18.5C18.9 0 19.25 0.15 19.55 0.45C19.85 0.75 20 1.1 20 1.5V15.5C20 15.9 19.85 16.25 19.55 16.55C19.25 16.85 18.9 17 18.5 17H4.5ZM4.5 15.5H18.5V1.5H4.5V15.5ZM1.5 20C1.1 20 0.75 19.85 0.45 19.55C0.15 19.25 0 18.9 0 18.5V3H1.5V18.5H17V20H1.5Z"/><path d="M10.1659 11V10H12.2111V9H10.6772C10.5295 9 10.4074 8.94097 10.3108 8.82292C10.2142 8.70486 10.1659 8.55556 10.1659 8.375V6.625C10.1659 6.44444 10.2142 6.29514 10.3108 6.17708C10.4074 6.05903 10.5295 6 10.6772 6H13.0291V7H10.984V8H12.5178C12.6655 8 12.7877 8.05903 12.8842 8.17708C12.9808 8.29514 13.0291 8.44444 13.0291 8.625V10.375C13.0291 10.5556 12.9808 10.7049 12.8842 10.8229C12.7877 10.941 12.6655 11 12.5178 11H10.1659ZM14.9038 11L13.7619 6H14.6311L15.381 9.41667L16.1308 6H17L15.8752 11H14.9038Z"/><path d="M6.54741 11C6.38641 11 6.25492 10.934 6.15295 10.8021C6.05098 10.6701 6 10.5 6 10.2917V6.70833C6 6.5 6.05098 6.32986 6.15295 6.19792C6.25492 6.06597 6.38641 6 6.54741 6H8.78535C8.94635 6 9.07784 6.06597 9.17981 6.19792C9.28178 6.32986 9.33276 6.5 9.33276 6.70833V7.66667H8.55995V7H6.77281V10H8.55995V9.33333H9.33276V10.2917C9.33276 10.5 9.28178 10.6701 9.17981 10.8021C9.07784 10.934 8.94635 11 8.78535 11H6.54741Z"/></svg>`;

export const addAudioIcon = `<svg style="padding: 3px" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" width="1080" height="1080" viewBox="0 0 1080 1080" xml:space="preserve">
<desc>Created with Fabric.js 5.2.4</desc>
<defs>
</defs>
<g transform="matrix(1 0 0 1 540 540)" id="b6df09b3-0f9a-4ed4-a490-f54a4607f300"  >
<rect style="stroke: none; stroke-width: 1; stroke-dasharray: none; stroke-linecap: butt; stroke-dashoffset: 0; stroke-linejoin: miter; stroke-miterlimit: 4; fill-rule: nonzero; opacity: 1; visibility: hidden;" vector-effect="non-scaling-stroke"  x="-540" y="-540" rx="0" ry="0" width="1080" height="1080" />
</g>
<g transform="matrix(1 0 0 1 540 540)" id="33eeb2ea-1c90-4b49-bc34-08f3c0cec8e8"  >
</g>
<g transform="matrix(29.3 0 0 29.3 538.94 538.94)" id="Auswahl"  >
<path style="stroke: rgb(0,0,0); stroke-width: 0; stroke-dasharray: none; stroke-linecap: butt; stroke-dashoffset: 0; stroke-linejoin: miter; stroke-miterlimit: 4; fill-rule: nonzero; opacity: 1;" vector-effect="non-scaling-stroke"  transform=" translate(-24, -24)" d="M 6 6 C 6 6 30 6 30 6 C 30 6 30 9 30 9 C 30 9 9 9 9 9 C 9 9 9 39 9 39 C 9 39 39 39 39 39 C 39 39 39 18 39 18 C 39 18 42 18 42 18 C 42 18 42 42 42 42 C 42 42 6 42 6 42 C 6 42 6 6 6 6 Z M 35 6 C 35 6 38 6 38 6 C 38 6 38 10 38 10 C 38 10 42 10 42 10 C 42 10 42 13 42 13 C 42 13 38.01 13.02 38.01 13.02 C 38.01 13.02 37.99 17.02 37.99 17.02 C 37.99 17.02 35.01 17.01 35.01 17.01 C 35.01 17.01 35 13.01 35 13.01 C 35 13.01 30.97 13.03 30.97 13.03 C 30.97 13.03 31 10.06 31 10.06 C 31 10.06 35 10 35 10 C 35 10 35 6 35 6 Z M 22 15.96 C 22 15.96 30 15.98 30 15.98 C 30 15.98 29.98 19 29.98 19 C 29.98 19 24 19.05 24 19.05 C 24 21.48 24 28.94 24.05 32.55 C 23.89 33.83 23.81 33.86 23.83 33.85 C 21.64 37.64 15.22 36.62 14.73 32.58 C 14.33 28.16 17.9 26.16 22 27 C 22 27 22 15.96 22 15.96 Z" stroke-linecap="round" />
</g>
</svg>`;