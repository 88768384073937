import { Component, forwardRef, Input, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { PickInventoryTypeDialogComponent } from './pick-inventory-type-dialog/pick-inventory-type-dialog.component';

@Component({
  selector: 'app-pick-inventory-type',
  templateUrl: './pick-inventory-type.component.html',
  styleUrls: ['./pick-inventory-type.component.sass'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => PickInventoryTypeComponent),
    multi: true
  }]
})
export class PickInventoryTypeComponent implements ControlValueAccessor, OnInit {

  address: any;
  displayedAddress: string;
  initialAddress: any;

  @Input('value') val: string;


  constructor(public dialog: MatDialog) {}

  ngOnInit() {
    this.initialAddress = this.val;
    if (this.val) {
      this.displayedAddress = this.val;
    }
  }

  onChange: any = () => {};
  onTouched: any = () => {};

  get value() {
    return this.val;
  }

  set value(val) {
    this.val = val;
    this.onChange(val);
    this.onTouched();
  }

  // Ok, seien wir ehlich: Ich habe an dieser Stelle noch nicht verstanden, wie das genau funktioniert mit dem Setzen von 'value'.
  // Es kann sein, dass das eine oder andere redundant ist, aber ich hab an dieser Gülle vier Tage rumgebastelt und jetzt funktioniert es endlich. - No questions asked.

  writeValue(address) {
    this.address = address;
    this.value = address;    
    if (address) {
      this.displayedAddress = `${this.address.street} ${this.address.house_number}, ${this.address.postcode} ${this.address.location}`;
    }
  }

  registerOnChange(fn) {
    this.onChange = fn;
  }

  registerOnTouched(fn) {
    this.onTouched = fn;
  }

  openAddressDialog() {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = false;
    dialogConfig.autoFocus = false;
    dialogConfig.height = "600px";
    dialogConfig.width = "800px";

    dialogConfig.data = {

    }

    const dialogRef = this.dialog.open(PickInventoryTypeDialogComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result => {
      this.writeValue(result);
    })
  }

}
