<div>
  <div class="playground-dialog-container">
    <!-- Inventarliste zum Spielplatz {{currentPlayground}} | Insgesamt {{getInventoryCount()}} Inventargegenstände -->

    <!-- <h2>Inventarliste zum Spielplatz {{currentPlayground}}</h2> -->
    <!-- TODO -->
    <h2 style="color: red">Beispieldaten (TODO)</h2>

    <table class="main-content-table">
      <thead>
        <tr>
          <th class="thPos">Nr.</th>
          <th class="thType">Inventartyp</th>
          <th class="thDate">Datum</th>
          <th class="thInfo">Information</th>
          <th class="thPhotos">Fotos</th>
          <th class="thActions">
            <!-- <app-add-spielplatz-inventar></app-add-spielplatz-inventar> -->
            <!-- <app-pdf tooltip-data="Alle Spielplätze als PDF ausgeben"></app-pdf> -->
          </th>
        </tr>
      </thead>

      <!-- <ng-container *ngFor="let inventory of playgroundInventory">
                <tbody>
                    <tr>
                        <td style="text-align: center">#{{inventory.inventory_number}}</td>
                        <td class="tdType"><span>{{inventory.description}}</span></td>
                        <td>Mi, 07.10.2020<br> um 11:25:40</td>
                        <ng-container>
                            <td class="stateItem">
                                <tr>
                                    <td>Modell</td>
                                    <td><span>{{inventory.model}}</span></td>
                                </tr>
                                <tr>
                                    <td>Material</td>
                                    <td><span>{{inventory.material}}</span></td>
                                </tr>
                                <tr>
                                    <td>Zustand</td>
                                    <td><span>{{inventory.condition}}</span></td>
                                </tr>
                                <tr>
                                    <td>Hersteller</td>
                                    <td><span>{{inventory.manufacturer}}</span></td>
                                </tr>
                                <tr>
                                    <td>Lieferant</td>
                                    <td><span>{{inventory.supplier}}</span></td>
                                </tr>
                                <tr>
                                    <td>Baujahr</td>
                                    <td><span>{{getBuildDate(inventory.date_built)}}</span></td>
                                </tr>
                                <tr>
                                    <td>Inventar-Nr.</td>
                                    <td><span>{{inventory.inventory_number}}</span></td>
                                </tr>
                            </td>
                        </ng-container>
                        <td style="text-align: center">
                            <div class="noImage">
                              <span class="material-symbols-outlined no-thumbnail-icon">
                                hide_image
                              </span>
                            </div>
                        </td>
                        <td>
                            <div class="singleActions">
                                <app-edit-spielplatz-inventar [playground]="currentPlaygroundName"
                                    [playgroundNumber]="currentPlaygroundNumber" [inventoryItem]="inventory">
                                </app-edit-spielplatz-inventar>
                                <button mat-icon-button>
                                    <mat-icon fontSet="material-symbols-outlined">delete</mat-icon>
                                </button>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </ng-container> -->

      <ng-container>
        <tbody>
          <tr>
            <td style="text-align: center">#1</td>
            <td class="tdType"><span>Spielturm</span></td>
            <td>
              Mi, 07.10.2020<br />
              um 11:25:40
            </td>
            <ng-container>
              <td class="stateItem">
                <tr>
                  <td>Modell</td>
                  <td><span>ABC1</span></td>
                </tr>
                <tr>
                  <td>Zustand</td>
                  <td class="itemBadge">
                    <span
                      class="greenBadge"
                      matBadge="akzeptabel"
                      matBadgeOverlap="false"
                    ></span>
                  </td>
                </tr>
                <tr>
                  <td>Hersteller</td>
                  <td><span>sik-Holz</span></td>
                </tr>
                <tr>
                  <td>Baujahr</td>
                  <td><span>2017</span></td>
                </tr>
                <tr>
                  <td>Inventar-Nr.</td>
                  <td><span>0003-01</span></td>
                </tr>
              </td>
            </ng-container>
            <td style="text-align: center">
              <div class="noImage">
                <span class="material-symbols-outlined no-thumbnail-icon">
                  hide_image
                </span>
              </div>
            </td>
            <td>
              <div class="singleActions">
                <!-- <app-edit-spielplatz-inventar></app-edit-spielplatz-inventar> -->
                <button mat-icon-button>
                  <mat-icon fontSet="material-symbols-outlined"
                    >delete</mat-icon
                  >
                </button>
              </div>
            </td>
          </tr>
          <tr>
            <td style="text-align: center">#2</td>
            <td class="tdType"><span>Bank</span></td>
            <td>
              Mi, 17.11.2020<br />
              um 11:30:40
            </td>
            <ng-container>
              <td class="stateItem">
                <tr>
                  <td>Modell</td>
                  <td><span>ABC1</span></td>
                </tr>
                <tr>
                  <td>Zustand</td>
                  <td class="itemBadge">
                    <span
                      class="yellowBadge"
                      matBadge="unansehnlich"
                      matBadgeOverlap="false"
                    ></span>
                  </td>
                </tr>
                <tr>
                  <td>Inventar-Nr.</td>
                  <td><span>0003-01</span></td>
                </tr>
              </td>
            </ng-container>
            <td style="text-align: center">
              <div class="noImage">
                <span class="material-symbols-outlined no-thumbnail-icon">
                  hide_image
                </span>
              </div>
            </td>
            <td>
              <div class="singleActions">
                <!-- <app-edit-spielplatz-inventar></app-edit-spielplatz-inventar> -->
                <button mat-icon-button>
                  <mat-icon fontSet="material-symbols-outlined"
                    >delete</mat-icon
                  >
                </button>
              </div>
            </td>
          </tr>
          <tr>
            <td style="text-align: center">#3</td>
            <td class="tdType"><span>Sandkasten</span></td>
            <td>
              Do, 15.10.2020<br />
              um 12:33:45
            </td>
            <ng-container>
              <td class="stateItem">
                <tr>
                  <td>Zustand</td>
                  <td class="itemBadge">
                    <span
                      class="redBadge"
                      matBadge="beschädigt"
                      matBadgeOverlap="false"
                    ></span>
                  </td>
                </tr>
                <tr>
                  <td>Lieferant</td>
                  <td><span>ABC-Firma</span></td>
                </tr>
                <tr>
                  <td>Baujahr</td>
                  <td><span>2015</span></td>
                </tr>
                <tr>
                  <td>Inventar-Nr.</td>
                  <td><span>0008-01</span></td>
                </tr>
              </td>
            </ng-container>
            <td style="text-align: center">
              <div class="noImage">
                <span class="material-symbols-outlined no-thumbnail-icon">
                  hide_image
                </span>
              </div>
            </td>
            <td>
              <div class="singleActions">
                <!-- <app-edit-spielplatz-inventar></app-edit-spielplatz-inventar> -->
                <button mat-icon-button>
                  <mat-icon fontSet="material-symbols-outlined"
                    >delete</mat-icon
                  >
                </button>
              </div>
            </td>
          </tr>
          <tr>
            <td style="text-align: center">#4</td>
            <td class="tdType"><span>Spielturm</span></td>
            <td>
              Mi, 07.10.2020<br />
              um 11:25:40
            </td>
            <ng-container>
              <td class="stateItem">
                <tr>
                  <td>Modell</td>
                  <td><span>ABC1</span></td>
                </tr>
                <tr>
                  <td>Zustand</td>
                  <td class="itemBadge">
                    <span
                      class="greenBadge"
                      matBadge="akzeptabel"
                      matBadgeOverlap="false"
                    ></span>
                  </td>
                </tr>
                <tr>
                  <td>Hersteller</td>
                  <td><span>sik-Holz</span></td>
                </tr>
                <tr>
                  <td>Baujahr</td>
                  <td><span>2017</span></td>
                </tr>
                <tr>
                  <td>Inventar-Nr.</td>
                  <td><span>0003-01</span></td>
                </tr>
              </td>
            </ng-container>
            <td style="text-align: center">
              <div class="noImage">
                <span class="material-symbols-outlined no-thumbnail-icon">
                  hide_image
                </span>
              </div>
            </td>
            <td>
              <div class="singleActions">
                <!-- <app-edit-spielplatz-inventar></app-edit-spielplatz-inventar> -->
                <button mat-icon-button>
                  <mat-icon fontSet="material-symbols-outlined"
                    >delete</mat-icon
                  >
                </button>
              </div>
            </td>
          </tr>
          <tr>
            <td style="text-align: center">#5</td>
            <td class="tdType"><span>Bank</span></td>
            <td>
              Mi, 07.10.2020<br />
              um 11:25:40
            </td>
            <ng-container>
              <td class="stateItem">
                <tr>
                  <td>Modell</td>
                  <td><span>ABC1</span></td>
                </tr>
                <tr>
                  <td>Zustand</td>
                  <td class="itemBadge">
                    <span
                      class="greenBadge"
                      matBadge="akzeptabel"
                      matBadgeOverlap="false"
                    ></span>
                  </td>
                </tr>
                <tr>
                  <td>Hersteller</td>
                  <td><span>sik-Holz</span></td>
                </tr>
                <tr>
                  <td>Baujahr</td>
                  <td><span>2017</span></td>
                </tr>
                <tr>
                  <td>Inventar-Nr.</td>
                  <td><span>0003-01</span></td>
                </tr>
              </td>
            </ng-container>
            <td style="text-align: center">
              <div class="noImage">
                <span class="material-symbols-outlined no-thumbnail-icon">
                  hide_image
                </span>
              </div>
            </td>
            <td>
              <div class="singleActions">
                <!-- <app-edit-spielplatz-inventar></app-edit-spielplatz-inventar> -->
                <button mat-icon-button>
                  <mat-icon fontSet="material-symbols-outlined"
                    >delete</mat-icon
                  >
                </button>
              </div>
            </td>
          </tr>
          <tr>
            <td style="text-align: center">#1</td>
            <td class="tdType"><span>Spielturm</span></td>
            <td>
              Mi, 07.10.2020<br />
              um 11:25:40
            </td>
            <ng-container>
              <td class="stateItem">
                <tr>
                  <td>Modell</td>
                  <td><span>ABC1</span></td>
                </tr>
                <tr>
                  <td>Zustand</td>
                  <td class="itemBadge">
                    <span
                      class="greenBadge"
                      matBadge="akzeptabel"
                      matBadgeOverlap="false"
                    ></span>
                  </td>
                </tr>
                <tr>
                  <td>Hersteller</td>
                  <td><span>sik-Holz</span></td>
                </tr>
                <tr>
                  <td>Baujahr</td>
                  <td><span>2017</span></td>
                </tr>
                <tr>
                  <td>Inventar-Nr.</td>
                  <td><span>0003-01</span></td>
                </tr>
              </td>
            </ng-container>
            <td style="text-align: center">
              <div class="noImage">
                <span class="material-symbols-outlined no-thumbnail-icon">
                  hide_image
                </span>
              </div>
            </td>
            <td>
              <div class="singleActions">
                <!-- <app-edit-spielplatz-inventar></app-edit-spielplatz-inventar> -->
                <button mat-icon-button>
                  <mat-icon fontSet="material-symbols-outlined"
                    >delete</mat-icon
                  >
                </button>
              </div>
            </td>
          </tr>
          <tr>
            <td style="text-align: center">#2</td>
            <td class="tdType"><span>Bank</span></td>
            <td>
              Mi, 17.11.2020<br />
              um 11:30:40
            </td>
            <ng-container>
              <td class="stateItem">
                <tr>
                  <td>Modell</td>
                  <td><span>ABC1</span></td>
                </tr>
                <tr>
                  <td>Zustand</td>
                  <td class="itemBadge">
                    <span
                      class="yellowBadge"
                      matBadge="unansehnlich"
                      matBadgeOverlap="false"
                    ></span>
                  </td>
                </tr>
                <tr>
                  <td>Inventar-Nr.</td>
                  <td><span>0003-01</span></td>
                </tr>
              </td>
            </ng-container>
            <td style="text-align: center">
              <div class="noImage">
                <span class="material-symbols-outlined no-thumbnail-icon">
                  hide_image
                </span>
              </div>
            </td>
            <td>
              <div class="singleActions">
                <!-- <app-edit-spielplatz-inventar></app-edit-spielplatz-inventar> -->
                <button mat-icon-button>
                  <mat-icon fontSet="material-symbols-outlined"
                    >delete</mat-icon
                  >
                </button>
              </div>
            </td>
          </tr>
          <tr>
            <td style="text-align: center">#3</td>
            <td class="tdType"><span>Sandkasten</span></td>
            <td>
              Do, 15.10.2020<br />
              um 12:33:45
            </td>
            <ng-container>
              <td class="stateItem">
                <tr>
                  <td>Zustand</td>
                  <td class="itemBadge">
                    <span
                      class="redBadge"
                      matBadge="beschädigt"
                      matBadgeOverlap="false"
                    ></span>
                  </td>
                </tr>
                <tr>
                  <td>Lieferant</td>
                  <td><span>ABC-Firma</span></td>
                </tr>
                <tr>
                  <td>Baujahr</td>
                  <td><span>2015</span></td>
                </tr>
                <tr>
                  <td>Inventar-Nr.</td>
                  <td><span>0008-01</span></td>
                </tr>
              </td>
            </ng-container>
            <td style="text-align: center">
              <div class="noImage">
                <span class="material-symbols-outlined no-thumbnail-icon">
                  hide_image
                </span>
              </div>
            </td>
            <td>
              <div class="singleActions">
                <!-- <app-edit-spielplatz-inventar></app-edit-spielplatz-inventar> -->
                <button mat-icon-button>
                  <mat-icon fontSet="material-symbols-outlined"
                    >delete</mat-icon
                  >
                </button>
              </div>
            </td>
          </tr>
          <tr>
            <td style="text-align: center">#4</td>
            <td class="tdType"><span>Spielturm</span></td>
            <td>
              Mi, 07.10.2020<br />
              um 11:25:40
            </td>
            <ng-container>
              <td class="stateItem">
                <tr>
                  <td>Modell</td>
                  <td><span>ABC1</span></td>
                </tr>
                <tr>
                  <td>Zustand</td>
                  <td class="itemBadge">
                    <span
                      class="greenBadge"
                      matBadge="akzeptabel"
                      matBadgeOverlap="false"
                    ></span>
                  </td>
                </tr>
                <tr>
                  <td>Hersteller</td>
                  <td><span>sik-Holz</span></td>
                </tr>
                <tr>
                  <td>Baujahr</td>
                  <td><span>2017</span></td>
                </tr>
                <tr>
                  <td>Inventar-Nr.</td>
                  <td><span>0003-01</span></td>
                </tr>
              </td>
            </ng-container>
            <td style="text-align: center">
              <div class="noImage">
                <span class="material-symbols-outlined no-thumbnail-icon">
                  hide_image
                </span>
              </div>
            </td>
            <td>
              <div class="singleActions">
                <!-- <app-edit-spielplatz-inventar></app-edit-spielplatz-inventar> -->
                <button mat-icon-button>
                  <mat-icon fontSet="material-symbols-outlined"
                    >delete</mat-icon
                  >
                </button>
              </div>
            </td>
          </tr>
          <tr>
            <td style="text-align: center">#5</td>
            <td class="tdType"><span>Bank</span></td>
            <td>
              Mi, 07.10.2020<br />
              um 11:25:40
            </td>
            <ng-container>
              <td class="stateItem">
                <tr>
                  <td>Modell</td>
                  <td><span>ABC1</span></td>
                </tr>
                <tr>
                  <td>Zustand</td>
                  <td class="itemBadge">
                    <span
                      class="greenBadge"
                      matBadge="akzeptabel"
                      matBadgeOverlap="false"
                    ></span>
                  </td>
                </tr>
                <tr>
                  <td>Hersteller</td>
                  <td><span>sik-Holz</span></td>
                </tr>
                <tr>
                  <td>Baujahr</td>
                  <td><span>2017</span></td>
                </tr>
                <tr>
                  <td>Inventar-Nr.</td>
                  <td><span>0003-01</span></td>
                </tr>
              </td>
            </ng-container>
            <td style="text-align: center">
              <div class="noImage">
                <span class="material-symbols-outlined no-thumbnail-icon">
                  hide_image
                </span>
              </div>
            </td>
            <td>
              <div class="singleActions">
                <!-- <app-edit-spielplatz-inventar></app-edit-spielplatz-inventar> -->
                <button mat-icon-button>
                  <mat-icon fontSet="material-symbols-outlined"
                    >delete</mat-icon
                  >
                </button>
              </div>
            </td>
          </tr>
        </tbody>
      </ng-container>
    </table>
  </div>
</div>
