import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  HostListener,
  AfterViewInit,
  NgZone,
} from "@angular/core";
import { Feature, Map, View } from "ol";
import TileLayer from "ol/layer/Tile";
import { fromLonLat } from "ol/proj";
import { Cluster, XYZ } from "ol/source";
import { mapTilesUrl } from "src/app/settings/settings.module";
import { CoordinateService } from "src/app/services/general/coordinate.service";
import CircleStyle from "ol/style/Circle";
import { Fill, Stroke, Style, Text } from "ol/style";
import VectorLayer from "ol/layer/Vector";
import VectorSource from "ol/source/Vector";
import { Point } from "ol/geom";
import { BehaviorSubject, combineLatest } from "rxjs";
import { SpecialCasesService } from "src/app/services/general/special-cases.service";
import { FormControl, FormGroup } from "@angular/forms";
import { boundingExtent } from "ol/extent";

@Component({
  selector: "app-tree-map",
  templateUrl: "./tree-map.component.html",
  styleUrls: ["./tree-map.component.sass"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TreeMapComponent implements OnInit, AfterViewInit {
  public value: string;

  public treeSelectionSubject = new BehaviorSubject(false);

  private map: Map;
  private source: XYZ;
  private view: View;
  private clusters;
  private features: Array<any> = [];

  public addressSearchForm = new FormGroup({
    street: new FormControl(""),
    house_number: new FormControl(""),
    postcode: new FormControl(""),
    city: new FormControl(""),
    country: new FormControl("Deutschland"),
  });

  // Transfer of tree selection to this tab
  @HostListener("window:message", ["$event"])
  onMessage(event) {
    if (event.data.indicator === "treeSelection") {
      this.treeSelectionSubject.next(event.data.data);
      // save in session storage in case of page refresh
      sessionStorage.setItem(
        "treeSelection",
        JSON.stringify(this.treeSelectionSubject.value)
      );
    }
  }

  constructor(
    private zone: NgZone,
    private coordinateDataService: CoordinateService,
    private specialCases: SpecialCasesService
  ) {
    // get tree selection from session storage in case of page refresh
    if (
      sessionStorage.getItem("treeSelection") &&
      !this.treeSelectionSubject.value
    ) {
      this.treeSelectionSubject.next(
        JSON.parse(sessionStorage.getItem("treeSelection"))
      );
    }

    // define view and source of map
    this.view = new View({
      center: fromLonLat([-710.59, 51.25]),
      zoom: 7,
    });
    this.source = new XYZ({
      url: mapTilesUrl,
    });
  }

  ngOnInit(): void {}

  ngAfterViewInit(): void {
    // =====Map=====
    if (!this.map) {
      // run OL in outside zone so that ChangeDetection is not triggered
      this.zone.runOutsideAngular(() => {
        this.map = new Map({
          target: "map",
          layers: [
            new TileLayer({
              source: this.source,
            }),
          ],
          view: this.view,
        });

        this.treeSelectionSubject.subscribe((treeSelection: any) => {

          this.features = [];
          if (treeSelection) {
            let multipleSubs = [];
            treeSelection.forEach((singleTreeData: object) => {
              multipleSubs.push(
                this.coordinateDataService.getSingleCordinateData(
                  singleTreeData["coordinate"]
                )
              );
            });

            combineLatest(multipleSubs).subscribe((coordinates) => {
              coordinates.forEach((coordinateData, i) => {
                // create features
                if (!Array.isArray(coordinateData)) {
                  this.features.push(
                    new Feature({
                      geometry: new Point(
                        fromLonLat([
                          coordinateData["longitude"],
                          coordinateData["latitude"],
                        ])
                      ),
                      attributes: {
                        id: treeSelection[i].id,
                        id_number: treeSelection[i].id_number,
                      },
                    })
                  );
                } else {
                  console.warn(
                    "more than one coordinate returned!",
                    coordinateData
                  );
                }
              });

              // create cluster source
              const clusterSource = new Cluster({
                distance: 35,
                source: new VectorSource({
                  features: this.features,
                }),
              });
              let styleCache = {};

              // create cluster layer
              this.clusters = new VectorLayer({
                source: clusterSource,
                style: function (feature) {
                  let size = feature.get("features").length;
                  let style = styleCache[size];
                  if (size > 1) {
                    if (!style) {
                      style = new Style({
                        image: new CircleStyle({
                          radius: 15,
                          fill: new Fill({
                            color: "#1e2399",
                          }),
                        }),
                        text: new Text({
                          text: size.toString(),
                          fill: new Fill({
                            color: "#fff",
                          }),
                          scale: 2,
                        }),
                      });
                      styleCache[size] = style;
                    }
                  } else {
                    style = [
                      new Style({
                        image: new CircleStyle({
                          radius: 10,
                          fill: new Fill({
                            color: feature.get("features")[0].values_.attributes
                            .highlight ? "#1e785d" : "#1e2399",
                          }),
                          stroke: feature.get("features")[0].values_.attributes
                            .highlight
                            ? new Stroke({
                                color: "#1e2399",
                                width: 3,
                              })
                            : undefined,
                        }),
                        text: new Text({
                          text: feature.get("features")[0].values_.attributes
                            .id_number,
                          font: "16px sans-serif",
                          backgroundFill: new Fill({
                            color: "rgba(255, 255, 255, 0.9)",
                          }),
                          offsetX: 25,
                          offsetY: -25,
                          padding: [5, 5, 5, 5],
                        }),
                      }),
                    ];
                  }
                  return style;
                },
              });

              this.map.addLayer(this.clusters);
            });
          }
        });

        this.map.on("pointermove", (event) => {
          if (this.clusters) {
            this.clusters.getFeatures(event.pixel).then((hoveredFeatures) => {
              if (hoveredFeatures.length > 0) {
                if (hoveredFeatures[0].get("features").length > 1) {
                  this.map.getTargetElement().style.cursor = "pointer";
                }
              } else {
                this.map.getTargetElement().style.cursor = "default";
              }
            });
          }
        });

        this.map.on("click", (event) => {
          if (this.clusters) {
            this.clusters.getFeatures(event.pixel).then((clickedFeatures) => {
              if (clickedFeatures.length > 0) {
                const features = clickedFeatures[0].get("features");
                if (features.length > 1) {
                  const extent = boundingExtent(
                    features.map((r) => r.getGeometry().getCoordinates())
                  );
                  this.view.fit(extent, {
                    duration: 250,
                    padding: [100, 100, 100, 500],
                  });
                }
              }
            });
          }
        });
      });
    }
    // =============
  }

  zoomToTree(treeId) {
    const feature = this.features.filter((feature) => {
      if (feature.values_.attributes.id == treeId) {
        feature.values_.attributes.highlight = true;
        return true;
      } else {
        feature.values_.attributes.highlight = false;
        return false;
      }
    })[0];

    this.view.animate({
      center: [
        feature.getGeometry().getExtent()[0],
        feature.getGeometry().getExtent()[1],
      ],
      zoom: 20,
      duration: 250,
    });
  }

  onAddressSearch(value) {
    if (this.addressSearchForm.valid) {
      this.specialCases.getCoordsFromAddress(value).subscribe((result) => {
        console.log(result);
        this.view.animate({
          zoom: value.street != "" ? 16 : 13,
          center: fromLonLat([result.longitude, result.latitude]),
          duration: 250,
        });
      });
    }
  }
}
