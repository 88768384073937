import { NgModule } from "@angular/core";

// Custom Modules
import { GraphQLModule } from "./graphql.module";
import { CustomDialogboxModule } from "./modules/dialog-boxes/custom-dialogbox.module";
import { AlphabeticalScrollbarModule } from "./modules/alphabetical-scrollbar/alphabetical-scrollbar.module";
import { LocationTreeModule } from "./modules/location-tree/location-tree.module";
import { SharedDirectivesModule } from "./directives/shared-directives.module";
import { GridModule } from "../ui/grid/grid.module";
import { DefaultValueCardModule } from "./modules/default-value/default-value.module";
import { pdfModule } from "./modules/functions/pdf/pdf.module";
import { AddresMapPickerModule } from "./modules/address-map-picker/address-map-picker.module";
import { SvgSourcesModule } from "src/ui/icons/svg-sources/svg-sources.module";
import { ContainerModule } from "src/ui/container/container.module";
import { DeleteDataModule } from "./modules/functions/delete-data/delete-data.module";
import { FilterChoiceModule } from "./modules/filter-choice/filter-choice.module";
import { ShowOnMapModule } from "./modules/functions/show-on-map/show-on-map.module";
import { ShowThumbnailModule } from "./modules/functions/show-thumbnail/showThumbnail.module";
import { ImageViewModule } from "./modules/image-view/image-view.module";
import { ColorPickerModule } from "./modules/color-picker/color-picker.module";
import { ImagePickerModule } from "./modules/image-picker/image-picker.module";
import { DeleteGroupFormModule } from "./modules/functions/delete-group-form/delete-group-form.module";

@NgModule({
  declarations: [],
  imports: [
    CustomDialogboxModule,
    SharedDirectivesModule,
    AlphabeticalScrollbarModule,
    LocationTreeModule,
    GraphQLModule,
    GridModule,
    DefaultValueCardModule,
    pdfModule,
    AddresMapPickerModule,
    SvgSourcesModule,
    ContainerModule,
    DeleteDataModule,
    FilterChoiceModule,
    ShowOnMapModule,
    ShowThumbnailModule,
    ImageViewModule,
    ColorPickerModule,
    ImagePickerModule,
    DeleteGroupFormModule,
  ],
  exports: [
    CustomDialogboxModule,
    SharedDirectivesModule,
    AlphabeticalScrollbarModule,
    LocationTreeModule,
    GraphQLModule,
    GridModule,
    DefaultValueCardModule,
    pdfModule,
    AddresMapPickerModule,
    SvgSourcesModule,
    ContainerModule,
    DeleteDataModule,
    FilterChoiceModule,
    ShowOnMapModule,
    ShowThumbnailModule,
    ImageViewModule,
    ColorPickerModule,
    ImagePickerModule,
    DeleteGroupFormModule,
  ],
})
export class CustomGlobalModules {}
