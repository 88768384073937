import { NgModule } from "@angular/core";
import { CustomGlobalModules } from "src/app/custom-global-modules.module";
import { GlobalModulesModule } from "src/app/global-modules.module";
import { TreeMapComponent } from "./tree-map.component";
import { AddEditControlMessageComponent } from "./tree-overview/control-message-management/add-edit-control-message/add-edit-control-message.component";
import { SelectDamageListingComponent } from "./tree-overview/control-message-management/add-edit-control-message/select-damage-listing/select-damage-listing.component";
import { ControlMessageManagementComponent } from "./tree-overview/control-message-management/control-message-management.component";
import { TreeOverviewComponent } from "./tree-overview/tree-overview.component";


@NgModule({
  declarations: [
    TreeMapComponent,
    TreeOverviewComponent,
    ControlMessageManagementComponent,
    AddEditControlMessageComponent,
    SelectDamageListingComponent
  ],
  imports: [GlobalModulesModule, CustomGlobalModules],
  exports: [],
})
export class TreeMapSiteModule {}
