import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DialogBoxComponent } from '../dialog-box/dialog-box.component';

/**
 * Inputs:
 * 
 * dimensions ( { width: '60vw', height: '10px' } )
 * 
 * formComponent
 * 
 * record?
 * 
 * otherSettings?
 * 
 * submitTitle? (default: "Speichern")
 * 
 * noButton?
 * 
 * ============
 * 
 * Outputs:
 * 
 * submitValue
 */
@Component({
  selector: 'app-dialog-box-trigger',
  templateUrl: './dialog-box-trigger.component.html',
  styleUrls: ['./dialog-box-trigger.component.sass']
})
export class DialogBoxTriggerComponent implements OnInit {
  @Input() dimensions: {width: string, height?: string, maxHeight?: string, maxWidth?: string};
  @Input() formComponent: Component;
  @Input() record? = "";
  @Input() otherSettings?: Object;
  @Input() mapHeader?: String;
  @Input() submitTitle?: String = "Speichern";
  @Input() noButton?: boolean = false;
  @Input() imageDiashow?: boolean = false;
  @Input() noPadding?: boolean = false;
  @Input() noFooter?: boolean = false;

  @Output() submitValue = new EventEmitter<any>();

  private dialogRef;

  constructor(public dialog: MatDialog) { }

  ngOnInit(): void {
  }

  openDialog(event){
    event.stopPropagation();
    this.dialogRef = this.dialog.open(DialogBoxComponent, {
      width: this.dimensions.width,
      height: this.dimensions.height,
      maxHeight: this.dimensions.maxHeight,
      maxWidth: this.dimensions.maxWidth,
      data:{
        submitTitle: this.submitTitle,
        record: this.record,
        formComponent: this.formComponent,
        otherSettings: this.otherSettings,
        imageDiashow: this.imageDiashow,
        noFooter: this.noFooter,
        mapHeader: this.mapHeader
      },
      autoFocus: false,
      panelClass: [this.imageDiashow ? "diashowDialog" : null, this.noPadding ? "noPadding" : null],
    });

    this.dialogRef.afterClosed().subscribe(result => {
      if(result){
        this.submitValue.emit(result);
      }
    });
  }

}
