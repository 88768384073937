import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";

@Component({
  selector: "app-add-edit-form",
  templateUrl: "./add-edit-form.component.html",
  styleUrls: ["./add-edit-form.component.sass"],
})
export class AddEditFormComponent implements OnInit {
  @Input() record;
  @Output() values = new EventEmitter();

  public treeTypeForm = new FormGroup({
    name_german: new FormControl("", [Validators.required]),
    name_botanic: new FormControl("", [Validators.required]),
  });

  constructor() {}

  ngOnInit(): void {
    this.treeTypeForm.valueChanges.subscribe((result) => {

      // checks if form valid
      if(this.treeTypeForm.valid){
        // if form is valid emit form data to dialog component
        // dialog component passes data to tree type component where they can added/edited
        this.values.emit(result);
      }else{
        // if false emitted submit button of dialog component will be disabled
        this.values.emit(false);
      }
    });

    // form will be filled when record is passed
    if (this.record) {
      if (this.record.name_german) {
        this.treeTypeForm.patchValue({
          name_german: this.record.name_german,
        });
      }
      if(this.record.name_botanic){
        this.treeTypeForm.patchValue({
          name_botanic: this.record.name_botanic,
        });
      }
    }
  }
}
