import { Component, OnInit, Input } from "@angular/core";
import * as moment from "moment";
import { PlaygroundInspectionService } from "src/app/services/playground/playground-inspection.service";

@Component({
  selector: "app-last-inspection-display",
  templateUrl: "./last-inspection-display.component.html",
  styleUrls: ["./last-inspection-display.component.sass"],
})
export class LastInspectionDisplayComponent implements OnInit {
  @Input() playgroundID: number;

  private currentInspectionData: any;

  public lastInspetions: object;

  constructor(
    private playgroundInspectionDataService: PlaygroundInspectionService
  ) {}

  ngOnInit(): void {
    this.playgroundInspectionDataService
      .getAllInspectionData()
      .subscribe((result) => {
        this.currentInspectionData = result.filter((inspectionData) => {
          return inspectionData.playground === this.playgroundID;
        });

        this.lastInspetions = this.playgroundInspectionDataService.getLastInspections(
          this.currentInspectionData
        );
      });
  }

}
