import {
  Component,
  Inject,
  OnInit,
  ElementRef,
  ViewChildren,
  AfterViewInit
} from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { GraphqlService } from "src/app/services/graphql.service";
import {
  FormGroup,
  FormBuilder,
  FormControl,
  Validators
} from "@angular/forms";

import { Map } from "ol";
import XYZ from "ol/source/XYZ";
import TileLayer from "ol/layer/Tile";
import View from "ol/View";

import { ZoomSlider, ScaleLine, ZoomToExtent, Rotate } from "ol/control";
import Feature from "ol/Feature";
import { fromLonLat, transform, toLonLat } from "ol/proj";
import VectorLayer from "ol/layer/Vector";
import Overlay from "ol/Overlay";
import { Control } from "ol/control";

// import { DataobjectSpecialCases } from "src/app/baseclasses/dataobject-generic/dataobject-special-cases";


@Component({
  selector: "app-manage-address-dialog",
  templateUrl: "./manage-address-dialog.component.html",
  styleUrls: ["./manage-address-dialog.component.sass"]
})
export class ManageAddressDialogComponent implements OnInit, AfterViewInit {
  @ViewChildren("locationPointer") locationPointer: ElementRef;
  @ViewChildren("locationPointerButton") locationPointerButton: ElementRef;



  // private _dataObject: DataobjectSpecialCases;

  private _checkAddressTimeout: any;
  checkTimeoutValue = 2000;

  location: FormGroup;

  dataTransfer: any;

  public map: Map;
  public source: XYZ;
  public layer: any; //TileLayer;
  public view: View;
  public marker: any; //Feature;
  public markerLayer: any; //VectorLayer;
  public locationPointerObject: Overlay;
  public locationPointerButtonObject: Overlay

  markerIsClicked = false;
  markerIsMoved = false;
  lastFormUpdate: any;
  formUpdateThreshold = 100;
  formIsLocked = true;
  mapIsUntouched = true;

  addressCoords = {
    latitude: null,
    longitude: null
  }

  pointerCoords = {
    latitude: null,
    longitude: null
  }


  mouseCoords = [];

  xCoord = 11.418946666667;
  yCoord = 53.601618333333;
  zoomFactor = 15;

  constructor(
    private graphQLService: GraphqlService,
    private formBuilder: FormBuilder,
    private dialogRef: MatDialogRef<ManageAddressDialogComponent>,
    @Inject(MAT_DIALOG_DATA) data
  ) {
    // this._dataObject = new DataobjectSpecialCases(this.graphQLService);

    this.dataTransfer = data.dataTransfer;

    this.location = this.formBuilder.group({
      country: new FormControl({
        value: "Deutschland",
        disabled: this.formIsLocked
      }),
      location: new FormControl({ value: "", disabled: this.formIsLocked }),
      district: new FormControl({ value: "", disabled: this.formIsLocked }),
      postcode: new FormControl({ value: "", disabled: this.formIsLocked }),
      street: new FormControl({ value: "", disabled: this.formIsLocked }),
      house_number: new FormControl({ value: "", disabled: this.formIsLocked }) //Ich breche hier ausnahmsweise meine Variablen-Formatierung, um den Wert nachher einfach parsen zu können. Siehe save()
    });

    if (this.dataTransfer) {
      this.location.controls["country"].setValue(this.dataTransfer.country);
      this.location.controls["location"].setValue(this.dataTransfer.location);
      this.location.controls["district"].setValue(this.dataTransfer.district);
      this.location.controls["postcode"].setValue(this.dataTransfer.postcode);
      this.location.controls["street"].setValue(this.dataTransfer.street);
      this.location.controls["house_number"].setValue(
        this.dataTransfer.house_number
      );
      this.displayAddressLocationPointer();
    }
  }

  ngOnInit(): void {
    this.source = new XYZ({
      url: "https://tile.osm.org/{z}/{x}/{y}.png"
    });
    //url: 'http://www.aidageo.de/hot/{z}/{x}/{y}.png'
    //url: 'http://tile.osm.org/{z}/{x}/{y}.png'

    this.layer = new TileLayer({
      source: this.source
    });


    console.log(this.xCoord)

    // let test
    // let toast; 
    // if(  toast = this.getCurrentGeoposition()) {
    //   test = toast; 
    //   console.log(toast);
    // }else {
    //   test = [this.xCoord, this.yCoord];
    // }
    // console.log(test);
    this.view = new View({
      center: fromLonLat([this.xCoord, this.yCoord]),
      zoom: this.zoomFactor
    });

    this.map = new Map({
      target: "addressMap",
      layers: [this.layer],
      view: this.view
    });

    var zoomslider = new ZoomSlider();
    this.map.addControl(zoomslider);

    var scaleline = new ScaleLine();
    this.map.addControl(scaleline);

    var zoomToExtent = new ZoomToExtent();
    this.map.addControl(zoomToExtent);

    var rotate = new Rotate();
    this.map.addControl(rotate);
  }


  // Abruf der Geolocation ließe sich umsetzen, braucht aber wahrscheinlich ein Promise oder ähnliches.
  // Im derzeitigen Zustand ist es unbrauchbar und nur eine nette Idee. 

  // getCurrentGeoposition() {
  //   if (navigator.geolocation) {
  //     navigator.geolocation.getCurrentPosition(this.geolocationSuccess, this.geolocationError); 
  //   } else {
  //     return null;
  //   }
  // }

  // geolocationSuccess(position) {
  //   let returnArray = [position.coords.longitude, position.coords.latitude];
  //   console.log(returnArray);
  //   return [position.coords.longitude, position.coords.latitude];
  // }

  // geolocationError() {
  //   return null;
  // }

  ngAfterViewInit() {
    const pointerOverlay = this.locationPointer["first"].nativeElement;

    this.locationPointerObject = new Overlay({
      element: pointerOverlay,
      positioning: "center-center"
    });

    // const buttonOverlay = this.locationPointerButton["first"].nativeElement;

    // this.locationPointerButtonObject = new Overlay({
    //   element: buttonOverlay,
    //   positioning: "center-center"
    // });


    this.map.on("pointerdrag", event => {
      this.handleMapMovement(event);
    });

    this.displayLocationPointerAt(toLonLat(this.view.getCenter()));
  }

  unlockForm() {
    this.formIsLocked = false;
    this.location.get("country").enable();
    this.location.get("country").setValidators([Validators.required]);
    this.location.get("location").enable();
    this.location.get("location").setValidators([Validators.required]);
    this.location.get("district").enable();
    this.location.get("postcode").enable();
    this.location.get("postcode").setValidators([Validators.required]);
    this.location.get("street").enable();
    this.location.get("street").setValidators([Validators.required]);
    this.location.get("house_number").enable();
  }

  handleMapMovement(event) {
    let locationCoords;
    if (this.markerIsClicked) {
      this.markerIsMoved = true;
      this.mouseCoords = toLonLat(event.coordinate);
      locationCoords = this.mouseCoords;
      this.locationPointerObject.setPosition(fromLonLat(this.mouseCoords));
      this.updateForm(locationCoords[1], locationCoords[0]);
    } else {
      if (this.markerIsMoved === false) {
        locationCoords = this.displayCoordsLocationPointer();
        this.updateForm(locationCoords[1], locationCoords[0]);
      }
    }
  }

  checkAddressTimeout() {
    let that = this;
    window.clearTimeout(this._checkAddressTimeout);
    this._checkAddressTimeout = window.setTimeout(
      () => {
        if (that.checkFormState()) {
          that.displayAddressLocationPointer();
        }
      },
      this.checkTimeoutValue,
      that
    );
  }

  resetTimeoutAndCheckForm() {
    if (this.checkFormState()) {
      this.displayAddressLocationPointer();
    }
    window.clearTimeout(this._checkAddressTimeout);
  }

  checkFormState() {
    if (this.location.value.location && this.location.value.postcode) {
      return true;
    }
    return false;
  }

  checkFormValidation() {
    if (this.formIsLocked) {
      if (this.mapIsUntouched) {
        return true;
      } else {
        return false;
      }
    } else {
      if (this.location.valid) {
        return false;
      } else {
        return true;
      }
    }
  }

  /**
   *
   * @param location Positionsdaten als LonLat Angaben
   */
  displayLocationPointerAt(location) {
    this.locationPointerObject.setPosition(fromLonLat(location));
    this.map.addOverlay(this.locationPointerObject);
  }

  displayCoordsLocationPointer() {
    let locationCoords = toLonLat(this.view.getCenter());
    this.displayLocationPointerAt(locationCoords);
    return locationCoords;
  }

  displayAddressLocationPointer() {
    let currentFormValue = Object.assign({}, this.location.value);
    Object.keys(currentFormValue).forEach(
      key =>
        (currentFormValue[key] == null || currentFormValue[key] == "") &&
        delete currentFormValue[key]
    );

    // let coordObj = this._dataObject.coordsFromAddress(currentFormValue);
    // let coordObjSubscr = coordObj.valueChanges.subscribe(result => {
    //   let addressCoords = [
    //     result.data["address_to_coordinate"][0].longitude,
    //     result.data["address_to_coordinate"][0].latitude
    //   ];
    //   this.displayLocationPointerAt(addressCoords);
    //   this.view.setCenter(fromLonLat(addressCoords));
    //   coordObjSubscr.unsubscribe();
    // });
  }

  liftLocationMarker() {
    this.markerIsClicked = true;
  }

  dropLocationMarker() {
    if (this.markerIsClicked) {
      this.markerIsClicked = false;
    }
    this.lastFormUpdate = this.lastFormUpdate - this.formUpdateThreshold;
    this.updateForm(this.mouseCoords[1], this.mouseCoords[0]);    
    this.displayLocationButton();
  }

  displayLocationButton() {
    // this.locationPointerButtonObject.setPosition(this.view.getCenter());
    // this.map.addOverlay(this.locationPointerButtonObject);
    
    //this.locationPointerButton["first"].nativeElement
    



  }





  updateForm(latitude, longitude) {
    let updateTime = new Date().getTime();
    if (updateTime - this.lastFormUpdate > this.formUpdateThreshold) {
      // let addrObj = this._dataObject.addressFromLatlon(latitude, longitude);
      // let addrObjSubscr = addrObj.valueChanges.subscribe(result => {
      //   this.location.controls["country"].setValue(
      //     result.data["coordinate_to_address"][0].country
      //   );
      //   this.location.controls["location"].setValue(
      //     result.data["coordinate_to_address"][0].location
      //   );
      //   this.location.controls["district"].setValue(
      //     result.data["coordinate_to_address"][0].district
      //   );
      //   this.location.controls["postcode"].setValue(
      //     result.data["coordinate_to_address"][0].postcode
      //   );
      //   this.location.controls["street"].setValue(
      //     result.data["coordinate_to_address"][0].street
      //   );
      //   this.location.controls["house_number"].setValue(
      //     result.data["coordinate_to_address"][0].house_number
      //   );
      //   addrObjSubscr.unsubscribe();
      //   this.mapIsUntouched = false;
      // });
    }
    this.lastFormUpdate = updateTime;
  }


  setPointerToMapCenter() {
    console.log("set");
    this.markerIsMoved = false;
    this.handleMapMovement(null);
  }


  save() {
    this.dialogRef.close(this.location.value);
  }

  close() {
    this.dialogRef.close(null);
  }
}
