import { Component, Inject, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { DialogBase } from 'src/app/baseclasses/dialog-base/dialog-base';
import {
  FormGroup,
  FormControl,
  Validators
} from "@angular/forms";

@Component({
  selector: 'app-inventorytype-category-dialog',
  templateUrl: './inventorytype-category-dialog.component.html',
  styleUrls: ['./inventorytype-category-dialog.component.sass']
})
export class InventorytypeCategoryDialogComponent extends DialogBase implements OnInit {

  form: FormGroup;

  constructor( 
    dialogRef: MatDialogRef<InventorytypeCategoryDialogComponent>,
    @Inject(MAT_DIALOG_DATA) data) {
      super(dialogRef, data);
    
  }

  ngOnInit() {

    this.form = new FormGroup({
      newName: new FormControl((this.currentValues? this.currentValues["category"] : null), [Validators.required]),
    });

    if (this.currentValues) {
      this.form.addControl("oldName", new FormControl(this.currentValues.category));
    }
  }
 

}