import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import gql from "graphql-tag";

export const getAllTreeTypeData = gql`
    query getAll {
        Tree_Type {
            id,
            name_german,
            name_botanic,
            favourite,
            creation_date,
            creation_user,
            modify_date,
            modify_user,
            canDelete
        }
    }
`

export const getSingleTreeTypeData = gql`
    query getSingleTreeTypeData (
        $id: Int
    ) {
        Tree_Type (id: $id){
            id,
            name_german,
            name_botanic,
            favourite,
            creation_date,
            creation_user,
            modify_date,
            modify_user,
            canDelete
        }
    }
`

export const updateTreeTypeData = gql`
    mutation update_Tree_Type (
        $id: Int!,
        $name_german: String,
        $name_botanic: String,
        $favourite: Int

    ) {
        update_Tree_Type(
            id: $id,
            name_german: $name_german,
            name_botanic: $name_botanic,
            favourite: $favourite
        ){
            result,
            error
        }
    }
`

export const addTreeTypeData = gql`
    mutation add_Tree_Type(
        $name_german: String,
        $name_botanic: String,
        $favourite: Int
    ) {
        add_Tree_Type(
            name_german: $name_german,
            name_botanic: $name_botanic,
            favourite: $favourite
        ){
            result,
            error
        }
    }
`

export const deleteTreeTypeData = gql`
    mutation delete_Tree_Type($id: Int!) {
        delete_Tree_Type(id: $id){
            result,
            error
        }
    }
`

@NgModule({
    declarations: [],
    imports: [CommonModule]
  })
export class TreeTypeQueriesModule {}