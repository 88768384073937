import { MatDialogRef } from "@angular/material/dialog";
import { FormGroup } from "@angular/forms";

export class DialogBase {
  // formTemplate: any;
  // //form: FormGroup;
  // head: string;
  // description: string;
  // okButtonLabel: string;
  // createForm: Boolean;
  // tabData: any;
  // currentValues: any;
  // returnFeedback: Boolean;
  // newValueID: any;
  // sizeTemplate: any;

  public form: FormGroup;
  public head: string;
  public okButtonLabel: string;
  public currentValues: any;

  constructor(public dialogRef: MatDialogRef<any>, data: any) {
    this.head = data.title || "";
    this.okButtonLabel = data.okButtonLabel || "OK";
    this.currentValues = data.currentValues || null;
    console.log(this.currentValues);

    // this.tabData = data.tabData || null;
    // this.returnFeedback = data.returnFeedback || false;
  }

  save() {
    // if (this.returnFeedback) {
    //   this.dialogRef.close({form: this.form.value, newValueID: this.newValueID});
    // } else {
    //   this.dialogRef.close(this.form.value);
    // }
    this.dialogRef.close(this.form.value);
  }

  close() {
    this.dialogRef.close(false);
  }
}
