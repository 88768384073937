<mat-expansion-panel
  hideToggle
  (afterExpand)="afterExpand()"
  (closed)="onCollapse()"
  (opened)="onExpand()"
  [expanded]="opened"
  #expansionPanel
>
  <mat-expansion-panel-header>
    <mat-panel-title>
      <div
        #expansionTitle
        style="color: #436187; padding: 7px 10.5px; line-height: 2"
      >
        {{ cardContent[0] }}
      </div>
    </mat-panel-title>
    <mat-panel-description
      style="color: #808080; margin: 2px 10.5px 0 0; line-height: 2"
    >
      <div class="actionsHeader">
        <app-dialog-box-trigger (submitValue)="addDefaultData($event)" [formComponent]="formComponent" [dimensions]="{width: '500px', height:'auto'}" [otherSettings]="{defaultDataName: cardContent[0], color: color}"><mat-icon class="material-symbols-outlined">note_add</mat-icon></app-dialog-box-trigger>
        <button mat-icon-button>
          <mat-icon #arrowStat class="arrowStat">keyboard_arrow_down</mat-icon>
        </button>
      </div>
    </mat-panel-description>
  </mat-expansion-panel-header>

  <div #tableWrapper>
    <table
      #dataTable
      class="dataTable"
      matSort
      (matSortChange)="sortData($event); checkSorting($event)"
      matSortActive="sorting"
      matSortDirection="asc"
      matSortDisableClear
    >
      <tr #tableTitle class="tableHeadRow">
        <th
          class="defaultDataHeaderTh"
          mat-sort-header="value"
          style="font-size: 14.2px"
        >
          <p style="color: #436187; width: fit-content">{{ cardContent[0] }}</p>
        </th>
        <th class="defaultDataSortTh" mat-sort-header="sorting">
          <mat-icon style="color: #808080">sort</mat-icon>
        </th>
        <th style="visibility: hidden">
          <mat-icon class="material-symbols-outlined">edit</mat-icon>
          <mat-icon class="material-symbols-outlined">delete</mat-icon>
        </th>
      </tr>
      <tr
        class="tableElement"
        *ngFor="let defaultData of sortedData"
        [attr.sorting]="defaultData.sorting"
      >
        <td
          *ngIf="
            defaultData.setting && defaultData.setting.includes('color=');
            else noSettings
          "
          style="vertical-align: middle"
        >
          <div
            class="customBadge"
            [style]="
              'background-color: ' + defaultData.setting.replace('color=', '')
            "
          >
            {{ defaultData.value }}
          </div>
        </td>
        <ng-template #noSettings>
          <td style="vertical-align: middle">{{ defaultData.value }}</td>
        </ng-template>
        <td style="vertical-align: middle;">{{ defaultData.sorting }}</td>
        <td class="actionsElement" style="vertical-align: middle;">
          <app-dialog-box-trigger (submitValue)="editDefaultData($event)" [formComponent]="formComponent" [dimensions]="{width: '500px', height:'auto'}" [record]="defaultData" [otherSettings]="{defaultDataName: cardContent[0], color: color}"><mat-icon class="material-symbols-outlined">edit_document</mat-icon></app-dialog-box-trigger>
          <button mat-icon-button>
            <mat-icon class="material-symbols-outlined">delete</mat-icon>
          </button>
        </td>
      </tr>
    </table>
  </div>
</mat-expansion-panel>

<!-- <button (click)="testFn()">test</button> -->
