import { Injectable } from "@angular/core";
import { DocumentNode } from "graphql";
import { first, map } from "rxjs/operators";
import { dataQueries } from "src/app/gql-queries/query-register.module";
import { NewGraphQLService } from "../new-graphql.service";

@Injectable({
  providedIn: "root",
})
export class SpecialCasesService {
  constructor(private newGraphQLService: NewGraphQLService) {}

  getAddressFromCoords(coordinate: { lon: number; lat: number }) {
    let dataQuery: DocumentNode = dataQueries["special_cases"].coordsToAddress;
    let queryRef = this.newGraphQLService.getSpecialData(dataQuery, coordinate);

    return queryRef.valueChanges.pipe(
      first(),
      map((result: any) => {
        return result.data.coordinate_to_address.length > 0
          ? result.data.coordinate_to_address[0]
          : null;
      })
    );

  }

  getCoordsFromAddress(addressObject: {
    country: string;
    postcode: string;
    city: string;
    street: string;
    house_number: string;
  }) {
    let dataQuery: DocumentNode = dataQueries["special_cases"].addressToCoords;
    let queryRef = this.newGraphQLService.getSpecialData(dataQuery, addressObject);

    return queryRef.valueChanges.pipe(
      first(),
      map((result: any) => {
        return result.data.address_to_coordinate.length > 0
          ? result.data.address_to_coordinate[0]
          : null;
      })
    );
  }

  // getInventoryDefects(requestData) {
  //   let resultSubject = this.graphQLService.getItem(
  //     dataQueries["special_cases"].getInventoryDefects,
  //     requestData
  //   );
  //   return resultSubject;
  // }
}
