import { AfterViewInit, Component, EventEmitter, Input, NgZone, OnInit, Output } from "@angular/core";

import Map from "ol/Map";
import View from "ol/View";
import TileLayer from "ol/layer/Tile";
import { fromLonLat, toLonLat } from "ol/proj";
import { Collection, Feature } from "ol";
import { Point } from "ol/geom";
import { Icon, Style } from "ol/style";
import VectorSource from "ol/source/Vector";
import VectorLayer from "ol/layer/Vector";
import { Subject } from "rxjs";
import { Attribution } from "ol/control";
import { Translate } from "ol/interaction";
import { XYZ } from "ol/source";
import { mapTilesUrl } from "src/app/settings/settings.module";
import { SpecialCasesService } from "src/app/services/general/special-cases.service";

@Component({
  selector: "app-map-picker",
  templateUrl: "./map-picker.component.html",
  styleUrls: ["./map-picker.component.sass"],
})
export class MapPicker implements OnInit, AfterViewInit {
  @Input() inputAddress: Subject<object> = new Subject();
  @Input() showAddressTakeOverSubject: Subject<boolean> = new Subject();
  @Input() mapHeader: String;
  @Output() AddressEvent = new EventEmitter<Object>();
  @Output() coordinateEvent = new EventEmitter<Object>();
  @Output() errorEvent = new EventEmitter<string>();

  public showAddressTakeOver: boolean = false;

  private map: Map;
  public source: XYZ;
  private svgString = '<svg xmlns="http://www.w3.org/2000/svg" height="48" width="48" viewBox="0 0 384 512"><path d="M215.7 499.2C267 435 384 279.4 384 192C384 86 298 0 192 0S0 86 0 192c0 87.4 117 243 168.3 307.2c12.3 15.3 35.1 15.3 47.4 0zM192 256c-35.3 0-64-28.7-64-64s28.7-64 64-64s64 28.7 64 64s-28.7 64-64 64z"/></svg>'

  private iconFeature = new Feature({
    geometry: new Point([0,0]),
    name: 'marker',
  })

  private iconStyle = new Style({
    image: new Icon({
      anchor:[0.5, 1],
      anchorXUnits: 'fraction',
      anchorYUnits: 'fraction',
      src: 'data:image/svg+xml;charset=utf-8,' + encodeURIComponent(this.svgString)
    })
  })

  private vectorSource;
  private vectorLayer;
  private view;

  private addressObj;
  public address;

  constructor(private zone: NgZone, private specialCases: SpecialCasesService) {

    this.iconFeature.setStyle(this.iconStyle);

    this.vectorSource = new VectorSource({
      features: [this.iconFeature],
    })

    this.vectorLayer = new VectorLayer({
      source: this.vectorSource,
    })

    this.view = new View({
      center: fromLonLat([11.418946666667, 53.601618333333]),
      zoom: 15,
    })

    this.source = new XYZ({
      url: mapTilesUrl
    });
  }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    if (!this.map) {
      this.zone.runOutsideAngular(() => {

        this.map = new Map({
          // interactions: defaults({mouseWheelZoom: false}).extend([
          //   new MouseWheelZoom({
          //     condition: platformModifierKeyOnly,
          //   })
          // ]),
          target: "map",
          layers: [
            new TileLayer({
              source: this.source,
            }),
          ],
          view: this.view,
        });

        this.map.getControls().forEach(control=>{
          if (control instanceof Attribution) {
            this.map.removeControl(control);
          }
        });

        const translate = new Translate({
          features: new Collection([this.iconFeature]),
        })

        translate.on('translateend', event => {
          this.coordinateToAddress(toLonLat(event.coordinate))
          this.takeCoordinate(event.coordinate);
       })

        this.map.addInteraction(translate)

        this.map.on('singleclick', event=>{
          this.editMarker(event.coordinate, true);

          this.takeCoordinate(event.coordinate);
        })
        
        this.inputAddress.subscribe((result: any)=>{
          if(!result){
            this.map.removeLayer(this.vectorLayer);
            this.address = undefined;
            return;
          }
          if(result.latitude && result.longitude){
            this.view.setCenter(fromLonLat([result.longitude,result.latitude]));
            this.view.setZoom(15);
      
            this.editMarker(fromLonLat([result.longitude,result.latitude]));
            this.takeCoordinate(fromLonLat([result.longitude,result.latitude]));
      
            return;
          }
          if(result["country"] && result["postcode"] && result["location"] && result["street"]){
            this.specialCases.getCoordsFromAddress(result).subscribe(result=>{
              let coordinate = result;

              if(coordinate){
                this.view.setCenter(fromLonLat([coordinate["longitude"],coordinate["latitude"]]));
                this.view.setZoom(15);
          
                this.editMarker(fromLonLat([coordinate["longitude"],coordinate["latitude"]]));
                this.takeCoordinate(fromLonLat([coordinate["longitude"],coordinate["latitude"]]));

                this.address = undefined;
              }else{
                this.errorEvent.emit("Keine Koordinaten gefunden!");
              }
            })
          }else{
            this.errorEvent.emit("Erforderliche Einträge fehlen!");
          }
        })
      });

      this.showAddressTakeOverSubject.subscribe(result=>{
        this.showAddressTakeOver = result;
      })
    }
  }

  editMarker(coordinate: number[], CoordToAddr?: boolean){
    this.map.removeLayer(this.vectorLayer);
    this.map.addLayer(this.vectorLayer);
    
    this.iconFeature.setGeometry(new Point(coordinate));

    this.coordinateToAddress(toLonLat(coordinate));
  }

  coordinateToAddress(coordinate: number[]){
    this.specialCases.getAddressFromCoords({lat: coordinate[1], lon: coordinate[0]}).subscribe(result => {
      this.addressObj = result;
      this.address = this.addressesToString(this.addressObj);
    });
  }

  addressesToString(address) {
    let string = "";
    string += `${address["street"]}`;
    if (address["house_number"]) {
      string += " ";
    }
    string += `${address["house_number"]}`;
    if (
      (address["street"] || address["house_number"]) &&
      (address["district"] ||
        address["postcode"] ||
        address["location"] ||
        address["country"])
    ) {
      string += ", ";
    }
    if (address["district"]) string += `${address["district"]}, `;
    string += `${address["postcode"]}`;
    if (address["location"]) {
      string += " ";
    }
    string += `${address["location"]}`;
    if ((address["postcode"] || address["location"]) && address["country"]) {
      string += ", ";
    }
    string += `${address["country"]}`;

    return string; 
  }

  takeAddress(){
    this.AddressEvent.emit(this.addressObj);
  }
  takeCoordinate(coordinate){
    this.coordinateEvent.emit(toLonLat(coordinate))
  }
  
}
