import { Component, OnInit } from '@angular/core';
import { ShareSearchDataService } from '../services/share-search-data/share-search-data.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.sass']
})
export class DashboardComponent implements OnInit {

  constructor(private shareSearchbarData: ShareSearchDataService) {
    this.shareSearchbarData.searchbarPlaceholder.emit("Suche")
  }

  ngOnInit() {
  }

}
