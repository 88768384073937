import { Injectable } from "@angular/core";
import { Query, QueryRef } from "apollo-angular";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { NewGraphQLService } from "../new-graphql.service";

@Injectable({
  providedIn: "root",
})
export class ControlGroupDataService {
  private graphqlObject: string = "control_group";

  private queryRef: QueryRef<Query>;
  private controlGroupObservable: Observable<any>;

  constructor(private newGraphQLService: NewGraphQLService) {}

  getAllControlGroupData() {
    // create only new stream if not already exist
    if (!this.controlGroupObservable) {
      this.queryRef = this.newGraphQLService.requestAllData(
        this.graphqlObject
      );

      this.controlGroupObservable = this.queryRef.valueChanges.pipe(
        map((result: any) => {
          return result.data.Control_Group;
        })
      );
    }

    return this.controlGroupObservable;
  }
}
