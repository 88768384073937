import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from "@angular/core";
import {
  AbstractControl,
  FormControl,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from "@angular/forms";
import { Subject, Subscription } from "rxjs";
import { DefaultValuesService } from "src/app/services/tree/administration/default-values.service";
import { AddressService } from "src/app/services/general/address.service";
import { cloneDeep } from "@apollo/client/utilities";
import { SelectTreeTypeFormComponent } from "../select-tree-type-form/select-tree-type-form.component";
import { AddressMapPickerComponent } from "src/app/modules/address-map-picker/address-map-picker.component";
import * as moment from "moment";
import { ImagePickerComponent } from "src/app/modules/image-picker/image-picker.component";
import { CoordinateService } from "src/app/services/general/coordinate.service";
import { ControlGroupDataService } from "src/app/services/tree/control-group-data.service";
import { SuppliersService } from "src/app/services/general/suppliers.service";
import { treetypeDataService } from "src/app/services/tree/tree-type-data.service";
import { SpecialCasesService } from "src/app/services/general/special-cases.service";
import { IconService } from "src/app/services/icons/icon.services";
import { addAudioIcon } from "src/ui/icons/svg-sources/svg-sources.icons";

@Component({
  selector: "app-add-edit-tree-form",
  templateUrl: "./add-edit-tree-form.component.html",
  styleUrls: ["./add-edit-tree-form.component.sass"],
})
export class AddEditTreeFormComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  @Input() private submitForm: Subject<object> = new Subject();

  @Output() values = new EventEmitter();

  // EDIT
  @Input() private record?: object;

  public submitAddress: Subject<void> = new Subject();

  private controlGroupSubscription: Subscription;
  private supplierSubscription: Subscription;
  private treeTypesSubscription: Subscription;
  private defaultValuesSubscription: Subscription;
  private damageDegreeSubscription: Subscription;

  public mapPickerAddressInput = new Subject();
  public addressValid = false;

  public treeForm: FormGroup;
  // ===Stammdaten===
  public controlGroups: Array<object>;
  public treeTypes: Array<object>;
  public suppliers: Array<object>;
  public Erziehungsform: Array<object>;
  // ===Standort===
  public Pflanzanlage: Array<object>;
  public plantingType: Array<object>;
  public locationCondition: Array<object>;
  public damageDegrees: Array<object>;

  public addressStrings = {};

  public selectTreeType = SelectTreeTypeFormComponent;
  public addressMapPicker = AddressMapPickerComponent;
  public imagePicker = ImagePickerComponent;

  constructor(
    private defaultDataService: DefaultValuesService,
    private conrolGroupDataService: ControlGroupDataService,
    private suppliersService: SuppliersService,
    private treeTypeDataService: treetypeDataService,
    private addressDataService: AddressService,
    private coordinateDataService: CoordinateService,
    private specialCases: SpecialCasesService,
    private readonly iconService: IconService
  ) {
    this.iconService.add("addAudio", addAudioIcon);
  }

  ngOnInit(): void {
    this.treeForm = new FormGroup({
      // ===Stammdaten===
      id_number: new FormControl(""),
      number: new FormControl(""),
      plat_number: new FormControl(""),
      control_group: new FormControl(0),
      next_inspection: new FormControl(""),
      supplier: new FormControl(0),
      treeType: new FormGroup({
        name_german: new FormControl(""),
        name_botanic: new FormControl(""),
      }),
      type: new FormControl("", [Validators.required]),
      training_method: new FormControl(""),

      address_obj: new FormGroup({
        address_string: new FormControl(""),
        street: new FormControl("", [Validators.required]),
        house_number: new FormControl(""),
        postcode: new FormControl("", [Validators.required]),
        location: new FormControl("", [Validators.required]),
        district: new FormControl(""),
        country: new FormControl("", [Validators.required]),
        coordinate: new FormControl(),
      }),
      address: new FormControl(0),

      coordinate_id: new FormControl(""),
      coordinate: new FormGroup({
        longitude: new FormControl("", [
          Validators.required,
          Validators.pattern(/[^0]/),
        ]),
        latitude: new FormControl("", [
          Validators.required,
          Validators.pattern(/[^0]/),
        ]),
      }),
      plantation: new FormControl(""),
      planting_Type: new FormControl(""),
      location_condition: new FormControl(""),

      // ===Grössenangabe===
      height: new FormControl(0, [this.typeNumber()]),
      trunk_height: new FormControl(0, [this.typeNumber()]),
      trunk_diameter: new FormControl(0, [this.typeNumber()]),
      trunk_amount: new FormControl(0, [this.typeNumber()]),
      crown_diameter: new FormControl(0, [this.typeNumber()]),
      crown_roots: new FormControl(0, [this.typeNumber()]),

      // ===Zustand===
      damage_degree: new FormControl(""),

      // ===Baumscheibe===
      disc_width: new FormControl(0, [this.typeNumber()]),
      disc_length: new FormControl(0, [this.typeNumber()]),
      underplanting: new FormControl(""),
      surface_stabilisation: new FormControl(""),

      // ===Bemerkungen===
      danger_potential: new FormControl(""),
      vincinity_danger: new FormControl(""),
      safety_precautions: new FormControl(""),
      notice: new FormControl(""),

      // ===Abstände===
      // +++Allgemein+++
      distance_buildings: new FormControl(0, [this.typeNumber()]),
      distance_curb: new FormControl(0, [this.typeNumber()]),
      distance_street: new FormControl(0, [this.typeNumber()]),
      distance_path: new FormControl(0, [this.typeNumber()]),
      distance_available_surface: new FormControl(0, [this.typeNumber()]),
      distance_border: new FormControl(0, [this.typeNumber()]),
      distance_grove: new FormControl(0, [this.typeNumber()]),
      distance_waters: new FormControl(0, [this.typeNumber()]),
      // +++Erdleitungen+++
      distance_earth_wire_power: new FormControl(0, [this.typeNumber()]),
      distance_earth_wire_phone: new FormControl(0, [this.typeNumber()]),
      distance_earth_wire_lighting: new FormControl(0, [this.typeNumber()]),
      distance_earth_wire_tv: new FormControl(0, [this.typeNumber()]),
      distance_earth_wire_water: new FormControl(0, [this.typeNumber()]),
      distance_earth_wire_sewage: new FormControl(0, [this.typeNumber()]),
      distance_earth_wire_gas: new FormControl(0, [this.typeNumber()]),
      distance_earth_wire_teleheating: new FormControl(0, [this.typeNumber()]),
      // +++Freileitungen+++
      distance_landline_power: new FormControl(0, [this.typeNumber()]),
      distance_landline_phone: new FormControl(0, [this.typeNumber()]),
      distance_landline_lighting: new FormControl(0, [this.typeNumber()]),

      // ===Fotos/Audio===
      // TODO

      // ===Sonstige===
      plant_date: new FormControl(""),
      age_expectation: new FormControl(0, [this.typeNumber()]),
      log_date: new FormControl(""),
    });

    this.treeForm.valueChanges.subscribe((result) => {
      // console.log(this.findInvalidControls());
      if (this.treeForm.valid) {
        delete result.treeType;
        if (typeof result.control_group == "object") {
          result.control_group = result.control_group.id;
        }
        if (typeof result.supplier == "object") {
          result.supplier = result.supplier.id;
        }

        this.values.emit(result);
      } else {
        this.values.emit(false);
      }
    });

    this.treeForm.get("next_inspection").valueChanges.subscribe((result) => {
      const date = moment(result).format("YYYY-MM-DD");
      if (date !== "Invalid date") {
        this.treeForm.patchValue(
          { next_inspection: date },
          { emitEvent: false }
        );
      } else {
        this.treeForm.patchValue({ next_inspection: "" }, { emitEvent: false });
      }

      console.log(this.treeForm.get("next_inspection").value);
    });

    this.treeForm.get("plant_date").valueChanges.subscribe((result) => {
      const date = moment(result).format("YYYY-MM-DD");
      if (date !== "Invalid date") {
        this.treeForm.patchValue(
          { plant_date: moment(result).format("YYYY-MM-DD") },
          { emitEvent: false }
        );
      } else {
        this.treeForm.patchValue({ plant_date: "" }, { emitEvent: false });
      }
    });

    this.treeForm.get("log_date").valueChanges.subscribe((result) => {
      const date = moment(result).format("YYYY-MM-DD");
      if (date !== "Invalid date") {
        this.treeForm.patchValue(
          { log_date: moment(result).format("YYYY-MM-DD") },
          { emitEvent: false }
        );
      } else {
        this.treeForm.patchValue({ log_date: "" }, { emitEvent: false });
      }
    });

    // _______EDIT_______
    if (this.record) {
      this.treeForm.patchValue({
        // ===Stammdaten===
        id_number: this.record["id_number"],
        number: this.record["number"],
        plat_number: this.record["plat_number"],
        training_method: this.record["training_method"],

        // ===Standort===
        //+++Adresse+++
        address: this.record["address"]["id"],
        address_obj: {
          address_string: this.addressDataService.addressObjToString(
            this.record["address"]
          ).addressString,
          country: this.record["address"]["country"],
          district: this.record["address"]["district"],
          house_number: this.record["address"]["house_number"],
          location: this.record["address"]["location"],
          postcode: this.record["address"]["postcode"],
          street: this.record["address"]["street"],
        },
        coordinate_id: this.record["coordinate"].id,
        coordinate: this.record["coordinate"],

        // ===Grössenangabe===
        height: this.record["height"],
        trunk_height: this.record["trunk_height"],
        trunk_diameter: this.record["trunk_diameter"],
        trunk_amount: this.record["trunk_amount"],
        crown_diameter: this.record["crown_diameter"],
        crown_roots: this.record["crown_roots"],

        // ===Zustand===
        damage_degree: this.record["damage_degree"]
          ? this.record["damage_degree"].value
          : "",

        // ===Baumscheibe===
        disc_width: this.record["disc_width"],
        disc_length: this.record["disc_length"],
        underplanting: this.record["underplanting"],
        surface_stabilisation: this.record["surface_stabilisation"],

        // ===Bemerkungen===
        danger_potential: this.record["danger_potential"],
        vincinity_danger: this.record["vincinity_danger"],
        safety_precautions: this.record["safety_precautions"],
        notice: this.record["notice"],

        // ===Abstände===
        // +++Allgemein+++
        distance_buildings: this.record["distance_buildings"],
        distance_curb: this.record["distance_curb"],
        distance_street: this.record["distance_street"],
        distance_path: this.record["distance_path"],
        distance_available_surface: this.record["distance_available_surface"],
        distance_border: this.record["distance_border"],
        distance_grove: this.record["distance_grove"],
        distance_waters: this.record["distance_waters"],

        // +++Erdleitungen+++
        distance_earth_wire_power: this.record["distance_earth_wire_power"],
        distance_earth_wire_phone: this.record["distance_earth_wire_phone"],
        distance_earth_wire_lighting:
          this.record["distance_earth_wire_lighting"],
        distance_earth_wire_tv: this.record["distance_earth_wire_tv"],
        distance_earth_wire_water: this.record["distance_earth_wire_water"],
        distance_earth_wire_sewage: this.record["distance_earth_wire_sewage"],
        distance_earth_wire_gas: this.record["distance_earth_wire_gas"],
        distance_earth_wire_teleheating:
          this.record["distance_earth_wire_teleheating"],

        // +++Freileitungen+++
        distance_landline_power: this.record["distance_landline_power"],
        distance_landline_phone: this.record["distance_landline_phone"],
        distance_landline_lighting: this.record["distance_landline_lighting"],

        // ===Sonstige===
        plant_date: this.record["plant_date"],
        age_expectation: this.record["age_expectation"],
        log_date: this.record["log_date"],
      });

      // ===Stammdaten===
      this.subscribe("control_group", () => {
        this.treeForm.patchValue({ control_group: this.record["control_group"] && this.record["control_group"].id ? this.record["control_group"].id : 0 });
      });
      this.treeForm.patchValue({
        next_inspection: this.record["next_inspection"],
      });
      this.subscribe("supplier", () => {
        this.treeForm.patchValue({ supplier: this.record["supplier"] && this.record["supplier"].id ? this.record["supplier"].id : 0 });
      });
      this.subscribe("tree_type", () => {
        this.treeForm.get("treeType").patchValue({
          name_german: this.record["type"]["name_german"],
          name_botanic: this.record["type"]["name_botanic"],
        });
        this.treeForm.patchValue({
          type: this.record["type"]["id"],
        });
      });

      this.subscribe("defaultValues", () => {
        this.treeForm.patchValue({
          plantation: this.record["plantation"],
          planting_Type: this.record["planting_Type"],
          location_condition: this.record["location_condition"],
        });
      });
    }
    // __________________

    this.submitForm.subscribe(() => {
      this.submitAddress.next();
    });
  }

  disableAnimation = true;
  ngAfterViewInit(): void {
    // timeout required to avoid 'ExpressionChangedAfterItHasBeenCheckedError'
    setTimeout(() => (this.disableAnimation = false));
  }

  ngOnDestroy(): void {
    if (this.treeTypesSubscription) {
      this.treeTypesSubscription.unsubscribe();
    }
    if (this.defaultValuesSubscription) {
      this.defaultValuesSubscription.unsubscribe();
    }
    if (this.controlGroupSubscription) {
      this.controlGroupSubscription.unsubscribe();
    }
    if (this.supplierSubscription) {
      this.supplierSubscription.unsubscribe();
    }
    if (this.damageDegreeSubscription) {
      this.damageDegreeSubscription.unsubscribe();
    }
  }

  subscribe(subscription: string, funct?: () => void) {
    switch (subscription) {
      case "control_group": {
        if (!this.controlGroupSubscription) {
          this.controlGroupSubscription = this.conrolGroupDataService
            .getAllControlGroupData()
            .subscribe((result) => {
              this.controlGroups = result.map((ctrlGrp) => {
                let ctrlGrpCopy = cloneDeep(ctrlGrp);
                let string = `[${ctrlGrp["number"]}] ${ctrlGrp["name"]}`;
                ctrlGrpCopy["Control_Group_String"] = string;
                return ctrlGrpCopy;
              });
              if (funct) {
                funct();
              }
              this.controlGroupSubscription.unsubscribe();
            });
        }
        break;
      }
      case "tree_type": {
        if (!this.treeTypesSubscription) {
          this.treeTypesSubscription = this.treeTypeDataService
            .getAllTreeData()
            .subscribe((result) => {
              this.treeTypes = result;
              if (funct) {
                funct();
              }
              this.treeTypesSubscription.unsubscribe();
            });
        }
        break;
      }
      case "supplier": {
        if (!this.supplierSubscription) {
          this.supplierSubscription = this.suppliersService
            .getAllSupplierData()
            .subscribe((result) => {
              this.suppliers = result.map((supplier) => {
                let supplierCopy = cloneDeep(supplier);
                let string = `[${supplier["number"]}] ${supplier["name1"]} ${supplier["name2"]}`;
                supplierCopy["Supplier_String"] = string;
                return supplierCopy;
              });

              if (funct) {
                funct();
              }
              this.supplierSubscription.unsubscribe();
            });
        }
        break;
      }
      case "defaultValues": {
        if (!this.defaultValuesSubscription) {
          this.defaultValuesSubscription = this.defaultDataService
            .getAllDefaultValueData()
            .subscribe((result) => {
              this.Erziehungsform = result["training_method"];
              this.Pflanzanlage = result["BAU_Pflanzanlage"];
              this.plantingType = result["BAU_Pflanzungsart"];
              this.locationCondition = result["BAU_Standortbedingungen"];
              this.damageDegrees = result["BAU_Schadstufe"];

              if (funct) {
                funct();
              }
              this.defaultValuesSubscription.unsubscribe();
            });
        }
        break;
      }
    }
  }

  typeNumber(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      return isNaN(control.value) ? { typeNumber: control.value } : null;
    };
  }

  setTreeType(value) {
    this.treeForm.get("treeType").patchValue({
      name_german: value.name_german,
      name_botanic: value.name_botanic,
    });
    this.treeForm.patchValue({
      type: value.id,
    });
  }

  public findInvalidControls() {
    const invalid = [];
    const controls = this.treeForm.controls;
    for (const name in controls) {
      if (controls[name].invalid) {
        invalid.push(name);
      }
    }
    return invalid;
  }

  setAddress(value) {
    console.log(value);
    if (
      value.address["country"] &&
      value.address["postcode"] &&
      value.address["location"] &&
      value.address["street"]
    ) {
      this.specialCases
        .getCoordsFromAddress(value.address)
        .subscribe((result) => {
          let coordinate = result;

          value.address["coordinate"] = {
            longitude: coordinate.longitude,
            latitude: coordinate.latitude,
          };

          this.treeForm.get("address").patchValue(value.address.id);
          this.treeForm.get("address_obj").patchValue(value.address);
          this.treeForm.get("coordinate_id").patchValue("");
          this.treeForm.get("coordinate").patchValue(value.coordinate);
        });
    }
  }

  onOpenItem(element, accordionEl) {
    let el: HTMLElement = accordionEl.children[element];
    let nextEl: HTMLElement = accordionEl.children[element + 1];
    let previousEl: HTMLElement = accordionEl.children[element - 1];

    if (previousEl) {
      previousEl.style.borderBottom = "1px solid #d9d9d9";
      previousEl.style.borderBottomLeftRadius = "4px";
      previousEl.style.borderBottomRightRadius = "4px";
    }
    el.style.border = "1px solid #d9d9d9";
    el.style.borderRadius = "4px";
    if (nextEl) {
      nextEl.style.borderTop = "1px solid #d9d9d9";
      nextEl.style.borderTopLeftRadius = "4px";
      nextEl.style.borderTopRightRadius = "4px";
    }
  }

  onCloseItem(element, accordionEl) {
    let el: HTMLElement = accordionEl.children[element];
    let nextEl: HTMLElement = accordionEl.children[element + 1];
    let previousEl: HTMLElement = accordionEl.children[element - 1];

    if (previousEl && !previousEl.classList.contains("mat-expanded")) {
      previousEl.style.borderBottom = "unset";
      previousEl.style.borderBottomLeftRadius = "unset";
      previousEl.style.borderBottomRightRadius = "unset";
      el.style.borderTop = "unset";
      el.style.borderTopLeftRadius = "unset";
      el.style.borderTopRightRadius = "unset";
    }
    if (nextEl && !nextEl.classList.contains("mat-expanded")) {
      nextEl.style.borderTop = "unset";
      nextEl.style.borderTopLeftRadius = "unset";
      nextEl.style.borderTopRightRadius = "unset";
      el.style.borderBottom = "unset";
      el.style.borderBottomLeftRadius = "unset";
      el.style.borderBottomRightRadius = "unset";
    }
  }

  addImage(value) {
    console.warn("addImage() TODO", value);
  }

  getDirtyValues(form: any) {
    let dirtyValues = {};

    Object.keys(form.controls).forEach((key) => {
      let currentControl = form.controls[key];

      if (currentControl.dirty) {
        if (currentControl.controls)
          dirtyValues[key] = this.getDirtyValues(currentControl);
        else dirtyValues[key] = currentControl.value;
      }
    });

    return dirtyValues;
  }
}
