<div class="header">Neue Baumart</div>
<div class="content" [formGroup]="treeTypeForm">

  <mat-form-field floatLabel="always" class="fullWidth" appearance="outline">
    <mat-label>Deutscher Name</mat-label>
    <input title="" matInput formControlName="name_german" required />
    <mat-error *ngIf="treeTypeForm.get('name_german').hasError('required')">
      Erforderlich
    </mat-error>
    <button
      *ngIf="treeTypeForm.get('name_german').value"
      matSuffix
      mat-icon-button
      aria-label="Clear"
      tabindex="-1"
      (click)="
        $event.stopPropagation();
        this.treeTypeForm.patchValue({ name_german: '' })
      "
    >
      <mat-icon>close</mat-icon>
    </button>
  </mat-form-field>

  <mat-form-field floatLabel="always" class="fullWidth" appearance="outline">
    <mat-label>Botanischer Name</mat-label>
    <input title="" matInput formControlName="name_botanic" required />
    <mat-error *ngIf="treeTypeForm.get('name_botanic').hasError('required')">
      Erforderlich
    </mat-error>
    <button
      *ngIf="treeTypeForm.get('name_botanic').value"
      matSuffix
      mat-icon-button
      aria-label="Clear"
      tabindex="-1"
      (click)="
        $event.stopPropagation();
        this.treeTypeForm.patchValue({ name_botanic: '' })
      "
    >
      <mat-icon>close</mat-icon>
    </button>
  </mat-form-field>

</div>
