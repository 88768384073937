<div (click)="toggleSortingArea()">
<svg style="width:24px;height:24px" viewBox="0 0 24 24">
    <path fill="currentColor" d="M18 21L14 17H17V7H14L18 3L22 7H19V17H22M2 19V17H12V19M2 13V11H9V13M2 7V5H6V7H2Z" />
</svg>
</div>
<div #sortingArea class="sorting-area">
    <div cdkDropList class="sorting-list" (cdkDropListDropped)="drop($event)">
        <div class="sorting-box" *ngFor="let item of sortingArray" cdkDrag>
            <div>{{item}}</div></div>
    </div>
      
    <button class="button" (click)="abort()">Abbrechen</button>
    <button class="button is-primary" (click)="apply()">Anwenden</button>
</div>