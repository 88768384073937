import { Component, OnDestroy, OnInit } from "@angular/core";
import { BehaviorSubject, Subscription } from "rxjs";
import { DefaultValuesService } from "src/app/services/tree/administration/default-values.service";

@Component({
  selector: "app-baumVorgabewerte",
  templateUrl: "./baumVorgabewerte.component.html",
  styleUrls: ["./baumVorgabewerte.component.sass"],
})
export class BaumVorgabewerteComponent implements OnInit, OnDestroy {
  private defaultValuesSubscription: Subscription;

  public defaultValuesTree: object;

  public cardContentErziehungsform = new BehaviorSubject(["", []]);
  public cardContentPflanzanlage = new BehaviorSubject(["", []]);
  public cardContentplantingType = new BehaviorSubject(["", []]);
  public cardContentDamagedegree = new BehaviorSubject(["", []]);
  public cardContentlocationCondition = new BehaviorSubject(["", []]);

  public opened = {
    Erziehungsform: true,
    Pflanzanlage: true,
    plantingType: true,
    Damagedegree: true,
    locationCondition: true,
  };

  constructor(private defaultDataService: DefaultValuesService) {
    // TODO
    this.defaultValuesSubscription = this.defaultDataService
      .getAllDefaultValueData()
      .subscribe((result) => {
        this.defaultValuesTree = {
          Erziehungsform: result["training_method"],
          Pflanzanlage: result["BAU_Pflanzanlage"],
          PlantingType: result["BAU_Pflanzungsart"],
          DamageDegree: result["BAU_Schadstufe"],
          LocationCondition: result["BAU_Standortbedingungen"],
        };

        this.cardContentErziehungsform.next([
          "Erziehungsform",
          this.defaultValuesTree["Erziehungsform"]
            ? this.defaultValuesTree["Erziehungsform"]
            : [],
        ]);
        this.cardContentPflanzanlage.next([
          "Pflanzanlage",
          this.defaultValuesTree["Pflanzanlage"]
            ? this.defaultValuesTree["Pflanzanlage"]
            : [],
        ]);
        this.cardContentplantingType.next([
          "Pflanzungsart",
          this.defaultValuesTree["PlantingType"]
            ? this.defaultValuesTree["PlantingType"]
            : [],
        ]);
        this.cardContentDamagedegree.next([
          "Schadstufe",
          this.defaultValuesTree["DamageDegree"]
            ? this.defaultValuesTree["DamageDegree"]
            : [],
        ]);
        this.cardContentlocationCondition.next([
          "Standortbedingungen",
          this.defaultValuesTree["LocationCondition"]
            ? this.defaultValuesTree["LocationCondition"]
            : [],
        ]);
      });
  }

  ngOnInit(): void {}

  ngOnDestroy(): void {
    this.defaultValuesSubscription.unsubscribe();
  }

  // TODO
  addData(value) {
    console.warn("addDefaultValueData TODO", value);
  }

  // TODO
  updateData(value) {
    console.warn("updateDefaultValueData TODO", value);
  }

  // TODO
  deleteData(id: number) {
    console.warn("deleteDefaultValueData TODO", id);
  }
}
