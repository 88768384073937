<!-- <div class="dialog-head-container">
    <h2 class="dialog-header">Kartendarstellung Spielplätze</h2>
    <button mat-icon-button class="closeButton" [mat-dialog-close]="true" (close)="close()">
        <mat-icon fontSet="material-symbols-outlined">close</mat-icon>
    </button>
</div> -->
<!-- <div id="playgroundMap" class="playground-map"></div> -->

<div class="mapWrapper">
    <div id="map" class="map"></div>
</div> 