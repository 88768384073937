import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-show-color-code',
  templateUrl: './show-color-code.component.html',
  styleUrls: ['./show-color-code.component.sass']
})
export class ShowColorCodeComponent implements OnInit {

  @Input() colorCode: string;

  constructor() { }

  ngOnInit() {
  }

}
