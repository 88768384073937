import { EventEmitter, Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ShareSearchDataService {

  searchbarValue: EventEmitter<string> = new EventEmitter();

  searchbarPlaceholder: EventEmitter<string> = new EventEmitter();

}
