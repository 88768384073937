<div class="toolbar">
  <span class="toolbarHeader"
    >Baumübersicht <span class="count">{{treeCount()}}</span></span
  >
  <div class="toolbarActions">
    <app-dialog-box-trigger
      [dimensions]="{ width: '60vw', maxHeight: '80vh' }"
      [formComponent]="formComponent"
      (submitValue)="addtree($event)"
      ><span class="material-symbols-outlined"
        >note_add</span
      ></app-dialog-box-trigger
    >

    <button mat-icon-button (click)="openMap()">
      <span class="material-symbols-outlined"> public </span>
    </button>
  </div>
</div>
<div class="mainContent">
  <div #mainView class="mainView" (scroll)="loadMoreSubject.next()">
    <table
      #mainContentTable
      class="main-content-table"
      (cdkObserveContent)="loadMoreSubject.next()"
    >
      <thead>
        <tr>
          <th class="thCheckBox tableCheckbox">
            <mat-checkbox
              [checked]="allComplete"
              (change)="setAllTrees($event.checked)"
              [indeterminate]="someLocationComplete()"
            >
            </mat-checkbox>
          </th>
          <th class="thHouseNumber">Haus-Nr.</th>
          <th class="thId">ID</th>
          <th class="thNumber">Nummer</th>
          <th class="thTreeType">Baumart</th>
          <th class="thPlatNumber">Flurst.</th>
          <th class="thControlGroup">K-Gruppe</th>
          <th class="thNextInspection">K-Termin</th>
          <th class="thNotice">Meld.</th>
          <th class="thDamageDegree">Note</th>
          <th class="thPicture">Bilder</th>
          <th class="thActions"></th>
        </tr>
      </thead>
      <ng-container
        *ngFor="
          let location of sortedLocationTreeRecords | keyvalue : originalOrder;
          trackBy: trackByStreet
        "
      >
        <tbody
          [id]="
            'addressId' +
            location.value[0].address.country +
            '|' +
            location.value[0].address.location +
            '|' +
            location.value[0].address.district +
            '|' +
            location.value[0].address.street
          "
        >
          <td class="locationAddress" colspan="12">
            <div>
              <div class="tableCheckbox">
                <mat-checkbox
                  [checked]="
                    locationComplete[
                      location.value[0].address.country +
                        '|' +
                        location.value[0].address.location +
                        '|' +
                        location.value[0].address.district +
                        '|' +
                        location.value[0].address.street
                    ]
                  "
                  (change)="
                    setTreesFromLocation(
                      $event.checked,
                      location.value[0].address.country +
                        '|' +
                        location.value[0].address.location +
                        '|' +
                        location.value[0].address.district +
                        '|' +
                        location.value[0].address.street
                    );
                    updateComplete()
                  "
                  [indeterminate]="
                    someComplete(
                      location.value[0].address.country +
                        '|' +
                        location.value[0].address.location +
                        '|' +
                        location.value[0].address.district +
                        '|' +
                        location.value[0].address.street
                    )
                  "
                >
                </mat-checkbox>
              </div>
              <div>
                <span class="countryData">{{
                  location.value[0].address.country
                    ? location.value[0].address.country
                    : "Ohne Land"
                }}</span>
                <span class="locationData">{{
                  location.value[0].address.location
                    ? location.value[0].address.location
                    : "Ohne Ort"
                }}</span>
                <span class="districtData"
                  ><span>OT:</span>
                  {{
                    location.value[0].address.district
                      ? location.value[0].address.district
                      : "Ohne Ortsteil"
                  }}</span
                >
                <span class="streetData">{{
                  location.value[0].address.street
                    ? location.value[0].address.street
                    : "Ohne Straße"
                }}</span>
              </div>
            </div>
          </td>
          <ng-container
            *ngFor="let record of location.value; trackBy: trackByTree"
          >
            <tr [id]="record.id" class="treeRecordTr">
              <td class="tableCheckbox">
                <mat-checkbox
                  [(ngModel)]="record.completed"
                  (ngModelChange)="
                    updateLocationComplete(
                      location.value[0].address.country +
                        '|' +
                        location.value[0].address.location +
                        '|' +
                        location.value[0].address.district +
                        '|' +
                        location.value[0].address.street
                    );
                    updateComplete();
                    setcheckedTrees()
                  "
                >
                </mat-checkbox>
              </td>

              <td class="tdHouse_number">
                {{ record.address.house_number }}
              </td>

              <td class="tdId_number">
                {{ record.id_number ? record.id_number : "--" }}
              </td>

              <td class="tdNumber">
                {{ record.number ? record.number : "--" }}
              </td>

              <td class="tdTreeType">
                <p>
                  {{ record.type.name_german ? record.type.name_german : "--" }}
                </p>
                <p>
                  {{
                    record.type.name_botanic ? record.type.name_botanic : "--"
                  }}
                </p>
              </td>

              <td class="tdPlat_number">
                {{ record.plat_number ? record.plat_number : "--" }}
              </td>
              <td class="tdControl_group">
                <ng-container *ngIf="record.control_group && record.control_group.number; else noControlGroup"
                  >[{{ record.control_group.number }}]&nbsp;{{
                    record.control_group.name
                  }}</ng-container
                >
                <ng-template #noControlGroup>--</ng-template>
              </td>

              <td class="tdNext_inspection">
                <ng-container
                  *ngIf="
                    record.next_inspection &&
                      record.next_inspection != '0000-00-00';
                    else invalidDate
                  "
                  >{{
                    record.next_inspection | date : "dd.MM.yyyy" : "de"
                  }}</ng-container
                >
                <ng-template #invalidDate>--</ng-template>
              </td>

              <!-- ========================================================================================== -->
              <td class="tdNotice" style="vertical-align: middle">
                <ng-container *ngIf="record.notice; else noNotice">
                  <app-dialog-box-trigger
                    [dimensions]="{
                      width: '60vw',
                      height: 'calc(100% - 20vh)'
                    }"
                    [formComponent]="noticesComponent"
                    [record]="record.notice"
                    [noButton]="true"
                    (submitValue)="(null)"
                  >
                    <div noButton class="customBadge noticeBadge">
                      <span style="transform: translateY(1px)">{{
                        record.notice.length
                      }}</span>
                    </div>
                  </app-dialog-box-trigger>
                </ng-container>
                <ng-template #noNotice>
                  <div class="customBadge noticeBadge emptyCustomBadge"></div>
                </ng-template>
              </td>
              <!-- ========================================================================================== -->

              <td
                class="tdDamage_degree"
                style="text-align: center; vertical-align: middle"
              >
                <ng-container *ngIf="record.damage_degree; else noDamageDegree">
                  <div
                    class="customBadge damageDegreeBadge"
                    [style]="
                      'background-color: ' +
                      record.damage_degree.setting.replace('color=', '')
                    "
                  >
                    {{ record.damage_degree.value }}
                  </div>
                </ng-container>
                <ng-template #noDamageDegree>
                  <div
                    class="customBadge defaultBadge damageDegreeBadge emptyCustomBadge"
                  ></div>
                </ng-template>
              </td>

              <td class="tdImage" style="text-align: center">
                <div class="noImage">
                  <span class="material-symbols-outlined no-thumbnail-icon">
                    hide_image
                  </span>
                </div>
              </td>

              <td>
                <div class="singleActions">
                  <app-dialog-box-trigger
                    [dimensions]="{
                      width: '60vw',
                      maxHeight: '80vh'
                    }"
                    [formComponent]="formComponent"
                    [record]="record"
                    (submitValue)="editTree($event)"
                    ><span class="material-symbols-outlined"
                      >edit_document</span
                    ></app-dialog-box-trigger
                  >
                  <!-- <app-delete-data
                    [deleteData]="record"
                    (returnInput)="deleteTree($event)"
                  ></app-delete-data> -->
                </div>
              </td>
            </tr>
          </ng-container>
          <tr class="tBodyLocationEnd">
            <td colspan="12"></td>
          </tr>
        </tbody>
      </ng-container>

      <!-- <ng-container *ngIf="!(sortedLocationTreeRecords | keyvalue)?.length">
        <tbody>
          <tr>
            <td colspan="12">
              <mat-progress-bar mode="indeterminate"></mat-progress-bar>
            </td>
          </tr>
        </tbody>
      </ng-container> -->
    </table>
  </div>
  <div class="locationTree">
    <app-location-tree
      [addressServiceType]="TreeAddressesServiceType"
      (selectedAddresses)="addressFilterSubject.next($event)"
    ></app-location-tree>
  </div>
</div>
