<!-- <div class="modul-container">
    <div class="modul-menu">
        <menu class="menu">
            <p class="menu-label">
                Fahrzeugverfolgung
            </p>
            <ul class="menu-list">
                <li><a [routerLink]="'/'+clientId+'/fahrzeugverfolgung/fahrzeugortung'" routerLinkActive="is-active"
                        >Fahrzeugortung</a></li> target="_blank"
                <li><a [routerLink]="'/'+clientId+'/fahrzeugverfolgung/fahrtenbuch'"
                        routerLinkActive="is-active">Fahrtenbuch</a></li>
                <li><a [routerLink]="'/'+clientId+'/fahrzeugverfolgung/poi_auswertung'" routerLinkActive="is-active">POI
                        Auswertung</a></li>
            </ul>
            <p class="menu-label">
                Administration
            </p>
            <ul class="menu-list">
                <li>
                    <a (click)="onClick(1)" [routerLink]="'/'+clientId+'/fahrzeugverfolgung/stammdaten'"
                        routerLinkActive="is-active">Stammdaten</a></li>
                <ul [@submenuOpenClose]="isOpen1? 'open':'close'">
                    <li><a>Projekt-Erfassung</a></li>
                    <li><a>Zeit-Erfassung</a></li>
                </ul>
                <li>
                    <a (click)="onClick(2)" [routerLink]="'/'+clientId+'/fahrzeugverfolgung/pze'"
                        routerLinkActive="is-active">PZE</a>
                    <ul [@submenuOpenClose]="isOpen2? 'open':'close'">
                        <li><a>Projekt-Erfassung</a></li>
                        <li><a>Zeit-Erfassung</a></li>
                    </ul>
                </li>
            </ul>
        </menu>
    </div>
    <div class="modul-content">
        <router-outlet></router-outlet>
    </div>
</div> -->

<mat-sidenav-container>
    <mat-sidenav mode="overlay" opened="{{sidenavState | async }}" fullscreen>

        <mat-nav-list>
            <mat-list-item [routerLink]="'/'+clientId+'/fahrzeugverfolgung/fahrzeugortung'" 
            routerLinkActive="is-active">Fahrzeugortung</mat-list-item>
            <mat-list-item [routerLink]="'/'+clientId+'/fahrzeugverfolgung/fahrtenbuch'"
            routerLinkActive="is-active">Fahrtenbuch</mat-list-item>
            <mat-list-item [routerLink]="'/'+clientId+'/fahrzeugverfolgung/poi_auswertung'" 
            routerLinkActive="is-active">POI Auswertung</mat-list-item>
        </mat-nav-list>

        <mat-nav-list class="lastChild">
            <mat-list-item [routerLink]="'/'+clientId+'/fahrzeugverfolgung/stammdaten'"
            routerLinkActive="is-active">Stammdaten</mat-list-item>
            <mat-list-item [routerLink]="'/'+clientId+'/fahrzeugverfolgung/pze'"
            routerLinkActive="is-active">PZE</mat-list-item>
        </mat-nav-list>

        <div class="poweredBy">
            Powered by<img src="assets/img/logo-aida.png">
        </div>
        
    </mat-sidenav>
    <mat-sidenav-content>
        <router-outlet></router-outlet>
    </mat-sidenav-content>
</mat-sidenav-container>
