import { NgModule } from "@angular/core";
import { CustomGlobalModules } from "../custom-global-modules.module";
import { GlobalModulesModule } from "../global-modules.module";
import { InventartypkatalogComponent } from "./inventartypkatalog/inventartypkatalog.component";
import { InventorytypeCategoryDialogComponent } from "./inventartypkatalog/inventorytype-category-dialog/inventorytype-category-dialog.component";
import { InventorytypeEntryDialogComponent } from "./inventartypkatalog/inventorytype-entry-dialog/inventorytype-entry-dialog.component";
import { InspectionPhotosComponent } from "./kontrollen/inspection-photos/inspection-photos.component";
import { KontrollenComponent } from "./kontrollen/kontrollen.component";
import { LieferantDialogComponent } from "./lieferant/lieferant-dialog/lieferant-dialog.component";
import { LieferantComponent } from "./lieferant/lieferant.component";
import { AddEditSpielplatzDataFormComponent } from "./spielplaetze/add-edit-spielplatz-form/add-edit-spielplatz-data-form/add-edit-spielplatz-data-form.component";
import { AddEditSpielplatzFormComponent } from "./spielplaetze/add-edit-spielplatz-form/add-edit-spielplatz-form.component";
import { AddEditSpielplatzInventarFormComponent } from "./spielplaetze/add-edit-spielplatz-form/add-edit-spielplatz-inventar-form/add-edit-spielplatz-inventar-form.component";
import { AddEditSpielplatzKontrollenFormComponent } from "./spielplaetze/add-edit-spielplatz-form/add-edit-spielplatz-kontrollen-form/add-edit-spielplatz-kontrollen-form.component";
import { LastInspectionDisplayComponent } from "./spielplaetze/last-inspection-display/last-inspection-display.component";
import { OpenDefectsDisplayComponent } from "./spielplaetze/open-defects-display/open-defects-display.component";
import { SpielplaetzeComponent } from "./spielplaetze/spielplaetze.component";
import { SpielplatzkontrolleComponent } from "./spielplatzkontrolle.component";
import { AllgemeinzustandDialogComponent } from "./vorgabewerte/allgemeinzustand-dialog/allgemeinzustand-dialog.component";
import { ErledigungZeiteinheitenDialogComponent } from "./vorgabewerte/erledigung-zeiteinheiten-dialog/erledigung-zeiteinheiten-dialog.component";
import { ErwerbsartDialogComponent } from "./vorgabewerte/erwerbsart-dialog/erwerbsart-dialog.component";
import { IdenttraegerartDialogComponent } from "./vorgabewerte/identtraegerart-dialog/identtraegerart-dialog.component";
import { KontrollartDialogComponent } from "./vorgabewerte/kontrollart-dialog/kontrollart-dialog.component";
import { KontrollergebnisDialogComponent } from "./vorgabewerte/kontrollergebnis-dialog/kontrollergebnis-dialog.component";
import { StatusDialogComponent } from "./vorgabewerte/status-dialog/status-dialog.component";
import { VorgabewerteComponent } from "./vorgabewerte/vorgabewerte.component";

@NgModule({
  declarations: [
    SpielplatzkontrolleComponent,
    AddEditSpielplatzFormComponent,
    AddEditSpielplatzDataFormComponent,
    AddEditSpielplatzKontrollenFormComponent,
    AddEditSpielplatzInventarFormComponent,
    SpielplaetzeComponent,
    KontrollenComponent,
    LieferantComponent,
    VorgabewerteComponent,
    InventartypkatalogComponent,
    InspectionPhotosComponent,
    LastInspectionDisplayComponent,
    OpenDefectsDisplayComponent,

    ErwerbsartDialogComponent,
    IdenttraegerartDialogComponent,
    KontrollartDialogComponent,
    KontrollergebnisDialogComponent,
    StatusDialogComponent,
    AllgemeinzustandDialogComponent,
    ErledigungZeiteinheitenDialogComponent,
    InventorytypeEntryDialogComponent,
    InventorytypeCategoryDialogComponent,
    LieferantDialogComponent,
  ],
  imports: [GlobalModulesModule, CustomGlobalModules],
  exports: [],
})
export class SpielplatzkontrolleModule {}
