import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import gql from "graphql-tag";

export const getAllControlGroups = gql`
    query getAll {
        Control_Group {
            id
            subject {
              id,
              shortcut,
              description,
              note,
              creation_date,
              creation_user,
              modify_date,
              modify_user,
              canDelete,
            }
            number
            name
            creation_date
            creation_user
            modify_date
            modify_user
            canDelete
        }
    }
`;

export const getSingleControlGroup = gql`
  query getSingleControlGroup($id: Int) {
    Control_Group(id: $id) {
      id
      subject {
        id,
        shortcut,
        description,
        note,
        creation_date,
        creation_user,
        modify_date,
        modify_user,
        canDelete,
      }
      number
      name
      creation_date
      creation_user
      modify_date
      modify_user
      canDelete
    }
  }
`;

@NgModule({
  declarations: [],
  imports: [CommonModule],
})
export class ControlGroupQueriesModule {}
