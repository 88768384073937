import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
  Output,
  EventEmitter,
} from "@angular/core";
import { ControlMessageManagementComponent } from "./control-message-management/control-message-management.component";
import { IconService } from "src/app/services/icons/icon.services";
import { baumkontrolleIcon } from "src/ui/icons/svg-sources/svg-sources.icons";
import { AddressService } from "src/app/services/general/address.service";
import { AddEditTreeFormComponent } from "../../baumuebersicht/add-edit-form/add-edit-tree-form.component";
import { Observable, Subscription } from "rxjs";
import { CoordinateService } from "src/app/services/general/coordinate.service";
import { TreeDataService } from "src/app/services/tree/tree-data.service";

@Component({
  selector: "app-tree-overview",
  templateUrl: "./tree-overview.component.html",
  styleUrls: ["./tree-overview.component.sass"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TreeOverviewComponent implements OnInit {
  public controlMessageManagement = ControlMessageManagementComponent;
  public formComponent = AddEditTreeFormComponent;

  @Input()
  public treeSelection: Array<Object>;

  @Output()
  private zoomToTreeId = new EventEmitter();

  constructor(
    private readonly iconService: IconService,
    private treeDataService: TreeDataService,
    public addressService: AddressService,
    private coordinateDataService: CoordinateService,
  ) {
    this.iconService.add("baumkontrolle", baumkontrolleIcon);
  }

  ngOnInit(): void {
    console.log(this.treeSelection);
    
  }

  zoomToTree(treeId) {
    this.zoomToTreeId.emit(treeId);
  }

  editTree(value) {
    console.warn("editTree() TODO", value);

    if (value.control_group == "") {
      value.control_group = null;
    }
    if (value.damage_degree == "") {
      value.damage_degree = null;
    }
    if (value.supplier == "") {
      value.supplier = null;
    }
    if (value.log_date == "") {
      value.log_date = null;
    }
    if (value.next_inspection == "") {
      value.next_inspection = null;
    }
    if (value.plant_date == "") {
      value.plant_date = null;
    }

    if (value.coordinate_id) {
      // known coordinate

      value.coordinate = value.coordinate_id;
      delete value.coordinate_id;

      if (value.address) {
        // known address
        delete value.address_obj;

        // TODO Refresh record without refresh all records
        this.treeDataService.updateTreeData(value).subscribe();
      } else {
        // new address
        // erst wichtig wenn address-picker edit funktion hat
        console.warn("new address TODO");
      }
    } else {
      // new coordinate

      console.warn("new coordinate");

      if (value.address) {
        // known address
        delete value.address_obj;

        let coordinateSubject: Observable<object> =
          this.coordinateDataService.addCoordinateData(value.coordinate);
        let coordinateSubscription: Subscription = coordinateSubject.subscribe(
          (result) => {
            value.coordinate = result["data"]["add_Coordinate"].result;

            this.treeDataService.updateTreeData(value);

            coordinateSubscription.unsubscribe();
          }
        );
      } else {
        let address = {
          country: value.address_obj.country,
          postcode: value.address_obj.postcode,
          location: value.address_obj.location,
          district: value.address_obj.district,
          street: value.address_obj.street,
          house_number: value.address_obj.house_number,
          coordinate: value.address_obj.coordinate,
          note: "",
        };

        let coordinateSubject: Observable<object> =
          this.coordinateDataService.addCoordinateData(address.coordinate);
        let coordinateSubscription: Subscription = coordinateSubject.subscribe(
          (result) => {
            address.coordinate = result["data"]["add_Coordinate"].result;

            let addressSubject: Observable<object> =
              this.addressService.addAddressData(address);
            let addressSubscription: Subscription = addressSubject.subscribe(
              (result) => {
                value.address = result["data"]["add_Address"].result;
                delete value.address_obj;

                let coordinate2Subject: Observable<object> =
                  this.coordinateDataService.addCoordinateData(
                    value.coordinate
                  );
                let coordinate2Subscription: Subscription =
                  coordinate2Subject.subscribe((result) => {
                    value.coordinate = result["data"]["add_Coordinate"].result;

                    this.treeDataService.updateTreeData(value);

                    coordinate2Subscription.unsubscribe();
                  });

                addressSubscription.unsubscribe();
              }
            );

            coordinateSubscription.unsubscribe();
          }
        );
      }
    }
  }
}
