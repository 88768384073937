/**
 * Zentrales Register für alle Queries. Jedes Query wird hier eingetragen und kann dann mittels
 * query["modul"].funktion im jeweiligen data-service aufgerufen werden.
 * Beispiel: playgroundQueries["supplierList"].add
 *
 * Aktuelle Struktur:
 * modul: {
 *  getAll: ...,
 *  getSingle: ...,
 *  add: ...,
 *  update: ...,
 *  delete: ...
 * }
 *
 */

import {
  getAllAddressData,
  getSingleAddressData,
  addAddressData,
  updateAddressData,
  deleteAddressData,
  coordinateToAddress,
  addressToCoordinate,
  getAllPhotos,
  addPhoto,
  updatePhoto,
  deletePhoto
} from "./generic-queries/generic-queries.module";
import {
  addDefaultValue,
  getAllDefaultValues,
  getSingleDefaultValue,
  updateDefaultValue,
  deleteDefaultValue
} from "./default-value-queries/default-value-queries.module";


//Playground Queries
import {
  getAllPlaygroundAddresses
} from "./playground-queries/playground-address.module";
import {
  getAllPlaygroundData,
  getSinglePlaygroundData,
  addPlaygroundData,
  updatePlaygroundData,
  deletePlaygroundData,
  getAllPlaygroundPhotos,
  getSinglePlaygroundPhoto,
  addPlaygroundPhoto,
  updatePlaygroundPhoto,
  deletePlaygroundPhoto,
  getAllPlaygroundInspections,
  getSinglePlaygroundInspection,
  addPlaygroundInspection,
  updatePlaygroundInspection,
  deletePlaygroundInspection,
  getAllPlaygroundInspectionPhotos,
  getSinglePlaygroundInspectionPhoto,
  addPlaygroundInspectionPhoto,
  updatePlaygroundInspectionPhoto,
  deletePlaygroundInspectionPhoto,
  getAllInventoryType,
  getSingleInventoryType,
  addInventoryType,
  updateInventoryType,
  deleteInventoryType,
  updateInventoryTypeCategory,
  getAllInventory,
  getSingleInventory,
  addInventory,
  updateInventory,
  deleteInventory,
  getAllInventoryPhotos,
  getSingleInventoryPhoto,
  addInventoryPhoto,
  updateInventoryPhoto,
  deleteInventoryPhoto,
  getAllInventoryInspections,
  getSingleInventoryInspection,
  addInventoryInspection,
  updateInventoryInspection,
  deleteInventoryInspection,
  getAllInventoryInspectionPhotos,
  getSingleInventoryInspectionPhoto,
  addInventoryInspectionPhoto,
  updateInventoryInspectionPhoto,
  deleteInventoryInspectionPhoto,
  getAllSuppliersData,
  getSingleSupplierData,
  addSupplierData,
  updateSupplierData,
  deleteSupplierData,
  getInventoryDefects,
  inventoryTypeCategory
} from "./playground-queries/playground-queries.module";

// Tree Queries
import {
  addTreeTypeData,
  getAllTreeTypeData, 
  getSingleTreeTypeData,
  updateTreeTypeData,
  deleteTreeTypeData
} from "./tree-queries/tree-type.module"
import {
  addTreeData,
  getAllTreeData, 
  getSingleTreeData,
  updateTreeData,
  deleteTreeData,
} from "./tree-queries/tree.module"
import {
  getAllTreePhotos,
} from "./tree-queries/tree-photo.module"
import {
  getAllTreeAddresses
} from "./tree-queries/tree-address.module"
import {
  getAllTreeDamageData,
  getSingleTreeDamageData,
  updateTreeDamageData,
  addTreeDamageData,
  deleteTreeDamageData
} from "./tree-queries/tree-damages/tree-damages.module"
import {
  getAllTreeDamageListingData,
  getSingleTreeDamageListingData,
  updateTreeDamageListingData,
  addTreeDamageListingData,
  deleteTreeDamageListingData
} from "./tree-queries/tree-damages/tree-damage-listing.module"
import {
  getAllTreeMeasuresData,
  getSingleTreeMeasuresData,
  updateTreeMeasuresData,
  addTreeMeasuresData,
  deleteTreeMeasuresData
} from "./tree-queries/tree-measures.module";
import {
  getAllControlGroups,
  getSingleControlGroup
} from "./tree-queries/control-group.module"

// Other
import { addCoordinate, deleteCoordinate, getAllCoordinates, getSingleCoordinate } from "./generic-queries/coordinate-queries.module";

export var dataQueries = {};

dataQueries = {
  // Generic Queries
  ["address"]: {
    getAll: getAllAddressData,
    getSingle: getSingleAddressData,
    add: addAddressData,
    update: updateAddressData,
    delete: deleteAddressData
  },
  // Default Value Queries
  ["default_value"]: {
    getAll: getAllDefaultValues,
    getSingle: getSingleDefaultValue,
    add: addDefaultValue,
    update: updateDefaultValue,
    delete: deleteDefaultValue
  },
  // Playground Queries
  ["playground"]: {
    getAll: getAllPlaygroundData,
    getSingle: getSinglePlaygroundData,
    add: addPlaygroundData,
    update: updatePlaygroundData,
    delete: deletePlaygroundData
  },
  ["playground_photo"]: {
    getAll: getAllPlaygroundPhotos,
    getSingle: getSinglePlaygroundPhoto,
    add: addPlaygroundPhoto,
    update: updatePlaygroundPhoto,
    delete: deletePlaygroundPhoto
  },
  ["playground_inspection"]: {
    getAll: getAllPlaygroundInspections,
    getSingle: getSinglePlaygroundInspection,
    add: addPlaygroundInspection,
    update: updatePlaygroundInspection,
    delete: deletePlaygroundInspection
  },
  ["playground_inspection_photo"]: {
    getAll: getAllPlaygroundInspectionPhotos,
    getSingle: getSinglePlaygroundInspectionPhoto,
    add: addPlaygroundInspectionPhoto,
    update: updatePlaygroundInspectionPhoto,
    delete: deletePlaygroundInspectionPhoto
  },
  ["playgroundAdresses"]: {
    getAll: getAllPlaygroundAddresses,
  },
  ["inventory_type"]: {
    getAll: getAllInventoryType,
    getSingle: getSingleInventoryType,
    add: addInventoryType,
    update: updateInventoryType,
    delete: deleteInventoryType
  },
  ["inventory"]: {
    getAll: getAllInventory,
    getSingle: getSingleInventory,
    add: addInventory,
    update: updateInventory,
    delete: deleteInventory
  },
  ["inventory_photo"]: {
    getAll: getAllInventoryPhotos,
    getSingle: getSingleInventoryPhoto,
    add: addInventoryPhoto,
    update: updateInventoryPhoto,
    delete: deleteInventoryPhoto
  },
  ["inventory_inspection"]: {
    getAll: getAllInventoryInspections,
    getSingle: getSingleInventoryInspection,
    add: addInventoryInspection,
    update: updateInventoryInspection,
    delete: deleteInventoryInspection
  },
  ["inventory_inspection_photo"]: {
    getAll: getAllInventoryInspectionPhotos,
    getSingle: getSingleInventoryInspectionPhoto,
    add: addInventoryInspectionPhoto,
    update: updateInventoryInspectionPhoto,
    delete: deleteInventoryInspectionPhoto
  },
  ["supplier"]: {
    getAll: getAllSuppliersData,
    getSingle: getSingleSupplierData,
    add: addSupplierData,
    update: updateSupplierData,
    delete: deleteSupplierData
  },
  ["photo"]: {
    getAll: getAllPhotos,
    getSingle: null,
    add: addPhoto,
    update: updatePhoto,
    delete: deletePhoto
  },

  //Tree Queries
  ["tree"]: {
    getAll: getAllTreeData,
    getSingle: getSingleTreeData,
    add: addTreeData,
    update: updateTreeData,
    delete: deleteTreeData,
  },
  ["tree_type"]: {
    getAll: getAllTreeTypeData,
    getSingle: getSingleTreeTypeData,
    add: addTreeTypeData,
    update: updateTreeTypeData,
    delete: deleteTreeTypeData
  },
  ["tree_photos"]: {
    getAll: getAllTreePhotos
  },
  ["treeAdresses"]: {
    getAll: getAllTreeAddresses,
  },
  ["treePhotos"]:{
    getAllTreePhotos: getAllTreePhotos
  },
  ["tree_damages"]:{
    getAll: getAllTreeDamageData,
    getSingle: getSingleTreeDamageData,
    add: addTreeDamageData,
    update: updateTreeDamageData,
    delete: deleteTreeDamageData
  },
  ["tree_damage_listing"]:{
    getAll: getAllTreeDamageListingData,
    getSingle: getSingleTreeDamageListingData,
    add: addTreeDamageListingData,
    update: updateTreeDamageListingData,
    delete: deleteTreeDamageListingData
  },
  ["tree_measures"]:{
    getAll: getAllTreeMeasuresData,
    getSingle: getSingleTreeMeasuresData,
    update: updateTreeMeasuresData,
    add: addTreeMeasuresData,
    delete: deleteTreeMeasuresData
  },
  ["control_group"]:{
    getAll: getAllControlGroups,
    getSingle: getSingleControlGroup
  },
  ["coordinate"]:{
    getAll: getAllCoordinates,
    getSingle: getSingleCoordinate,
    add: addCoordinate,
    delete: deleteCoordinate
  },

  // Alle absoluten Sonderfälle, niemals direkt über das GraphQl-Objekt aufrufen
  // Bekommt ein eigenes Sonderobjekt
  ["special_cases"]: {
    coordsToAddress: coordinateToAddress,
    addressToCoords: addressToCoordinate,
    getInventoryDefects: getInventoryDefects,
    updateInventoryTypeCategory: inventoryTypeCategory
  }
};

// // Alter Mist -----------------------------
// // (wird aber -noch- gebraucht)

// export var playgroundQueries = {};
// export var treeControl = {};
// // ... restlichen Module

// playgroundQueries = {
//   ["supplierList"]: {
//     getAll: getAllSuppliersData,
//     getSingle: null,
//     add: addSupplierData,
//     change: null,
//     delete: null
//   },
//   ["defaultValues"]: {
//     getAll: null,
//     getSingle: null,
//     add: null,
//     change: null,
//     delete: null
//   }
// };
