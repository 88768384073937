import { Injectable } from '@angular/core';
import { BehaviorSubject, combineLatest } from "rxjs";
import { GraphqlService } from "../graphql.service";

import { DataobjectPlaygroundInspectionPhotos } from "../../baseclasses/dataobject-playground/dataobject-playground-inspection-photos";

@Injectable({
  providedIn: 'root'
})
export class PlaygroundInspectionPhotosService {

  private _initialPhotoFilter = 0;
  private _photoFilterSubject: BehaviorSubject<number>;
  private _inspectionPhotoObject: any;
  private _inspectionPhotoSubject: BehaviorSubject<object>;

  private _inspectionThumbnailSubject: BehaviorSubject<any>;
  private _inspectionsPhotosSubject: BehaviorSubject<any>;


  constructor(private graphQl: GraphqlService) {
    this._inspectionPhotoSubject = new BehaviorSubject<object>([]);
    this._photoFilterSubject = new BehaviorSubject<number>(this._initialPhotoFilter);
    this._inspectionPhotoObject = new DataobjectPlaygroundInspectionPhotos(this.graphQl);
    this._inspectionThumbnailSubject = new BehaviorSubject<any>({})
    this._inspectionsPhotosSubject = new BehaviorSubject<any>({});

    let thumbnailSubscription = this._inspectionPhotoObject.dataObserver.subscribe( result => {
      console.log("PlaygroundInspectionPhotosService: Subscription of DataobjectPlaygroundInspectionPhotos: ", result);
      this._inspectionsPhotosSubject.next(result);
      let thumbs = this.parseThumbnailIDs(result);
      this._inspectionThumbnailSubject.next(thumbs);
    });

    combineLatest([this._inspectionThumbnailSubject, this._photoFilterSubject]).subscribe( result => {
      
      let photoData = result[0];
      let filterData = result[1];
      
      let photoObjectArray = [];
      if (photoData[filterData]) {
        for(let i = 0; i < photoData[filterData].length; i++) {
          photoObjectArray.push({photo: photoData[filterData][i]});
        }
      }

      this._inspectionPhotoSubject.next(photoObjectArray);
    });

    combineLatest([this._inspectionPhotoObject.dataObserver, this._photoFilterSubject]).subscribe( result => {
      let photoData = result[0];
      let filterData = result[1];

      if(!photoData) {
        return [];
      }

      let filteredPhotos = this._inspectionPhotoObject.filterData(photoData, [filterData], "playground_inspection");
    });


  }

  set currentInspection(inspection) {
    this._photoFilterSubject.next(inspection);
  }

  get currentInspectionPhotos() {
    return this._inspectionPhotoSubject;
  }

  get currentRawInspectionPhotos() {
    return this._inspectionsPhotosSubject;
  }

  get currentThumbnails() {
    return this._inspectionThumbnailSubject;
  }


  parseThumbnailIDs(data) {
    let parsedIDs = {};

    if(!data) {
      return parsedIDs;
    }

    for (let i = 0; i < data.length; i++) {
      let inspectionID = data[i].playground_inspection;
      if (parsedIDs[inspectionID] === undefined) {
        parsedIDs[inspectionID] = [data[i].photo];
      } else {
        parsedIDs[inspectionID].push(data[i].photo);
      }
    }

    return parsedIDs;
  }

  addInspectionPhoto(values) {
    this._inspectionPhotoObject.baseObject.addData(values);
  }

  updateInspectionPhoto(values) {
    this._inspectionPhotoObject.baseObject.updateData(values);
  }

  deleteInspectionPhoto(value) {
    this._inspectionPhotoObject.baseObject.deleteData(value);
  }

}
 