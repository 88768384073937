import { AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from "@angular/core";
import { BehaviorSubject } from "rxjs";

@Component({
    selector: 'app-alphabetical-scrollbar',
    templateUrl: './alphabetical-scrollbar.component.html',
    styleUrls: ['./alphabetical-scrollbar.component.sass']
})
export class AlphabeticalScrollbarComponent implements OnInit, OnDestroy, AfterViewInit{
    private _disabledLetters = new BehaviorSubject<Object>({});
    private _activeLetter = new BehaviorSubject<Array<any>>([]);
    private _disabledLettersSubscription;
    private _activeLetterSubscription;

    private currentActivLetter: any;

    private currentSortingDirection: string = "asc";

    private _data = new BehaviorSubject<Array<Object>>([]);

    @Input()
    set disabledLetters(value){
        this._disabledLetters.next(value)
    }

    @Input()
    set activeLetter(value){
        this._activeLetter.next(value);
    }

    @Input()
    set data(value){
        this._data.next(value)
    }

    @Output() scroll = new EventEmitter<string>();

    @ViewChild('alphabetical') alphabetical;

    ngOnInit() {        

    }

    ngAfterViewInit(): void {
        this._activeLetterSubscription = this._activeLetter.subscribe((data)=>{
            if(data && data[1].id && data[0] === "VISIBLE"){
                if(this.currentActivLetter){this.currentActivLetter.classList.remove("activeLink")};
                document.getElementById('scroll'+data[1].id).classList.add("activeLink");
                this.currentActivLetter=document.getElementById('scroll'+data[1].id);
            }
        })
        this._disabledLettersSubscription = this._disabledLetters.subscribe((data)=>{
            Object.entries(data).forEach(([key, value])=>{
                if(value){
                    document.getElementById('scroll'+key).classList.remove("disabledLink");
                }else{
                    document.getElementById('scroll'+key).classList.add("disabledLink");
                }
            })
        })
    }

    ngOnDestroy() {
        this._disabledLettersSubscription.unsubscribe();
        this._activeLetterSubscription.unsubscribe();
    }

    scrollToElement(fragment) {
        this.scroll.emit(fragment);
    }

    alphabeticalScrollbarOrder(direction){
        if(this.currentSortingDirection != direction){
            let element = [].slice.call(document.getElementsByClassName("alphabetical")[0].getElementsByTagName("li"));
    
            for(let i = element.length - 1; i>=0; i--){
                document.getElementsByClassName("alphabetical")[0].getElementsByTagName("ul")[0].appendChild(element[i]);
            }
            this.currentSortingDirection = direction;
        }
    }
    
    disable(){
        this.alphabetical.nativeElement.parentNode.style.color = "blue";
    }
}