import { Component, OnInit } from "@angular/core";
import { PlaygroundInspectionService } from "src/app/services/playground/playground-inspection.service";

@Component({
  selector: "app-kontrollen",
  templateUrl: "./kontrollen.component.html",
  styleUrls: ["./kontrollen.component.sass"],
})
export class KontrollenComponent implements OnInit {
  public inspectionData;

  // Kann gelöscht werden wenn Backend inspectionData mit folgender Struktur zurück gibt.
  private exampleInspectionData = [
    {
      playground: {
        number: 1,
        description: "Am Hang",
        address: {
          country: "Deutschland",
          district: "Wadersloh",
          house_number: 1,
          location: "Wadersloh",
          postcode: "59329",
          street: "Am Hang",
          // ...
        },
        inventory_items: [1, 2, 3, 4, 5],
        // ...
      },
      date: "2020-10-02",
      time: "09:52:36",
      type: "Sichtkontrolle",
      person1: "Mustermann",
      person2: "",
      result: "mit neuen Mängeln",
      inventory_inspection: [
        {
          status: "ohne neue Beanstandung",
          // ...
        },
        {
          status: "Gefahrbeseitigung",
          // ...
        },
        {
          status: "Handlungsbedarf",
          // ...
        },
        {
          status: "Gefahrbeseitigung",
          // ...
        },
        {
          status: "ohne neue Beanstandung",
          // ...
        }
      ],
      // ...
    },
    {
      playground: {
        number: 1,
        description: "Am Hang",
        address: {
          country: "Deutschland",
          district: "Wadersloh",
          house_number: 1,
          location: "Wadersloh",
          postcode: "59329",
          street: "Am Hang",
          // ...
        },
        inventory_items: [1, 2, 3, 4, 5],
        // ...
      },
      date: "2020-10-02",
      time: "09:52:36",
      type: "Sichtkontrolle",
      person1: "Mustermann",
      person2: "",
      result: "mit neuen Mängeln",
      inventory_inspection: [
        {
          status: "ohne neue Beanstandung",
          // ...
        },
        {
          status: "Gefahrbeseitigung",
          // ...
        },
        {
          status: "Handlungsbedarf",
          // ...
        },
        {
          status: "Gefahrbeseitigung",
          // ...
        },
        {
          status: "ohne neue Beanstandung",
          // ...
        }
      ],
      // ...
    },
    {
      playground: {
        number: 1,
        description: "Am Hang",
        address: {
          country: "Deutschland",
          district: "Wadersloh",
          house_number: 1,
          location: "Wadersloh",
          postcode: "59329",
          street: "Am Hang",
          // ...
        },
        inventory_items: [1, 2, 3, 4, 5],
        // ...
      },
      date: "2020-10-02",
      time: "09:52:36",
      type: "Sichtkontrolle",
      person1: "Mustermann",
      person2: "",
      result: "mit neuen Mängeln",
      inventory_inspection: [
        {
          status: "ohne neue Beanstandung",
          // ...
        },
        {
          status: "Gefahrbeseitigung",
          // ...
        },
        {
          status: "Handlungsbedarf",
          // ...
        },
        {
          status: "Gefahrbeseitigung",
          // ...
        },
        {
          status: "ohne neue Beanstandung",
          // ...
        }
      ],
      // ...
    },
    {
      playground: {
        number: 1,
        description: "Am Hang",
        address: {
          country: "Deutschland",
          district: "Wadersloh",
          house_number: 1,
          location: "Wadersloh",
          postcode: "59329",
          street: "Am Hang",
          // ...
        },
        inventory_items: [1, 2, 3, 4, 5],
        // ...
      },
      date: "2020-10-02",
      time: "09:52:36",
      type: "Sichtkontrolle",
      person1: "Mustermann",
      person2: "",
      result: "mit neuen Mängeln",
      inventory_inspection: [
        {
          status: "ohne neue Beanstandung",
          // ...
        },
        {
          status: "Gefahrbeseitigung",
          // ...
        },
        {
          status: "Handlungsbedarf",
          // ...
        },
        {
          status: "Gefahrbeseitigung",
          // ...
        },
        {
          status: "ohne neue Beanstandung",
          // ...
        }
      ],
      // ...
    },
    {
      playground: {
        number: 1,
        description: "Am Hang",
        address: {
          country: "Deutschland",
          district: "Wadersloh",
          house_number: 1,
          location: "Wadersloh",
          postcode: "59329",
          street: "Am Hang",
          // ...
        },
        inventory_items: [1, 2, 3, 4, 5],
        // ...
      },
      date: "2020-10-02",
      time: "09:52:36",
      type: "Sichtkontrolle",
      person1: "Mustermann",
      person2: "",
      result: "mit neuen Mängeln",
      inventory_inspection: [
        {
          status: "ohne neue Beanstandung",
          // ...
        },
        {
          status: "Gefahrbeseitigung",
          // ...
        },
        {
          status: "Handlungsbedarf",
          // ...
        },
        {
          status: "Gefahrbeseitigung",
          // ...
        },
        {
          status: "ohne neue Beanstandung",
          // ...
        }
      ],
      // ...
    },
    {
      playground: {
        number: 1,
        description: "Am Hang",
        address: {
          country: "Deutschland",
          district: "Wadersloh",
          house_number: 1,
          location: "Wadersloh",
          postcode: "59329",
          street: "Am Hang",
          // ...
        },
        inventory_items: [1, 2, 3, 4, 5],
        // ...
      },
      date: "2020-10-02",
      time: "09:52:36",
      type: "Sichtkontrolle",
      person1: "Mustermann",
      person2: "",
      result: "mit neuen Mängeln",
      inventory_inspection: [
        {
          status: "ohne neue Beanstandung",
          // ...
        },
        {
          status: "Gefahrbeseitigung",
          // ...
        },
        {
          status: "Handlungsbedarf",
          // ...
        },
        {
          status: "Gefahrbeseitigung",
          // ...
        },
        {
          status: "ohne neue Beanstandung",
          // ...
        }
      ],
      // ...
    },
    {
      playground: {
        number: 1,
        description: "Am Hang",
        address: {
          country: "Deutschland",
          district: "Wadersloh",
          house_number: 1,
          location: "Wadersloh",
          postcode: "59329",
          street: "Am Hang",
          // ...
        },
        inventory_items: [1, 2, 3, 4, 5],
        // ...
      },
      date: "2020-10-02",
      time: "09:52:36",
      type: "Sichtkontrolle",
      person1: "Mustermann",
      person2: "",
      result: "mit neuen Mängeln",
      inventory_inspection: [
        {
          status: "ohne neue Beanstandung",
          // ...
        },
        {
          status: "Gefahrbeseitigung",
          // ...
        },
        {
          status: "Handlungsbedarf",
          // ...
        },
        {
          status: "Gefahrbeseitigung",
          // ...
        },
        {
          status: "ohne neue Beanstandung",
          // ...
        }
      ],
      // ...
    },
    {
      playground: {
        number: 1,
        description: "Am Hang",
        address: {
          country: "Deutschland",
          district: "Wadersloh",
          house_number: 1,
          location: "Wadersloh",
          postcode: "59329",
          street: "Am Hang",
          // ...
        },
        inventory_items: [1, 2, 3, 4, 5],
        // ...
      },
      date: "2020-10-02",
      time: "09:52:36",
      type: "Sichtkontrolle",
      person1: "Mustermann",
      person2: "",
      result: "mit neuen Mängeln",
      inventory_inspection: [
        {
          status: "ohne neue Beanstandung",
          // ...
        },
        {
          status: "Gefahrbeseitigung",
          // ...
        },
        {
          status: "Handlungsbedarf",
          // ...
        },
        {
          status: "Gefahrbeseitigung",
          // ...
        },
        {
          status: "ohne neue Beanstandung",
          // ...
        }
      ],
      // ...
    },

  ];

  constructor(private inspectionDataService: PlaygroundInspectionService) {
    this.inspectionData = this.exampleInspectionData;
  }

  ngOnInit(): void {
    this.inspectionDataService.getAllInspectionData().subscribe((result) => {
      console.log(result);
    });
  }

  countInventoryInspectionsByType(inspection, type: string){
    return inspection.inventory_inspection.filter(item => item.status === type).length;
  }
}
