import { Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef, Output, EventEmitter } from '@angular/core';
import { TreeDamageListingService } from 'src/app/services/tree/tree-damages/tree-damage-listing.service';

@Component({
  selector: 'app-select-damage-listing',
  templateUrl: './select-damage-listing.component.html',
  styleUrls: ['./select-damage-listing.component.sass'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SelectDamageListingComponent implements OnInit {

  @Output() values = new EventEmitter();

  public treeDamageListings: Array<object>;

  constructor(private treeDamageListingService: TreeDamageListingService, private cd: ChangeDetectorRef) {}

  ngOnInit(): void {
    this.treeDamageListingService.getAllTreeDamageListingsData().subscribe(result=>{
      console.log(result)
      this.treeDamageListings = result;
      this.cd.detectChanges();
    })
  }

}
