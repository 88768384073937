import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

@Component({
  selector: 'app-dialog-box-footer',
  templateUrl: './dialog-box-footer.component.html',
  styleUrls: ['./dialog-box-footer.component.sass']
})
export class DialogBoxFooterComponent implements OnInit, OnDestroy {
  @Input() valuesBSubject = new BehaviorSubject({});
  @Input() submitTitle: String;

  private valuesBSubjectSubscription;

  public values;
  public disabled: boolean = true;

  constructor() { }

  ngOnInit(): void {
    this.valuesBSubjectSubscription = this.valuesBSubject.subscribe((result: object)=>{
      if(!result){
        this.disabled=true;
      }else{
        this.disabled=false;
      }
      this.values = result;
    })
  }

  ngOnDestroy(): void {
    this.valuesBSubjectSubscription.unsubscribe();
  }

}
