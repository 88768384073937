import { Component, OnInit, ChangeDetectionStrategy, AfterViewInit, ChangeDetectorRef, EventEmitter, Output } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { cloneDeep } from '@apollo/client/utilities';
import * as moment from 'moment';
import { Subscription } from 'rxjs';
import { AddressMapPickerComponent } from 'src/app/modules/address-map-picker/address-map-picker.component';
import { SuppliersService } from 'src/app/services/general/suppliers.service';

@Component({
  selector: 'app-add-edit-spielplatz-data-form',
  templateUrl: './add-edit-spielplatz-data-form.component.html',
  styleUrls: ['./add-edit-spielplatz-data-form.component.sass'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AddEditSpielplatzDataFormComponent implements OnInit, AfterViewInit {

  @Output() values = new EventEmitter();

  private supplierSubscription: Subscription;
  public suppliers: Array<object>;

  public disableAnimation = true;

  public playgroundForm: FormGroup;
  public addressField: FormGroup;
  public addressMapPicker = AddressMapPickerComponent;

  constructor(
    private cd: ChangeDetectorRef,
    private suppliersService: SuppliersService,
  ) { }

  ngOnInit(): void {

    this.playgroundForm = new FormGroup({
      number: new FormControl("", [Validators.required, this.typeNumber()]), //*
      description: new FormControl("", [Validators.required]), //*
      category: new FormControl(""),
      double_entry: new FormControl(""),
      note: new FormControl(""),
      commissioning_date: new FormControl(""),
      decommissioning_date: new FormControl(""),
      address: new FormControl("", [Validators.required]),
      coordinate: new FormControl("", [Validators.required]),
      location: new FormControl(""),
      area: new FormControl(0.0, [this.typeNumber()]),
      enclosure: new FormControl(0, [this.typeNumber()]),
      soil: new FormControl(""),
      planting: new FormControl(""),
      supplier: new FormControl(0)
    });
    this.addressField = new FormGroup({
      address_string: new FormControl("", [Validators.required])
    })


    this.playgroundForm.valueChanges.subscribe((result)=>{
      if(this.playgroundForm.valid){
        this.values.emit(result);
      }else{
        this.values.emit(false);
      }
    })

    this.playgroundForm.get("commissioning_date").valueChanges.subscribe((result) => {
      const date = moment(result).format("YYYY-MM-DD");
      if (date !== "Invalid date") {
        this.playgroundForm.patchValue(
          { commissioning_date: date },
          { emitEvent: false }
        );
      } else {
        this.playgroundForm.patchValue({ commissioning_date: "" }, { emitEvent: false });
      }
    });
    this.playgroundForm.get("decommissioning_date").valueChanges.subscribe((result) => {
      const date = moment(result).format("YYYY-MM-DD");
      if (date !== "Invalid date") {
        this.playgroundForm.patchValue(
          { decommissioning_date: date },
          { emitEvent: false }
        );
      } else {
        this.playgroundForm.patchValue({ decommissioning_date: "" }, { emitEvent: false });
      }
    });
  }

  ngAfterViewInit(): void {
    // timeout required to avoid 'ExpressionChangedAfterItHasBeenCheckedError'
    setTimeout(() => (this.disableAnimation = false));
  }

  setAddress(event){
    // console.log(event);
    this.playgroundForm.patchValue({ coordinate: event.coordinate });
    if(event.address.id){
      this.playgroundForm.patchValue({ address: event.address.id });
      this.addressField.patchValue({ address_string: event.address.address_string });
    }else{
      this.playgroundForm.patchValue({ address: event.address });
    }
  }

  subscribe(subscription: string, funct?: () => void) {
    switch (subscription) {

      case "supplier": {
        if (!this.supplierSubscription) {
          this.supplierSubscription = this.suppliersService
            .getAllSupplierData()
            .subscribe((result) => {
              this.suppliers = result.map((supplier) => {
                let supplierCopy = cloneDeep(supplier);
                let string = `[${supplier["number"]}] ${supplier["name1"]} ${supplier["name2"]}`;
                supplierCopy["Supplier_String"] = string;
                return supplierCopy;
              });

              if (funct) {
                funct();
              }
              this.cd.detectChanges();
              this.supplierSubscription.unsubscribe();
            });
        }
        break;
      }
    }
  }


  // =====================================================================================================
  onOpenItem(element, accordionEl) {
    let el: HTMLElement = accordionEl.children[element];
    let nextEl: HTMLElement = accordionEl.children[element + 1];
    let previousEl: HTMLElement = accordionEl.children[element - 1];

    if (previousEl) {
      previousEl.style.borderBottom = "1px solid #d9d9d9";
      previousEl.style.borderBottomLeftRadius = "4px";
      previousEl.style.borderBottomRightRadius = "4px";
    }
    el.style.border = "1px solid #d9d9d9";
    el.style.borderRadius = "4px";
    if (nextEl) {
      nextEl.style.borderTop = "1px solid #d9d9d9";
      nextEl.style.borderTopLeftRadius = "4px";
      nextEl.style.borderTopRightRadius = "4px";
    }
  }

  onCloseItem(element, accordionEl) {
    let el: HTMLElement = accordionEl.children[element];
    let nextEl: HTMLElement = accordionEl.children[element + 1];
    let previousEl: HTMLElement = accordionEl.children[element - 1];

    if (previousEl && !previousEl.classList.contains("mat-expanded")) {
      previousEl.style.borderBottom = "unset";
      previousEl.style.borderBottomLeftRadius = "unset";
      previousEl.style.borderBottomRightRadius = "unset";
      el.style.borderTop = "unset";
      el.style.borderTopLeftRadius = "unset";
      el.style.borderTopRightRadius = "unset";
    }
    if (nextEl && !nextEl.classList.contains("mat-expanded")) {
      nextEl.style.borderTop = "unset";
      nextEl.style.borderTopLeftRadius = "unset";
      nextEl.style.borderTopRightRadius = "unset";
      el.style.borderBottom = "unset";
      el.style.borderBottomLeftRadius = "unset";
      el.style.borderBottomRightRadius = "unset";
    }
  }

  typeNumber(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      return isNaN(control.value) ? { typeNumber: control.value } : null;
    };
  }

}
