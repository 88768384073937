// import {
//   Component,
//   Input,
//   Output,
//   EventEmitter,
//   HostListener
// } from "@angular/core";
// import { FormBuilder, FormGroup, FormControl } from "@angular/forms";

// @Component({
//   selector: "app-filter-choice",
//   templateUrl: "./filter-choice.component.html",
//   styleUrls: ["./filter-choice.component.sass"],
// })
// export class FilterChoiceComponent {
//   @Input() filterObject;
//   @Input() filterNameArray;
//   @Input() specialFilterData?;
//   @Output() addFilter = new EventEmitter();
//   @Output() removeFilter = new EventEmitter();
//   @Output() addSpecialFilter? = new EventEmitter();

//   isNotDisplayingFilterList = true;
//   isNotDisplayingTimeframe = true;
//   isNotDisplayingMonths = true;
//   isNotDisplayingYears = true;
//   filterWasClicked = false;

//   form: FormGroup;
//   monthForm: FormGroup;
//   yearsForm: FormGroup;

//   @HostListener("document:click", ["$event"])
//   clickout(event) {
//     if (this.filterWasClicked) {
//       this.filterWasClicked = false;
//     } else {
//       if (this.isNotDisplayingFilterList === false) {
//         this.isNotDisplayingFilterList = true;
//       }
//       if (this.isNotDisplayingTimeframe === false) {
//         this.isNotDisplayingTimeframe = true;
//       }
//       if (this.isNotDisplayingMonths === false) {
//         this.isNotDisplayingMonths = true;
//       }
//       if (this.isNotDisplayingYears === false) {
//         this.isNotDisplayingYears = true;
//       }
//     }
//   }

//   constructor(fb: FormBuilder) {

//     let dateNow = Date.now();
//     let dateThen = new Date().setMonth(new Date().getMonth() - 1);

//     this.form = fb.group({
//       begin: new FormControl(new Date(dateThen).toISOString().substr(0, 10)),
//       end: new FormControl(new Date(dateNow).toISOString().substr(0, 10))
//     });

//     this.monthForm = fb.group({
//       frame: new FormControl()
//     });

//     this.yearsForm = fb.group({
//       year: new FormControl(),
//       month: new FormControl()
//     });

//   }

//   openFilterPopup() {
//     this.filterWasClicked = true;
//     this.isNotDisplayingFilterList = false;
//   }

//   choseFilter(filter) {
//     this.addFilter.emit(filter);
//   }

//   filterRemoval(filter) {
//     this.removeFilter.emit(filter);
//   }

//   setTimeframe(filter) {
//     this.isNotDisplayingMonths = true;
//     this.isNotDisplayingMonths = true;
//     this.isNotDisplayingYears = true;
//     this.isNotDisplayingFilterList = true;

//     let beginDate = new Date(this.form.value.begin);
//     let endDate = new Date(this.form.value.end);

//     console.log(this.form.value)

//     this.addSpecialFilter.emit({
//       key: filter,
//       type: "date",
//       filterArray: [this.form.value],
//       label: `Zeitraum: ${beginDate.toLocaleDateString('de-De')} - ${endDate.toLocaleDateString('de-De')}`
//     })
//   }

//   setMonths(filter) {
//     this.isNotDisplayingMonths = true;
//     this.isNotDisplayingTimeframe = true;
//     this.isNotDisplayingFilterList = true;
//     this.isNotDisplayingYears = true;

//     let label;
//     switch(this.monthForm.value.frame) {
//       case "1":
//         label = "Zeitraum: letzter Monat"
//         break;
//       case "3":
//         label = "Zeitraum: 3 Monate"
//         break;
//       case "6":
//         label = "Zeitraum: 6 Monate"
//         break;
//       case "9":
//         label = "Zeitraum: 9 Monate"
//         break;
//       case "12":
//         label = "Zeitraum: Letztes Jahr"
//         break;
//       case "24":
//         label = "Zeitraum: Letzte 2 Jahre"
//         break;
//     }

//     let timeframeObject = this.getTimeframe(parseInt(this.monthForm.value.frame));

//     this.addSpecialFilter.emit({
//       key: filter,
//       type: "date",
//       filterArray: [timeframeObject],
//       label: label
//     })

//   }

//   getTimeframe(months) {
//     let dateNow = Date.now();
//     let dateThen = new Date().setMonth(new Date().getMonth() - months);

//     return {begin: new Date(dateThen).toISOString().substr(0, 10), end: new Date(dateNow).toISOString().substr(0, 10)}
//   }

//   choseYears(event) {
//     console.log(event);
//   }

//   openTimeframeDialog(event) {
//     event.stopPropagation();
//     this.isNotDisplayingTimeframe = false;
//     if (this.isNotDisplayingMonths === false) {
//       this.isNotDisplayingMonths = true;
//     }
//     if (this.isNotDisplayingYears === false) {
//       this.isNotDisplayingYears = true;
//     }
//   }

//   openMonthsDialog(event) {
//     event.stopPropagation();
//     this.isNotDisplayingMonths = false;
//     if (this.isNotDisplayingTimeframe === false) {
//       this.isNotDisplayingTimeframe = true;
//     }
//     if (this.isNotDisplayingYears === false) {
//       this.isNotDisplayingYears = true;
//     }
//   }

//   openYearsDialog(event) {
//     console.log(this.specialFilterData)

//     event.stopPropagation();
//     this.isNotDisplayingYears = false;
//     if (this.isNotDisplayingTimeframe === false) {
//       this.isNotDisplayingTimeframe = true;
//     }
//     if (this.isNotDisplayingMonths === false) {
//       this.isNotDisplayingMonths = true;
//     }
//   }
// }

import { Component, ElementRef, ViewChild } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { MatMenuTrigger } from "@angular/material/menu";
import { MatDatepicker } from "@matheo/datepicker";
import moment, { Moment } from "moment";

@Component({
  selector: "app-filter-choice",
  templateUrl: "./filter-choice.component.html",
  styleUrls: ["./filter-choice.component.sass"],
})
export class FilterChoiceComponent {
  @ViewChild("menuTrigger", { read: MatMenuTrigger })
  menuTrigger: MatMenuTrigger;

  public range = new FormGroup({
    start: new FormControl(),
    end: new FormControl(),
  });

  public filterYear = new FormControl();

  private filterId = 0;
  public filterArray = [];

  constructor() {
    this.range.valueChanges.subscribe((result) => {
      if (result.start && result.end) this.setTimeInterval(result);
    });
    this.filterYear.valueChanges.subscribe((result) => {
      this.setYear(result.getFullYear());
    });
  }

  getMonthName(monthNumber) {
    const date = new Date();
    date.setMonth(monthNumber - 1);

    return date.toLocaleString("de-DE", { month: "long" });
  }

  setTimeInterval(timeInterval) {
    // console.log(timeInterval)
    this.filterArray.push({
      id: this.filterId,
      name: `${moment(timeInterval.start).format("D.M.YY")} - ${moment(
        timeInterval.end
      ).format("D.M.YY")}`,
      type: "timeInterval",
      start: timeInterval.start,
      end: timeInterval.end,
    });
    this.filterId++;
    this.closeFilterMenu();
  }
  setMonth(month) {
    this.filterArray.push({
      id: this.filterId,
      name: this.getMonthName(month),
      type: "month",
      month: month
    });
    this.filterId++;
  }
  setYear(year) {
    this.filterArray.push({ id: this.filterId, name: year, type: "year", year: year});
    this.filterId++;
    this.closeFilterMenu();
  }

  removeFilter(clickedFilter) {
    const index = this.filterArray.indexOf(clickedFilter);

    if (index >= 0) {
      this.filterArray.splice(index, 1);
    }
  }

  closeFilterMenu() {
    this.menuTrigger.closeMenu();
  }

  clearInputs() {
    this.range.patchValue(
      {
        start: "",
        end: "",
      },
      { emitEvent: false }
    );
    this.filterYear.patchValue(undefined, { emitEvent: false });
  }
}
