import { Component, OnInit, Input } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { GraphqlService } from 'src/app/services/graphql.service';
import { AddPictureDialogComponent } from './add-picture-dialog/add-picture-dialog.component';
import { HttpService } from 'src/app/services/http.service';
/*import {
  getDefaultValuesQuery
} from "src/app/gql-queries/default-value-queries/default-value-queries.module";
*/

@Component({
  selector: 'app-add-picture',
  templateUrl: './add-picture.component.html',
  styleUrls: ['./add-picture.component.sass']
})
export class AddPictureComponent implements OnInit {

  @Input() baseQuery: any;
  @Input() picturesQuery: any; 
  @Input() addQuery: any;
  @Input() playGroundID: any;

  constructor(public dialog: MatDialog, private graphQLService: GraphqlService, private httpService: HttpService) { }

  ngOnInit(): void {
   
  }
  
  addPicture() {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = false;
    dialogConfig.autoFocus = true;
    dialogConfig.minHeight = '500px';
    dialogConfig.minWidth = '600px';

    dialogConfig.data = {}

    const dialogRef = this.dialog.open(AddPictureDialogComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.httpService.uploadPicture(result).subscribe(
          data => {
            let variableObject = {
              playground: this.playGroundID,
              photo: Number(data["file"])
            }
            this.graphQLService.sendMutation(this.addQuery, variableObject, this.baseQuery, this.picturesQuery);
          },
          error => {
            console.error(error);
          }
        );
      }
    })
  }

}
