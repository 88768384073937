import { NgModule } from "@angular/core";
import { SharedDirectivesModule } from "src/app/directives/shared-directives.module";
import { GlobalModulesModule } from "src/app/global-modules.module";

import { GridModule } from "src/ui/grid/grid.module";
import { AlphabeticalScrollbarModule } from "../alphabetical-scrollbar/alphabetical-scrollbar.module";
import { AddressMapPickerComponent } from "./address-map-picker.component";
import { MapPicker } from "./map-picker/map-picker.component";

@NgModule({
    declarations: [
        AddressMapPickerComponent,
        MapPicker,
    ],
    imports: [
        GlobalModulesModule,
        GridModule,
        AlphabeticalScrollbarModule,
        SharedDirectivesModule
    ],
    exports: [
        AddressMapPickerComponent
    ],
  })
  export class AddresMapPickerModule {}