<app-row web style="height: 100%">
  <app-column web="6" class="col1">
    <mat-tab-group (focusChange)="tabChange($event)">
      <mat-tab label="Bekannte Addressen">
        <div style="height: 100%; padding-right: 12px;">
          <div class="searchWrapper">
            <mat-form-field
              class="search-bar mat-form-field-appearance-fill mat-form-field-appearance-no-padding"
              appearance="fill"
              style="flex: 1 1 auto"
            >
              <input
                matInput
                type="text"
                [(ngModel)]="searchValue"
                (input)="onSearchInput($event.target.value)"
                placeholder="Suche"
              />
  
              <div matSuffix style="display: flex">
                <button
                  *ngIf="searchValue"
                  mat-icon-button
                  aria-label="Clear"
                  (click)="searchValue = ''; onSearchInput('')"
                >
                  <mat-icon>close</mat-icon>
                </button>
                <div class="searchIcon">
                  <span class="material-symbols-outlined"> search </span>
                </div>
              </div>
            </mat-form-field>
            <div>
              <mat-form-field
                floatLabel="always"
                class="fullWidth mat-form-field-appearance-fill simple"
                appearance="fill"
              >
                <mat-select
                  value=""
                  disableOptionCentering
                  panelClass="simplePanel"
                  (selectionChange)="selectLocation($event.value)"
                >
                  <mat-option value="">Alle Orte</mat-option>
                  <mat-option
                    *ngFor="let location of locations"
                    [value]="location"
                  >
                    {{ location }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>
          <div class="knownAddressesWrapper">
            <div class="knownAddressesTable">
              <table
                matSort
                (matSortChange)="sortData($event)"
                matSortActive="street"
                matSortDirection="asc"
                matSortDisableClear
              >
                <thead>
                  <tr>
                    <th class="thDistrict" mat-sort-header="district">
                      Ortsteil
                    </th>
                    <th class="thPostcode" mat-sort-header="postcode">PLZ</th>
                    <th class="thStreet" mat-sort-header="street">Straße</th>
                    <th class="thHouseNumber" mat-sort-header="house_number">
                      Haus-Nr.
                    </th>
                  </tr>
                </thead>
              </table>
              <cdk-virtual-scroll-viewport
                id="viewport"
                itemSize="44"
                minBufferPx="150px"
                class="virtualScrollViewport"
                style="height: calc(100% - 51px)"
              >
                <table
                  matSort
                  selectableTable
                  [multiSelection]="false"
                  [outerClickExcepions]="[
                    dialogActions,
                    alphabeticalScrollWrapper,
                    tabHeader
                  ]"
                  [dataArray]="this.knownAddresses.sortedData"
                  (selectedItemsOutput)="setSelectedItem($event)"
                >
                  <tbody #tbody class="noselect">
                    <tr
                      *cdkVirtualFor="
                        let item of this.knownAddresses.sortedData;
                        let i = index;
                        let odd = even
                      "
                      [id]="
                        firstLetter(
                          this.knownAddresses.sortedData[i][sorting]
                        )
                      "
                      [attr.selectable-table-data-target]="item.id"
                      enterTheViewportNotifier
                      (visibilityChange)="inViewport($event)"
                      [class]="selectedItem.includes(item) ? 'selected' : ''"
                      [ngClass]="{ trOdd: odd }"
                    >
                      <td class="tdDistrict">
                        {{ item.district ? item.district : "--" }}
                      </td>
                      <td class="tdPostcode">
                        {{ item.postcode ? item.postcode : "--" }}
                      </td>
                      <td class="tdStreet">
                        {{ item.street ? item.street : "--" }}
                      </td>
                      <td class="tdHouseNumber">
                        {{ item.house_number ? item.house_number : "--" }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </cdk-virtual-scroll-viewport>
            </div>
            <div #alphabeticalScrollWrapper class="alphabeticalScrollbar">
              <app-alphabetical-scrollbar
                [disabledLetters]="this.disabledLetters"
                [activeLetter]="this.activeLetter"
                (scroll)="scrollTo($event)"
              ></app-alphabetical-scrollbar>
            </div>
          </div>
        </div>
      </mat-tab>
      <mat-tab label="Neue Adresse">
        <mat-card [formGroup]="addressForm" style="margin: 10px 12px 0 0;" [ngClass]="{ testInvalid: addressDuplicate ? true : null }">
          <app-row web style="height: 100%">
            <app-column web="12" class="paddingBottom">
              <mat-form-field
                floatLabel="always"
                class="fullWidth"
                appearance="fill"
              >
                <mat-label>Straße</mat-label>
                <input title="" matInput formControlName="street" required />
                <mat-error
                  *ngIf="addressForm.get('street').hasError('required')"
                >
                  Erforderlich
                </mat-error>
                <button
                  tabindex="-1"
                  *ngIf="addressForm.get('street').value"
                  matSuffix
                  mat-icon-button
                  aria-label="Clear"
                  (click)="this.addressForm.patchValue({ street: '' })"
                >
                  <mat-icon>close</mat-icon>
                </button>
              </mat-form-field>
            </app-column>
            <app-column web="12" class="paddingBottom">
              <mat-form-field
                floatLabel="always"
                class="fullWidth"
                appearance="fill"
              >
                <mat-label>Hausnr.</mat-label>
                <input matInput formControlName="house_number" />
                <button
                  tabindex="-1"
                  *ngIf="addressForm.get('house_number').value"
                  matSuffix
                  mat-icon-button
                  aria-label="Clear"
                  (click)="this.addressForm.patchValue({ house_number: '' })"
                >
                  <mat-icon>close</mat-icon>
                </button>
              </mat-form-field>
            </app-column>
            <app-column web="12" class="paddingBottom">
              <mat-form-field
                floatLabel="always"
                class="fullWidth"
                appearance="fill"
              >
                <mat-label>PLZ</mat-label>
                <input title="" matInput formControlName="postcode" required />
                <mat-error
                  *ngIf="addressForm.get('postcode').hasError('required')"
                >
                  Erforderlich
                </mat-error>
                <mat-error
                  *ngIf="addressForm.get('postcode').hasError('typeNumber')"
                >
                  Zahl erforderlich
                </mat-error>
                <button
                  tabindex="-1"
                  *ngIf="addressForm.get('postcode').value"
                  matSuffix
                  mat-icon-button
                  aria-label="Clear"
                  (click)="this.addressForm.patchValue({ postcode: '' })"
                >
                  <mat-icon>close</mat-icon>
                </button>
              </mat-form-field>
            </app-column>
            <app-column web="12" class="paddingBottom">
              <mat-form-field
                floatLabel="always"
                class="fullWidth"
                appearance="fill"
              >
                <mat-label>Ort</mat-label>
                <input title="" matInput formControlName="location" required />
                <mat-error
                  *ngIf="addressForm.get('location').hasError('required')"
                >
                  Erforderlich
                </mat-error>
                <button
                  tabindex="-1"
                  *ngIf="addressForm.get('location').value"
                  matSuffix
                  mat-icon-button
                  aria-label="Clear"
                  (click)="this.addressForm.patchValue({ location: '' })"
                >
                  <mat-icon>close</mat-icon>
                </button>
              </mat-form-field>
            </app-column>
            <app-column web="12" class="paddingBottom">
              <mat-form-field
                floatLabel="always"
                class="fullWidth"
                appearance="fill"
              >
                <mat-label>Ortsteil</mat-label>
                <input matInput formControlName="district" />
                <button
                  tabindex="-1"
                  *ngIf="addressForm.get('district').value"
                  matSuffix
                  mat-icon-button
                  aria-label="Clear"
                  (click)="this.addressForm.patchValue({ district: '' })"
                >
                  <mat-icon>close</mat-icon>
                </button>
              </mat-form-field>
            </app-column>
            <app-column web="12">
              <mat-form-field
                floatLabel="always"
                class="fullWidth"
                appearance="fill"
              >
                <mat-label>Land</mat-label>
                <input title="" matInput formControlName="country" required />
                <mat-error
                  *ngIf="addressForm.get('country').hasError('required')"
                >
                  Erforderlich
                </mat-error>
                <button
                  tabindex="-1"
                  *ngIf="addressForm.get('country').value"
                  matSuffix
                  mat-icon-button
                  aria-label="Clear"
                  (click)="this.addressForm.patchValue({ country: '' })"
                >
                  <mat-icon>close</mat-icon>
                </button>
              </mat-form-field>
            </app-column>
          </app-row>
          <mat-error *ngIf="addressDuplicate" class="addressDuplicate">
            Die Adresse ist bereits vorhanden.
          </mat-error>
        </mat-card>
      </mat-tab>
    </mat-tab-group>
  </app-column>
  <app-column web="6" class="col2">
    <div class="mapHeader"></div>
    <div class="mapContentWrapper" style="height: calc(100% - 155px);">
      <app-map-picker
        [inputAddress]="mapPickerAddressInput"
        [showAddressTakeOverSubject]="showAddressTakeOverSubject"
        [mapHeader]="mapHeader"
        (AddressEvent)="selectAddress($event)"
        (coordinateEvent)="selectCoordinate($event)"
        (errorEvent)="null"
      ></app-map-picker>
      <mat-card style="margin-top: 10px;">
        <app-row web [formGroup]="currentAddress.get('coordinate')" class="coords">
          <app-column web="4">
            <mat-form-field floatLabel="always" class="fullWidth" appearance="fill">
              <mat-label>Longitude</mat-label>
              <input
                title=""
                matInput
                formControlName="longitude"
                readonly
                required
              />
              <button
                tabindex="-1"
                *ngIf="currentAddress.get('coordinate').get('longitude').value"
                matSuffix
                mat-icon-button
                aria-label="Clear"
                (click)="
                  currentAddress.get('coordinate').patchValue({
                    latitude: 0,
                    longitude: 0
                  });
                  this.mapPickerAddressInput.next()
                "
              >
                <mat-icon>close</mat-icon>
              </button>
            </mat-form-field>
          </app-column>
          <app-column web="4">
            <mat-form-field floatLabel="always" class="fullWidth" appearance="fill">
              <mat-label>Latitude</mat-label>
              <input
                title=""
                matInput
                formControlName="latitude"
                readonly
                required
              />
              <button
                tabindex="-1"
                *ngIf="currentAddress.get('coordinate').get('latitude').value"
                matSuffix
                mat-icon-button
                aria-label="Clear"
                (click)="
                  currentAddress.get('coordinate').patchValue({
                    latitude: 0,
                    longitude: 0
                  });
                  this.mapPickerAddressInput.next()
                "
              >
                <mat-icon>close</mat-icon>
              </button>
            </mat-form-field>
          </app-column>
          <app-column web="4">
            <button
              mat-raised-button
              (click)="showOnMap()"
              matTooltip="Koordinaten aus Addresse ermitteln"
              matTooltipShowDelay="1000"
              class="getCoords"
            >
              Koordinaten ermitteln
            </button>
          </app-column>
        </app-row>
      </mat-card>
    </div>
  </app-column>
</app-row>
