import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { FormGroup, FormControl, Validators, FormsModule } from '@angular/forms';

@Component({
  selector: 'app-add-picture-dialog',
  templateUrl: './add-picture-dialog.component.html',
  styleUrls: ['./add-picture-dialog.component.sass']
})
export class AddPictureDialogComponent implements OnInit {

  pictureForm: FormGroup;
  pictureFile: string;
  pictureText: string;

  pictureData: any;
  pictureComment: string;

  picturePreview: any;
  pictureName: string = "...";
  errorMessage: string;
  allowNote: Boolean = false;

  constructor(
    private dialogRef: MatDialogRef<AddPictureDialogComponent>,
    @Inject(MAT_DIALOG_DATA) data ) { }

  ngOnInit() {
    this.pictureForm = new FormGroup({
      pictureFile: new FormControl(null,[Validators.required]), 
      pictureText: new FormControl()
    });
  }

  onImageSelected(event) {
    let file = event.target.files[0];

    if (file.length === 0) {
      console.log("File zu klein");
      this.errorMessage = "Die Datei ist ungültig";
      this.allowNote = false;
      this.pictureForm.reset();
      this.picturePreview = null;
      this.pictureName = "...";
      return;
    }

    let mimeType = file.type;
    if (mimeType.match(/image\/*/) == null ) {
      this.errorMessage = "Nur Bilder sind erlaubt";
      this.allowNote = false;
      this.pictureForm.reset();
      this.picturePreview = null;
      this.pictureName = "...";
      return;
    } else {
      this.errorMessage = null;
      this.pictureName = file.name;
      this.allowNote = true;
    }

    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = (_event) => {
      this.picturePreview = reader.result;
      this.pictureData = file;
    }
  }
  
  onPictureTextChanged(event) {
    // Hier könnte man bei Gelegenheit noch eine genaue Überprüfung durchführen,
    // da einfach alles zugelassen wird. Ich hoffe aber, da der Nutzer 
    // authorisiert ist, dass er keinen Mist baut. 
    this.pictureComment = event.target.value;
  }


  hasPicture() {
    return false;
  }


  save() {
    this.dialogRef.close({file: this.pictureData, comment: this.pictureComment});
  }

  close() {
    this.dialogRef.close(false);
  }

}
