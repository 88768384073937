import { Injectable } from "@angular/core";
import { Query, QueryRef } from "apollo-angular";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { NewGraphQLService } from "../../new-graphql.service";

@Injectable({
  providedIn: "root",
})
export class TreeDamageListingService {
  private graphqlObject: string = "tree_damage_listing";

  private queryRef: QueryRef<Query>;
  private treeDamageListingDataObservable: Observable<any>;

  private singleTreeDamageListingObservables: object = {};
  private singleTreeDamageListingQueryRefs: object = {};

  constructor(private newGraphQLService: NewGraphQLService) {}

  getAllTreeDamageListingsData() {
    // create only new stream if not already exist
    if (!this.treeDamageListingDataObservable) {
      this.queryRef = this.newGraphQLService.requestAllData(
        this.graphqlObject
      );

      this.treeDamageListingDataObservable = this.queryRef.valueChanges.pipe(
        map((result: any) => {
          return result.data.Tree_Damage_Listing;
        })
      );
    }

    return this.treeDamageListingDataObservable;
  }

  getSingleTreeDamageListing(id) {
    // create only new stream if not already exist
    if (!this.singleTreeDamageListingObservables[id]) {
      this.singleTreeDamageListingQueryRefs[id] =
        this.newGraphQLService.getSingleData(this.graphqlObject, {
          id: id,
        });

      this.singleTreeDamageListingObservables[id] = this.singleTreeDamageListingQueryRefs[
        id
      ].valueChanges.pipe(
        map((result: any) => {
          return result.data.Tree_Damage_Listing;
        })
      );
    }

    return this.singleTreeDamageListingObservables[id];
  }
}
