import { Component, Inject, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { DialogBase } from 'src/app/baseclasses/dialog-base/dialog-base';
import {
  FormGroup,
  FormControl,
  Validators
} from "@angular/forms";

@Component({
  selector: 'app-erwerbsart-dialog',
  templateUrl: './erwerbsart-dialog.component.html',
  styleUrls: ['./erwerbsart-dialog.component.sass']
})
export class ErwerbsartDialogComponent extends DialogBase implements OnInit{

  form: FormGroup;
  defaultValue = "";
  defaultSorting = 0;

  constructor( 
    dialogRef: MatDialogRef<ErwerbsartDialogComponent>,
    @Inject(MAT_DIALOG_DATA) data) {
      super(dialogRef, data);
      console.log(this.currentValues);
  }

  ngOnInit() {
    this.form = new FormGroup({
      value: new FormControl((this.currentValues? this.currentValues["value"] : this.defaultValue), [Validators.required]),
      sorting: new FormControl((this.currentValues? this.currentValues["sorting"] : this.defaultSorting)),
      type: new FormControl("Inventory__Acquisition_Type")
    });

    if (this.currentValues) {
      this.form.addControl("id", new FormControl(this.currentValues.id));
    }
  }
}
