import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { GraphqlService } from 'src/app/services/graphql.service';
import { CheckUndefinedPipe } from 'src/app/pipes/check-undefined.pipe'; 


@Component({
  selector: 'app-edit-note',
  templateUrl: './edit-note.component.html',
  styleUrls: ['./edit-note.component.sass']
})
export class EditNoteComponent implements OnInit {

  @Input() baseQuery: any;
  @Input() picturesQuery: any;
  @Input() updateQuery: any;
  @Input() pictureID: any;
  @Input() noteValue: any;

  textAreaForm: FormGroup;
  pictureNote: string;
  editMode = false;
  currentNoteValue: string;

  constructor(private graphQLService: GraphqlService) { }

  ngOnInit(): void {
    this.currentNoteValue = this.noteValue;
    
    this.textAreaForm = new FormGroup({
      pictureNote: new FormControl({value: this.noteValue, disabled: true})
    });
  }

  editPhotoNote() {
    this.editMode = true; 
    this.textAreaForm.controls["pictureNote"].enable();
  }

  onPictureTextChanged(event) {
    // Hier könnte man bei Gelegenheit noch eine genaue Überprüfung durchführen,
    // da einfach alles zugelassen wird. Ich hoffe aber, da der Nutzer 
    // authorisiert ist, dass er keinen Mist baut. 
    this.pictureNote = event.target.value;
  }

  acceptChange() {
    this.editMode = false; 
    this.textAreaForm.controls["pictureNote"].disable();

    let variableObject = {
      id: this.pictureID,
      note: this.pictureNote
    }
    this.graphQLService.sendMutation(this.updateQuery, variableObject, this.baseQuery, this.picturesQuery);
  }

  dropChange() {
    this.editMode = false; 
    this.textAreaForm.controls["pictureNote"].patchValue(this.noteValue);
    this.textAreaForm.controls["pictureNote"].disable();
  }

}
