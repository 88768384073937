import { NgModule } from "@angular/core";
import { GlobalModulesModule } from "src/app/global-modules.module";
import { CustomDialogboxModule } from "../dialog-boxes/custom-dialogbox.module";
import { ShowThumbnailModule } from "../functions/show-thumbnail/showThumbnail.module";
import { ImageDiashowComponent } from "./image-diashow/image-diashow.component";
import { ImageViewComponent } from "./image-view.component";


@NgModule({
  declarations: [
    ImageViewComponent,
    ImageDiashowComponent,
  ],
  imports: [GlobalModulesModule, CustomDialogboxModule, ShowThumbnailModule],
  exports: [
    ImageViewComponent,
    ImageDiashowComponent,
  ],
})
export class ImageViewModule {}
