import { Directive, ElementRef, EventEmitter, HostListener, Input, Output } from '@angular/core';

/** 
 * Directive um Zeilen in einer Tabelle auswählbar zu machen.
 * 
 * mit "dataArray" gesamtes Array aller Daten übergeben
 * 
 * tr's mit Attribut "selectable-table-data-target" versehen um Daten im "dataArray" zu lokalisieren
 * 
 * Rückgabe der ausgewählten Zeilen über "selectedItemsOutput"
 * 
 * um Reset-Außnahmen bei Klick außerhalb der Tabelle hin zu zufügen outerClickExcepions nutzen
*/
@Directive({
  selector: 'table[selectableTable]',
})
export class SelectableTableDirective {

  @Input() outerClickExcepions?: Array<any> = [];
  @Input() dataArray: Array<any> = [];
  @Input() multiSelection: boolean = true;
  @Output() selectedItemsOutput: EventEmitter<any> = new EventEmitter();

  private firstShiftClickRow;
  private selectedItems = [];

  constructor(private el: ElementRef) {
  }

  @HostListener('document:mousedown', ['$event'])
  onGlobalClick(event): void {
    let clickedElement = event.target;
    let tbody = this.el.nativeElement.children[0];
    if (!tbody.contains(clickedElement)) {
      let clickException: boolean = false;
      
      this.outerClickExcepions.every(element => {
        if(!element){
          clickException = true;
          return false;
        }
        if(element.contains(clickedElement)){
          clickException = true;
          return false;
        }
        return true;
      });

      if(!clickException){
        this.selectedItems=[];
        this.firstShiftClickRow=undefined;
        this.selectedItemsOutput.emit(this.selectedItems);
      }
    }
  }

  @HostListener('mousedown', ['$event'])
  onClick(event: any): void {
    let clickedTr = event.target.parentElement;
    let id = clickedTr.getAttribute('selectable-table-data-target');
    let item = this.dataArray.filter(element=>{return element.id == id})[0];
    
    if(this.multiSelection){
      if(event.shiftKey){
        if(!this.firstShiftClickRow){
          this.selectedItems = [item];
        }else{
          let firstShiftClickRowIndex = this.dataArray.indexOf(this.firstShiftClickRow);
          let itemIndex = this.dataArray.indexOf(item);
          if(firstShiftClickRowIndex < itemIndex){
            this.selectedItems = this.dataArray.slice(firstShiftClickRowIndex, itemIndex+1);
          }else{
            this.selectedItems = this.dataArray.slice(itemIndex, firstShiftClickRowIndex+1);
          }
        }
        if(!this.firstShiftClickRow){this.firstShiftClickRow=item;}
      }else{
        if(event.ctrlKey){
          let index = this.selectedItems.indexOf(item);
          if( index > -1){
            this.selectedItems.splice(index, 1);
          }else{
            this.selectedItems.push(item);
            this.firstShiftClickRow=item;
          }
        }else{
          this.selectedItems = [item];
          this.firstShiftClickRow=item;
        }
      }
    }else{
      this.selectedItems = [item];
    }

    // console.log(this.selectedItems);
    this.selectedItemsOutput.emit(this.selectedItems);
  }


}
