import { Injectable } from "@angular/core";
import { Query, QueryRef } from "apollo-angular";
import { Observable, Observer, Subject, merge } from "rxjs";
import { first, map, mergeAll, scan } from "rxjs/operators";
import { NewGraphQLService } from "../new-graphql.service";

@Injectable({
  providedIn: "root",
})
export class PlaygroundDataService {
  private graphqlObject: string = "playground";

  private defaultQueryVariables = {
    onlyNewestInspection: true,
    limit: 100,
    offset: 0,
    args: {
      sort: null,
      filter: null,
    },
  };

  private queryVariables = {
    onlyNewestInspection: true,
    limit: 100,
    offset: 0,
    args: {
      sort: null,
      filter: null,
    },
  };

  private offset: number = this.queryVariables.offset;
  private queryRef: QueryRef<Query>;
  private playgroundDataObservable: Observable<any>;

  private manuallyUpdate = new Subject();

  constructor(private newGraphQLService: NewGraphQLService) {}

  setFilter(filter: {
    address: { location: string; district: string; street: string }[];
  }) {
    if (filter.address.length === 0) {
      this.manuallyUpdate.next([]);
      this.queryRef.resetLastResults();
    } else {
      this.queryVariables.args.filter = filter;
      this.queryRef.refetch();
    }
  }

  getAllPlaygroundData() {
    // create only new stream if not already exist
    if (!this.playgroundDataObservable) {
      this.queryRef = this.newGraphQLService.requestAllData(
        this.graphqlObject,
        this.queryVariables
      );

      this.playgroundDataObservable = this.queryRef.valueChanges.pipe(
        map((result: any) => {
          this.offset = result.data.Playground.length;
          return result.data.Playground;
        })
      );
    }

    return merge(this.playgroundDataObservable, this.manuallyUpdate);
  }

  // getSinglePlaygroundData(playgroundId) {
  //   console.log("getSinglePlaygroundData()");
  // }

  addPlayground(playgroundData: object) {
    let queryRef = this.newGraphQLService.addData(
      this.graphqlObject,
      playgroundData,
      this.defaultQueryVariables
    );

    let subscription = queryRef.subscribe((result) => {
      subscription.unsubscribe();
    });
  }

  updatePlaygroundData(playgroundData: object) {
    let queryRef = this.newGraphQLService.updateData(
      this.graphqlObject,
      playgroundData,
      this.defaultQueryVariables
    );

    return queryRef.pipe(
      first(),
      map((result: any) => {
        return result.data.update_Playground;
      })
    );
  }

  deletePlayground(playgroundId: number) {
    let queryRef = this.newGraphQLService.deleteData(
      this.graphqlObject,
      playgroundId,
      null,
      false
    );

    return queryRef.pipe(
      first(),
      map((result: any) => {
        return result.data.delete_Playground;
      })
    );
  }

  loadMore() {
    this.newGraphQLService.loadmoreData(this.queryRef, this.offset);
  }
}
