import { Component, OnInit, Input } from "@angular/core";
import { HttpService } from "src/app/services/http.service";

@Component({
  selector: "app-show-thumbnail",
  templateUrl: "./show-thumbnail.component.html",
  styleUrls: ["./show-thumbnail.component.sass"]
})
export class ShowThumbnailComponent implements OnInit{
  thumbnail: any;

  @Input() thumbnailID: any;

  constructor(private httpService: HttpService) {}

  ngOnInit(): void {
    if (this.thumbnailID !== null && this.thumbnailID !== undefined) {
      this.thumbnail = this.httpService.buildImageLink(this.thumbnailID, true);
    } else {
      this.thumbnail = null;
    }
  }
}
