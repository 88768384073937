import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-fahrtenbuch',
  templateUrl: './fahrtenbuch.component.html',
  styleUrls: ['./fahrtenbuch.component.sass']
})
export class FahrtenbuchComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

  scrollEvent(event) {
    console.log(event);
  }
}
