import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import gql from "graphql-tag";

export const getAllPlaygroundData = gql`
  query getAll(
    $offset: Int, 
    $limit: Int, 
    $args: Arguments,
    $onlyNewestInspection: Boolean!
  ) {
    Playground(offset: $offset, limit: $limit, args: $args)  @connection(key: "playgrounds") {
      id
      photo(onlyNewest: false) {
        id
        thumbnail
        timestamp
        path
        file
      }
      photo_count
      inspection(onlyNewest: $onlyNewestInspection) {
        id
        date
        playground {
          id
        }
        time
        type
        person1
        person2
        result
        inventory_inspection {
          id
        }
      }
      inventory_items {
        id,
        description
      }
      number
      description
      category
      double_entry
      control_group {
        id
        subject {
          id,
          shortcut,
          description,
          note,
          creation_date,
          creation_user,
          modify_date,
          modify_user,
          canDelete,
        }
        number
        name
        creation_date
        creation_user
        modify_date
        modify_user
        canDelete
      }
      note
      commissioning_date
      decommissioning_date
      address {
        id
        country
        postcode
        location
        district
        street
        house_number
        latitude
        longitude
      }
      location
      area
      enclosure
      soil
      planting
      supplier {
        id,
        number,
        name1,
        name2,
        address {
            id,
            country,
            postcode,
            location,
            district,
            street,
            house_number,
            latitude,
            longitude,
        },
        note,
        creation_date,
        creation_user,
        modify_date,
        modify_user,
        canDelete,
      }
      geometry {
        id,
        type,
        geometry,
        creation_date,
        creation_user,
        modify_date,
        modify_user,
        canDelete,
      }
      coordinate{
        id,
        longitude,
        latitude,
        altitude,
        timestamp,
        creation_date,
        creation_user,
        modify_date,
        modify_user,
        canDelete
      },
      creation_date
      creation_user
      modify_date
      modify_user
    }
  }
`;

export const getSinglePlaygroundData = gql`
  query getSinglePlaygroundData(
    $id: Int
    $onlyNewestInspection: Boolean!
    $onlyNewestInventoryPhoto: Boolean!
  ) {
    Playground(id: $id) {
      id
      photo(onlyNewest: false) {
        id
        thumbnail
        timestamp
        path
        file
      }
      photo_count
      inspection(onlyNewest: $onlyNewestInspection) {
        id
        date
        playground {
          id
        }
        time
        type
        person1
        person2
        result
        inventory_inspection {
          id
        }
      }
      inventory_items {
        id
      }
      number
      description
      category
      double_entry
      control_group {
        id
        subject {
          id,
          shortcut,
          description,
          note,
          creation_date,
          creation_user,
          modify_date,
          modify_user,
          canDelete,
        }
        number
        name
        creation_date
        creation_user
        modify_date
        modify_user
        canDelete
      }
      note
      commissioning_date
      decommissioning_date
      address {
        id
        country
        postcode
        location
        district
        street
        house_number
        latitude
        longitude
      }
      location
      area
      enclosure
      soil
      planting
      supplier {
        id,
        number,
        name1,
        name2,
        address {
            id,
            country,
            postcode,
            location,
            district,
            street,
            house_number,
            latitude,
            longitude,
        },
        note,
        creation_date,
        creation_user,
        modify_date,
        modify_user,
        canDelete,
      }
      geometry {
        id,
        type,
        geometry,
        creation_date,
        creation_user,
        modify_date,
        modify_user,
        canDelete,
      }
      coordinate{
        id,
        longitude,
        latitude,
        altitude,
        timestamp,
        creation_date,
        creation_user,
        modify_date,
        modify_user,
        canDelete
      },
      creation_date
      creation_user
      modify_date
      modify_user
    }
  }
`;

// TODO: Löschen!!!
export const addPlaygroundDataQuery = gql`
  mutation add_Playground(
    $number: String
    $description: String
    $category: String
    $double_entry: String
    $control_group: Int
    $note: String
    $commissioning_date: String
    $decommissioning_date: String
    $address: Int
    $location: String
    $area: Float
    $enclosure: Int
    $soil: String
    $planting: String
    $supplier: Int
    $geometry: Int
    $coordinate: Int
  ) {
    add_Playground(
      number: $number
      description: $description
      category: $category
      double_entry: $double_entry
      control_group: $control_group
      note: $note
      commissioning_date: $commissioning_date
      decommissioning_date: $decommissioning_date
      address: $address
      location: $location
      area: $area
      enclosure: $enclosure
      soil: $soil
      planting: $planting
      supplier: $supplier
      geometry: $geometry
      coordinate: $coordinate
    ){
      result,
      error
    }
  }
`;

export const addPlaygroundData = gql`
  mutation add_Playground(
    $number: String
    $description: String
    $category: String
    $double_entry: String
    $control_group: Int
    $note: String
    $commissioning_date: String
    $decommissioning_date: String
    $address: Int
    $location: String
    $area: Float
    $enclosure: Int
    $soil: String
    $planting: String
    $supplier: Int
    $geometry: Int
    $coordinate: Int
  ) {
    add_Playground(
      number: $number
      description: $description
      category: $category
      double_entry: $double_entry
      control_group: $control_group
      note: $note
      commissioning_date: $commissioning_date
      decommissioning_date: $decommissioning_date
      address: $address
      location: $location
      area: $area
      enclosure: $enclosure
      soil: $soil
      planting: $planting
      supplier: $supplier
      geometry: $geometry
      coordinate: $coordinate
    ){
      result,
      error
    }
  }
`;

// TODO: Löschen!!!
export const updatePlaygroundDataQuery = gql`
  mutation update_Playground(
    $id: Int!
    $number: String
    $description: String
    $category: String
    $double_entry: String
    $control_group: Int
    $note: String
    $commissioning_date: String
    $decommissioning_date: String
    $address: Int
    $location: String
    $area: Float
    $enclosure: Int
    $soil: String
    $planting: String
    $supplier: Int
    $geometry: Int
    $coordinate: Int
  ) {
    update_Playground(
      id: $id
      number: $number
      description: $description
      category: $category
      double_entry: $double_entry
      control_group: $control_group
      note: $note
      commissioning_date: $commissioning_date
      decommissioning_date: $decommissioning_date
      address: $address
      location: $location
      area: $area
      enclosure: $enclosure
      soil: $soil
      planting: $planting
      supplier: $supplier
      geometry: $geometry
      coordinate: $coordinate
    ){
      result,
      error
    }
  }
`;

export const updatePlaygroundData = gql`
  mutation update_Playground(
    $id: Int!
    $number: String
    $description: String
    $category: String
    $double_entry: String
    $control_group: Int
    $note: String
    $commissioning_date: String
    $decommissioning_date: String
    $address: Int
    $location: String
    $area: Float
    $enclosure: Int
    $soil: String
    $planting: String
    $supplier: Int
    $geometry: Int
    $coordinate: Int
  ) {
    update_Playground(
      id: $id
      number: $number
      description: $description
      category: $category
      double_entry: $double_entry
      control_group: $control_group
      note: $note
      commissioning_date: $commissioning_date
      decommissioning_date: $decommissioning_date
      address: $address
      location: $location
      area: $area
      enclosure: $enclosure
      soil: $soil
      planting: $planting
      supplier: $supplier
      geometry: $geometry
      coordinate: $coordinate
    ){
      result,
      error
    }
  }
`;

export const deletePlaygroundData = gql`
  mutation delete_Playground($id: Int!) {
    delete_Playground(id: $id)
  }
`;

// ****************************************************************
// Special Case
// ****************************************************************

// TODO: Löschen!!!
export const getPlaygroundPhotosOnlyQuery = gql`
  query getPlaygroundPhotosOnly($id: Int) {
    Playground(id: $id) {
      id
      photo(onlyNewest: false) {
        id
        thumbnail
        timestamp
        path
        file
        note
        creation_user
        creation_date
        modify_date
        modify_user
      }
      photo_count
    }
  }
`;

export const getPlaygroundPhotosOnly = gql`
  query getPlaygroundPhotosOnly($id: Int) {
    Playground(id: $id) {
      id
      photo(onlyNewest: false) {
        id
        thumbnail
        timestamp
        path
        file
        note
        creation_user
        creation_date
        modify_date
        modify_user
      }
      photo_count
    }
  }
`;
// ****************************************************************

export const getAllPlaygroundPhotos = gql`
  query Playground_Photo {
    Playground_Photo {
      id
      playground {
        id
        photo(onlyNewest: false) {
          id
          thumbnail
          timestamp
          path
          file
        }
        photo_count
        inspection(onlyNewest: $onlyNewestInspection) {
          id
          date
          playground {
            id
          }
          time
          type
          person1
          person2
          result
          inventory_inspection {
            id
          }
        }
        inventory_items {
          id
        }
        number
        description
        category
        double_entry
        control_group {
          id
          subject {
            id,
            shortcut,
            description,
            note,
            creation_date,
            creation_user,
            modify_date,
            modify_user,
            canDelete,
          }
          number
          name
          creation_date
          creation_user
          modify_date
          modify_user
          canDelete
        }
        note
        commissioning_date
        decommissioning_date
        address {
          id
          country
          postcode
          location
          district
          street
          house_number
          latitude
          longitude
        }
        location
        area
        enclosure
        soil
        planting
        supplier {
          id,
          number,
          name1,
          name2,
          address {
              id,
              country,
              postcode,
              location,
              district,
              street,
              house_number,
              latitude,
              longitude,
          },
          note,
          creation_date,
          creation_user,
          modify_date,
          modify_user,
          canDelete,
        }
        geometry {
          id,
          type,
          geometry,
          creation_date,
          creation_user,
          modify_date,
          modify_user,
          canDelete,
        }
        coordinate{
          id,
          longitude,
          latitude,
          altitude,
          timestamp,
          creation_date,
          creation_user,
          modify_date,
          modify_user,
          canDelete
        },
        creation_date
        creation_user
        modify_date
        modify_user  
      }
      photo {
        id,
        thumbnail,
        path,
        file,
        timestamp,
        note,
        creation_date,
        creation_user,
        modify_date,
        modify_user,
        canDelete,
      }
      creation_date
      creation_user
      modify_date
      modify_user
      canDelete
    }
  }
`;

export const getSinglePlaygroundPhoto = gql`
  query Playground_Photo($id: Int!) {
    Playground_Photo(id: $id) {
      id
      playground {
        id
        photo(onlyNewest: false) {
          id
          thumbnail
          timestamp
          path
          file
        }
        photo_count
        inspection(onlyNewest: $onlyNewestInspection) {
          id
          date
          playground {
            id
          }
          time
          type
          person1
          person2
          result
          inventory_inspection {
            id
          }
        }
        inventory_items {
          id
        }
        number
        description
        category
        double_entry
        control_group {
          id
          subject {
            id,
            shortcut,
            description,
            note,
            creation_date,
            creation_user,
            modify_date,
            modify_user,
            canDelete,
          }
          number
          name
          creation_date
          creation_user
          modify_date
          modify_user
          canDelete
        }
        note
        commissioning_date
        decommissioning_date
        address {
          id
          country
          postcode
          location
          district
          street
          house_number
          latitude
          longitude
        }
        location
        area
        enclosure
        soil
        planting
        supplier {
          id,
          number,
          name1,
          name2,
          address {
              id,
              country,
              postcode,
              location,
              district,
              street,
              house_number,
              latitude,
              longitude,
          },
          note,
          creation_date,
          creation_user,
          modify_date,
          modify_user,
          canDelete,
        }
        geometry {
          id,
          type,
          geometry,
          creation_date,
          creation_user,
          modify_date,
          modify_user,
          canDelete,
        }
        coordinate{
          id,
          longitude,
          latitude,
          altitude,
          timestamp,
          creation_date,
          creation_user,
          modify_date,
          modify_user,
          canDelete
        },
        creation_date
        creation_user
        modify_date
        modify_user  
      }
      photo {
        id,
        thumbnail,
        path,
        file,
        timestamp,
        note,
        creation_date,
        creation_user,
        modify_date,
        modify_user,
        canDelete,
      }
      creation_date
      creation_user
      modify_date
      modify_user
      canDelete
    }
  }
`;

// TODO: Löschen!!!
export const addPlaygroundPhotoQuery = gql`
  mutation add_Playground_Photo($playground: Int, $photo: Int) {
    add_Playground_Photo(playground: $playground, photo: $photo){
      result,
      error
    }
  }
`;

export const addPlaygroundPhoto = gql`
  mutation add_Playground_Photo($playground: Int, $photo: Int) {
    add_Playground_Photo(playground: $playground, photo: $photo){
      result,
      error
    }
  }
`;

export const updatePlaygroundPhoto = gql`
  mutation update_Playground_Photo($id: Int!, $playground: Int, $photo: Int) {
    update_Playground_Photo(id: $id, playground: $playground, photo: $photo){
      result,
      error
    }
  }
`;

// TODO: Löschen
export const deletePlaygroundPhotoQuery = gql`
  mutation delete_Photo($id: Int!) {
    delete_Photo(id: $id){
      result,
      error
    }
  }
`;

export const deletePlaygroundPhoto = gql`
  mutation delete_Photo($id: Int!) {
    delete_Photo(id: $id){
      result,
      error
    }
  }
`;

export const getAllPlaygroundInspections = gql`
  query Playground_Inspection($args: Arguments) {
    Playground_Inspection(args: $args) {
      inventory_inspection {
        id
        playground_inspection_id
        inventory_id
        state
        status
        defect
        settlement_time_value
        settlement_time_unit
        settlement_date_until
        settlement_date_at
        creation_date
        creation_user
        modify_date
        modify_user
        canDelete
      }
      id
      playground {
        id
        photo(onlyNewest: false) {
          id
          thumbnail
          timestamp
          path
          file
        }
        photo_count
        inspection(onlyNewest: $onlyNewestInspection) {
          id
          date
          playground {
            id
          }
          time
          type
          person1
          person2
          result
          inventory_inspection {
            id
          }
        }
        inventory_items {
          id
        }
        number
        description
        category
        double_entry
        control_group {
          id
          subject {
            id,
            shortcut,
            description,
            note,
            creation_date,
            creation_user,
            modify_date,
            modify_user,
            canDelete,
          }
          number
          name
          creation_date
          creation_user
          modify_date
          modify_user
          canDelete
        }
        note
        commissioning_date
        decommissioning_date
        address {
          id
          country
          postcode
          location
          district
          street
          house_number
          latitude
          longitude
        }
        location
        area
        enclosure
        soil
        planting
        supplier {
          id,
          number,
          name1,
          name2,
          address {
              id,
              country,
              postcode,
              location,
              district,
              street,
              house_number,
              latitude,
              longitude,
          },
          note,
          creation_date,
          creation_user,
          modify_date,
          modify_user,
          canDelete,
        }
        geometry {
          id,
          type,
          geometry,
          creation_date,
          creation_user,
          modify_date,
          modify_user,
          canDelete,
        }
        coordinate{
          id,
          longitude,
          latitude,
          altitude,
          timestamp,
          creation_date,
          creation_user,
          modify_date,
          modify_user,
          canDelete
        },
        creation_date
        creation_user
        modify_date
        modify_user  
      }
      date
      time
      type
      person1
      person2
      result
      coordinate {
        id,
        longitude,
        latitude,
        altitude,
        timestamp,
        creation_date,
        creation_user,
        modify_date,
        modify_user,
        canDelete
      }
      creation_date
      creation_user
      modify_date
      modify_user
      canDelete
    }
  }
`;

export const getSinglePlaygroundInspection = gql`
  query Playground_Inspection($id: Int) {
    Playground_Inspection(id: $id) {
      inventory_inspection {
        id
        playground_inspection_id
        inventory_id
        state
        status
        defect
        settlement_time_value
        settlement_time_unit
        settlement_date_until
        settlement_date_at
        creation_date
        creation_user
        modify_date
        modify_user
        canDelete
      }
      id
      playground {
        id
        photo(onlyNewest: false) {
          id
          thumbnail
          timestamp
          path
          file
        }
        photo_count
        inspection(onlyNewest: $onlyNewestInspection) {
          id
          date
          playground {
            id
          }
          time
          type
          person1
          person2
          result
          inventory_inspection {
            id
          }
        }
        inventory_items {
          id
        }
        number
        description
        category
        double_entry
        control_group {
          id
          subject {
            id,
            shortcut,
            description,
            note,
            creation_date,
            creation_user,
            modify_date,
            modify_user,
            canDelete,
          }
          number
          name
          creation_date
          creation_user
          modify_date
          modify_user
          canDelete
        }
        note
        commissioning_date
        decommissioning_date
        address {
          id
          country
          postcode
          location
          district
          street
          house_number
          latitude
          longitude
        }
        location
        area
        enclosure
        soil
        planting
        supplier {
          id,
          number,
          name1,
          name2,
          address {
              id,
              country,
              postcode,
              location,
              district,
              street,
              house_number,
              latitude,
              longitude,
          },
          note,
          creation_date,
          creation_user,
          modify_date,
          modify_user,
          canDelete,
        }
        geometry {
          id,
          type,
          geometry,
          creation_date,
          creation_user,
          modify_date,
          modify_user,
          canDelete,
        }
        coordinate{
          id,
          longitude,
          latitude,
          altitude,
          timestamp,
          creation_date,
          creation_user,
          modify_date,
          modify_user,
          canDelete
        },
        creation_date
        creation_user
        modify_date
        modify_user  
      }
      date
      time
      type
      person1
      person2
      result
      coordinate {
        id,
        longitude,
        latitude,
        altitude,
        timestamp,
        creation_date,
        creation_user,
        modify_date,
        modify_user,
        canDelete
      }
      creation_date
      creation_user
      modify_date
      modify_user
      canDelete
    }
  }
`;

export const addPlaygroundInspection = gql`
  mutation add_Playground_Inspection(
    $playground: Int
    $date: String
    $time: String
    $type: String
    $person1: String
    $person2: String
    $result: String
    $coordinate: Int
  ) {
    add_Playground_Inspection(
      playground: $playground
      date: $date
      time: $time
      type: $type
      person1: $person1
      person2: $person2
      result: $result
      coordinate: $coordinate
    ){
      result,
      error
    }
  }
`;

export const updatePlaygroundInspection = gql`
  mutation update_Playground_Inspection(
    $id: Int!
    $playground: Int
    $date: String
    $time: String
    $type: String
    $person1: String
    $person2: String
    $result: String
    $coordinate: Int
  ) {
    update_Playground_Inspection(
      id: $id
      playground: $playground
      date: $date
      time: $time
      type: $type
      person1: $person1
      person2: $person2
      result: $result
      coordinate: $coordinate
    ){
      result,
      error
    }
  }
`;

export const deletePlaygroundInspection = gql`
  mutation delete_Playground_Inspection($id: Int!) {
    delete_Playground_Inspection(id: $id){
      result,
      error
    }
  }
`;

export const getAllPlaygroundInspectionPhotos = gql`
  query Playground_Inspection_Photo {
    Playground_Inspection_Photo {
      id
      playground_inspection {
        inventory_inspection {
          id
          playground_inspection_id
          inventory_id
          state
          status
          defect
          settlement_time_value
          settlement_time_unit
          settlement_date_until
          settlement_date_at
          creation_date
          creation_user
          modify_date
          modify_user
          canDelete
        }
        id
        playground {
          id
          photo(onlyNewest: false) {
            id
            thumbnail
            timestamp
            path
            file
          }
          photo_count
          inspection(onlyNewest: $onlyNewestInspection) {
            id
            date
            playground {
              id
            }
            time
            type
            person1
            person2
            result
            inventory_inspection {
              id
            }
          }
          inventory_items {
            id
          }
          number
          description
          category
          double_entry
          control_group {
            id
            subject {
              id,
              shortcut,
              description,
              note,
              creation_date,
              creation_user,
              modify_date,
              modify_user,
              canDelete,
            }
            number
            name
            creation_date
            creation_user
            modify_date
            modify_user
            canDelete
          }
          note
          commissioning_date
          decommissioning_date
          address {
            id
            country
            postcode
            location
            district
            street
            house_number
            latitude
            longitude
          }
          location
          area
          enclosure
          soil
          planting
          supplier {
            id,
            number,
            name1,
            name2,
            address {
                id,
                country,
                postcode,
                location,
                district,
                street,
                house_number,
                latitude,
                longitude,
            },
            note,
            creation_date,
            creation_user,
            modify_date,
            modify_user,
            canDelete,
          }
          geometry {
            id,
            type,
            geometry,
            creation_date,
            creation_user,
            modify_date,
            modify_user,
            canDelete,
          }
          coordinate{
            id,
            longitude,
            latitude,
            altitude,
            timestamp,
            creation_date,
            creation_user,
            modify_date,
            modify_user,
            canDelete
          },
          creation_date
          creation_user
          modify_date
          modify_user  
        }
        date
        time
        type
        person1
        person2
        result
        coordinate {
          id,
          longitude,
          latitude,
          altitude,
          timestamp,
          creation_date,
          creation_user,
          modify_date,
          modify_user,
          canDelete
        }
        creation_date
        creation_user
        modify_date
        modify_user
        canDelete  
      }
      photo {
        id,
        thumbnail,
        path,
        file,
        timestamp,
        note,
        creation_date,
        creation_user,
        modify_date,
        modify_user,
        canDelete,
      }
      creation_date
      creation_user
      modify_date
      modify_user
      canDelete
    }
  }
`;

export const getSinglePlaygroundInspectionPhoto = gql`
  query Playground_Inspection_Photo($id: Int!) {
    Playground_Inspection_Photo(id: $id) {
      id
      playground_inspection {
        inventory_inspection {
          id
          playground_inspection_id
          inventory_id
          state
          status
          defect
          settlement_time_value
          settlement_time_unit
          settlement_date_until
          settlement_date_at
          creation_date
          creation_user
          modify_date
          modify_user
          canDelete
        }
        id
        playground {
          id
          photo(onlyNewest: false) {
            id
            thumbnail
            timestamp
            path
            file
          }
          photo_count
          inspection(onlyNewest: $onlyNewestInspection) {
            id
            date
            playground {
              id
            }
            time
            type
            person1
            person2
            result
            inventory_inspection {
              id
            }
          }
          inventory_items {
            id
          }
          number
          description
          category
          double_entry
          control_group {
            id
            subject {
              id,
              shortcut,
              description,
              note,
              creation_date,
              creation_user,
              modify_date,
              modify_user,
              canDelete,
            }
            number
            name
            creation_date
            creation_user
            modify_date
            modify_user
            canDelete
          }
          note
          commissioning_date
          decommissioning_date
          address {
            id
            country
            postcode
            location
            district
            street
            house_number
            latitude
            longitude
          }
          location
          area
          enclosure
          soil
          planting
          supplier {
            id,
            number,
            name1,
            name2,
            address {
                id,
                country,
                postcode,
                location,
                district,
                street,
                house_number,
                latitude,
                longitude,
            },
            note,
            creation_date,
            creation_user,
            modify_date,
            modify_user,
            canDelete,
          }
          geometry {
            id,
            type,
            geometry,
            creation_date,
            creation_user,
            modify_date,
            modify_user,
            canDelete,
          }
          coordinate{
            id,
            longitude,
            latitude,
            altitude,
            timestamp,
            creation_date,
            creation_user,
            modify_date,
            modify_user,
            canDelete
          },
          creation_date
          creation_user
          modify_date
          modify_user  
        }
        date
        time
        type
        person1
        person2
        result
        coordinate {
          id,
          longitude,
          latitude,
          altitude,
          timestamp,
          creation_date,
          creation_user,
          modify_date,
          modify_user,
          canDelete
        }
        creation_date
        creation_user
        modify_date
        modify_user
        canDelete  
      }
      photo {
        id,
        thumbnail,
        path,
        file,
        timestamp,
        note,
        creation_date,
        creation_user,
        modify_date,
        modify_user,
        canDelete,
      }
      creation_date
      creation_user
      modify_date
      modify_user
      canDelete
    }
  }
`;

export const getCertainPlaygroundInspectionPhoto = gql`
  query Playground_Inspection_Photo($playground_inspection: Int!) {
    Playground_Inspection_Photo(playground_inspection: $id) {
      id
      playground_inspection {
        inventory_inspection {
          id
          playground_inspection_id
          inventory_id
          state
          status
          defect
          settlement_time_value
          settlement_time_unit
          settlement_date_until
          settlement_date_at
          creation_date
          creation_user
          modify_date
          modify_user
          canDelete
        }
        id
        playground {
          id
          photo(onlyNewest: false) {
            id
            thumbnail
            timestamp
            path
            file
          }
          photo_count
          inspection(onlyNewest: $onlyNewestInspection) {
            id
            date
            playground {
              id
            }
            time
            type
            person1
            person2
            result
            inventory_inspection {
              id
            }
          }
          inventory_items {
            id
          }
          number
          description
          category
          double_entry
          control_group {
            id
            subject {
              id,
              shortcut,
              description,
              note,
              creation_date,
              creation_user,
              modify_date,
              modify_user,
              canDelete,
            }
            number
            name
            creation_date
            creation_user
            modify_date
            modify_user
            canDelete
          }
          note
          commissioning_date
          decommissioning_date
          address {
            id
            country
            postcode
            location
            district
            street
            house_number
            latitude
            longitude
          }
          location
          area
          enclosure
          soil
          planting
          supplier {
            id,
            number,
            name1,
            name2,
            address {
                id,
                country,
                postcode,
                location,
                district,
                street,
                house_number,
                latitude,
                longitude,
            },
            note,
            creation_date,
            creation_user,
            modify_date,
            modify_user,
            canDelete,
          }
          geometry {
            id,
            type,
            geometry,
            creation_date,
            creation_user,
            modify_date,
            modify_user,
            canDelete,
          }
          coordinate{
            id,
            longitude,
            latitude,
            altitude,
            timestamp,
            creation_date,
            creation_user,
            modify_date,
            modify_user,
            canDelete
          },
          creation_date
          creation_user
          modify_date
          modify_user  
        }
        date
        time
        type
        person1
        person2
        result
        coordinate {
          id,
          longitude,
          latitude,
          altitude,
          timestamp,
          creation_date,
          creation_user,
          modify_date,
          modify_user,
          canDelete
        }
        creation_date
        creation_user
        modify_date
        modify_user
        canDelete  
      }
      photo {
        id,
        thumbnail,
        path,
        file,
        timestamp,
        note,
        creation_date,
        creation_user,
        modify_date,
        modify_user,
        canDelete,
      }
      creation_date
      creation_user
      modify_date
      modify_user
      canDelete
    }
  }
`;

export const addPlaygroundInspectionPhoto = gql`
  mutation add_Playground_Inspection_Photo(
    $playground_inspection: Int
    $photo: Int
  ) {
    add_Playground_Inspection_Photo(
      playground_inspection: $playground_inspection
      photo: $photo
    ){
      result,
      error
    }
  }
`;

export const updatePlaygroundInspectionPhoto = gql`
  mutation update_Playground_Inspection_Photo(
    $id: Int!
    $playground_inspection: Int
    $photo: Int
  ) {
    update_Playground_Inspection_Photo(
      id: $id
      playground_inspection: $playground_inspection
      photo: $photo
    ){
      result,
      error
    }
  }
`;

export const deletePlaygroundInspectionPhoto = gql`
  mutation delete_Playground_Inspection_Photo($id: Int!) {
    delete_Playground_Inspection_Photo(id: $id){
      result,
      error
    }
  }
`;

export const getAllInventoryType = gql`
  query Inventory_Type {
    Inventory_Type {
      id
      category
      description
      creation_date
      creation_user
      modify_date
      modify_user
      canDelete
    }
  }
`;

export const getSingleInventoryType = gql`
  query Inventory_Type($id: Int!) {
    Inventory_Type(id: $id) {
      id
      category
      description
      creation_date
      creation_user
      modify_date
      modify_user
      canDelete
    }
  }
`;

export const addInventoryType = gql`
  mutation add_Inventory_Type($category: String, $description: String) {
    add_Inventory_Type(category: $category, description: $description){
      result,
      error
    }
  }
`;

export const updateInventoryType = gql`
  mutation update_Inventory_Type($id: Int!, $category: String, $description: String) {
    update_Inventory_Type(id: $id, category: $category, description: $description){
      result,
      error
    }
  }
`;

export const deleteInventoryType = gql`
  mutation delete_Inventory_Type($id: Int!) {
    delete_Inventory_Type(id: $id){
      result,
      error
    }
  }
`;

// Absoluter Sonderfall!
export const updateInventoryTypeCategory = gql`
  mutation update_Inventory_Type_Category(
    $oldName: String!
    $newName: String!
  ) {
    update_Inventory_Type_Category(oldName: $oldName, newName: $newName){
      result,
      error
    }
  }
`;

export const getAllInventory = gql`
  query Inventory {
    Inventory {
      photo(onlyNewest: false) {
        id
        thumbnail
        path
        file
        timestamp
        note
        creation_date
        creation_user
        modify_date
        modify_user
        canDelete
      }
      id
      description
      inventory_number
      inventory_id
      sort_number
      ident_carrier_type
      playground {
        id
        photo(onlyNewest: false) {
          id
          thumbnail
          timestamp
          path
          file
        }
        photo_count
        inspection(onlyNewest: $onlyNewestInspection) {
          id
          date
          playground {
            id
          }
          time
          type
          person1
          person2
          result
          inventory_inspection {
            id
          }
        }
        inventory_items {
          id
        }
        number
        description
        category
        double_entry
        control_group {
          id
          subject {
            id,
            shortcut,
            description,
            note,
            creation_date,
            creation_user,
            modify_date,
            modify_user,
            canDelete,
          }
          number
          name
          creation_date
          creation_user
          modify_date
          modify_user
          canDelete
        }
        note
        commissioning_date
        decommissioning_date
        address {
          id
          country
          postcode
          location
          district
          street
          house_number
          latitude
          longitude
        }
        location
        area
        enclosure
        soil
        planting
        supplier {
          id,
          number,
          name1,
          name2,
          address {
              id,
              country,
              postcode,
              location,
              district,
              street,
              house_number,
              latitude,
              longitude,
          },
          note,
          creation_date,
          creation_user,
          modify_date,
          modify_user,
          canDelete,
        }
        geometry {
          id,
          type,
          geometry,
          creation_date,
          creation_user,
          modify_date,
          modify_user,
          canDelete,
        }
        coordinate{
          id,
          longitude,
          latitude,
          altitude,
          timestamp,
          creation_date,
          creation_user,
          modify_date,
          modify_user,
          canDelete
        },
        creation_date
        creation_user
        modify_date
        modify_user
  
      }
      Inventory_Type {
        id
        category
        description
        creation_date
        creation_user
        modify_date
        modify_user
        canDelete
      }
      model
      serial_number
      material
      article_number
      acquisition_type
      date_built
      inclusion_date
      exclusion_date
      warranty_end
      usage_end
      condition
      note
      inspection_info
      dimension
      weight
      supplier {
        id,
        number,
        name1,
        name2,
        address {
            id,
            country,
            postcode,
            location,
            district,
            street,
            house_number,
            latitude,
            longitude,
        },
        note,
        creation_date,
        creation_user,
        modify_date,
        modify_user,
        canDelete,
      }
      manufacturer
      area
      fall_height
      fall_protection
      fall_protection_thickness
      age_class
      creation_date
      creation_user
      modify_date
      modify_user
      canDelete
    }
  }
`;

export const getSingleInventory = gql`
  query Inventory($id: Int!, $onlyNewest: Boolean!) {
    Inventory(id: $id) {
      photo(onlyNewest: $onlyNewest) {
        id
        thumbnail
        path
        file
        timestamp
        note
        creation_date
        creation_user
        modify_date
        modify_user
        canDelete
      }
      id
      description
      inventory_number
      inventory_id
      sort_number
      ident_carrier_type
      playground {
        id
      }
      inventory_type
      model
      serial_number
      material
      article_number
      acquisition_type
      date_built
      inclusion_date
      exclusion_date
      warranty_end
      usage_end
      condition
      note
      inspection_info
      dimension
      weight
      supplier
      manufacturer
      area
      fall_height
      fall_protection
      fall_protection_thickness
      age_class
      creation_date
      creation_user
      modify_date
      modify_user
      canDelete
    }
  }
`;

export const addInventory = gql`
  mutation add_Inventory(
    $description: String
    $inventory_number: String
    $inventory_id: String
    $sort_number: Int
    $ident_carrier_type: String
    $playground: Int
    $inventory_type: Int
    $model: String
    $serial_number: String
    $material: String
    $article_number: String
    $acquisition_type: String
    $date_built: Int
    $inclusion_date: String
    $exclusion_date: String
    $warranty_end: String
    $usage_end: String
    $condition: String
    $note: String
    $inspection_info: String
    $dimension: String
    $weight: Float
    $supplier: Int
    $manufacturer: String
    $area: Float
    $fall_height: Float
    $fall_protection: String
    $fall_protection_thickness: Float
    $age_class: String
  ) {
    add_Inventory(
      description: $description
      inventory_number: $inventory_number
      inventory_id: $inventory_id
      sort_number: $sort_number
      ident_carrier_type: $ident_carrier_type
      playground: $playground
      inventory_type: $inventory_type
      model: $model
      serial_number: $serial_number
      material: $material
      article_number: $article_number
      acquisition_type: $acquisition_type
      date_built: $date_built
      inclusion_date: $inclusion_date
      exclusion_date: $exclusion_date
      warranty_end: $warranty_end
      usage_end: $usage_end
      condition: $condition
      note: $note
      inspection_info: $inspection_info
      dimension: $dimension
      weight: $weight
      supplier: $supplier
      manufacturer: $manufacturer
      area: $area
      fall_height: $fall_height
      fall_protection: $fall_protection
      fall_protection_thickness: $fall_protection_thickness
      age_class: $age_class
    ){
      result,
      error
    }
  }
`;

export const updateInventory = gql`
  mutation update_Inventory(
    $id: Int!
    $description: String
    $inventory_number: String
    $inventory_id: String
    $sort_number: Int
    $ident_carrier_type: String
    $playground: Int
    $inventory_type: Int
    $model: String
    $serial_number: String
    $material: String
    $article_number: String
    $acquisition_type: String
    $date_built: Int
    $inclusion_date: String
    $exclusion_date: String
    $warranty_end: String
    $usage_end: String
    $condition: String
    $note: String
    $inspection_info: String
    $dimension: String
    $weight: Float
    $supplier: Int
    $manufacturer: String
    $area: Float
    $fall_height: Float
    $fall_protection: String
    $fall_protection_thickness: Float
    $age_class: String
  ) {
    update_Inventory(
      id: $id
      description: $description
      inventory_number: $inventory_number
      inventory_id: $inventory_id
      sort_number: $sort_number
      ident_carrier_type: $ident_carrier_type
      playground: $playground
      inventory_type: $inventory_type
      model: $model
      serial_number: $serial_number
      material: $material
      article_number: $article_number
      acquisition_type: $acquisition_type
      date_built: $date_built
      inclusion_date: $inclusion_date
      exclusion_date: $exclusion_date
      warranty_end: $warranty_end
      usage_end: $usage_end
      condition: $condition
      note: $note
      inspection_info: $inspection_info
      dimension: $dimension
      weight: $weight
      supplier: $supplier
      manufacturer: $manufacturer
      area: $area
      fall_height: $fall_height
      fall_protection: $fall_protection
      fall_protection_thickness: $fall_protection_thickness
      age_class: $age_class
    ){
      result,
      error
    }
  }
`;

export const deleteInventory = gql`
  mutation delete_Inventory($id: Int!) {
    delete_Inventory(id: $id){
      result,
      error
    }
  }
`;

// TODO: Löschen!!!
export const getInventoryPhoto = gql`
  query Inventory_Photo {
    Inventory_Photo {
      id
      inventory {
        photo(onlyNewest: $onlyNewest) {
          id
          thumbnail
          path
          file
          timestamp
          note
          creation_date
          creation_user
          modify_date
          modify_user
          canDelete
        }
        id
        description
        inventory_number
        inventory_id
        sort_number
        ident_carrier_type
        playground {
          id
        }
        inventory_type
        model
        serial_number
        material
        article_number
        acquisition_type
        date_built
        inclusion_date
        exclusion_date
        warranty_end
        usage_end
        condition
        note
        inspection_info
        dimension
        weight
        supplier
        manufacturer
        area
        fall_height
        fall_protection
        fall_protection_thickness
        age_class
        creation_date
        creation_user
        modify_date
        modify_user
        canDelete  
      }
      photo {
        photo(onlyNewest: $onlyNewest) {
          id
          thumbnail
          path
          file
          timestamp
          note
          creation_date
          creation_user
          modify_date
          modify_user
          canDelete
        }
      }
      creation_date
      creation_user
      modify_date
      modify_user
      canDelete
    }
  }
`;

// TODO: Löschen!!!
export const getSelectedInventoryPhoto = gql`
  query Inventory_Photo($id: Int) {
    Inventory_Photo(id: $id) {
      id
      inventory {
        photo(onlyNewest: $onlyNewest) {
          id
          thumbnail
          path
          file
          timestamp
          note
          creation_date
          creation_user
          modify_date
          modify_user
          canDelete
        }
        id
        description
        inventory_number
        inventory_id
        sort_number
        ident_carrier_type
        playground {
          id
        }
        inventory_type
        model
        serial_number
        material
        article_number
        acquisition_type
        date_built
        inclusion_date
        exclusion_date
        warranty_end
        usage_end
        condition
        note
        inspection_info
        dimension
        weight
        supplier
        manufacturer
        area
        fall_height
        fall_protection
        fall_protection_thickness
        age_class
        creation_date
        creation_user
        modify_date
        modify_user
        canDelete  
      }
      photo {
        photo(onlyNewest: $onlyNewest) {
          id
          thumbnail
          path
          file
          timestamp
          note
          creation_date
          creation_user
          modify_date
          modify_user
          canDelete
        }
      }
      creation_date
      creation_user
      modify_date
      modify_user
      canDelete
    }
  }
`;

export const getAllInventoryPhotos = gql`
  query Inventory_Photo {
    Inventory_Photo {
      id
      inventory {
        photo(onlyNewest: $onlyNewest) {
          id
          thumbnail
          path
          file
          timestamp
          note
          creation_date
          creation_user
          modify_date
          modify_user
          canDelete
        }
        id
        description
        inventory_number
        inventory_id
        sort_number
        ident_carrier_type
        playground {
          id
        }
        inventory_type
        model
        serial_number
        material
        article_number
        acquisition_type
        date_built
        inclusion_date
        exclusion_date
        warranty_end
        usage_end
        condition
        note
        inspection_info
        dimension
        weight
        supplier
        manufacturer
        area
        fall_height
        fall_protection
        fall_protection_thickness
        age_class
        creation_date
        creation_user
        modify_date
        modify_user
        canDelete  
      }
      photo {
        photo(onlyNewest: $onlyNewest) {
          id
          thumbnail
          path
          file
          timestamp
          note
          creation_date
          creation_user
          modify_date
          modify_user
          canDelete
        }
      }
      creation_date
      creation_user
      modify_date
      modify_user
      canDelete
    }
  }
`;

export const getSingleInventoryPhoto = gql`
  query Inventory_Photo($id: Int) {
    Inventory_Photo(id: $id) {
      id
      inventory {
        photo(onlyNewest: $onlyNewest) {
          id
          thumbnail
          path
          file
          timestamp
          note
          creation_date
          creation_user
          modify_date
          modify_user
          canDelete
        }
        id
        description
        inventory_number
        inventory_id
        sort_number
        ident_carrier_type
        playground {
          id
        }
        inventory_type
        model
        serial_number
        material
        article_number
        acquisition_type
        date_built
        inclusion_date
        exclusion_date
        warranty_end
        usage_end
        condition
        note
        inspection_info
        dimension
        weight
        supplier
        manufacturer
        area
        fall_height
        fall_protection
        fall_protection_thickness
        age_class
        creation_date
        creation_user
        modify_date
        modify_user
        canDelete  
      }
      photo {
        photo(onlyNewest: $onlyNewest) {
          id
          thumbnail
          path
          file
          timestamp
          note
          creation_date
          creation_user
          modify_date
          modify_user
          canDelete
        }
      }
      creation_date
      creation_user
      modify_date
      modify_user
      canDelete
    }
  }
`;

export const addInventoryPhoto = gql`
  mutation add_Inventory_Photo($inventory: Int, $photo: Int) {
    add_Inventory_Photo(inventory: $inventory, photo: $photo){
      result,
      error
    }
  }
`;

export const updateInventoryPhoto = gql`
  mutation update_Inventory_Photo($id: Int!, $inventory: Int, $photo: Int) {
    update_Inventory_Photo(id: $id, inventory: $inventory, photo: $photo){
      result,
      error
    }
  }
`;

export const deleteInventoryPhoto = gql`
  mutation delete_Inventory_Photo($id: Int!) {
    delete_Inventory_Photo(id: $id){
      result,
      error
    }
  }
`;

export const getAllInventoryInspections = gql`
  query Inventory_Inspection {
    Inventory_Inspection {
      id
      playground_inspection_id
      inventory_id
      state
      status
      defect
      settlement_time_value
      settlement_time_unit
      settlement_date_until
      settlement_date_at
      creation_date
      creation_user
      modify_date
      modify_user
      canDelete
    }
  }
`;

export const getInventoryDefects = gql`
  query get_Inventory_Defects($inventory_id: Int!, $date: String!) {
    get_Inventory_Defects(inventory_id: $inventory_id, date: $date) 
  }
`;


export const getSingleInventoryInspection = gql`
  query Inventory_Inspection($id: Int, $inventory_id: Int) {
    Inventory_Inspection(id: $id, inventory_id: $inventory_id) {
      id
      playground_inspection_id
      inventory_id
      state
      status
      defect
      settlement_time_value
      settlement_time_unit
      settlement_date_until
      settlement_date_at
      creation_date
      creation_user
      modify_date
      modify_user
      canDelete
    }
  }
`;

export const addInventoryInspection = gql`
  mutation add_Inventory_Inspection(
    $playground_inspection_id: Int
    $inventory_id: Int
    $state: String
    $status: String
    $defect: String
    $settlement_time_value: Int
    $settlement_time_unit: String
    $settlement_date_until: String
    $settlement_date_at: String
  ) {
    add_Inventory_Inspection(
      playground_inspection_id: $playground_inspection_id
      inventory_id: $inventory_id
      state: $state
      status: $status
      defect: $defect
      settlement_time_value: $settlement_time_value
      settlement_time_unit: $settlement_time_unit
      settlement_date_until: $settlement_date_until
      settlement_date_at: $settlement_date_at
    ){
      result,
      error
    }
  }
`;

export const updateInventoryInspection = gql`
  mutation update_Inventory_Inspection(
    $id: Int!
    $playground_inspection_id: Int
    $inventory_id: Int
    $state: String
    $status: String
    $defect: String
    $settlement_time_value: Int
    $settlement_time_unit: String
    $settlement_date_until: String
    $settlement_date_at: String
  ) {
    update_Inventory_Inspection(
      id: $id
      playground_inspection_id: $playground_inspection_id
      inventory_id: $inventory_id
      state: $state
      status: $status
      defect: $defect
      settlement_time_value: $settlement_time_value
      settlement_time_unit: $settlement_time_unit
      settlement_date_until: $settlement_date_until
      settlement_date_at: $settlement_date_at
    ){
      result,
      error
    }
  }
`;

export const deleteInventoryInspection = gql`
  mutation delete_Inventory_Inspection($id: Int!) {
    delete_Inventory_Inspection(id: $id){
      result,
      error
    }
  }
`;

export const getAllInventoryInspectionPhotos = gql`
  query Inventory_Inspection_Photo {
    Inventory_Inspection_Photo {
      id
      inventory_inspection {
        id
        playground_inspection_id
        inventory_id
        state
        status
        defect
        settlement_time_value
        settlement_time_unit
        settlement_date_until
        settlement_date_at
        creation_date
        creation_user
        modify_date
        modify_user
        canDelete
      }
      photo {
        id,
        thumbnail,
        path,
        file,
        timestamp,
        note,
        creation_date,
        creation_user,
        modify_date,
        modify_user,
        canDelete,
      }
      creation_date
      creation_user
      modify_date
      modify_user
      canDelete
    }
  }
`;

export const getSingleInventoryInspectionPhoto = gql`
  query Inventory_Inspection_Photo($id: Int!) {
    Inventory_Inspection_Photo(id: $id) {
      id
      inventory_inspection {
        id
        playground_inspection_id
        inventory_id
        state
        status
        defect
        settlement_time_value
        settlement_time_unit
        settlement_date_until
        settlement_date_at
        creation_date
        creation_user
        modify_date
        modify_user
        canDelete
      }
      photo {
        id,
        thumbnail,
        path,
        file,
        timestamp,
        note,
        creation_date,
        creation_user,
        modify_date,
        modify_user,
        canDelete,
      }
      creation_date
      creation_user
      modify_date
      modify_user
      canDelete
    }
  }
`;

export const addInventoryInspectionPhoto = gql`
  mutation add_Inventory_Inspection_Photo(
    $inventory_inspection: Int
    $photo: Int
  ) {
    add_Inventory_Inspection_Photo(
      inventory_inspection: $inventory_inspection
      photo: $photo
    ){
      result,
      error
    }
  }
`;

export const updateInventoryInspectionPhoto = gql`
  mutation update_Inventory_Inspection_Photo(
    $id: Int!
    $inventory_inspection: Int
    $photo: Int
  ) {
    update_Inventory_Inspection_Photo(
      id: $id
      inventory_inspection: $inventory_inspection
      photo: $photo
    ){
      result,
      error
    }
  }
`;

export const deleteInventoryInspectionPhoto = gql`
  mutation delete_Inventory_Inspection_Photo($id: Int!) {
    delete_Inventory_Inspection_Photo(id: $id){
      result,
      error
    }
  }
`;

export const getAllSuppliersData = gql`
  query Supplier {
    Supplier {
      id
      number
      name1
      name2
      address {
        postcode
        location
        street
        house_number
      }
      note
      creation_date
      creation_user
      modify_date
      modify_user
      canDelete
    }
  }
`;

export const getSingleSupplierData = gql`
  query Supplier($id: Int!) {
    Supplier(id: $id) {
      id
      number
      name1
      name2
      address {
        postcode
        location
        street
        house_number
      }
      note
      creation_date
      creation_user
      modify_date
      modify_user
      canDelete
    }
  }
`;

export const addSupplierData = gql`
  mutation add_Supplier(
    $number: String
    $name1: String
    $name2: String
    $address: Int
    $note: String
  ) {
    add_Supplier(
      number: $number
      name1: $name1
      name2: $name2
      address: $address
      note: $note
    ){
      result,
      error
    }
  }
`;

export const updateSupplierData = gql`
  mutation update_Supplier(
    $id: Int!
    $number: String
    $name1: String
    $name2: String
    $address: Int
    $note: String
  ) {
    update_Supplier(
      id: $id
      number: $number
      name1: $name1
      name2: $name2
      address: $address
      note: $note
    ){
      result,
      error
    }
  }
`;

export const deleteSupplierData = gql`
  mutation delete_Supplier($id: Int!) {
    delete_Supplier(id: $id){
      result,
      error
    }
  }
`;

export const inventoryTypeCategory = gql`
  mutation update_Inventory_Type_Category($oldName: String!, $newName: String!) {
    update_Inventory_Type_Category(oldName: $oldName, newName: $newName){
      result,
      error
    }
  }
`;

@NgModule({
  declarations: [],
  imports: [CommonModule]
})
export class PlaygroundQueriesModule {}
