<span class="status-container">
    <span class="status1">
        <span>Kontrollart:</span>
        <span>Zu X Gegenständen</span> 
        <span>Y Rückmeldungen</span>
    </span>
    <span class="status2">
        <span class="gree-circle"></span><span>Beanstandung</span><span>-</span>
        <span class="yellow-circle"></span><span>Handlungsbedarf</span><span>-</span>
        <span class="orange-circle"></span><span>Gefahrenbeseitigung</span><span>-</span>
        <span class="red-circle"></span><span>Außerbetriebnahme</span><span>-</span>
    </span>
</span>