import { ChangeDetectionStrategy, Component } from '@angular/core';

import { IconService } from 'src/app/services/icons/icon.services';

import { fahrzeugverfolgungIcon, straßenkontrolleIcon, baumkontrolleIcon, spielplatzkontrolleIcon, csvIcon, addAudioIcon } from './svg-sources.icons';

const ICONS: { name: string; source: string }[] = [
  {
    name: 'fahrzeugverfolgung',
    source: fahrzeugverfolgungIcon,
  },
  {
    name: 'straßenkontrolle',
    source: straßenkontrolleIcon,
  },
  {
    name: 'baumkontrolle',
    source: baumkontrolleIcon,
  },
	{
    name: 'spielplatzkontrolle',
    source: spielplatzkontrolleIcon,
  },
  {
    name: 'csv',
    source: csvIcon,
  },
  {
    name: 'addAudio',
    source: addAudioIcon
  }
];

@Component({
  selector: 'app-svg-sources',
  templateUrl: './svg-sources.component.html',
  styleUrls: ['./svg-sources.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SvgSourcesComponent {
  constructor(private readonly iconService: IconService) {
    for (const icon of ICONS) {
      this.iconService.add(icon.name, icon.source);
    }
  }
}