import { SelectionModel } from "@angular/cdk/collections";
import { FlatTreeControl } from "@angular/cdk/tree";
import { HttpClient } from "@angular/common/http";
import {
  Component,
  EventEmitter,
  Inject,
  Injectable,
  Injector,
  Input,
  OnInit,
  Output,
} from "@angular/core";
import {
  MatTreeFlatDataSource,
  MatTreeFlattener,
} from "@angular/material/tree";
import { BehaviorSubject } from "rxjs";
import { GraphqlService } from "src/app/services/graphql.service";

export class LocationNode {
  children: LocationNode[];
  item: string;
  contentCount: number;
  locationID: number;
  location: string;
  district: string;
}

export class LocationFlatNode {
  item: string;
  contentCount: number;
  locationID: number;
  level: number;
  location: string;
  district: string;
  expandable: boolean;
  children: LocationNode[];
}

@Injectable()
export class ChecklistDatabase {
  dataChange = new BehaviorSubject<LocationNode[]>([]);

  get data(): LocationNode[] {
    return this.dataChange.value;
  }

  constructor() {
    // this.initialize();
  }

  initialize(addresses) {
    // Build the tree nodes from Json object. The result is a list of `LocationNode` with nested
    // file node as children.
    const data = this.buildFileTree(addresses, 0);

    // Notify the change.
    this.dataChange.next(data);
  }

  /**
   *
   * @param value
   */
  buildChildNodeArray(value: any): LocationNode[] {
    let nodeArray = [];
    value.map((item) => {
      const node = new LocationNode();
      if (item.addressStreet === " " || item.addressStreet === "") {
        node.item = "Ort ohne Bezeichnung";
      } else {
        node.item = item.addressStreet;
      }
      node.locationID = item.id;
      node.location = item.addressLocation;
      node.district = item.addressDistrict;
      nodeArray.push(node);
    });
    return nodeArray;
  }
  /**
   * Build the file structure tree. The `value` is the Json object, or a sub-tree of a Json object.
   * The return value is the list of `LocationNode`.
   */
  buildFileTree(obj: { [key: string]: any }, level: number): LocationNode[] {
    return Object.keys(obj).reduce<LocationNode[]>((accumulator, key) => {
      const value = obj[key];
      const node = new LocationNode();
      if (key === " " || key === "") {
        node.item = "Ohne Kategorie"; // Ich würde das später gerne noch spezifizieren und zB "Ohne PLZ" einfügen.
      } else {
        node.item = key;
      }

      if (value != null) {
        if (typeof value === "object") {
          node.contentCount = Object.keys(value).length;
          if (Array.isArray(value)) {
            if (value[0].addressStreet) {
              node.children = this.buildChildNodeArray(value);
            }
          } else {
            node.children = this.buildFileTree(value, level + 1);
          }
        } else {
          node.item = value;
        }
      }
      return accumulator.concat(node);
    }, []);
  }

  updateItem(node: LocationNode, name: string) {
    node.item = name;
    this.dataChange.next(this.data);
  }
}

@Component({
  selector: "app-location-tree",
  templateUrl: "./location-tree.component.html",
  styleUrls: ["./location-tree.component.sass"],
  providers: [ChecklistDatabase],
})
export class LocationTreeComponent implements OnInit {
  @Input() addressServiceType;
  private addressService;

  @Output() selectedAddresses = new EventEmitter();

  /** Map from flat node to nested node. This helps us finding the nested node to be modified */
  flatNodeMap = new Map<LocationFlatNode, LocationNode>();

  /** Map from nested node to flattened node. This helps us to keep the same object for selection */
  nestedNodeMap = new Map<LocationNode, LocationFlatNode>();

  treeControl: FlatTreeControl<LocationFlatNode>;

  treeFlattener: MatTreeFlattener<LocationNode, LocationFlatNode>;

  dataSource: MatTreeFlatDataSource<LocationNode, LocationFlatNode>;

  /** The selection for checklist */
  checklistSelection = new SelectionModel<LocationFlatNode>(
    true /* multiple */
  );

  constructor(
    private graphQl: GraphqlService,
    private http: HttpClient,
    private _database: ChecklistDatabase,
    // @Inject("test") private testService,
    private injector: Injector
  ) {
    this.treeFlattener = new MatTreeFlattener(
      this.transformer,
      this.getLevel,
      this.isExpandable,
      this.getChildren
    );
    this.treeControl = new FlatTreeControl<LocationFlatNode>(
      this.getLevel,
      this.isExpandable
    );
    this.dataSource = new MatTreeFlatDataSource(
      this.treeControl,
      this.treeFlattener
    );

    _database.dataChange.subscribe((data) => {
      this.dataSource.data = data;
    });
  }

  ngOnInit(): void {
    // this._database.initialize(TREE_DATA);

    // this.addressService = new this.addressServiceType();
    // if(this.addressServiceType.name === "TreeAddressesService"){
    //   this.addressService.treeAddresses.subscribe(
    //     result => {
    //       if (result && result !== null && result !== undefined) {
    //         this._database.initialize(result);
    //         this._expandFullTree();
    //         this.selectFullTree();
    //       }
    //     }
    //   )

    // }

    this.addressService = this.injector.get<any>(this.addressServiceType);

    this.addressService.getAllAddressData().subscribe((result) => {
      this._database.initialize(this.buildSortedList(result));
      this._expandFullTree();
      this.selectFullTree();
    });
  }

  getLevel = (node: LocationFlatNode) => node.level;

  isExpandable = (node: LocationFlatNode) => node.expandable;

  getChildren = (node: LocationNode): LocationNode[] => node.children;

  hasChild = (_: number, _nodeData: LocationFlatNode) => _nodeData.expandable;

  hasNoContent = (_: number, _nodeData: LocationFlatNode) =>
    _nodeData.item === "";

  /**
   * Transformer to convert nested node to flat node. Record the nodes in maps for later use.
   */
  transformer = (node: LocationNode, level: number) => {
    const existingNode = this.nestedNodeMap.get(node);
    const flatNode =
      existingNode && existingNode.item === node.item
        ? existingNode
        : new LocationFlatNode();
    flatNode.item = node.item;
    flatNode.contentCount = node.contentCount;
    flatNode.level = level;
    flatNode.locationID = node.locationID;
    flatNode.location = node.location;
    flatNode.district = node.district;
    flatNode.expandable = !!node.children?.length;
    this.flatNodeMap.set(flatNode, node);
    this.nestedNodeMap.set(node, flatNode);
    return flatNode;
  };

  /** Whether all the descendants of the node are selected. */
  descendantsAllSelected(node: LocationFlatNode): boolean {
    const descendants = this.treeControl.getDescendants(node);
    const descAllSelected =
      descendants.length > 0 &&
      descendants.every((child) => {
        return this.checklistSelection.isSelected(child);
      });
    return descAllSelected;
  }

  /** Whether part of the descendants are selected */
  descendantsPartiallySelected(node: LocationFlatNode): boolean {
    const descendants = this.treeControl.getDescendants(node);
    const result = descendants.some((child) =>
      this.checklistSelection.isSelected(child)
    );
    return result && !this.descendantsAllSelected(node);
  }

  /** Toggle the to-do item selection. Select/deselect all the descendants node */
  todoItemSelectionToggle(node: LocationFlatNode): void {
    this.checklistSelection.toggle(node);
    const descendants = this.treeControl.getDescendants(node);
    this.checklistSelection.isSelected(node)
      ? this.checklistSelection.select(...descendants)
      : this.checklistSelection.deselect(...descendants);

    // Force update for the parent
    descendants.forEach((child) => this.checklistSelection.isSelected(child));
    this.checkAllParentsSelection(node);
  }

  /** Toggle a leaf to-do item selection. Check all the parents to see if they changed */
  todoLeafItemSelectionToggle(node: LocationFlatNode): void {
    this.checklistSelection.toggle(node);
    this.checkAllParentsSelection(node);
  }

  /* Checks all the parents when a leaf node is selected/unselected */
  checkAllParentsSelection(node: LocationFlatNode): void {
    let parent: LocationFlatNode | null = this.getParentNode(node);
    while (parent) {
      this.checkRootNodeSelection(parent);
      parent = this.getParentNode(parent);
    }
  }

  /** Check root node checked state and change it accordingly */
  checkRootNodeSelection(node: LocationFlatNode): void {
    const nodeSelected = this.checklistSelection.isSelected(node);
    const descendants = this.treeControl.getDescendants(node);
    const descAllSelected =
      descendants.length > 0 &&
      descendants.every((child) => {
        return this.checklistSelection.isSelected(child);
      });
    if (nodeSelected && !descAllSelected) {
      this.checklistSelection.deselect(node);
    } else if (!nodeSelected && descAllSelected) {
      this.checklistSelection.select(node);
    }
  }

  /* Get the parent node of a node */
  getParentNode(node: LocationFlatNode): LocationFlatNode | null {
    const currentLevel = this.getLevel(node);

    if (currentLevel < 1) {
      return null;
    }

    const startIndex = this.treeControl.dataNodes.indexOf(node) - 1;

    for (let i = startIndex; i >= 0; i--) {
      const currentNode = this.treeControl.dataNodes[i];

      if (this.getLevel(currentNode) < currentLevel) {
        return currentNode;
      }
    }
    return null;
  }

  private _expandFullTree() {
    this.treeControl.expandAll();
  }

  selectFullTree() {
    for (let i = 0; i < this.treeControl.dataNodes.length; i++) {
      if (!this.checklistSelection.isSelected(this.treeControl.dataNodes[i]))
        this.checklistSelection.toggle(this.treeControl.dataNodes[i]);
      this.treeControl.expand(this.treeControl.dataNodes[i]);
    }
  }

  getSelection() {
    this.selectedAddresses.emit(
      this.checklistSelection.selected.filter(
        (selection) => selection.locationID
      )
    );
  }

  buildSortedList(addressesObj) {
    let addressIdsFiltered: Array<string> = [];

    let addressesSorted: Object = {};

    addressesObj.forEach((element) => {
      let address = element;
      if (!addressIdsFiltered.includes(address.id)) {
        addressIdsFiltered.push(address.id);

        // if(!addressesSorted[address.country]){
        //   addressesSorted[address.country] = {};
        // }
        if (!addressesSorted[address.location]) {
          addressesSorted[address.location] = {};
        }
        if (!addressesSorted[address.location][address.district]) {
          addressesSorted[address.location][address.district] = [];
        }

        if (
          !addressesSorted[address.location][address.district].some(
            (e) => e.address === address.street
          )
        ) {
          addressesSorted[address.location][address.district].push({
            id: address.id,
            addressStreet: address.street,
            addressLocation: address.location,
            addressDistrict: address.district,
          });
        }
      }
    });
    return addressesSorted;
  }
}
