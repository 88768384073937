import { Injectable } from "@angular/core";
import { BehaviorSubject, Subject } from "rxjs";
import { PlaygroundInspectionService } from "./playground-inspection.service";

@Injectable({
  providedIn: "root",
})
export class PlaygroundDefectsService {
  private allDefects: BehaviorSubject<any> = new BehaviorSubject(null);

  constructor(
    private playgroundInspectionDataService: PlaygroundInspectionService
  ) {
    this.buildDefectList();
  }

  getOpenDefectsDataOfPlayground(playgroundId: number) {
    let subject: BehaviorSubject<any> = new BehaviorSubject([]);
    this.allDefects.subscribe((result) => {
      if (result) {
        let data = [];
        result[playgroundId].forEach((element) => {
          if(this.checkDefectTimestamp(element)){
            data.push(element);
          };
        });
        subject.next(data);
      }
    });
    return subject
  }

  getOpenDefectsCountOfPlayground(playgroundId: number) {
    let subject: Subject<any> = new Subject;
    this.allDefects.subscribe((result) => {
      if (result) {
        let count = 0;
        result[playgroundId].forEach((element) => {
          if(this.checkDefectTimestamp(element)){
            count++;
          };
        });
        subject.next(count);
      }
    });
    return subject
  }

  buildDefectList() {
    this.playgroundInspectionDataService
      .getAllInspectionData()
      .subscribe((result) => {
        // console.log("(re)build defectList");
        let allDefects = result.filter((result) => {
          return result["inventory_inspection"] !== null;
        });

        let allDefectsSorted = {};
        allDefects.forEach((element) => {
          if (!element.inventory_inspection) {
            return;
          }
          if (!allDefectsSorted[element.playground]) {
            allDefectsSorted[element.playground] = [];
          }
          allDefectsSorted[element.playground] = [
            ...allDefectsSorted[element.playground],
            ...element.inventory_inspection,
          ];
        });
        this.allDefects.next(allDefectsSorted);
      });
  }

  checkDefectTimestamp(defect) {
    return defect.settlement_date_at == "0000-00-00 00:00:00"
  }
}
