import { NgModule } from "@angular/core";
import { GlobalModulesModule } from "src/app/global-modules.module";

import { DeleteGroupFormComponent } from "./delete-group-form.component";

@NgModule({
  declarations: [
    DeleteGroupFormComponent,
  ],
  imports: [GlobalModulesModule],
  exports: [
    DeleteGroupFormComponent,
  ],
})
export class DeleteGroupFormModule {}
