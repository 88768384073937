import { DataIndex } from './dataobject';
import { GraphqlService } from "../../services/graphql.service";
import { Subject } from "rxjs";

export class ObservableDataobject extends DataIndex {

    private _dataObserver = new Subject<object>();

    constructor(private graphQL?: GraphqlService, dataSource?) {
        super(dataSource);
    }

    /**
    *
    */
    get dataObserver() {
        return this._dataObserver;
    }
}
