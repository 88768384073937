import { CdkVirtualScrollViewport } from "@angular/cdk/scrolling";
import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import {
  AbstractControl,
  FormControl,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from "@angular/forms";
import { Sort, SortDirection } from "@angular/material/sort";
import { cloneDeep } from "@apollo/client/utilities";
import { Subject, Subscription } from "rxjs";
import { AlphabeticalScrollbarComponent } from "src/app/modules/alphabetical-scrollbar/alphabetical-scrollbar.component";
import { AddressService } from "src/app/services/general/address.service";
import { CoordinateService } from "src/app/services/general/coordinate.service";
import { GraphqlService } from "src/app/services/graphql.service";

@Component({
  selector: "app-address-map-picker",
  templateUrl: "./address-map-picker.component.html",
  styleUrls: ["./address-map-picker.component.sass"],
})
export class AddressMapPickerComponent implements OnInit, AfterViewInit {
  @Input() submitAddress: Subject<void> = new Subject();
  @Input() addressInput: Object;
  @Input() public mapHeader: string;
  @Output() addressOutput: EventEmitter<object | false> =
    new EventEmitter<Object>();

  @Output() values = new EventEmitter();

  @ViewChild(CdkVirtualScrollViewport) viewPort: CdkVirtualScrollViewport;
  @ViewChild(AlphabeticalScrollbarComponent, { static: true })
  alphabeticalScrollbarComponent: AlphabeticalScrollbarComponent;

  public selectedItem = [];
  public dialogActions =
    document.getElementsByTagName("mat-dialog-actions")[
      document.getElementsByTagName("mat-dialog-actions").length - 1
    ];
  public tabHeader = document.getElementsByTagName("mat-tab-header")[0];

  public searchValue: String;

  public mapPickerAddressInput = new Subject();
  public showAddressTakeOverSubject = new Subject();

  public addressForm: FormGroup;
  public filteredObjects: object = {};

  private addressServiceSubscription: Subscription;

  public activeLetter: Array<any>;
  public disabledLetters: object = {};
  public firstLetterIndex = {};
  public sorting = "street";
  private direction: SortDirection = "asc";

  public addressDuplicate: boolean = false;

  public knownAddresses = {
    addresses: null,
    filteredData: null,
    sortedData: null,
  };
  private currentLocationFilter = "";
  private currentSearchValue = "";

  public currentAddress: FormGroup;

  private selectedAddress = {
    id: null,
    street: null,
    house_number: null,
    postcode: null,
    location: null,
    district: null,
    country: null,
    latitude: null,
    longitude: null,
  };

  private scrollPosition = 0;

  public locations: Array<String>;

  constructor(
    private addressService: AddressService,
    private coordinateDataService: CoordinateService,
    private graphQl: GraphqlService
  ) {}

  ngOnInit(): void {
    // Neue Adresse
    this.addressForm = new FormGroup({
      street: new FormControl("", [Validators.required]),
      house_number: new FormControl(""),
      postcode: new FormControl("", [Validators.required]),
      location: new FormControl("", [Validators.required]),
      district: new FormControl(""),
      country: new FormControl("Deutschland", [Validators.required]),

    });

    this.currentAddress = new FormGroup({
      address: new FormGroup({
        id: new FormControl(""),
        street: new FormControl("", [Validators.required]),
        house_number: new FormControl(""),
        postcode: new FormControl("", [Validators.required]),
        location: new FormControl("", [Validators.required]),
        district: new FormControl(""),
        country: new FormControl("", [Validators.required]),
        latitude: new FormControl(""),
        longitude: new FormControl(""),
        address_string: new FormControl(""),
      }),
      coordinate: new FormGroup({
        longitude: new FormControl(0, [this.isNotZero()]),
        latitude: new FormControl(0, [this.isNotZero()]),
      }),
    });

    this.addressForm.valueChanges.subscribe((result) => {
      this.currentAddress.get("address").patchValue(this.addressForm.value);
      this.currentAddress.get("address").patchValue({
        address_string: this.addressService.addressObjToString(
          this.addressForm.value
        ).addressString,
      });

      let filteredAddresses = this.knownAddresses.addresses.filter(
        (address) => {
          if (
            (address.street == this.addressForm.value.street ||
              (!address.street && !this.addressForm.value.street)) &&
            (address.house_number == this.addressForm.value.house_number ||
              (!address.house_number &&
                !this.addressForm.value.house_number)) &&
            (address.postcode == this.addressForm.value.postcode ||
              (!address.postcode && !this.addressForm.value.postcode)) &&
            (address.location == this.addressForm.value.location ||
              (!address.location && !this.addressForm.value.location)) &&
            (address.district == this.addressForm.value.district ||
              (!address.district && !this.addressForm.value.district)) &&
            (address.country == this.addressForm.value.country ||
              (!address.country && !this.addressForm.value.country))
          ) {
            return true;
          }
        }
      );

      if (filteredAddresses.length > 0) {
        this.addressDuplicate = true;
        this.values.emit(false);
      } else {
        this.addressDuplicate = false;
      }
    });

    this.currentAddress.valueChanges.subscribe((result) => {
      if (this.currentAddress.valid) {
        this.values.emit(this.currentAddress.value);
      } else {
        this.values.emit(false);
      }
    });

    if (this.addressInput) {
      this.addressForm.patchValue({
        street: this.addressInput["street"],
        house_number: this.addressInput["house_number"],
        postcode: this.addressInput["postcode"],
        location: this.addressInput["location"],
        district: this.addressInput["district"],
        country: this.addressInput["country"],
      });
      this.coordinateDataService
        .getSingleCordinateData(this.addressInput["coordinateId"])
        .subscribe((result) => {
          if (!Array.isArray(result)) {
            this.addressForm.patchValue({
              longitude: result.longitude,
              latitude: result.latitude,
            });
            this.mapPickerAddressInput.next({
              longitude: this.addressForm.get("longitude").value,
              latitude: this.addressForm.get("latitude").value,
            });
          } else {
            console.warn("more than one coordinate returned!", result);
          }
        });
    }

    this.addressServiceSubscription = this.addressService
      .getAllAddressData()
      .subscribe((result) => {
        this.knownAddresses.addresses = result;
        this.onSearchInput("");

        this.getLocations();
      });
  }

  ngAfterViewInit(): void {}

  isNotZero(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      return control.value == 0 ? { typeNumber: control.value } : null;
    };
  }

  selectAddress(address) {
    this.addressForm.patchValue({
      street: address.street,
      house_number: address.house_number,
      postcode: address.postcode,
      location: address.location,
      district: address.district,
      country: address.country,
      latitude: address.latitude,
      longitude: address.longitude,
    });
  }
  selectCoordinate(coordinate) {
    this.currentAddress.get("coordinate").patchValue({
      longitude: coordinate[0],
      latitude: coordinate[1],
    });

    if (this.addressDuplicate) {
      this.values.emit(false);
    }
  }

  showOnMap() {
    this.mapPickerAddressInput.next(this.currentAddress.get("address").value);
  }

  onCloseSubmenu() {
    this.addressServiceSubscription.unsubscribe();
  }

  filter(
    value: string,
    searchIn: Array<object>,
    filteredObjectsKey: string,
    objectKeys: Array<string>
  ) {
    this.filteredObjects[filteredObjectsKey] = searchIn.filter((obj) => {
      let include: boolean = false;
      objectKeys.forEach((element) => {
        if (obj[element] != "" && obj[element].toLowerCase().includes(value)) {
          include = true;
          return;
        }
      });
      return include;
    });
  }

  firstLetter(value) {
    if (value === null) {
      return "";
    }
    return value
      .charAt(0)
      .toUpperCase()
      .replace("Ü", "U")
      .replace("Ä", "A")
      .replace("Ö", "O")
      .replace(/^$|[^a-zA-Z]/, "SpecialChar");
  }

  inViewport(event) {
    this.activeLetter = event;
  }

  sortData(sort: Sort) {
    if (this.viewPort) {
      this.viewPort.scrollToOffset(0);
    }
    this.sorting = sort.active;
    this.direction = sort.direction;

    const data = this.knownAddresses.filteredData.slice();
    if (!sort.active || sort.direction === "") {
      this.knownAddresses.sortedData = data;
      return;
    }

    this.knownAddresses.sortedData = data.sort((a, b) => {
      const isAsc = sort.direction === "asc";
      switch (sort.active) {
        case "district":
          return compare(a.district, b.district, isAsc);
        case "postcode":
          return compare(a.postcode, b.postcode, isAsc);
        case "street":
          return compare(a.street, b.street, isAsc);
        case "house_number":
          return compare(a.house_number, b.house_number, isAsc);
        default:
          return 0;
      }
    });

    this.disableLetters();
    switch (sort.direction) {
      case "asc":
        this.alphabeticalScrollbarComponent.alphabeticalScrollbarOrder("asc");
        break;
      case "desc":
        this.alphabeticalScrollbarComponent.alphabeticalScrollbarOrder("desc");
        break;
      default:
        this.alphabeticalScrollbarComponent.disable();
        break;
    }
    if (this.knownAddresses.sortedData[0]) {
      if (this.knownAddresses.sortedData[0][this.sorting] === null) {
        this.activeLetter = [
          "VISIBLE",
          {
            id: "SpecialChar",
          },
        ];
      } else {
        this.activeLetter = [
          "VISIBLE",
          {
            id: this.knownAddresses.sortedData[0][this.sorting]
              .charAt(0)
              .toUpperCase()
              .replace("Ü", "U")
              .replace("Ä", "A")
              .replace("Ö", "O")
              .replace(/^$|[^a-zA-Z]/, "SpecialChar"),
          },
        ];
      }
    }
    this.setFirstLetterIndex();
  }

  scrollTo(fragment) {
    if (this.firstLetterIndex[fragment] === 1) {
      this.viewPort.scrollToOffset(0, "smooth");
    } else {
      this.viewPort.scrollToOffset(
        this.firstLetterIndex[fragment] * 44,
        "smooth"
      );
    }
  }

  setFirstLetterIndex() {
    this.firstLetterIndex = {};
    this.knownAddresses.sortedData.forEach((element, index) => {
      if (!this.firstLetterIndex[this.firstLetter(element[this.sorting])]) {
        this.firstLetterIndex[this.firstLetter(element[this.sorting])] = index;
      }
    });
  }

  disableLetters() {
    this.disabledLetters = {
      SpecialChar: false,
      A: false,
      B: false,
      C: false,
      D: false,
      E: false,
      F: false,
      G: false,
      H: false,
      I: false,
      J: false,
      K: false,
      L: false,
      M: false,
      N: false,
      O: false,
      P: false,
      Q: false,
      R: false,
      S: false,
      T: false,
      U: false,
      V: false,
      W: false,
      X: false,
      Y: false,
      Z: false,
    };

    this.knownAddresses.sortedData.forEach((treeType) => {
      if (treeType[this.sorting] === null) {
        this.disabledLetters["SpecialChar"] = true;
      } else {
        this.disabledLetters[
          treeType[this.sorting]
            .charAt(0)
            .toUpperCase()
            .replace("Ü", "U")
            .replace("Ä", "A")
            .replace("Ö", "O")
            .replace(/^$|[^a-zA-Z]/, "SpecialChar")
        ] = true;
      }
    });
  }

  setSelectedItem(items) {
    this.selectedItem = items;
    if (items.length > 0) {
      this.selectedAddress = {
        id: items[0].id,
        street: items[0].street,
        house_number: items[0].house_number,
        postcode: items[0].postcode,
        location: items[0].location,
        district: items[0].district,
        country: items[0].country,
        latitude: items[0].latitude,
        longitude: items[0].longitude,
      };
      this.currentAddress.get("address").patchValue(this.selectedAddress);
      this.currentAddress.get("address").patchValue({
        address_string: this.addressService.addressObjToString(
          this.selectedAddress
        ).addressString,
      });
    } else {
      this.values.emit(false);
    }
  }

  tabChange(event) {
    if (event.index == 0) {
      setTimeout(() => {
        this.viewPort.scrollToOffset(this.scrollPosition);
        this.viewPort.checkViewportSize();
      }, 50);
      this.currentAddress.get("address").patchValue(this.selectedAddress);

      this.showAddressTakeOverSubject.next(false);
    } else {
      this.scrollPosition = this.viewPort.elementRef.nativeElement.scrollTop;
      this.currentAddress.get("address").get("id").patchValue("");
      this.currentAddress.get("address").patchValue(this.addressForm.value);

      this.showAddressTakeOverSubject.next(true);

      if (this.addressDuplicate) {
        this.values.emit(false);
      }
    }
  }

  onSearchInput(searchValue) {
    this.knownAddresses.filteredData = this.knownAddresses.addresses.filter(
      (address) => {
        let editAddress = cloneDeep(address);
        editAddress.location = "";
        return (
          this.addressService
            .addressObjToString(editAddress)
            .addressString.toLowerCase()
            .includes(searchValue.toLowerCase()) &&
          (this.currentLocationFilter == "" ||
            address.location == this.currentLocationFilter)
        );
      }
    );
    this.sortData({ active: this.sorting, direction: this.direction });
    this.currentSearchValue = searchValue;
  }

  getLocations() {
    let locations: Array<string> = [];
    this.knownAddresses.addresses.forEach((address) => {
      if (!locations.includes(address.location)) {
        locations.push(address.location);
      }
    });

    locations.sort((a, b) => {
      return compare(a, b, true);
    });

    this.locations = locations;
  }

  selectLocation(location) {
    this.currentLocationFilter = location;
    this.onSearchInput(this.currentSearchValue);
  }
}

function compare(a: number | string, b: number | string, isAsc: boolean) {
  if (typeof a == "string") {
    a = a
      .replaceAll("Ä", "A")
      .replaceAll("ä", "a")
      .replaceAll("Ö", "O")
      .replaceAll("ö", "o")
      .replaceAll("Ü", "U")
      .replaceAll("ü", "ü");
  }
  if (typeof b == "string") {
    b = b
      .replaceAll("Ä", "A")
      .replaceAll("ä", "a")
      .replaceAll("Ö", "O")
      .replaceAll("ö", "o")
      .replaceAll("Ü", "U")
      .replaceAll("ü", "ü");
  }

  if (a == null) {
    a = "0000-00-00 00:00:00";
  }
  if (b == null) {
    b = "0000-00-00 00:00:00";
  }

  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}
