<div #alphabetical class="alphabetical content-frame">
    <ul>
        <li><a id="scrollSpecialChar" (click)="scrollToElement('SpecialChar')">#</a></li>
        <li><a id="scrollA" (click)="scrollToElement('A')">A</a></li>
        <li><a id="scrollB" (click)="scrollToElement('B')">B</a></li>
        <li><a id="scrollC" (click)="scrollToElement('C')">C</a></li>
        <li><a id="scrollD" (click)="scrollToElement('D')">D</a></li>
        <li><a id="scrollE" (click)="scrollToElement('E')">E</a></li>
        <li><a id="scrollF" (click)="scrollToElement('F')">F</a></li>
        <li><a id="scrollG" (click)="scrollToElement('G')">G</a></li>
        <li><a id="scrollH" (click)="scrollToElement('H')">H</a></li>
        <li><a id="scrollI" (click)="scrollToElement('I')">I</a></li>
        <li><a id="scrollJ" (click)="scrollToElement('J')">J</a></li>
        <li><a id="scrollK" (click)="scrollToElement('K')">K</a></li>
        <li><a id="scrollL" (click)="scrollToElement('L')">L</a></li>
        <li><a id="scrollM" (click)="scrollToElement('M')">M</a></li>
        <li><a id="scrollN" (click)="scrollToElement('N')">N</a></li>
        <li><a id="scrollO" (click)="scrollToElement('O')">O</a></li>
        <li><a id="scrollP" (click)="scrollToElement('P')">P</a></li>
        <li><a id="scrollQ" (click)="scrollToElement('Q')">Q</a></li>
        <li><a id="scrollR" (click)="scrollToElement('R')">R</a></li>
        <li><a id="scrollS" (click)="scrollToElement('S')">S</a></li>
        <li><a id="scrollT" (click)="scrollToElement('T')">T</a></li>
        <li><a id="scrollU" (click)="scrollToElement('U')">U</a></li>
        <li><a id="scrollV" (click)="scrollToElement('V')">V</a></li>
        <li><a id="scrollW" (click)="scrollToElement('W')">W</a></li>
        <li><a id="scrollX" (click)="scrollToElement('X')">X</a></li>
        <li><a id="scrollY" (click)="scrollToElement('Y')">Y</a></li>
        <li><a id="scrollZ" (click)="scrollToElement('Z')">Z</a></li>
    </ul>
</div>