import { Component, OnInit, Input, HostListener, OnDestroy, ViewChild, AfterViewInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { addAudioIcon, baumkontrolleIcon, fahrzeugverfolgungIcon, spielplatzkontrolleIcon, straßenkontrolleIcon } from 'src/ui/icons/svg-sources/svg-sources.icons';
import { IconService } from '../services/icons/icon.services';
import { MediatorService } from '../services/mediator/mediator.service';
import { ShareSearchDataService } from '../services/share-search-data/share-search-data.service';
//import { scrollResizeAnimation } from '../../animations';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.sass'],
  //animations: [scrollResizeAnimation]
})
export class HeaderComponent implements OnInit, AfterViewInit, OnDestroy {

  sideNav: Boolean;

  selected = 'fahrzeugverfolgung';

  administration: string = 'Administration';
  contact: string = 'Kontakt';
  impressum: string = 'Impressum';

  public value;
  public placeholder = "Suche";
  private searchbarPlaceholderSubscription: Subscription;

  @Input() clientId: string;

  @ViewChild('sideNavToggleButton') sideNavToggleButton;

  constructor(private readonly iconService: IconService, private mediator: MediatorService, private shareSearchData: ShareSearchDataService, private router: Router) { 
    this.iconService.add('fahrzeugverfolgung', fahrzeugverfolgungIcon);
		this.iconService.add('straßenkontrolle', straßenkontrolleIcon);
		this.iconService.add('baumkontrolle', baumkontrolleIcon);
		this.iconService.add('spielplatzkontrolle', spielplatzkontrolleIcon);
		this.iconService.add('addAudio', addAudioIcon);

    // this.router.events.subscribe(result=>{
    //   this.value="";
    // })
    this.searchbarPlaceholderSubscription = this.shareSearchData.searchbarPlaceholder.subscribe(result=>{this.placeholder=result;})
  }

  ngOnInit() {
    this.sideNav=true;
    this.mediator.getSideNavState().subscribe(result=>{
      this.sideNav=result;
    })
  }

  ngAfterViewInit(): void {
    this.mediator.sideNavToggleButton = this.sideNavToggleButton;
  }

  ngOnDestroy(): void {
    this.searchbarPlaceholderSubscription.unsubscribe();
  }

  setSideNavState() {
    this.mediator.setSideNavState(!this.sideNav);
  }

  onSearchInput(value){
    this.shareSearchData.searchbarValue.emit(value);
  }


  /* // Alter Code um das Menü beim Scrollen zu verkleinern
  isScrolled = false;

  @HostListener('window:scroll', ['$event']) onscroll(event) {
    const scrollPosition = window.pageYOffset;
   //console.log(scrollPosition);

    if (scrollPosition > 100 && !this.isScrolled) {
     // console.log("scrolle!");
      this.isScrolled = !this.isScrolled;
    } else {
      if (scrollPosition < 100 && this.isScrolled) {
        this.isScrolled = !this.isScrolled;
      } 
    }
  }*/

}
