import { Component, OnInit, ChangeDetectionStrategy, Input, Output, EventEmitter } from '@angular/core';

// TODO make usable for all data
@Component({
  selector: 'app-delete-group-form',
  templateUrl: './delete-group-form.component.html',
  styleUrls: ['./delete-group-form.component.sass'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DeleteGroupFormComponent implements OnInit {
  @Input() record;
  @Output() values = new EventEmitter();



  constructor() { }

  ngOnInit(): void {
    if(this.record.length > 0){
      this.values.emit(this.record);
    }else{
      this.values.emit(false);
    }
  }

}
