import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import gql from "graphql-tag";

export const getAllTreeDamageListingData = gql`
    query getAll ($args: Arguments) {
        Tree_Damage_Listing(args: $args) {
            id,
            number,
            damage_type,
            note,
            root,
            sfu,
            stem,
            kra,
            crown,
            creation_date,
            creation_user,
            modify_date,
            modify_user,
            canDelete
        }
    }
`

export const getSingleTreeDamageListingData = gql`
    query getSingleTreeDamageData (
        $id: Int
    ) {
        Tree_Damage_Listing (id: $id){
            id,
            number,
            damage_type,
            note,
            root,
            sfu,
            stem,
            kra,
            crown,
            creation_date,
            creation_user,
            modify_date,
            modify_user,
            canDelete
        }
    }
`

export const updateTreeDamageListingData = gql`
    mutation update_Tree_Damage_Listing (
        $id: Int
        $number: String
        $damage_type: String
        $note: String
        $root: String
        $sfu: String
        $stem: String
        $kra: String
        $crown: String
    ) {
        update_Tree_Damage(
            id: $id,
            number: $number,
            damage_type: $damage_type,
            note: $note,
            root: $root,
            sfu: $sfu,
            stem: $stem,
            kra: $kra,
            crown: $crown,
        ){
            result,
            error
        }
    }
`

export const addTreeDamageListingData = gql`
    mutation add_Tree_Damage_Listing(
        $id: Int
        $number: String
        $damage_type: String
        $note: String
        $root: String
        $sfu: String
        $stem: String
        $kra: String
        $crown: String
    ) {
        add_Tree_Damage(
            id: $id,
            number: $number,
            damage_type: $damage_type,
            note: $note,
            root: $root,
            sfu: $sfu,
            stem: $stem,
            kra: $kra,
            crown: $crown,
        ){
            result,
            error
        }
    }
`

export const deleteTreeDamageListingData = gql`
    mutation delete_Tree_Damage_Listing($id: Int!) {
        delete_Tree_Damage(id: $id){
            result,
            error
        }
    }
`

@NgModule({
    declarations: [],
    imports: [CommonModule]
  })
export class TreeDamageListingQueriesModule {}