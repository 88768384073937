import { NgModule } from "@angular/core";

import { DialogBoxComponent } from "./dialog-box/dialog-box.component";
import { DialogBoxTriggerComponent } from "./dialog-box-trigger/dialog-box-trigger.component";
import { DialogBoxFooterComponent } from "./dialog-box-footer/dialog-box-footer.component";
import { GlobalModulesModule } from "src/app/global-modules.module";

@NgModule({
    declarations: [
        DialogBoxComponent,
        DialogBoxTriggerComponent,
        DialogBoxFooterComponent,
    ],
    imports: [GlobalModulesModule,],
    exports: [
        DialogBoxComponent,
        DialogBoxTriggerComponent,
        DialogBoxFooterComponent,
    ],
  })
  export class CustomDialogboxModule {}