import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {LoginComponent} from './login/login.component';
import {IndexComponent} from './index/index.component';
import {DashboardComponent} from './dashboard/dashboard.component';
import {StrassenkontrolleComponent} from './strassenkontrolle/strassenkontrolle.component';
import {BaumkontrolleComponent} from './baumkontrolle/baumkontrolle.component';
import {BaumuebersichtComponent} from './baumkontrolle/baumuebersicht/baumuebersicht.component';
import {BaumartenComponent} from './baumkontrolle/baumarten/baumarten.component';
import {SpielplatzkontrolleComponent} from './spielplatzkontrolle/spielplatzkontrolle.component';
import {FahrzeugverfolgungComponent} from './fahrzeugverfolgung/fahrzeugverfolgung.component';
import {FahrzeugortungComponent} from './fahrzeugverfolgung/fahrzeugortung/fahrzeugortung.component';
import {FahrtenbuchComponent} from './fahrzeugverfolgung/fahrtenbuch/fahrtenbuch.component';
import {PoiAuswertungComponent} from './fahrzeugverfolgung/poi-auswertung/poi-auswertung.component';
import {SpielplaetzeComponent} from './spielplatzkontrolle/spielplaetze/spielplaetze.component';
import {KontrollenComponent} from './spielplatzkontrolle/kontrollen/kontrollen.component';
import {LieferantComponent} from './spielplatzkontrolle/lieferant/lieferant.component';
import {InventartypkatalogComponent} from './spielplatzkontrolle/inventartypkatalog/inventartypkatalog.component';
import {VorgabewerteComponent} from './spielplatzkontrolle/vorgabewerte/vorgabewerte.component';
import { BaumVorgabewerteComponent } from './baumkontrolle/baumVorgabewerte/baumVorgabewerte.component';

import {MainMenuGuard} from './main-menu.guard';
import { TestComponent } from './test/test.component';
import { TreeMapComponent } from './baumkontrolle/tree-map/tree-map.component';

const routes: Routes = [
  {path: '', component: IndexComponent},
  {path: ':client', redirectTo: ':client/dashboard', pathMatch: 'full'},
  {path: ':client', component: LoginComponent, children: [
      {path: 'dashboard', component: DashboardComponent},
      {path: 'fahrzeugverfolgung', component: FahrzeugverfolgungComponent, 
      canActivate: [MainMenuGuard],
      children: [
        {path: 'fahrzeugortung', component: FahrzeugortungComponent},
        {path: 'fahrtenbuch', component: FahrtenbuchComponent},
        {path: 'poi_auswertung', component: PoiAuswertungComponent},
        {path: 'stammdaten', children: [
          {path: 'einsatz', redirectTo: 'stammdaten'},
          {path: 'gps', redirectTo: 'stammdaten'}
        ]},
        {path: 'pze', children: [
          {path: 'projekterfassung', redirectTo: 'pze'},
          {path: 'zeiterfassung', redirectTo: 'pze'}
        ]}
      ]},
      {path: 'strassenkontrolle', 
        component: StrassenkontrolleComponent,
        canActivate: [MainMenuGuard] // TODO: Routeguards richtig umsetzen, damit es auf den jeweiligen Account abgestimmt ist. 
      },
      {path: 'baumkontrolle', redirectTo: 'baumkontrolle/baumuebersicht', pathMatch: 'full'},
      {path: 'baumkontrolle/karte', component: TreeMapComponent, pathMatch: 'full'},
      {path: 'baumkontrolle', 
        component: BaumkontrolleComponent, 
        canActivate: [MainMenuGuard],
        children: [
          {path: 'baumuebersicht', component: BaumuebersichtComponent},
          {path: 'baumarten', component: BaumartenComponent},
          {path: 'testseite', component: TestComponent},
          {path: 'stammdaten', 
            children: [
              {path: 'lieferant', component: LieferantComponent},
              {path: 'vorgabewerte', component: BaumVorgabewerteComponent}
            ]
          }
        ]
      },
      {path: 'spielplatzkontrolle', redirectTo: 'spielplatzkontrolle/spielplätze', pathMatch: 'full'},
      {path: 'spielplatzkontrolle', 
        component: SpielplatzkontrolleComponent,
        canActivate: [MainMenuGuard],
        children: [
          {path: 'spielplätze', component: SpielplaetzeComponent},
          {path: 'kontrollen', component: KontrollenComponent},
          {path: 'stammdaten', 
            children: [
            {path: 'lieferant', component: LieferantComponent},
            {path: 'inventartypkatalog', component: InventartypkatalogComponent},
            {path: 'vorgabewerte', component: VorgabewerteComponent}
          ]}
        ]},
      //{path: '', redirectTo: 'dashboard', pathMatch: 'full'}
      //{path: '', redirectTo: 'spielplatzkontrolle', pathMatch: 'full'}
    ]
  },
  {path: 'api', redirectTo: '', pathMatch: 'full'} // !TODO: Routeguard o. Ä. einbauen, damit auf /api nicht zugegriffen werden kann
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {paramsInheritanceStrategy: 'always', enableTracing: false, scrollPositionRestoration: 'disabled', anchorScrolling: 'enabled', relativeLinkResolution: 'legacy'})],
  exports: [RouterModule]
})
export class AppRoutingModule { }


// {path: 'fahrzeugverfolgung', loadChildren: () => import(`./fahrzeugverfolgung/fahrzeugverfolgung.module`).then(m => m.FahrzeugverfolgungModule)},
