<div class="inspection-photo-container">
    <div class="inspection-photo-thumbnail-container"
        [ngClass]="photoDialogIsClickable ? 'is-clickable-child' : 'is-forbidden-child'" (click)="showPhotoDialog()">
        <app-show-thumbnail [thumbnailID]=thumbnailID class="inspection-photo-thumbnail">

        </app-show-thumbnail>
    </div>
    <ng-container *ngIf="actualCount !== 0; else noPhotos">
        <div class="inspection-photo-thumbnail-count">Fotos gesamt: {{actualCount}} </div>
    </ng-container>
    <ng-template #noPhotos>
        <div class="inspection-photo-thumbnail-nophoto">
            <!-- Keine Fotos vorhanden -->
        </div>
    </ng-template>
</div>