import { NgModule } from "@angular/core";
import { AppComponent } from "./app.component";
import { GlobalModulesModule } from "./global-modules.module";
import { CustomGlobalModules } from "./custom-global-modules.module";
import { IndexModule } from "./index/index.module";
import { LoginModule } from "./login/login.module";
import { DashboardModule } from "./dashboard/dashboard.module";
import { FahrzeugverfolgungModule } from "./fahrzeugverfolgung/fahrzeugverfolgung.module";
import { StrassenkontrolleModule } from "./strassenkontrolle/strassenkontrolle.module";
import { SpielplatzkontrolleModule } from "./spielplatzkontrolle/spielplatzkontrolle.module";
import { BaumkontrolleModule } from "./baumkontrolle/baumkontrolle.module";
import { TestComponent } from "./test/test.component";

import { ShowPicturesComponent } from "./modules/functions/show-pictures/show-pictures.component";
import { SanitizePipe } from "./pipes/sanitize.pipe";
import { ShowColorCodeComponent } from "./modules/show-color-code/show-color-code.component";
import { PictureDialogComponent } from "./modules/functions/show-pictures/picture-dialog/picture-dialog.component";
import { EditComplexDataComponent } from "./modules/functions/edit-complex-data/edit-complex-data.component";
import { AddPictureComponent } from "./modules/functions/add-picture/add-picture.component";
import { EditPicturesComponent } from "./modules/functions/edit-pictures/edit-pictures.component";
import { DeletePictureComponent } from "./modules/functions/delete-picture/delete-picture.component";
import { AddPictureDialogComponent } from "./modules/functions/add-picture/add-picture-dialog/add-picture-dialog.component";
import { EditPicturesDialogComponent } from "./modules/functions/edit-pictures/edit-pictures-dialog/edit-pictures-dialog.component";
import { CheckUndefinedPipe } from "./pipes/check-undefined.pipe";
import { EditNoteComponent } from "./modules/functions/edit-note/edit-note.component";
import { ManageAddressComponent } from "./modules/functions/manage-address/manage-address.component";
import { ManageAddressDialogComponent } from "./modules/functions/manage-address/manage-address-dialog/manage-address-dialog.component";
import { SelectSupplierComponent } from './modules/functions/select-supplier/select-supplier.component';
import { ManageInventoryItemComponent } from './modules/functions/manage-inventory-item/manage-inventory-item.component';
import { ShowInspectionStateComponent } from './modules/playground-inspections/show-inspection-state/show-inspection-state.component';
import { ManageInventoryItemDialogComponent } from './modules/functions/manage-inventory-item/manage-inventory-item-dialog/manage-inventory-item-dialog.component';
import { SimpleDatachoiceComponent } from './modules/functions/simple-datachoice/simple-datachoice.component';
import { SimpleDatachoiceDialogComponent } from './modules/functions/simple-datachoice/simple-datachoice-dialog/simple-datachoice-dialog.component';
import { PickInventoryTypeComponent } from './modules/functions/pick-inventory-type/pick-inventory-type.component';
import { PickInventoryTypeDialogComponent } from './modules/functions/pick-inventory-type/pick-inventory-type-dialog/pick-inventory-type-dialog.component';
import { ManageInspectionComponent } from './modules/playground-inspections/manage-inspection/manage-inspection.component';
import { ManageInspectionDialogComponent } from './modules/playground-inspections/manage-inspection/manage-inspection-dialog/manage-inspection-dialog.component';
import { DataSelectComponent } from './modules/functions/data-select/data-select.component';
import { InputEventsComponent } from './modules/experiments/input-events/input-events.component';
import { PickAddressSimpleComponent } from './modules/functions/pick-address-simple/pick-address-simple.component';
import { InputFilterSelectAddComponent } from './modules/functions/input-filter-select-add/input-filter-select-add.component';
import { DragDropSortingComponent } from './modules/functions/drag-drop-sorting/drag-drop-sorting.component';
import { PickInventoryDisplayComponent } from './modules/functions/pick-inventory-display/pick-inventory-display.component';
import { PickInventoryDialogComponent } from './modules/functions/pick-inventory-display/pick-inventory-dialog/pick-inventory-dialog.component';
import { PickIdentcarrierComponent } from './modules/functions/pick-identcarrier/pick-identcarrier.component';
import { IdentCarrierDialogComponent } from './modules/functions/pick-identcarrier/ident-carrier-dialog/ident-carrier-dialog.component';
import { PickAcquisitionComponent } from './modules/functions/pick-acquisition/pick-acquisition.component';
import { PickAcquisitionDialogComponent } from './modules/functions/pick-acquisition/pick-acquisition-dialog/pick-acquisition-dialog.component';
import { PickConditionComponent } from './modules/functions/pick-condition/pick-condition.component';
import { PickConditionDialogComponent } from './modules/functions/pick-condition/pick-condition-dialog/pick-condition-dialog.component';
import { MediatorService } from "./services/mediator/mediator.service";



@NgModule({
  declarations: [
    AppComponent,

    // =====needs to be deleted or sorted=====
    ShowPicturesComponent,
    SanitizePipe,
    ShowColorCodeComponent,
    PictureDialogComponent,
    EditComplexDataComponent,
    AddPictureComponent,
    EditPicturesComponent,
    DeletePictureComponent,
    AddPictureDialogComponent,
    EditPicturesDialogComponent,
    CheckUndefinedPipe,
    EditNoteComponent,
    ManageAddressComponent,
    ManageAddressDialogComponent,
    SelectSupplierComponent,
    ManageInventoryItemComponent,
    ShowInspectionStateComponent,
    ManageInventoryItemDialogComponent,
    SimpleDatachoiceComponent,
    SimpleDatachoiceDialogComponent,
    PickInventoryTypeComponent,
    PickInventoryTypeDialogComponent,
    ManageInspectionComponent,
    ManageInspectionDialogComponent,
    DataSelectComponent,
    InputEventsComponent,
    PickAddressSimpleComponent,
    InputFilterSelectAddComponent,
    DragDropSortingComponent,
    PickInventoryDisplayComponent,
    PickInventoryDialogComponent,
    PickIdentcarrierComponent,
    IdentCarrierDialogComponent,
    PickAcquisitionComponent,
    PickAcquisitionDialogComponent,
    PickConditionComponent,
    PickConditionDialogComponent,

    // =======================================

    TestComponent,
  ],
  imports: [
    GlobalModulesModule,
    CustomGlobalModules,

    IndexModule,
    LoginModule,
    DashboardModule,
    FahrzeugverfolgungModule,
    StrassenkontrolleModule,
    BaumkontrolleModule,
    SpielplatzkontrolleModule,
  ],
  providers: [
    MediatorService,
  ],
  bootstrap: [AppComponent],
  entryComponents: [
  ]
})
export class AppModule {}
