<span *ngIf="itemID; else newItem">
    <span (click)="manageInspection()">
        <svg class="table-inner-symbol" viewBox="0 0 24 24">
            <path fill="currentColor"
                d="M6,2C4.89,2 4,2.89 4,4V20A2,2 0 0,0 6,22H10V20.09L12.09,18H6V16H14.09L16.09,14H6V12H18.09L20,10.09V8L14,2H6M13,3.5L18.5,9H13V3.5M20.15,13C20,13 19.86,13.05 19.75,13.16L18.73,14.18L20.82,16.26L21.84,15.25C22.05,15.03 22.05,14.67 21.84,14.46L20.54,13.16C20.43,13.05 20.29,13 20.15,13M18.14,14.77L12,20.92V23H14.08L20.23,16.85L18.14,14.77Z" />
        </svg>
    </span>
</span>
<ng-template #newItem>
    <span (click)="manageInspection()">
        <svg class="table-inner-symbol" viewBox="0 0 24 24">
            <path fill="currentColor"
                d="M13,9H18.5L13,3.5V9M6,2H14L20,8V20A2,2 0 0,1 18,22H6C4.89,22 4,21.1 4,20V4C4,2.89 4.89,2 6,2M15,18V16H6V18H15M18,14V12H6V14H18Z" />
        </svg>
    </span>
</ng-template>