import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import gql from "graphql-tag";

export const getAllTreeData = gql`
    query getAll($offset: Int, $limit: Int, $args: Arguments) {
        Tree(offset: $offset, limit: $limit, args: $args)  @connection(key: "trees"){
            id,
            id_number,
            number,
            coordinate{
                id,
                longitude,
                latitude,
                altitude,
                timestamp,
                creation_date,
                creation_user,
                modify_date,
                modify_user,
                canDelete
            },
            address{
                id,
                country,
                postcode,
                location,
                district,
                street,
                house_number,
                latitude,
                longitude,
            },
            expiration,
            parent_record,
            plat_number,
            plant_date,
            age_expectation,
            log_date,
            supplier{
                id,
                number,
                name1,
                name2,
                address {
                    id,
                    country,
                    postcode,
                    location,
                    district,
                    street,
                    house_number,
                    latitude,
                    longitude,
                },
                note,
                creation_date,
                creation_user,
                modify_date,
                modify_user,
                canDelete,
            },
            inspection_interval,
            next_inspection,
            type{
                id,
                name_german,
                name_botanic,
            },
            plantation,
            planting_Type,
            training_method,
            location_condition,
            height,
            trunk_height,
            trunk_diameter,
            trunk_amount,
            crown_diameter,
            crown_roots,
            safety_precautions,
            danger_potential,
            vincinity_danger,
            root_condition,
            root_collar_condition,
            trunk_condition,
            crown_root_condition,
            crown_condition,
            damage_degree,
            disc_width,
            disc_length,
            underplanting,
            surface_stabilisation,
            distance_buildings,
            distance_curb,
            distance_street,
            distance_path,
            distance_available_surface,
            distance_border,
            distance_grove,
            distance_waters,
            distance_landline_power,
            distance_landline_phone,
            distance_landline_lighting,
            distance_earth_wire_power,
            distance_earth_wire_phone,
            distance_earth_wire_lighting,
            distance_earth_wire_tv,
            distance_earth_wire_water,
            distance_earth_wire_sewage,
            distance_earth_wire_gas,
            distance_earth_wire_teleheating,
            control_group{
                id,
                subject {
                    id,
                    shortcut,
                    description,
                    note,
                    creation_date,
                    creation_user,
                    modify_date,
                    modify_user,
                    canDelete,
                }
                number,
                name,
                creation_date,
                creation_user,
                modify_date,
                modify_user,
                canDelete
            }
            notice,
            creation_date,
            creation_user,
            modify_date,
            modify_user,
            canDelete
        }
    }
`

export const getSingleTreeData = gql`
    query getSingleTreeData (
        $id: Int
    ) {
        Tree (id: $id){
            id,
            id_number,
            number,
            coordinate{
                id,
                longitude,
                latitude,
                altitude,
                timestamp,
                creation_date,
                creation_user,
                modify_date,
                modify_user,
                canDelete
            },
            address {
                id,
                country,
                postcode,
                location,
                district,
                street,
                house_number,
                latitude,
                longitude,
            },
            expiration,
            parent_record,
            plat_number,
            plant_date,
            age_expectation,
            log_date,
            supplier{
                id,
                number,
                name1,
                name2,
                address {
                    id,
                    country,
                    postcode,
                    location,
                    district,
                    street,
                    house_number,
                    latitude,
                    longitude,
                },
                note,
                creation_date,
                creation_user,
                modify_date,
                modify_user,
                canDelete,
            },
            inspection_interval,
            next_inspection,
            type{
                id,
                name_german,
                name_botanic,
            },
            plantation,
            planting_Type,
            training_method,
            location_condition,
            height,
            trunk_height,
            trunk_diameter,
            trunk_amount,
            crown_diameter,
            crown_roots,
            safety_precautions,
            danger_potential,
            vincinity_danger,
            root_condition,
            root_collar_condition,
            trunk_condition,
            crown_root_condition,
            crown_condition,
            damage_degree,
            disc_width,
            disc_length,
            underplanting,
            surface_stabilisation,
            distance_buildings,
            distance_curb,
            distance_street,
            distance_path,
            distance_available_surface,
            distance_border,
            distance_grove,
            distance_waters,
            distance_landline_power,
            distance_landline_phone,
            distance_landline_lighting,
            distance_earth_wire_power,
            distance_earth_wire_phone,
            distance_earth_wire_lighting,
            distance_earth_wire_tv,
            distance_earth_wire_water,
            distance_earth_wire_sewage,
            distance_earth_wire_gas,
            distance_earth_wire_teleheating,
            control_group{
                id,
                subject {
                    id,
                    shortcut,
                    description,
                    note,
                    creation_date,
                    creation_user,
                    modify_date,
                    modify_user,
                    canDelete,
                }
                number,
                name,
                creation_date,
                creation_user,
                modify_date,
                modify_user,
                canDelete
            }
            notice,
            creation_date,
            creation_user,
            modify_date,
            modify_user,
            canDelete
        }
    }
`

export const updateTreeData = gql`
    mutation update_Tree (
        $id: Int!,
        $id_number: String,
        $number: String,
        $coordinate: Int,
        $address: Int,
        $expiration: String,
        $parent_record: Int,
        $plat_number: String,
        $plant_date: String,
        $age_expectation: Float,
        $log_date: String,
        $supplier: Int,
        $inspection_interval: String,
        $next_inspection: String,
        $type: Int,
        $plantation: String,
        $planting_Type: String,
        $training_method: String,
        $location_condition: String,
        $height: Float,
        $trunk_height: Float,
        $trunk_diameter: Float,
        $trunk_amount: Float,
        $crown_diameter: Float,
        $crown_roots: Float,
        $safety_precautions: String,
        $danger_potential: String,
        $vincinity_danger: String,
        $root_condition: String,
        $root_collar_condition: String,
        $trunk_condition: String,
        $crown_root_condition: String,
        $crown_condition: String,
        $damage_degree: String,
        $disc_width: Float,
        $disc_length: Float,
        $underplanting: String,
        $surface_stabilisation: String,
        $distance_buildings: Float,
        $distance_curb: Float,
        $distance_street: Float,
        $distance_path: Float,
        $distance_available_surface: Float,
        $distance_border: Float,
        $distance_grove: Float,
        $distance_waters: Float,
        $distance_landline_power: Float,
        $distance_landline_phone: Float,
        $distance_landline_lighting: Float,
        $distance_earth_wire_power: Float,
        $distance_earth_wire_phone: Float,
        $distance_earth_wire_lighting: Float,
        $distance_earth_wire_tv: Float,
        $distance_earth_wire_water: Float,
        $distance_earth_wire_sewage: Float,
        $distance_earth_wire_gas: Float,
        $distance_earth_wire_teleheating: Float,
        $control_group: Int,
        $notice: String
    ) {
        update_Tree(
            id: $id,
            id_number: $id_number,
            number: $number,
            coordinate: $coordinate,
            address: $address,
            expiration: $expiration,
            parent_record: $parent_record,
            plat_number: $plat_number,
            plant_date: $plant_date,
            age_expectation: $age_expectation,
            log_date: $log_date,
            supplier: $supplier,
            inspection_interval: $inspection_interval,
            next_inspection: $next_inspection,
            type: $type,
            plantation: $plantation,
            planting_Type: $planting_Type,
            training_method: $training_method,
            location_condition: $location_condition,
            height: $height,
            trunk_height: $trunk_height,
            trunk_diameter: $trunk_diameter,
            trunk_amount: $trunk_amount,
            crown_diameter: $crown_diameter,
            crown_roots: $crown_roots,
            safety_precautions: $safety_precautions,
            danger_potential: $danger_potential,
            vincinity_danger: $vincinity_danger,
            root_condition: $root_condition,
            root_collar_condition: $root_collar_condition,
            trunk_condition: $trunk_condition,
            crown_root_condition: $crown_root_condition,
            crown_condition: $crown_condition,
            damage_degree: $damage_degree,
            disc_width: $disc_width,
            disc_length: $disc_length,
            underplanting: $underplanting,
            surface_stabilisation: $surface_stabilisation,
            distance_buildings: $distance_buildings,
            distance_curb: $distance_curb,
            distance_street: $distance_street,
            distance_path: $distance_path,
            distance_available_surface: $distance_available_surface,
            distance_border: $distance_border,
            distance_grove: $distance_grove,
            distance_waters: $distance_waters,
            distance_landline_power: $distance_landline_power,
            distance_landline_phone: $distance_landline_phone,
            distance_landline_lighting: $distance_landline_lighting,
            distance_earth_wire_power: $distance_earth_wire_power,
            distance_earth_wire_phone: $distance_earth_wire_phone,
            distance_earth_wire_lighting: $distance_earth_wire_lighting,
            distance_earth_wire_tv: $distance_earth_wire_tv,
            distance_earth_wire_water: $distance_earth_wire_water,
            distance_earth_wire_sewage: $distance_earth_wire_sewage,
            distance_earth_wire_gas: $distance_earth_wire_gas,
            distance_earth_wire_teleheating: $distance_earth_wire_teleheating,
            control_group: $control_group,
            notice: $notice
        ){
            result,
            error
        }
    }
`

export const addTreeData = gql`
    mutation add_Tree(
        $id_number: String,
        $number: String,
        $coordinate: Int,
        $address: Int,
        $expiration: String,
        $parent_record: Int,
        $plat_number: String,
        $plant_date: String,
        $age_expectation: Float,
        $log_date: String,
        $supplier: Int,
        $inspection_interval: String,
        $next_inspection: String,
        $type: Int,
        $plantation: String,
        $planting_Type: String,
        $training_method: String,
        $location_condition: String,
        $height: Float,
        $trunk_height: Float,
        $trunk_diameter: Float,
        $trunk_amount: Float,
        $crown_diameter: Float,
        $crown_roots: Float,
        $safety_precautions: String,
        $danger_potential: String,
        $vincinity_danger: String,
        $root_condition: String,
        $root_collar_condition: String,
        $trunk_condition: String,
        $crown_root_condition: String,
        $crown_condition: String,
        $damage_degree: String,
        $disc_width: Float,
        $disc_length: Float,
        $underplanting: String,
        $surface_stabilisation: String,
        $distance_buildings: Float,
        $distance_curb: Float,
        $distance_street: Float,
        $distance_path: Float,
        $distance_available_surface: Float,
        $distance_border: Float,
        $distance_grove: Float,
        $distance_waters: Float,
        $distance_landline_power: Float,
        $distance_landline_phone: Float,
        $distance_landline_lighting: Float,
        $distance_earth_wire_power: Float,
        $distance_earth_wire_phone: Float,
        $distance_earth_wire_lighting: Float,
        $distance_earth_wire_tv: Float,
        $distance_earth_wire_water: Float,
        $distance_earth_wire_sewage: Float,
        $distance_earth_wire_gas: Float,
        $distance_earth_wire_teleheating: Float,
        $control_group: Int,
        $notice: String
    ) {
        add_Tree(
            id_number: $id_number,
            number: $number,
            coordinate: $coordinate,
            address: $address,
            expiration: $expiration,
            parent_record: $parent_record,
            plat_number: $plat_number,
            plant_date: $plant_date,
            age_expectation: $age_expectation,
            log_date: $log_date,
            supplier: $supplier,
            inspection_interval: $inspection_interval,
            next_inspection: $next_inspection,
            type: $type,
            plantation: $plantation,
            planting_Type: $planting_Type,
            training_method: $training_method,
            location_condition: $location_condition,
            height: $height,
            trunk_height: $trunk_height,
            trunk_diameter: $trunk_diameter,
            trunk_amount: $trunk_amount,
            crown_diameter: $crown_diameter,
            crown_roots: $crown_roots,
            safety_precautions: $safety_precautions,
            danger_potential: $danger_potential,
            vincinity_danger: $vincinity_danger,
            root_condition: $root_condition,
            root_collar_condition: $root_collar_condition,
            trunk_condition: $trunk_condition,
            crown_root_condition: $crown_root_condition,
            crown_condition: $crown_condition,
            damage_degree: $damage_degree,
            disc_width: $disc_width,
            disc_length: $disc_length,
            underplanting: $underplanting,
            surface_stabilisation: $surface_stabilisation,
            distance_buildings: $distance_buildings,
            distance_curb: $distance_curb,
            distance_street: $distance_street,
            distance_path: $distance_path,
            distance_available_surface: $distance_available_surface,
            distance_border: $distance_border,
            distance_grove: $distance_grove,
            distance_waters: $distance_waters,
            distance_landline_power: $distance_landline_power,
            distance_landline_phone: $distance_landline_phone,
            distance_landline_lighting: $distance_landline_lighting,
            distance_earth_wire_power: $distance_earth_wire_power,
            distance_earth_wire_phone: $distance_earth_wire_phone,
            distance_earth_wire_lighting: $distance_earth_wire_lighting,
            distance_earth_wire_tv: $distance_earth_wire_tv,
            distance_earth_wire_water: $distance_earth_wire_water,
            distance_earth_wire_sewage: $distance_earth_wire_sewage,
            distance_earth_wire_gas: $distance_earth_wire_gas,
            distance_earth_wire_teleheating: $distance_earth_wire_teleheating,
            control_group: $control_group,
            notice: $notice
        ){
            result,
            error
        }
    }
`

export const deleteTreeData = gql`
    mutation delete_Tree($id: Int!) {
        delete_Tree(id: $id){
            result,
            error
        }
    }
`

@NgModule({
    declarations: [],
    imports: [CommonModule]
  })
export class TreeQueriesModule {}