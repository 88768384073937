import { Component, forwardRef, Input, OnInit } from "@angular/core";
import { MatDialog, MatDialogConfig } from "@angular/material/dialog";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";
import { GraphqlService } from "src/app/services/graphql.service";
import {
  FormGroup,
  FormControl,
  Validators,
  FormBuilder
} from "@angular/forms";
// import { getDefaultValuesQuery } from "src/app/gql-queries/default-value-queries/default-value-queries.module";


@Component({
  selector: 'app-data-select',
  templateUrl: './data-select.component.html',
  styleUrls: ['./data-select.component.sass'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DataSelectComponent),
      multi: true
    }
  ]
})
export class DataSelectComponent implements ControlValueAccessor, OnInit {

  form: FormGroup;
  requestQuery: any;
  
  @Input() dataArray: any;
  @Input() mutationQuery: any;
  @Input() refreshQueries: any[];
  @Input("value") val: any;

  constructor(
    private formBuilder: FormBuilder,
    private graphQLService: GraphqlService,
    public dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.form = new FormGroup({
      dataInput: new FormControl()
    });

    /*

    this.requestQuery = this.graphQLService
    .getData(getDefaultValuesQuery)
    .valueChanges.subscribe(result => {
      console.log(result);


    });
    */

  }

  onChange: any = () => {};
  onTouched: any = () => {};

  get value() {
    return this.val;
  }

  set value(val) {
    this.val = val;
    this.onChange(val);
    this.onTouched();
  }

  // Ok, seien wir ehlich: Ich habe an dieser Stelle noch nicht verstanden, wie das genau funktioniert mit dem Setzen von 'value'.
  // Es kann sein, dass das eine oder andere redundant ist, aber ich hab an dieser Gülle vier Tage rumgebastelt und jetzt funktioniert es endlich. - No questions asked.

  writeValue(inputValue) {
    this.value = inputValue;
    this.form.controls.dataInput.setValue(inputValue);
  }

  registerOnChange(fn) {
    this.onChange = fn;
  }

  registerOnTouched(fn) {
    this.onTouched = fn;
  }

  refineData(dataSource, field) {
    let returnArray = [];

    dataSource.data["Default_Values"].forEach(object => {
      if (object.field === field) {
        returnArray.push(object.value);
      }
    });

    return returnArray;
  }

  selectData() {


  }

}
