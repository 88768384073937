import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Output,
  EventEmitter,
  Input,
  AfterViewInit,
  ChangeDetectorRef,
} from "@angular/core";
import { Sort } from "@angular/material/sort";
import { cloneDeep } from "@apollo/client/utilities";
import { combineLatest, Subject, Subscription, timer } from "rxjs";
import { startWith } from "rxjs/operators";
import { DeleteDataComponent } from "src/app/modules/functions/delete-data/delete-data.component";
import { TreeDamageListingService } from "src/app/services/tree/tree-damages/tree-damage-listing.service";
import { TreeDamagesService } from "src/app/services/tree/tree-damages/tree-damages.service";
import { TreeMeasuresService } from "src/app/services/tree/tree-measures.service";
import { AddEditControlMessageComponent } from "./add-edit-control-message/add-edit-control-message.component";

@Component({
  selector: "app-control-message-management",
  templateUrl: "./control-message-management.component.html",
  styleUrls: ["./control-message-management.component.sass"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ControlMessageManagementComponent implements OnInit {
  @Input() public record;
  @Output() values = new EventEmitter();

  public addEditControlMessage = AddEditControlMessageComponent;
  public deleteData = DeleteDataComponent;
  private treeDamages = [];

  public sortedTreeDamages: any[];

  constructor(
    private treeDamagesService: TreeDamagesService,
    private treeDamageListingService: TreeDamageListingService,
    private treeMeasuresService: TreeMeasuresService,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.treeDamagesService
      .getTreeDamagesDataByTree(this.record.id)
      .subscribe((result) => {
        this.treeDamages = cloneDeep(result);

        let multipleDamgeListingSubs = [];
        let multipleMeasuresSubs = [];

        // create measure and damagelisting observables for each tree damage
        this.treeDamages.forEach((treeDamage) => {
          if (treeDamage.tree_damage_listing) {
            multipleDamgeListingSubs.push(
              this.treeDamageListingService.getSingleTreeDamageListing(
                treeDamage.tree_damage_listing
              )
            );
          } else {
            multipleDamgeListingSubs.push(
              new Subject().pipe(startWith([null]))
            );
          }

          if (treeDamage.id) {
            multipleMeasuresSubs.push(
              this.treeMeasuresService.getTreeMeasuresByDamage(treeDamage.id)
            );
          } else {
            multipleMeasuresSubs.push(new Subject().pipe(startWith([null])));
          }
        });

        // combine all measure and damagelisting observables
        combineLatest([
          combineLatest(multipleDamgeListingSubs),
          combineLatest(multipleMeasuresSubs),
        ]).subscribe((result) => {
          // data processing
          const treeDamageListings = result[0];
          const damageMeasures = result[1];
          this.treeDamages.forEach((treeDamage, i) => {
            treeDamage.tree_damage_listing = treeDamageListings[i][0];
            treeDamage.measures =
              damageMeasures[i] &&
              JSON.stringify(damageMeasures[i]) !== "[null]"
                ? damageMeasures[i]
                : null;
          });

          // sort damages and update view
          this.sortData({ active: "date", direction: "asc" });
          this.cd.detectChanges();
        });
      });
  }

  sortData(sort: Sort) {
    const data = this.treeDamages.slice();
    if (!sort.active || sort.direction === "") {
      this.sortedTreeDamages = data;
      return;
    }

    this.sortedTreeDamages = data.sort((a, b) => {
      const isAsc = sort.direction === "asc";
      switch (sort.active) {
        case "date":
          return compareDates(a.date, b.date, isAsc);
        case "type":
          return compareStrings(a.type, b.type, isAsc);
        case "treePart":
          return compareStrings(a.treePart, b.treePart, isAsc);
        case "position":
          return compareStrings(a.position, b.position, isAsc);
        case "damage":
          return compareStrings(
            typeof a.tree_damage_listing === "object"
              ? "[" +
                  a.tree_damage_listing.number +
                  "] " +
                  a.tree_damage_listing.damage_type
              : "--",
            typeof b.tree_damage_listing === "object"
              ? "[" +
                  b.tree_damage_listing.number +
                  "] " +
                  b.tree_damage_listing.damage_type
              : "--",
            isAsc
          );
        default:
          return 0;
      }
    });
  }

  addTreeDamage(treeDamage) {
    treeDamage["tree"] = this.record.id;
    // console.log(treeDamage);
    this.treeDamagesService
      .addTreeDamageData(treeDamage)
      .subscribe((result) => {
        console.log(result);
      });
  }

  updateTreeDamage(treeDamage) {
    treeDamage["tree"] = this.record.id;
    this.treeDamagesService
      .updateTreeDamageData(treeDamage)
      .subscribe((result) => {
        console.log(result);
      });
  }

  deleteTreeDamage(treeDamage) {
    this.treeDamagesService
      .deleteTreeDamageData(treeDamage)
      .subscribe((result) => {
        console.log(result);
      });
  }
}

function compareStrings(
  a: number | string,
  b: number | string,
  isAsc: boolean
) {
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}

function compareDates(date1: string, date2: string, isAsc: boolean): number {
  const a = new Date(date1);
  const b = new Date(date2);

  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}
