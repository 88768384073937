import { DataIndex } from "../dataobject/dataobject";
import { dataQueries } from "../../gql-queries/query-register.module";
import { GraphqlService } from "../../services/graphql.service";
import { Subject, Subscription } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { QueryRef } from "apollo-angular";
import { cloneDeep } from "@apollo/client/utilities";
import { gql, useQuery } from '@apollo/client';

/**
 * Example: 
 * 
    this.dataObject = new DataobjectGraphQL("supplier", this.graphQLService);

    let subscribeData = this.dataObject.dataObserver.subscribe( (result) => {
      console.log(result);
      this.tester.data = result;
    })
 */

export class DataobjectGraphQL extends DataIndex {
  subscriptionObject: any;
  hasReceivedInitialData: Boolean = false;

  static isLoggedIn = false;
  static _queryList = [];
  private _dataObserver = new Subject<object>();
  private _stateObserver = new Subject<object>();

  private queryRefObj: object = {};
  private feed;
  private requestAllDataSubscription: Subscription;

  /**
   * 
   * @param graphQlObject 
   * @param graphQL 
   * @param singleItem 
   * @param itemID 
   * @param onlyNewest 
   * @param customQuery
   * @param queryRefName
   * @param dataSource 
   * 
   */
  constructor(
    private graphQlObject: string,
    private graphQL?: GraphqlService,
    private singleItem?: Boolean,
    private itemID?: number,
    private onlyNewest?: Boolean,
    private offset?: number,
    private limit?: number,
    private queryRefName?: string,
    private args?: object,
    private onlyAddEdit?: Boolean, // siehe coordinate.service.ts
    dataSource?: object,
  ) {
    super(dataSource);

    this._dataObserver.asObservable();

    if (!this.graphQL) {
      return;
    }
    // if(this.offset !== undefined && this.limit !== undefined){
    //   console.log("request Data Section of " + this.graphQlObject)
    //   this.requestDataSection(this.graphQL, this.graphQlObject);
    //   return;
    // }
    // if(customQuery){
    //   console.log("request custom Attributes of " + this.graphQlObject);
    //   this.requestCustomData(this.graphQL, this.graphQlObject, this.customQuery);
    //   return;
    // }
    if(this.onlyAddEdit){return;}
    if (!this.singleItem) {
      console.log("DataobjectGraphQL: requestAllData (" + this.graphQlObject + ")");
      this.requestAllData(this.graphQL, this.graphQlObject, this.offset, this.limit, this.args);
      return;
    }
    if (this.itemID) {
      if (!this.onlyNewest) {
        this.onlyNewest = false;
      }
      if (dataQueries[this.graphQlObject].getSingle) {
        this.subscriptionObject = this.graphQL
          .getItem(dataQueries[this.graphQlObject].getSingle, {
            id: this.itemID,
            onlyNewest: this.onlyNewest
          })
          .valueChanges.subscribe(result => {
            if (typeof result.data === "object") {
              let resultCopy = cloneDeep(result.data);
              this.dataStore = resultCopy[
                Object.keys(result.data)[0]
              ].pop();
              this.hasReceivedInitialData = true;
              this._dataObserver.next(this.dataStore);
            }
          });
        this._updateInternList(this.graphQlObject);
      } else {
        console.error(
          "getSingle Query for " +
          graphQlObject +
          " not found in query register"
        );
      }
    } else {
      console.error("itemID for " + this.graphQlObject + " not provided");
    }
  }

  /**
   *
   */
  get dataObserver() {
    return this._dataObserver;
  }

  /**
   * Durch diese Funktion wird verhindert, dass nach dem Einloggen
   * noch einmal manuell neu geladen werden muss, weil die Verbindung zum
   * Server noch nicht gegeben ist. 
   * @param graphQL 
   * @param graphQlObject 
   */
  requestAllData(graphQL: GraphqlService, graphQlObject: string, offset?: number, limit?: number, args?: object) {
    if (dataQueries[graphQlObject].getAll) {
      this.subscriptionObject = graphQL
        .getData(dataQueries[graphQlObject].getAll, offset, limit, args);

      if(this.queryRefName){
        this.queryRefObj[this.queryRefName] = this.subscriptionObject
      }

      if(this.requestAllDataSubscription){
        this.requestAllDataSubscription.unsubscribe();
      }


      this.requestAllDataSubscription = this.subscriptionObject.valueChanges.subscribe(result => {
        if (typeof result.data === "object") {
          this.dataStore = result.data[Object.keys(result.data)[0]];
          this.hasReceivedInitialData = true;


          this._dataObserver.next(this.dataStore);

          this.offset = this.queryRefName ? this.offset+this.limit : this.offset;
        }
      }, error => {
        console.error(error);
        console.log("Teste Verbindung und frage Daten für " + graphQlObject + " erneut an");

        // TODO: recomment in production environment
        // graphQL.provideLoginState().subscribe(state => {
        //   if (state["state"]) {
        //     this.requestAllData(graphQL, graphQlObject);
        //   }
        // });
      });

      this._updateInternList(graphQlObject);
    } else {
      console.error(
        "getAll Query for " + graphQlObject + " not found in query register"
      );
    }
  }
  
  loadMore() {
    this.queryRefObj[this.queryRefName].fetchMore({
      variables: {
        offset: this.offset
      }
    });
  }

  reload(filter) {
    this.offset = 0;
    this.args['filter'] = filter;
    this.requestAllDataSubscription.unsubscribe();
    this.requestAllData(this.graphQL, this.graphQlObject, this.offset, this.limit, this.args);

    // this.graphQL.getData(dataQueries[this.graphQlObject].getAll, this.offset, this.limit, this.args).valueChanges.subscribe(result => {
    //   console.log("test", this.args, result);
    // });
  }


  /**
   *
   * @param mutationVariables Objekt mit den jeweiligen Daten
   */
  addData(mutationVariables, reload?:boolean) {
    if (dataQueries[this.graphQlObject].add) {
      // console.log(DataobjectGraphQL._queryList);
      let mutationResult = this.graphQL.asyncMutation(
        dataQueries[this.graphQlObject].add,
        mutationVariables,
        this.args && this.args['filter']?this.args['filter']:null,
        ...[dataQueries[this.graphQlObject].getAll] //könnte fehler verursachen, siehe auch deleteData() und updateData()
        // ...DataobjectGraphQL._queryList
      );
      mutationResult.subscribe(({ data }) => {
        console.log('got data', data);
        this._stateObserver.next({ data });
        if(reload){
          this.reload(this.args['filter']);
        }
      }, (error) => {
        console.log('there was an error sending the query', error);
        alert("Anfrage konnte nicht gesendet werden. Grund: " + error);
      });
      return this._stateObserver;
    } else {
      console.error(
        "add Query for " + this.graphQlObject + " not found in query register"
      );
    }
  }

  /**
   *
   * @param mutationVariables Objekt mit den jeweiligen Daten
   */
  updateData(mutationVariables) {
    if (dataQueries[this.graphQlObject].update) {
      this.graphQL.sendMutation(
        dataQueries[this.graphQlObject].update,
        mutationVariables,
        this.args && this.args['filter']?this.args['filter']:null,
        ...[dataQueries[this.graphQlObject].getAll] //könnte fehler verursachen, siehe auch deleteData() und addData()
        // ...DataobjectGraphQL._queryList
      );
    } else {
      console.error(
        "update Query for " +
        this.graphQlObject +
        " not found in query register"
      );
    }
  }

  /**
   *
   * @param mutationVariables Objekt mit der zu löschenden ID
   */
  deleteData(mutationVariables) {
    if (dataQueries[this.graphQlObject].delete) {
      this.graphQL.asyncMutation(
        dataQueries[this.graphQlObject].delete,
        mutationVariables,
        this.args && this.args['filter']?this.args['filter']:null,
        ...[dataQueries[this.graphQlObject].getAll] //könnte fehler verursachen, siehe auch addData() und updateData()
        // ...DataobjectGraphQL._queryList
      ).subscribe(({ data }) => {
        console.log('got data', data);
        this._stateObserver.next({ data });
      }, (error) => {
        console.log('there was an error sending the query', error, '|', mutationVariables, '|');
        alert("Anfrage konnte nicht gesendet werden. Grund: " + error);
      });
      return this._stateObserver;
    } else {
      console.error(
        "delete Query for " +
        this.graphQlObject +
        " not found in query register"
      );
    }
  }

  // At the moment this intern list got one problem: Since it is possible to
  // have several instances of one query at the same time, it would need
  // an intern counter for all open queries as well, just to be able to delete them.
  // This is more work to be done than use gotten out of.
  // So at this point this list contains all queries registered at some point
  // until the whole memory is reset by freshing everything.
  _updateInternList(item) {
    if (DataobjectGraphQL._queryList.indexOf(dataQueries[item].getAll) === -1) {
      DataobjectGraphQL._queryList.push(dataQueries[item].getAll);
    }
  }
}
