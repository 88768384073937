<div id="button-wrapper">
  <button mat-icon-button [mat-dialog-close]="true">
    <mat-icon fontSet="material-symbols-outlined">close</mat-icon>
  </button>
</div>

<div id="gallery-wrapper">
  <div id="gallery-container">
    <span (click)="lastGalleryImage()">
      <svg class="switchImageButton" viewBox="0 0 24 24">
        <path fill="currentColor" d="M15.41,16.58L10.83,12L15.41,7.41L14,6L8,12L14,18L15.41,16.58Z" />
      </svg>
    </span>
    <div id="gallery-image">
      <img [src]=currentImage()>
    </div>
    <span (click)="nextGalleryImage()">
      <svg class="switchImageButton" viewBox="0 0 24 24">
        <path fill="currentColor" d="M8.59,16.58L13.17,12L8.59,7.41L10,6L16,12L10,18L8.59,16.58Z" />
      </svg>
    </span>
  </div>

  <div id="thumb-gallery-container">
    <div id="thumb-gallery-pan-left" (click)="panThumbsLeft()">
      <svg class="thumb-panning-icon" viewBox="0 0 24 24">
        <path fill="currentColor"
          d="M2,12A10,10 0 0,1 12,2A10,10 0 0,1 22,12A10,10 0 0,1 12,22A10,10 0 0,1 2,12M18,11H10L13.5,7.5L12.08,6.08L6.16,12L12.08,17.92L13.5,16.5L10,13H18V11Z" />
      </svg> </div>
    <div #thumbGallery id="thumb-gallery" class="dragging-container">
      <div #thumbSlider id="thumb-slider" appDragging>
        <div *ngFor="let thumb of thumbImages; let index = index">
          <img [src]="thumb" alt="image" (mousedown)="thumbClicked({event:$event, index:index})" draggable="false">
        </div>
      </div>
    </div>
    <div id="thumb-gallery-pan-left" (click)="panThumbsRight()">
      <svg class="thumb-panning-icon" viewBox="0 0 24 24">
        <path fill="currentColor"
          d="M22,12A10,10 0 0,1 12,22A10,10 0 0,1 2,12A10,10 0 0,1 12,2A10,10 0 0,1 22,12M6,13H14L10.5,16.5L11.92,17.92L17.84,12L11.92,6.08L10.5,7.5L14,11H6V13Z" />
      </svg>
    </div>
  </div>
</div>