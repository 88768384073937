<h4 mat-dialog-title>Inventartyp</h4>

<div class="inventory-table-container">
    <table *ngFor="let inventoryTypeCategory of inventoryTypeValues | keyvalue" class="inventory-category-table">
        <th class="inventory-category">{{inventoryTypeCategory.key}}</th>
        <th class="inventory-category-actions">
            <!-- <app-add-dialog-icon (click)="openDialog('kategorie', 'add')">
            </app-add-dialog-icon>
            <app-edit-dialog-icon
                (click)="openDialog('kategorieTyp', 'editType', {category: inventoryTypeCategory.key})">
            </app-edit-dialog-icon> -->
        </th>
        <tr *ngFor="let inventoryType of inventoryTypeCategory.value; let i = index">
            <td class="inventory-description" (click)="choseInventoryType({event: $event, type: inventoryType})">
                {{inventoryType.description}}
            </td>
            <td class="inventory-action">
                <!-- <app-edit-dialog-icon (click)="openDialog('kategorie', 'edit', inventoryType)">
                </app-edit-dialog-icon> -->
                <!-- <app-delete-data [deleteData]='{id: inventoryType.id}' (returnInput)=deleteValue($event)>
                </app-delete-data> -->
            </td>
        </tr>
    </table>
</div>

<div class="dialog-functions">
    <div [formGroup]="filterForm" class="filter-form">
        <label>Suchen:</label>
        <div class="filter-field-container">
            <input class="input" formControlName="filter" (keydown)="filterInventoryTypes($event)">
            <svg viewBox="0 0 24 24" (click)="removeCurrentFilter()" class="remove-filter-icon">
                <path fill="currentColor"
                    d="M14.76,20.83L17.6,18L14.76,15.17L16.17,13.76L19,16.57L21.83,13.76L23.24,15.17L20.43,18L23.24,20.83L21.83,22.24L19,19.4L16.17,22.24L14.76,20.83M12,12V19.88C12.04,20.18 11.94,20.5 11.71,20.71C11.32,21.1 10.69,21.1 10.3,20.71L8.29,18.7C8.06,18.47 7.96,18.16 8,17.87V12H7.97L2.21,4.62C1.87,4.19 1.95,3.56 2.38,3.22C2.57,3.08 2.78,3 3,3V3H17V3C17.22,3 17.43,3.08 17.62,3.22C18.05,3.56 18.13,4.19 17.79,4.62L12.03,12H12Z" />
            </svg>
        </div>
    </div>
    <app-pdf class="placeholder dialog-pdf-action" tooltip-data="Alle Spielplätze als PDF ausgeben"></app-pdf>
</div>


<span class="current-filter-display">Derzeitige Auswahl: {{currentTypeDescription}}</span>
<div class="dialog-actions">
    <button class="button" (click)="close()">Abbrechen</button>
    <button class="button is-primary" (click)="save()">OK</button>
</div>