import { NgModule } from "@angular/core";
import { GlobalModulesModule } from "../global-modules.module";
import { HeaderComponent } from "./header.component";


@NgModule({
  declarations: [
    HeaderComponent
  ],
  imports: [GlobalModulesModule],
  exports: [
    HeaderComponent
  ],
})
export class HeaderModule {}
