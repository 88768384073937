<h2 mat-dialog-title>{{head}}</h2>
<mat-dialog-content [formGroup]="form">
    <mat-form-field floatLabel="always" class="fullWidth" appearance="outline">
        <mat-label for="Name">Kategorie</mat-label>
        <input type="text" matInput formControlName="category" />
        <mat-error *ngIf="form.get('category').hasError('required')">
            Erforderlich
        </mat-error>
    </mat-form-field>
    <mat-form-field floatLabel="always" class="fullWidth" appearance="outline">
        <mat-label for="Sortierung">Inventar-Typ</mat-label>
        <input type="text" matInput formControlName="description" />
    </mat-form-field>
</mat-dialog-content>
 
<div class="row">
    <button class="submit" mat-flat-button (click)="save()" [disabled]="!form.valid">{{okButtonLabel}}</button>
    <button class="cancel" mat-flat-button mat-dialog-close>Abrechen</button>
</div>


<!-- <h2 mat-dialog-title>{{head}}</h2>
<div class="dialog-content" [formGroup]="form">
    <div class="dialog-content-container">
        <input autofocus class="focus-catcher">
        <div class="field dialog-field">
            <label class="has-text-danger is-required" for="Name">Kategorie</label>
            <app-input-filter-select-add #inputFilterSelectAdd [setSelectData]="setSelectData.asObservable()" formControlName="category"></app-input-filter-select-add>
            <label class="" for="Sortierung">Inventar-Typ</label>
            <input type="text" class="input number-counter" formControlName="description">
        </div>
    </div>
</div>
<div class="dialog-actions">
    <button class="button" (click)="close()">Abbrechen</button>
    <button class="button is-primary" (click)="save()" [disabled]="!form.valid">{{okButtonLabel}}</button>
</div> -->