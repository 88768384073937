/**
 * TODO: Eventuell ist diese Komponente obsolet. Sollte sich keine Verwendung mehr finden,
 * bitte in die ewigen Jagdgründe schicken
 */

import { Component, OnInit, Input } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ManageAddressDialogComponent } from './manage-address-dialog/manage-address-dialog.component';
import { EditPicturesDialogComponent } from 'src/app/modules/functions/edit-pictures/edit-pictures-dialog/edit-pictures-dialog.component';

@Component({
  selector: 'app-manage-address',
  templateUrl: './manage-address.component.html',
  styleUrls: ['./manage-address.component.sass']
})
export class ManageAddressComponent implements OnInit {

  @Input() addressData: any;

  constructor(public dialog: MatDialog) { }

  ngOnInit(): void {
    //console.log(this.addressData);
  }

  editAddress() {
   console.log("jupp");
    const dialogConfig = new MatDialogConfig();

    

    dialogConfig.disableClose = false;
    dialogConfig.autoFocus = false;
    dialogConfig.height = "1000px";
    dialogConfig.width = "1200px";

    dialogConfig.data = {
      dataTransfer: this.addressData
    }

    const dialogRef = this.dialog.open(ManageAddressDialogComponent, dialogConfig);
    
    dialogRef.afterClosed().subscribe(result => {
      console.log(result);
    })
  }

}
