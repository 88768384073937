import { Component, Input, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { DefaultValueAddEditFormComponent } from '../default-value-add-edit-form/default-value-add-edit-form.component';

@Component({
  selector: 'app-default-value-add-dialog',
  templateUrl: './default-value-add-dialog.component.html',
  styleUrls: ['./default-value-add-dialog.component.sass']
})
export class DefaultValueAddDialogComponent implements OnInit {
  @Input() color: boolean = false;


  constructor(public dialog: MatDialog) { }

  ngOnInit(): void {
  }

  openDialog(){

    event.stopPropagation();
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = false;
    dialogConfig.autoFocus = true;
    dialogConfig.width = '60vw';
    dialogConfig.height = 'calc(100% - 20vh)';
    dialogConfig.data = {
      color: this.color
    };

    const dialogRef = this.dialog.open(
      DefaultValueAddEditFormComponent,
      dialogConfig
    );

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        console.log(result);
      }
    });

  }
}
