import { Map } from "ol";
import Point from "ol/geom/Point";
import LineString from "ol/geom/LineString";
import Feature from "ol/Feature";
import { fromLonLat } from "ol/proj";
import { Vector } from "ol/source";
import VectorLayer from "ol/layer/Vector";
import { Style, Fill, Stroke, Text, Circle } from "ol/style";

import { Apollo } from "apollo-angular";
import { HttpParams, HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from "src/environments/environment";

export class DisplayGPSonMapObject {
  map: Map;
  apollo: Apollo;
  apolloQuery: any;
  device: number;
  deviceName: string;
  longitude: number;
  latitude: number;
  creationDate: string;

  panViewAt: boolean;
  pollInterval: number;

  vectorSource: any//Vector;
  marker: any; //Feature;
  markerText: Text;
  showMarker: boolean;
  markerLayer: any; //VectorLayer;

  isInitiatedLine: boolean;
  lineString: LineString;
  lineFeature: any; //Feature;
  lineVector: any //Vector;
  lineVectorLayer: any; //VectorLayer;

  initPromise: any;

  constructor(
    map: Map,
    apollo: Apollo,
    apolloQuery: any,
    device: number,
    deviceName: string,
    private http: HttpClient
  ) {
    this.map = map;
    this.apollo = apollo;
    this.apolloQuery = apolloQuery;
    this.device = device;
    this.deviceName = deviceName;

    this.pollInterval = 1000;

    this.panViewAt = false;
    this.showMarker = true;

    this.isInitiatedLine = false;

    this.initiateInitPromise();
    //this.getApolloData();
    this.getRestData();
  }

  initiatePointOnMap() {
    this.marker = new Feature({});

    let vectorSource = new Vector({
      features: [this.marker]
    });

    this.markerText = new Text({
      text: this.deviceName + " | ",
      font: "bold 12px",
      offsetX: 50,
      offsetY: -15,
      padding: [1, 5, 0, 5],
      backgroundFill: new Fill({
        color: "rgba(150, 179, 227, 0.5)"
      }),
      backgroundStroke: new Stroke({
        color: "rgba(255,255,255,0.75)",
        width: 1
      })
    });

    let textStyle = new Style({
      text: this.markerText,
      image: new Circle({
        radius: 6,
        fill: new Fill({
          color: "green"
        }),
        stroke: new Stroke({
          color: "white"
        })
      })
    });

    this.markerLayer = new VectorLayer({
      source: vectorSource,
      style: textStyle
    });

    this.map.addLayer(this.markerLayer);
  }

  initiateInitPromise() {
    var self = this;
    this.initPromise = new Promise<void>(function(resolve) {
      self.initiatePointOnMap();
      resolve();
    });
  }

  initiateLineString() {
    let initCoord1 = fromLonLat([this.longitude, this.latitude]);

    this.lineString = new LineString([initCoord1, initCoord1]);

    this.lineFeature = new Feature({
      geometry: this.lineString
    });

    this.lineVector = new Vector({
      features: [this.lineFeature]
    });

    this.lineVectorLayer = new VectorLayer({
      source: this.lineVector
    });

    this.map.addLayer(this.lineVectorLayer);
  }

  setLinePoint() {
    let lineDot = new Feature({
      geometry: new Point(fromLonLat([this.longitude, this.latitude]))
    });
    lineDot.setStyle(
      new Style({
        image: new Circle({
          radius: 2,
          fill: new Fill({
            color: "blue"
          })
        })
      })
    );

    this.lineVector.addFeature(lineDot);
  }

  getRestData() {
    this.http
      .request(
        "GET",
        environment.dataApiUrl + "gps_device_data?device_id=" + this.device
      )
      .subscribe(
        data => {
          console.log(data);
          if (data !== null) {
            this.longitude = data["longitude"];
            this.latitude = data["latitude"];
            this.creationDate = data["timestamp"];

            this.managePointOnMap();

            if (!this.isInitiatedLine) {
              this.isInitiatedLine = true;
              this.initiateLineString();
            } else {
              this.manageLineString();
            }
          }
          //this.setLinePoint();

          if (this.panViewAt) {
            this.map
              .getView()
              .setCenter(fromLonLat([this.longitude, this.latitude]));
          }

          window.setTimeout( () => {
            this.getRestData()
          }, 500);
        },
        error => {
          console.log(error);
          window.setTimeout( () => {
            this.getRestData()
          }, 500);
        }
      );
  }

  getApolloData() {
    let apolloQuery = this.apollo
      .watchQuery({
        query: this.apolloQuery,
        variables: {
          device: this.device //`${this.device}`
        },
        pollInterval: this.pollInterval
      })
      .valueChanges.subscribe(result => {
        console.log(result);
        if (result.data["GPS_Data_Query"].length !== 0) {
          console.log(result.data["GPS_Data_Query"][0]);
          this.longitude = result.data["GPS_Data_Query"][0].longitude;
          this.latitude = result.data["GPS_Data_Query"][0].latitude;
          this.creationDate = result.data["GPS_Data_Query"][0].timestamp;

          this.managePointOnMap();

          if (!this.isInitiatedLine) {
            this.isInitiatedLine = true;
            this.initiateLineString();
          } else {
            this.manageLineString();
          }

          //this.setLinePoint();

          if (this.panViewAt) {
            this.map
              .getView()
              .setCenter(fromLonLat([this.longitude, this.latitude]));
          }
        }
      });
  }

  managePointOnMap() {
    this.markerText.setText(this.deviceName + " | " + this.creationDate);
    this.marker.setGeometry(
      new Point(fromLonLat([this.longitude, this.latitude]))
    );
  }

  manageLineString() {
    this.lineString.appendCoordinate(
      fromLonLat([this.longitude, this.latitude])
    );
  }

  panViewTo(setView: boolean) {
    this.panViewAt = setView;

    if (this.longitude !== undefined) {
      if (this.panViewAt) {
        this.map
          .getView()
          .setCenter(fromLonLat([this.longitude, this.latitude]));
      }
    }
  }

  toggleMarker() {
    if (this.showMarker) {
      this.showMarker = false;
      this.map.removeLayer(this.markerLayer);
    } else {
      this.showMarker = true;
      this.map.addLayer(this.markerLayer);
    }
  }

  getTimeStatus() {
    let currentTime = Date.now();
    console.log(currentTime);
  }
}
