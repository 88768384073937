import { Component, Inject, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { GraphqlService } from "src/app/services/graphql.service";
import { FormGroup, FormBuilder, FormControl } from "@angular/forms";


@Component({
  selector: 'app-manage-inventory-item-dialog',
  templateUrl: './manage-inventory-item-dialog.component.html',
  styleUrls: ['./manage-inventory-item-dialog.component.sass']
})
export class ManageInventoryItemDialogComponent implements OnInit {
  form: FormGroup;

  dataTransfer: any;
  itemData: any;
  itemID: any;

  constructor(
    private graphQLService: GraphqlService,
    private formBuilder: FormBuilder,
    private dialogRef: MatDialogRef<ManageInventoryItemDialogComponent>,
    @Inject(MAT_DIALOG_DATA) data
  ) {
    this.itemID = data.itemID
    this.itemData = data.dialogData
  }


  ngOnInit(): void {
    this.form = new FormGroup({
      inventory_type: new FormControl(),
      inventory_number: new FormControl(),
      model: new FormControl(),
      ident_carrier_type: new FormControl(),
      material: new FormControl(),
      inventory_id: new FormControl(),
      age_class: new FormControl(),
      condition: new FormControl(),
      acquisition_type: new FormControl(),
      supplier: new FormControl(),
      inclusion_date: new FormControl(),
      manufacturer: new FormControl(),
      warranty_end: new FormControl(),
      article_number: new FormControl(),
      usage_end: new FormControl(),
      serial_number: new FormControl(),
      exclusion_date: new FormControl(),
      date_build: new FormControl(),
      dimension: new FormControl(),
      weight: new FormControl(),
      area: new FormControl(),
      fall_height: new FormControl(),
      fall_protection: new FormControl(),
      fall_protection_thickness: new FormControl(),
      note: new FormControl(),
      inspection_info: new FormControl()
    });

    if (this.itemID) {
      for (let item in this.itemData) {
        if (this.form.controls[item]) {
          this.form.controls[item].setValue(this.itemData[item]);
        }
      }
    }


  }

  save() {
    this.dialogRef.close(null);
  }

  close() {
    this.dialogRef.close(null);
  }
}
