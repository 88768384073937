<!-- <header>
    <nav role="navigation" aria-label="main navigation">
        <div class="nav-wrapper">
            <div>
                <a [routerLink]="'/'+clientId+'/dashboard'" class="nav-icon">
                    <img src="assets/img/aidageo_logo.png">
                </a>
            </div>
            <div class="nav-main">
                <div class="nav-list">

                    
                    <a [routerLink]="'/'+clientId+'/fahrzeugverfolgung'" routerLinkActive="active-icon">

                        <svg viewBox="0 0 24 24" class="menu-icon">
                            <path
                                d="M5,11L6.5,6.5H17.5L19,11M17.5,16A1.5,1.5 0 0,1 16,14.5A1.5,1.5 0 0,1 17.5,13A1.5,1.5 0 0,1 19,14.5A1.5,1.5 0 0,1 17.5,16M6.5,16A1.5,1.5 0 0,1 5,14.5A1.5,1.5 0 0,1 6.5,13A1.5,1.5 0 0,1 8,14.5A1.5,1.5 0 0,1 6.5,16M18.92,6C18.72,5.42 18.16,5 17.5,5H6.5C5.84,5 5.28,5.42 5.08,6L3,12V20A1,1 0 0,0 4,21H5A1,1 0 0,0 6,20V19H18V20A1,1 0 0,0 19,21H20A1,1 0 0,0 21,20V12L18.92,6Z" />
                        </svg>
                        <span>Fahrzeugverfolgung</span>
                    </a> -->
                    <!--
                        <a [routerLink]="'/'+clientId+'/strassenkontrolle'" routerLinkActive="active-icon">
                    -->
                    <!-- <a>
                        <svg viewBox="0 0 24 24" class="menu-icon">
                            <path
                                d="M18.1,4.8C18,4.3 17.6,4 17.1,4H13L13.2,7H10.8L11,4H6.8C6.3,4 5.9,4.4 5.8,4.8L3.1,18.8C3,19.4 3.5,20 4.1,20H10L10.3,15H13.7L14,20H19.8C20.4,20 20.9,19.4 20.8,18.8L18.1,4.8M10.4,13L10.6,9H13.2L13.4,13H10.4Z" />
                        </svg>
                        <span>Straßenkontrolle</span>
                    </a>
                    
                    <a [routerLink]="'/'+clientId+'/baumkontrolle'" routerLinkActive="active-icon" >
                        <svg viewBox="0 0 24 24" class="menu-icon">
                            <path
                                d="M11,21V16.74C10.53,16.91 10.03,17 9.5,17C7,17 5,15 5,12.5C5,11.23 5.5,10.09 6.36,9.27C6.13,8.73 6,8.13 6,7.5C6,5 8,3 10.5,3C12.06,3 13.44,3.8 14.25,5C14.33,5 14.41,5 14.5,5A5.5,5.5 0 0,1 20,10.5A5.5,5.5 0 0,1 14.5,16C14,16 13.5,15.93 13,15.79V21H11Z" />
                        </svg>
                        <span>Baumkontrolle</span>
                    </a> -->
                    <!-- <a> -->
                    <!-- <a [routerLink]="'/'+clientId+'/spielplatzkontrolle'" routerLinkActive="active-icon"> 
                        <svg viewBox="0 0 24 24" class="menu-icon">
                            <path
                                d="M2,13H4V15H6V13H8V15H10V13H12V15H14V10L17,7V1H19L23,3L19,5V7L22,10V22H11V19A2,2 0 0,0 9,17A2,2 0 0,0 7,19V22H2V13M18,10C17.45,10 17,10.54 17,11.2V13H19V11.2C19,10.54 18.55,10 18,10Z" />
                        </svg>
                        <span>Spielplatzkontrolle</span>
                    </a>
                </div>
            </div>
        </div>
    </nav>
</header> -->

<mat-toolbar class="header">
    <button #sideNavToggleButton color="primary" (click)="setSideNavState()" mat-icon-button class="burgerIcon">
        <mat-icon>menu</mat-icon>
    </button>
    <a [routerLink]="'/'+clientId+'/dashboard'" class="navIcon">
        <img src="assets/img/geoDummy-logo.png">
    </a>

    <div class="navWrapper">
        <mat-select class="navMain" [(value)]="selected" floatlLabel="never">
            <mat-select-trigger>
                <mat-icon color="primary" svgIcon="{{selected}}"></mat-icon> {{selected}}
            </mat-select-trigger>
            <mat-option value="fahrzeugverfolgung" [routerLink]="'/'+clientId+'/fahrzeugverfolgung'" routerLinkActive="active-icon">
                <mat-icon svgIcon="fahrzeugverfolgung"></mat-icon>
                <span>Fahrzeugverfolgung</span>
            </mat-option>
            <mat-option value="straßenkontrolle" [routerLink]="'/'+clientId+'/strassenkontrolle'" routerLinkActive="active-icon">
                <mat-icon svgIcon="straßenkontrolle"></mat-icon>
                <span>Straßenkontrolle</span>
            </mat-option>
            <mat-option value="baumkontrolle" [routerLink]="'/'+clientId+'/baumkontrolle'" routerLinkActive="active-icon">
                <mat-icon svgIcon="baumkontrolle"></mat-icon>
                <span>Baumkontrolle</span>
            </mat-option>
            <mat-option value="spielplatzkontrolle" [routerLink]="'/'+clientId+'/spielplatzkontrolle'" routerLinkActive="active-icon">
                <mat-icon svgIcon="spielplatzkontrolle"></mat-icon>
                <span>Spielplatzkontrolle</span>
            </mat-option>
        </mat-select>
    </div>
    
    <mat-form-field class="search-bar mat-form-field-appearance-fill" appearance="fill" style="flex: 1 1 auto;">
        <input matInput type="text" [(ngModel)]="value" (input)="onSearchInput($event.target.value)" [placeholder]="placeholder">
    
        <div matSuffix style="display: flex;">
          <button
            *ngIf="value"
            mat-icon-button
            aria-label="Clear"
            (click)="
              value='';
              onSearchInput('');
            "
          >
            <mat-icon>close</mat-icon>
          </button>
          <div class="searchIcon">
            <span class="material-symbols-outlined">
              search
            </span>
          </div>
        </div>
    </mat-form-field>
    
    <div class="userDisplay">
        <mat-icon class="material-symbols-outlined">person</mat-icon>Mustermann<span>/ {{clientId}}</span>
    </div>
    <button color="primary" mat-icon-button [matMenuTriggerFor]="menuFunctions">
        <mat-icon>more_vert</mat-icon>
      </button>
      <mat-menu #menuFunctions="matMenu">
        <button mat-menu-item>
            <mat-icon class="material-symbols-outlined">settings</mat-icon>
            <span>{{administration}}</span>
        </button>
        <button mat-menu-item>
            <mat-icon class="material-symbols-outlined">contact_support</mat-icon>
            <span>{{contact}}</span>
        </button>
        <button mat-menu-item>
            <mat-icon class="material-symbols-outlined">info</mat-icon>
            <span>{{impressum}}</span>
        </button>
        <a href="api/logout" mat-menu-item>
            <mat-icon class="material-symbols-outlined">logout</mat-icon>
            <span>Abmelden</span>
        </a>
      </mat-menu>      
</mat-toolbar>


<!-- // Alter Header, der sich beim Scrollen in der Breite angepasst hat 
<header [@resizeElement]="isScrolled ? 'side':'full'">
        <nav role="navigation" aria-label="main navigation">
            <div class="nav-wrapper" [ngClass]="isScrolled ? 'column-nav' : 'row-nav'">
            <div [ngClass]="isScrolled ? 'row-nav' : 'column-nav'">
                <div class="nav-icon">
                    <a [routerLink]="'/'+clientId+'/dashboard'">
                        <img src="assets/img/geoDummy-logo.png">
                    </a>
                </div>
                <div class="user-display">
                    <p>User: {{clientId}} | <a class="logout" href="api/logout">Logout</a></p>
                </div>
            </div>
            <div class="nav-main">
                <div class="nav-list">
                    
                    <a [routerLink]="'/'+clientId+'/fahrzeugverfolgung'" routerLinkActive="active-icon">
                    
                        <svg viewBox="0 0 24 24" class="menu-icon" >
                        <path d="M5,11L6.5,6.5H17.5L19,11M17.5,16A1.5,1.5 0 0,1 16,14.5A1.5,1.5 0 0,1 17.5,13A1.5,1.5 0 0,1 19,14.5A1.5,1.5 0 0,1 17.5,16M6.5,16A1.5,1.5 0 0,1 5,14.5A1.5,1.5 0 0,1 6.5,13A1.5,1.5 0 0,1 8,14.5A1.5,1.5 0 0,1 6.5,16M18.92,6C18.72,5.42 18.16,5 17.5,5H6.5C5.84,5 5.28,5.42 5.08,6L3,12V20A1,1 0 0,0 4,21H5A1,1 0 0,0 6,20V19H18V20A1,1 0 0,0 19,21H20A1,1 0 0,0 21,20V12L18.92,6Z" />
                        </svg>
                        <span [ngClass]="isScrolled ? 'hide-nav-text' : 'show-nav-text'">Fahrzeugverfolgung</span>
                    </a>

                    <a>
                        <svg viewBox="0 0 24 24" class="menu-icon">
                            <path d="M18.1,4.8C18,4.3 17.6,4 17.1,4H13L13.2,7H10.8L11,4H6.8C6.3,4 5.9,4.4 5.8,4.8L3.1,18.8C3,19.4 3.5,20 4.1,20H10L10.3,15H13.7L14,20H19.8C20.4,20 20.9,19.4 20.8,18.8L18.1,4.8M10.4,13L10.6,9H13.2L13.4,13H10.4Z" />
                        </svg>
                        <span [ngClass]="isScrolled ? 'hide-nav-text' : 'show-nav-text'">Straßenkontrolle</span>
                    </a>

                    <a>
                        <svg viewBox="0 0 24 24" class="menu-icon">
                            <path d="M11,21V16.74C10.53,16.91 10.03,17 9.5,17C7,17 5,15 5,12.5C5,11.23 5.5,10.09 6.36,9.27C6.13,8.73 6,8.13 6,7.5C6,5 8,3 10.5,3C12.06,3 13.44,3.8 14.25,5C14.33,5 14.41,5 14.5,5A5.5,5.5 0 0,1 20,10.5A5.5,5.5 0 0,1 14.5,16C14,16 13.5,15.93 13,15.79V21H11Z" />
                        </svg>
                        <span [ngClass]="isScrolled ? 'hide-nav-text' : 'show-nav-text'">Baumkontrolle</span>
                    </a>
                    <a [routerLink]="'/'+clientId+'/spielplatzkontrolle'" routerLinkActive="active-icon">
                        <svg viewBox="0 0 24 24" class="menu-icon">
                            <path d="M2,13H4V15H6V13H8V15H10V13H12V15H14V10L17,7V1H19L23,3L19,5V7L22,10V22H11V19A2,2 0 0,0 9,17A2,2 0 0,0 7,19V22H2V13M18,10C17.45,10 17,10.54 17,11.2V13H19V11.2C19,10.54 18.55,10 18,10Z" />
                        </svg>
                        <span [ngClass]="isScrolled ? 'hide-nav-text' : 'show-nav-text'">Spielplatzkontrolle</span>
                    </a>
                </div>
            </div>
        </div>
        </nav>
    </header>
-->