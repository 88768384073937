import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import gql from "graphql-tag";

export const getAllTreeMeasuresData = gql`
    query getAll ($args: Arguments) {
        Tree_Measures(args: $args) {
            id,
            damage {
                id,
                start,
                end,
                damage_class {
                    id,
                    kz,
                    description,
                    subject {
                        id,
                        shortcut,
                        description,
                        note,
                        creation_date,
                        creation_user,
                        modify_date,
                        modify_user,
                        canDelete,
                    },
                    note,
                    creation_date,
                    creation_user,
                    modify_date,
                    modify_user,
                    canDelete,
                },
                damage_degree {
                    id,
                    shortcut,
                    description,
                    note,
                    creation_date,
                    creation_user,
                    modify_date,
                    modify_user,
                    canDelete,
                },
                object {
                    id,
                    number,
                    shortcut,
                    description,
                    age,
                    deadline,
                    type {
                        id,
                        icon,
                        shortcut,
                        description,
                        object_class {
                            id,
                            icon,
                            shortcut,
                            description,
                            subject {
                                id,
                                shortcut,
                                description,
                                note,
                                creation_date,
                                creation_user,
                                modify_date,
                                modify_user,
                                canDelete,
                            },
                            note,
                            creation_date,
                            creation_user,
                            modify_date,
                            modify_user,
                            canDelete,
                        },
                        note,
                        creation_date,
                        creation_user,
                        modify_date,
                        modify_user,
                        canDelete,
                    },
                    supplier {
                        id,
                        number,
                        name1,
                        name2,
                        address{
                            id,
                            country,
                            postcode,
                            location,
                            district,
                            street,
                            house_number,
                            latitude,
                            longitude,        
                        },
                        note,
                        creation_date,
                        creation_user,
                        modify_date,
                        modify_user,
                        canDelete,
                    },
                    product,
                    address {
                        id,
                        country,
                        postcode,
                        location,
                        district,
                        street,
                        house_number,
                        latitude,
                        longitude,    
                    },
                    coordinate {
                        id,
                        longitude,
                        latitude,
                        altitude,
                        timestamp,
                        creation_date,
                        creation_user,
                        modify_date,
                        modify_user,
                        canDelete
                    },
                    note,
                    creation_date,
                    creation_user,
                    modify_date,
                    modify_user,
                    canDelete,
                },
                priority {
                    id,
                    shortcut,
                    description,
                    points,
                    color,
                    note,
                    creation_date,
                    creation_user,
                    modify_date,
                    modify_user,
                    canDelete,
                },
                coordinate {
                    id,
                    longitude,
                    latitude,
                    altitude,
                    timestamp,
                    creation_date,
                    creation_user,
                    modify_date,
                    modify_user,
                    canDelete
                },
                assignment {
                    id,
                    reason,
                    start,
                    end,
                    duration,
                    vehicle {
                        id,
                        license_plate_number,
                        vehicle_type {
                            id,
                            kz,
                            description,
                            note,
                            creation_date,
                            creation_user,
                            modify_date,
                            modify_user,
                            canDelete,
                        },
                        description,
                        group,
                        telephone_number,
                        active,
                        travel_mode,
                        history,
                        note,
                        creation_date,
                        creation_user,
                        modify_date,
                        modify_user,
                        canDelete,
                    },
                    type {
                        id,
                        shortcut,
                        description,
                        note,
                        creation_date,
                        creation_user,
                        modify_date,
                        modify_user,
                        canDelete,
                    }
                    note,
                    creation_date,
                    creation_user,
                    modify_date,
                    modify_user,
                    canDelete,
                },
                street {
                    id,
                    osm_id,
                    name,
                    coordinate {
                        id,
                        longitude,
                        latitude,
                        altitude,
                        timestamp,
                        creation_date,
                        creation_user,
                        modify_date,
                        modify_user,
                        canDelete
                    },
                    creation_date,
                    creation_user,
                    modify_date,
                    modify_user,
                    number,
                    postcode,
                    location,
                    canDelete,
                },
                original_damage {
                    id,
                },
                note,
                order_number,
                unit,
                amount,
                status,
                exported,
                creation_date,
                creation_user,
                modify_date,
                modify_user,
                canDelete,
            },
            tree_measure_listing {
                id,
                number,
                measure {
                    id,
                },
                note,
                creation_date,
                creation_user,
                modify_date,
                modify_user,
                canDelete,
            },
            priority {
                id,
                shortcut,
                description,
                points,
                color,
                note,
                creation_date,
                creation_user,
                modify_date,
                modify_user,
                canDelete,
            },
            person_responsible,
            deadline,
            note,
            status,
            finished,
            exported,
            creation_date,
            creation_user,
            modify_date,
            modify_user,
            canDelete
        }
    }
`

export const getSingleTreeMeasuresData = gql`
    query getSingleTreeDamageData (
        $id: Int
    ) {
        Tree_Measures (id: $id){
            id,
            damage {
                id,
                start,
                end,
                damage_class {
                    id,
                    kz,
                    description,
                    subject {
                        id,
                        shortcut,
                        description,
                        note,
                        creation_date,
                        creation_user,
                        modify_date,
                        modify_user,
                        canDelete,
                    },
                    note,
                    creation_date,
                    creation_user,
                    modify_date,
                    modify_user,
                    canDelete,
                },
                damage_degree {
                    id,
                    shortcut,
                    description,
                    note,
                    creation_date,
                    creation_user,
                    modify_date,
                    modify_user,
                    canDelete,
                },
                object {
                    id,
                    number,
                    shortcut,
                    description,
                    age,
                    deadline,
                    type {
                        id,
                        icon,
                        shortcut,
                        description,
                        object_class {
                            id,
                            icon,
                            shortcut,
                            description,
                            subject {
                                id,
                                shortcut,
                                description,
                                note,
                                creation_date,
                                creation_user,
                                modify_date,
                                modify_user,
                                canDelete,
                            },
                            note,
                            creation_date,
                            creation_user,
                            modify_date,
                            modify_user,
                            canDelete,
                        },
                        note,
                        creation_date,
                        creation_user,
                        modify_date,
                        modify_user,
                        canDelete,
                    },
                    supplier {
                        id,
                        number,
                        name1,
                        name2,
                        address{
                            id,
                            country,
                            postcode,
                            location,
                            district,
                            street,
                            house_number,
                            latitude,
                            longitude,        
                        },
                        note,
                        creation_date,
                        creation_user,
                        modify_date,
                        modify_user,
                        canDelete,
                    },
                    product,
                    address {
                        id,
                        country,
                        postcode,
                        location,
                        district,
                        street,
                        house_number,
                        latitude,
                        longitude,    
                    },
                    coordinate {
                        id,
                        longitude,
                        latitude,
                        altitude,
                        timestamp,
                        creation_date,
                        creation_user,
                        modify_date,
                        modify_user,
                        canDelete
                    },
                    note,
                    creation_date,
                    creation_user,
                    modify_date,
                    modify_user,
                    canDelete,
                },
                priority {
                    id,
                    shortcut,
                    description,
                    points,
                    color,
                    note,
                    creation_date,
                    creation_user,
                    modify_date,
                    modify_user,
                    canDelete,
                },
                coordinate {
                    id,
                    longitude,
                    latitude,
                    altitude,
                    timestamp,
                    creation_date,
                    creation_user,
                    modify_date,
                    modify_user,
                    canDelete
                },
                assignment {
                    id,
                    reason,
                    start,
                    end,
                    duration,
                    vehicle {
                        id,
                        license_plate_number,
                        vehicle_type {
                            id,
                            kz,
                            description,
                            note,
                            creation_date,
                            creation_user,
                            modify_date,
                            modify_user,
                            canDelete,
                        },
                        description,
                        group,
                        telephone_number,
                        active,
                        travel_mode,
                        history,
                        note,
                        creation_date,
                        creation_user,
                        modify_date,
                        modify_user,
                        canDelete,
                    },
                    type {
                        id,
                        shortcut,
                        description,
                        note,
                        creation_date,
                        creation_user,
                        modify_date,
                        modify_user,
                        canDelete,
                    }
                    note,
                    creation_date,
                    creation_user,
                    modify_date,
                    modify_user,
                    canDelete,
                },
                street {
                    id,
                    osm_id,
                    name,
                    coordinate {
                        id,
                        longitude,
                        latitude,
                        altitude,
                        timestamp,
                        creation_date,
                        creation_user,
                        modify_date,
                        modify_user,
                        canDelete
                    },
                    creation_date,
                    creation_user,
                    modify_date,
                    modify_user,
                    number,
                    postcode,
                    location,
                    canDelete,
                },
                original_damage {
                    id,
                },
                note,
                order_number,
                unit,
                amount,
                status,
                exported,
                creation_date,
                creation_user,
                modify_date,
                modify_user,
                canDelete,
            },
            tree_measure_listing {
                id,
                number,
                measure {
                    id,
                },
                note,
                creation_date,
                creation_user,
                modify_date,
                modify_user,
                canDelete,
            },
            priority {
                id,
                shortcut,
                description,
                points,
                color,
                note,
                creation_date,
                creation_user,
                modify_date,
                modify_user,
                canDelete,
            },
            person_responsible,
            deadline,
            note,
            status,
            finished,
            exported,
            creation_date,
            creation_user,
            modify_date,
            modify_user,
            canDelete
        }
    }
`

export const updateTreeMeasuresData = gql`
    mutation update_Tree_Measures (
        $id: Int,
        $damage: Int,
        $tree_measure_listing: Int,
        $priority: String,
        $person_responsible: Int,
        $deadline: String,
        $note: String,
        $status: Int,
        $finished: String,
        $exported: Int
    ) {
        update_Tree_Measures(
            id: $id,
            damage: $damage,
            tree_measure_listing: $tree_measure_listing,
            priority: $priority,
            person_responsible: $person_responsible,
            deadline: $deadline,
            note: $note,
            status: $status,
            finished: $finished,
            exported: $exported
        ){
            result,
            error
        }
    }
`

export const addTreeMeasuresData = gql`
    mutation add_Tree_Measures(
        $id: Int,
        $damage: Int,
        $tree_measure_listing: Int,
        $priority: String,
        $person_responsible: Int,
        $deadline: String,
        $note: String,
        $status: Int,
        $finished: String,
        $exported: Int
    ) {
        add_Tree_Measures(
            id: $id,
            damage: $damage,
            tree_measure_listing: $tree_measure_listing,
            priority: $priority,
            person_responsible: $person_responsible,
            deadline: $deadline,
            note: $note,
            status: $status,
            finished: $finished,
            exported: $exported
        ){
            result,
            error
        }
    }
`

export const deleteTreeMeasuresData = gql`
    mutation delete_Tree_Measures($id: Int!) {
        delete_Tree_Measures(id: $id){
            result,
            error
        }
    }
`

@NgModule({
    declarations: [],
    imports: [CommonModule]
  })
export class TreeMeasuresQueriesModule {}