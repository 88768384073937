import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-strassenkontrolle',
  templateUrl: './strassenkontrolle.component.html',
  styleUrls: ['./strassenkontrolle.component.sass']
})
export class StrassenkontrolleComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
