import { NgModule } from "@angular/core";
import { AmBadgeDirective } from "./am-badge.directive";
import { AmCardHeaderDirective } from "./am-card-header.directive";

@NgModule({
    declarations: [
        AmCardHeaderDirective,
        AmBadgeDirective,
    ],
    imports: [],
    exports: [
        AmCardHeaderDirective,
        AmBadgeDirective,
    ],
  })
  export class AmDirectivesModule {}