// TODO select image from image preview and go to next/previous
import { Component, OnInit, ChangeDetectionStrategy, Input, ViewChild } from '@angular/core';
import { HttpService } from 'src/app/services/http.service';

@Component({
  selector: 'app-image-diashow',
  templateUrl: './image-diashow.component.html',
  styleUrls: ['./image-diashow.component.sass'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ImageDiashowComponent implements OnInit {
  @Input() record;

  @ViewChild('currentImageElement') currentImageElement;

  public currentImage: string;
  public imagePreviews: Array<string>

  constructor(private httpService: HttpService) { }

  ngOnInit(): void {
    console.log(this.record);
    this.currentImage = this.httpService.buildImageLink(this.record[0].id);

    this.imagePreviews = [];
    this.record.forEach(image => {
      this.imagePreviews.push(this.httpService.buildImageLink(image.id, true))
    });
  }

}

