// import { Component, OnInit, Input, OnDestroy } from "@angular/core";
// import { PlaygroundDefectsService } from "src/app/services/playground/playground-defects.service";
// import { DefaultValuesService } from "src/app/services/playground/administration/default-values.service";
// import {combineLatest} from 'rxjs';

// @Component({
//   selector: "app-open-defects-display",
//   templateUrl: "./open-defects-display.component.html",
//   styleUrls: ["./open-defects-display.component.sass"]
// })
// export class OpenDefectsDisplayComponent implements OnInit, OnDestroy {
//   @Input() playgroundID: number;
//   @Input() justShowProblems?: boolean = false;
//   @Input() inspectionData?: any;

//   defectCountObject: any;

//   allDefects: number;
//   openDefects: number;
//   statusObject = {};

//   parsedStatusObject = {};

//   defaultValueSubscription: any;
//   defectsSubscription: any;

//   serviceSubscription: any;

//   /**
//    *
//    * @param defectService
//    */
//   constructor(
//     private defectService: PlaygroundDefectsService,
//     private defaultValueService: DefaultValuesService
//   ) {}

//   ngOnInit(): void {

//     if (this.inspectionData) {

//       this.serviceSubscription = this.defaultValueService.defaultValues.subscribe( result => {

//         const ivkStatusObject = result["IVK_Status"];
//         this.parsedStatusObject = this._parseStatusValues(ivkStatusObject);

//         let counts = {}
//         for (let i = 0; i < this.inspectionData.length; i++) {
//           if (counts[this.inspectionData[i].status]) {
//             counts[this.inspectionData[i].status]++;
//           } else {
//             counts[this.inspectionData[i].status] = 1;
//           }
//         }

//         for (let entry in this.parsedStatusObject) {
//           this.parsedStatusObject[entry].count = counts[this.parsedStatusObject[entry].value]
//         }

//         this.allDefects = this.inspectionData.length;
//         this.openDefects = this.allDefects - counts["ohne Beanstandung"];

//         this.statusObject = this.parsedStatusObject;
//       });
//     } else {
//       // this.serviceSubscription = combineLatest(this.defaultValueService.defaultValues, this.defectService.allOpenDefectsCount).subscribe( result => {
//       //   if (result) {

//       //     // console.log(result);

//       //     const ivkStatusObject = result[0]["IVK_Status"];
//       //     const openDefects = result[1];
//       //     if (ivkStatusObject && openDefects) {
//       //       this.parsedStatusObject = this._parseStatusValues(ivkStatusObject);
//       //       let filteredCountObject = this._filterDefectObject(openDefects);
//       //       if (filteredCountObject) {
//       //         //console.log(filteredCountObject)
//       //         // ACHTUNG: Das hier wird brechen, sobald der unwahrscheinliche Fall eintritt, dass jemand die Kategorie umbenennt.
//       //         // Eine bessere Lösung gibt es aber derzeit nicht.
//       //         this.allDefects = filteredCountObject.counts.all;
//       //         this.openDefects = filteredCountObject.counts.all - filteredCountObject.counts["ohne Beanstandung"];
//       //         this.statusObject = this._setStatusCount(this.parsedStatusObject, filteredCountObject);
//       //       }
//       //     }
//       //     // console.log(this.statusObject);
//       //   }
//       // });
//     }
//   }

//   ngOnDestroy(): void {
//     this.serviceSubscription.unsubscribe();
//   }

//   _filterDefectObject(openDefects) {
//     let returnObject;
//     let dataObject;
//     if (Array.isArray(openDefects)) {
//       dataObject = openDefects.filter(item => {
//         return item.playground === this.playgroundID;
//       });
//     }
//     if (dataObject.length > 0) {
//       returnObject = dataObject[0];
//     }
//     return returnObject;
//   }

//   _parseStatusValues(statusObject) {
//     let parsedObject = statusObject.map(item => {
//       return {value: item["value"], setting: item["setting"]};
//     });
//     return parsedObject;
//   }

//   _setStatusCount(statusValueObject, countObject?) {
//     for (let i = 0; i < statusValueObject.length; i++) {
//       let valueData = statusValueObject[i].value;
//       if (countObject.counts[valueData]) {
//         statusValueObject[i]["count"] = countObject.counts[valueData];
//       }
//     }
//     return statusValueObject;
//   }

//   showThisValue(state) {
//     if (this.justShowProblems) {
//       if (state === "ohne Beanstandung") {
//         return false;
//       }
//     }
//     return true;
//   }
// }

import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { Subject } from "rxjs";
import { DefaultValuesService } from "src/app/services/playground/administration/default-values.service";
import { PlaygroundDefectsService } from "src/app/services/playground/playground-defects.service";

@Component({
  selector: "app-open-defects-display",
  templateUrl: "./open-defects-display.component.html",
  styleUrls: ["./open-defects-display.component.sass"],
})
export class OpenDefectsDisplayComponent implements OnInit, OnDestroy {
  @Input() playgroundID: number;

  public openDefectsCount: number;
  public openDefects: object = {};
  public defectStats: string;

  constructor(
    private playgroundDefectsDataService: PlaygroundDefectsService,
    private defaultValuesDataService: DefaultValuesService
  ) {}

  ngOnInit(): void {
    this.playgroundDefectsDataService
      .getOpenDefectsDataOfPlayground(this.playgroundID)
      .subscribe((result) => {
        this.openDefectsCount = result.length;

        let openDefect = {};
        result.forEach(element => {
          if(!openDefect[element.status]){openDefect[element.status] = []}
          openDefect[element.status].push(element);
        });
        this.openDefects = openDefect;
      });

    this.defaultValuesDataService
      .getAllDefaultValueData()
      .subscribe((result) => {
        this.defectStats = result.IVK_Status;
      });
  }
  ngOnDestroy(): void {}
}
